import { connect } from "react-redux";
import { updateActiveAgent } from "../../../actions/user";
import ClientAgentsModal from "../ClientAgentsModal";

function select({ user }) {
  return {
    agents: user.agents,
    activeAgentId: user.activeAgentId
  };
}

const actions = {
  updateActiveAgent
};

export default connect(select, actions)(ClientAgentsModal);
