import styled from "styled-components";

export const NavLayout = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto 2rem;
`;

export const ContentLayout = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
`;

export const Content = styled.div`
  width: 100%;
`;

export const RouteHeading = styled.h1`
  ${({ theme }) => theme.text.h3};
  margin-bottom: 1.6rem;
  text-align: center;
`;

export const CardLayout = styled.div`
  display: grid;
  grid-template-columns: 50fr 50fr;
  grid-column-gap: 2.4rem;
  grid-row-gap: 2.4rem;

  @media (max-width: 700px) {
    grid-template-columns: 100fr;
  }
`;

export const LoadingLayout = styled.div`
  position: absolute;
  width: 100%;
  max-width: 76.8rem;
  z-index: 10;
`;

export const Empty = styled.div`
  font-size: 1.8rem;
  line-height: 2.2rem;
  text-align: center;
  padding: 5.2rem 0;
  border-radius: 0.3rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.greyLighter};
  color: ${({ theme }) => theme.colors.blackLight};
  background: white;
`;
