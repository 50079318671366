import { breakpoints, colors, getStatusColor, shadows } from "@wrstudios/theme";
import styled from "styled-components";

export const Listing = styled.div`
  padding: 1.2rem;
  box-shadow: ${shadows.raised};
  border-radius: 0.8rem;
`;

export const ListingGrid = styled.div`
  display: flex;
  margin: -0.8rem;
`;

export const ListingCell = styled.div`
  padding: 0.8rem;

  &:nth-child(2) {
    min-width: 0;
  }
`;

export const ListingImage = styled.div`
  width: 8rem;
  height: 6rem;
  background: ${colors.gray[200]} center center / cover;
  border-radius: 0.3rem;
`;

export const ListingAddress = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.2rem;
  margin-bottom: 0.2rem;
  color: ${colors.gray[900]};

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 1.7rem;
    line-height: 2.4rem;
  }
`;

export const ListingDetails = styled.div`
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-bottom: 0.4rem;
  color: ${colors.gray[800]};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 1.3rem;
    line-height: 1.7rem;
  }
`;

export const ListingPrice = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6rem;
  color: ${colors.gray[900]};

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 1.3rem;
    line-height: 1.7rem;
  }
`;

export const ListingStatus = styled(ListingPrice)`
  text-transform: uppercase;
  color: ${({ status }) => getStatusColor(status)};
`;

export const ListingDom = styled(ListingPrice)`
  text-transform: uppercase;
  color: ${colors.gray[800]};
`;
