import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "@wrstudios/components";
import IconError from "../../icons/IconError";
import Input from "../../common/Input";
import Label from "../../common/Label";
import CasSSO from "../../common/CasSSO";
import LwSSO from "../../common/LwSSO";
// import MlsSSO from "../../common/MlsSSO";
import IconArrowLeft from "../../icons/IconArrowLeft";
import IconCloudStreamsVertical from "../../icons/IconCloudStreamsVertical";
import IconArrowRightLong from "../../icons/IconArrowRightLong";
import IconCASHorizontal from "../../icons/IconCASHorizontal";
import {
  Container,
  Header,
  CASLink,
  Arrow,
  CASLogo,
  LearnMore,
  Logo,
  Content,
  Title,
  Divider,
  FormContainer,
  FormGroup,
  Error,
  SubmitContainer,
  Submit,
  Reset,
  Nav,
  NavItems,
  NavItem
} from "./styled/login-route";

function LoginRoute({ isLoggingIn, loginError, emitAction, login }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    login({ email, password, isImpersonating: false });
  };

  useEffect(() => {
    emitAction({ type: "RESET_LOGIN_TO_DEFAULT" });
    return () => {
      emitAction({ type: "SET_FRIENDLY_REDIRECT", payload: "" });
    };
  }, [emitAction]);

  return (
    <Container>
      <Header>
        <CASLink
          href="https://cloudagentsuite.com/"
          target="_blank"
          rel="noopener noreferrer"
          title="Cloud Agent Suite">
          <Arrow>
            <IconArrowLeft />
          </Arrow>
          <CASLogo>
            <IconCASHorizontal />
          </CASLogo>
        </CASLink>
        <LearnMore
          href="https://cloudagentsuite.com/tools/streams"
          target="_blank"
          rel="noopener noreferrer">
          Learn more about Cloud Streams
          <IconArrowRightLong />
        </LearnMore>
      </Header>
      <Content>
        <Logo>
          <IconCloudStreamsVertical />
        </Logo>
        <FormContainer>
          <Title>Sign In</Title>
          <FormGroup>
            <CasSSO href="https://cloudagentsuite.com/app/launchpad" />
          </FormGroup>
          <FormGroup>
            <LwSSO />
          </FormGroup>
          {/* <FormGroup>
            <MlsSSO href="#" />
          </FormGroup> */}
          <FormGroup>
            <Divider>
              <span>Or</span>
            </Divider>
          </FormGroup>

          <form onSubmit={handleSubmit}>
            <FormGroup>
              <Label htmlFor="email">Email</Label>
              <Input
                type="email"
                id="email"
                placeholder="john@acmerealty.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="password">Password</Label>
              <Input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormGroup>
            {loginError && (
              <FormGroup>
                <Error role="alert">
                  <IconError />
                  {loginError}
                </Error>
              </FormGroup>
            )}
            <SubmitContainer>
              <Submit disabled={isLoggingIn}>
                {isLoggingIn ? "Signing In..." : "Sign In"}
              </Submit>
            </SubmitContainer>
          </form>
        </FormContainer>
        <Reset>
          <Link to="/password/forgot">Forgot Password?</Link>
        </Reset>
      </Content>
      <Nav>
        <NavItems>
          <NavItem>
            <a
              href="https://cloudagentsuite.com/tos"
              target="_blank"
              rel="noopener noreferrer">
              Terms of Service
            </a>
          </NavItem>
          <NavItem>
            <a
              href="https://www.wrstudios.com/privacy_policy"
              target="_blank"
              rel="noopener noreferrer">
              Privacy
            </a>
          </NavItem>
          <NavItem>
            <a
              href="https://www.wrstudios.com/dmca_policy"
              target="_blank"
              rel="noopener noreferrer">
              DMCA
            </a>
          </NavItem>
        </NavItems>
      </Nav>
    </Container>
  );
}

LoginRoute.propTypes = {
  login: PropTypes.func.isRequired,
  emitAction: PropTypes.func.isRequired,
  isLoggingIn: PropTypes.bool.isRequired,
  loginError: PropTypes.string
};

export default LoginRoute;
