import { connect } from "react-redux";
import { getClientSocialData } from "../../../actions/client";
import { SOCIAL_ICONS, SOCIAL_PROFILE_STATUS } from "../../../modules/client";
import ClientProfileSocial from "../ClientProfileSocial";

function select({ clients }, { clientId }) {
  const client = clients.byId[clientId] || {};
  const socialProfiles = client.socialProfiles || [];
  const supportedProfiles = socialProfiles.filter(
    ({ type }) => SOCIAL_ICONS[type]
  );

  const socialProfileStatusNotFetched =
    client.socialProfileStatus === SOCIAL_PROFILE_STATUS.NOT_FETCHED;
  const socialProfileStatusProcessing =
    client.socialProfileStatus === SOCIAL_PROFILE_STATUS.PROCESSING;
  const socialProfileStatusFound =
    client.socialProfileStatus === SOCIAL_PROFILE_STATUS.FOUND;
  const socialProflileStatusNotFound =
    client.socialProfileStatus === SOCIAL_PROFILE_STATUS.NOT_FOUND ||
    (client.socialProfileStatus === SOCIAL_PROFILE_STATUS.FOUND &&
      !supportedProfiles.length);

  return {
    id: clientId || "",
    socialProfiles: supportedProfiles,
    hasSocialProfiles: !!socialProfiles.length,
    socialProfileStatusNotFetched,
    socialProfileStatusProcessing,
    socialProfileStatusFound,
    socialProflileStatusNotFound,
    isGettingSocialData: clients.isGettingSocialData || false,
    hasSocialDataError: clients.hasSocialDataError || false
  };
}

const actions = {
  getClientSocialData
};

export default connect(select, actions)(ClientProfileSocial);
