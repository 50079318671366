import React from "react";
import { PhotoCardLoading } from "@wrstudios/components";
import {
  CardLayout,
  LoadingLayout
} from "../routes/clients/styled/client-route";

function ClientStreamsLoading() {
  return (
    <LoadingLayout>
      <CardLayout>
        {new Array(18).fill().map((_, index) => (
          <PhotoCardLoading key={index} />
        ))}
      </CardLayout>
    </LoadingLayout>
  );
}

export default ClientStreamsLoading;
