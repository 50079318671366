import React from "react";
import PropTypes from "prop-types";
import ClientProfileContactInfo from "./connected/ClientProfileContactInfo";
import ClientProfileSocial from "./connected/ClientProfileSocial";
// import ClientProfileSharesWith from "./connected/ClientProfileSharesWith";
import { Container, Card } from "./styled/client-profile";

function ClientProfile({ clientId }) {
  return (
    <Container>
      <Card>
        <ClientProfileContactInfo clientId={clientId} />
        <ClientProfileSocial clientId={clientId} />
        {/* Hidding for now until we can figure out what to do with it. */}
        {/* <ClientProfileSharesWith clientId={clientId} /> */}
      </Card>
    </Container>
  );
}

ClientProfile.propTypes = {
  clientId: PropTypes.string.isRequired
};

export default ClientProfile;
