import {
  ApplicationSwitcher,
  Navbar,
  NavbarAdditionalItems,
  NavbarHelpLink,
  NavbarNavigationBar,
  NavbarUserMenu
} from "@helix/navbar";
import PlatformService from "@lwolf-common/platform-service/build/PlatformService";
import { Link } from "@wrstudios/components";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

export default function NewNavigation({
  isAuthed,
  userName,
  userEmail,
  userAvatar,
  lwaJwt,
  isImpersonating,
  adminUrl,
  logout
}) {
  const { data: appSwitcher } = useAppSwitcher({
    lwaJwt
  });

  return (
    <Navbar dataLwtId="default">
      {isAuthed && (
        <>
          <ApplicationSwitcher
            applicationName="Cloud Streams"
            appSections={appSwitcher?.appSections || []}
            launchpadUrl={process.env.REACT_APP_LW_PLATFORM_URL}
          />
          <NavbarNavigationBar
            navItems={[
              { label: "Activity", url: "/activity" },
              { label: "Streams", url: "/streams" },
              { label: "Clients", url: "/clients" },
              { label: "Liked", url: "/liked" }
            ].filter(Boolean)}
            linkWrapper={({ item }) => <Link to={item.url}>{item.label}</Link>}
          />
          <NavbarAdditionalItems>
            <NavbarHelpLink
              url="https://lonewolf.my.site.com/s/topic/0TO2L0000008JRFWA2/cloud-streams"
              linkWrapper={({ url, label }) => (
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {label}
                </a>
              )}
            />
            <NavbarUserMenu
              id="avatar"
              user={{
                name: userName,
                email: userEmail,
                avatarURL: userAvatar
              }}
              menuItems={[
                { label: "Account Settings", url: "/settings" },
                { label: "Manage Notifications", url: "/subscriptions" },
                { divider: true },

                {
                  label: "Lead Generation",
                  url: "/settings/lead_generation"
                },
                { divider: true },
                {
                  label: "Learning Resources",
                  url: "https://learn.cloudagentsuite.com/videos-streams"
                },
                {
                  label: "Support Center",
                  url: "https://lonewolf.my.site.com/s/topic/0TO2L0000008JRFWA2/cloud-streams"
                },
                { divider: true },
                {
                  label: isImpersonating ? "Back to your account" : "Logout",
                  url: "#logout"
                }
              ].filter((item) => item && !!Object.keys(item).length)}
              linkWrapper={({ item }) => {
                if (item.url === "#logout") {
                  return (
                    <a
                      href={item.url}
                      onClick={(e) => {
                        e.preventDefault();
                        logout();

                        if (isImpersonating) {
                          window.location.href = adminUrl;
                        }
                      }}>
                      {item.label}
                    </a>
                  );
                }

                const isExternal = [
                  "Learning Resources",
                  "Support Center"
                ].includes(item.label);

                if (isExternal) {
                  return (
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noreferrer noopener">
                      {item.label}
                    </a>
                  );
                }

                return <Link to={item.url}>{item.label}</Link>;
              }}
            />
          </NavbarAdditionalItems>
        </>
      )}
    </Navbar>
  );
}

NewNavigation.propTypes = {
  isAuthed: PropTypes.bool,
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  userAvatar: PropTypes.string,
  lwaJwt: PropTypes.string,
  isImpersonating: PropTypes.bool,
  adminUrl: PropTypes.string
};

function useAppSwitcher(currentUser) {
  const { lwaJwt } = currentUser || {};

  const [data, setData] = useState();

  useEffect(() => {
    if (!lwaJwt) {
      return;
    }
    async function initGlobalHeader() {
      try {
        const platformService = await PlatformService.initialize({
          lwDomain: process.env.REACT_APP_LW_URL,
          token: lwaJwt,
          appId: "cs"
        });
        const appSections = await platformService.getAppSections();

        setData({ appSections });
      } catch (error) {
        console.log("error", error);
      }
    }
    initGlobalHeader();
  }, [lwaJwt]);

  return { data };
}
