import React from "react";
import { Container as Layout } from "./activity-types/styled/activity";
import { Container as Activity } from "./styled/activity";
import {
  DummyAvatar,
  DummyText,
  DummyPhoto
} from "./styled/activities-loading";

function ActivitiesLoading() {
  return Array(3)
    .fill()
    .map((_, index) => (
      <Activity key={index}>
        <Layout>
          <DummyAvatar />
          <DummyText />
          <DummyPhoto />
        </Layout>
      </Activity>
    ));
}

export default ActivitiesLoading;
