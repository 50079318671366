import React from "react";

function IconTimes() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
      <path
        fillRule="evenodd"
        d="M8.64.29l.79.78L.79 9.71l-.8-.78L8.65.29z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M0 1.07L.79.3l8.64 8.64-.79.78L0 1.07z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default IconTimes;
