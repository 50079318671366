import React from "react";
import PropTypes from "prop-types";
import { ListingCardLoading } from "@wrstudios/components";
import { Cell } from "./styled/stream-items";
import { Container } from "./styled/stream-items-loading";

function StreamItemsLoading({
  columns,
  isCreating,
  isEditingCriteria,
  isMapShowing
}) {
  return (
    <Container>
      {Array(18)
        .fill()
        .map((_, index) => (
          <Cell key={index} isMapShowing={isMapShowing} columns={columns}>
            <ListingCardLoading
              showFooter={!isCreating && !isEditingCriteria}
            />
          </Cell>
        ))}
    </Container>
  );
}

StreamItemsLoading.propTypes = {
  columns: PropTypes.number.isRequired,
  isCreating: PropTypes.bool.isRequired,
  isEditingCriteria: PropTypes.bool.isRequired,
  isMapShowing: PropTypes.bool.isRequired
};

export default StreamItemsLoading;
