import { connect } from "react-redux";
import StreamFilterBedsOverlay from "../StreamFilterBedsOverlay";

function select({ stream: { filters } }) {
  const beds = filters.byId.beds || {};
  const min = beds.gte;
  const max = beds.lte;

  return {
    min,
    max
  };
}

export default connect(select)(StreamFilterBedsOverlay);
