import styled from "styled-components";

export const Container = styled.div`
  display: flex;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    display: block;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    width: calc(100% + 3.2rem);
    padding: 0 1.6rem 0.8rem;

    > div {
      white-space: nowrap;

      button:last-of-type {
        margin-right: 1.6rem;
      }
    }
  }
`;
