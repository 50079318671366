import { connect } from "react-redux";
import { hasLocationHash } from "@wrstudios/utils";
import StreamItemComment from "../StreamItemComment";

function select({ streamItems: { byId } }, { streamItemId }) {
  const { commentsCount } = byId[streamItemId] || {};
  const hasComments = !!commentsCount;
  const hasCommentsHash = hasLocationHash("comments");

  return {
    commentsCount,
    hasComments,
    hasCommentsHash
  };
}

export default connect(select)(StreamItemComment);
