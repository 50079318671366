import React, { Component } from "react";
import PropTypes from "prop-types";
import { getClients } from "../../modules/stream";
import { AnimatePresence, motion } from "framer-motion";
import ClientStreamsLoading from "./ClientStreamsLoading";
import StreamCard from "../stream/connected/StreamCard";
import StreamCardPokerChips from "../stream/StreamCardPokerChips";
import StreamCardSocial from "../stream/StreamCardSocial";
import { CardLayout } from "../routes/clients/styled/client-route";

class ClientStreams extends Component {
  render() {
    const {
      user,
      streams,
      isLoading,
      hasStreams,
      streamIds,
      deleteStream
    } = this.props;

    return (
      <AnimatePresence initial={!hasStreams}>
        {isLoading && (
          <motion.div key="loading-state" {...animationConfig}>
            <ClientStreamsLoading />
          </motion.div>
        )}
        {hasStreams && (
          <motion.div key="data-state" {...animationConfig}>
            <CardLayout>
              {streamIds.map((streamId) => {
                const stream = streams.byId[streamId] || {};
                const { likesCount, commentsCount } = stream;

                return (
                  <StreamCard
                    key={streamId}
                    streamId={streamId}
                    deleteStream={() => deleteStream({ streamId })}
                    footer={
                      <>
                        <StreamCardPokerChips
                          clients={getClients({ stream, user })}
                        />
                        <StreamCardSocial
                          likes={likesCount}
                          comments={commentsCount}
                        />
                      </>
                    }
                  />
                );
              })}
            </CardLayout>
          </motion.div>
        )}
      </AnimatePresence>
    );
  }

  componentDidMount() {
    this.props.getClientStreams(this.props.clientId);
  }
}

ClientStreams.propTypes = {
  user: PropTypes.object.isRequired,
  streams: PropTypes.object.isRequired,
  streamIds: PropTypes.array.isRequired,
  hasStreams: PropTypes.bool.isRequired,
  deleteStream: PropTypes.func.isRequired,
  getClientStreams: PropTypes.func.isRequired
};

export default ClientStreams;

const animationConfig = {
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
};
