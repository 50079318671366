import { connect } from "react-redux";
import ActivityAvatar from "../ActivityAvatar";

function select({ activities, user }, { activityId }) {
  const activity = activities.byId[activityId] || {};
  const client = activity.client || {};

  return {
    type: activity.type || "",
    clientId: client.id || "",
    clientInitials: client.initials || "",
    clientInitialsColor: client.initialsColor || "",
    clientAvatar: client.avatar || "",
    isAgent: user.isAgent || false
  };
}

export default connect(select)(ActivityAvatar);
