import { connect } from "react-redux";
import { ListingHighlights } from "@wrstudios/components";

function select({ listings: { byId } }, { listingId }) {
  const listing = byId[listingId] || {};

  return {
    primaryBeds: Number(listing.beds),
    primaryBaths: Number(listing.baths),
    primarySqft: Number(listing.sqft),
    primaryGarages: Number(listing.garages),
    primaryYearBuilt: Number(listing.built),
    primaryLotSize: Number(listing.lotSize)
  };
}

export default connect(select)(ListingHighlights);
