import React, { Component } from "react";
import PropTypes from "prop-types";
import FocusTrap from "focus-trap-react";
import { pluralize } from "@wrstudios/utils";
import { Portal } from "@wrstudios/components";
import IconClose from "../icons/IconClose";
import ListingCard from "../listing/connected/ListingCard";
import { getFriendlyType } from "./utils";
import {
  Container,
  Modal,
  Header,
  Close,
  Detail,
  DetailType,
  DetailLink,
  Body,
  Title,
  Text,
  Listings,
  Listing,
  Disclaimer
} from "./styled/activity-modal";

class ActivityModal extends Component {
  state = {
    showModal: false
  };

  render() {
    const {
      type,
      clientName,
      clientEmail,
      listingIds,
      streamItemIds,
      hasStreamItemIds,
      timestamp,
      render
    } = this.props;

    return (
      <>
        {render({ openModal: this.handleOnOpen })}
        {this.state.showModal && (
          <Portal>
            <Container>
              <FocusTrap
                focusTrapOptions={{
                  onDeactivate: this.handleOnClose,
                  clickOutsideDeactivates: true,
                  initialFocus: "#modal"
                }}>
                <Modal id="modal" tabIndex="-1">
                  <Header>
                    <Close onClick={this.handleOnClose}>
                      <IconClose />
                    </Close>
                    <Detail>
                      <DetailType>To:</DetailType> {clientName}{" "}
                      <DetailLink href={`mailto:${clientEmail}`}>
                        {clientEmail}
                      </DetailLink>
                    </Detail>
                    <Detail>
                      <DetailType>When:</DetailType> {timestamp}
                    </Detail>
                  </Header>
                  <Body hasStreamItemIds={hasStreamItemIds}>
                    {hasStreamItemIds && (
                      <>
                        <Title>
                          The {getFriendlyType(type)} alert contained the
                          following {pluralize("listings", listingIds.length)}:
                        </Title>
                        {hasStreamItemIds && (
                          <Listings>
                            {listingIds.map((listingId, index) => (
                              <Listing key={listingId}>
                                <ListingCard
                                  url={
                                    streamItemIds[index]
                                      ? `/stream_items/${streamItemIds[index]}`
                                      : `/listings/${listingId}`
                                  }
                                  listingId={listingId}
                                />
                              </Listing>
                            ))}
                          </Listings>
                        )}
                        <Disclaimer>
                          You are viewing the listings in their current status.
                          Status may have changed since the email was sent out
                        </Disclaimer>
                      </>
                    )}
                    {!hasStreamItemIds && (
                      <Text>No listings were sent in this alert.</Text>
                    )}
                  </Body>
                </Modal>
              </FocusTrap>
            </Container>
          </Portal>
        )}
      </>
    );
  }

  handleOnOpen = () => {
    this.setState({ showModal: true });
  };

  handleOnClose = () => {
    this.setState({ showModal: false });
  };
}

ActivityModal.propTypes = {
  type: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
  clientEmail: PropTypes.string.isRequired,
  listingIds: PropTypes.array.isRequired,
  hasListingIds: PropTypes.bool.isRequired,
  timestamp: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired
};

export default ActivityModal;
