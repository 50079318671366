import { connect } from "react-redux";
import { getStreamItems } from "../../../actions/stream";
import StreamTabs from "../StreamTabs";

function select({ stream: { filter, limit }, streams }, { streamId }) {
  const foundStream = streams.byId[streamId] || {};
  const {
    recommendedCount = 0,
    likedCount = 0,
    commentedCount = 0,
    hiddenCount = 0
  } = foundStream;

  return {
    streamId,
    likedCount,
    commentedCount,
    recommendedCount,
    hiddenCount,
    filter,
    limit
  };
}

const actions = {
  getStreamItems
};

export default connect(select, actions)(StreamTabs);
