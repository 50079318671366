import qs from "qs";
import { connect } from "react-redux";
import AttractConnectRoute from "../AttractConnectRoute";
import { logout } from "../../../../actions/app";
import { getAttractConnectUrl } from "../../../../modules/interProduct";

// http://localhost:3100/connect/cloud_attract?redirect=http://localhost:3300/connect/streams&continue_to=account-settings
function select({ user }, { location: { search } }) {
  const { redirect, continue_to } = qs.parse(search, {
    ignoreQueryPrefix: true
  });
  return {
    email: user.email,
    attractConnectUrl: getAttractConnectUrl({
      redirect,
      continue_to,
      email: user.email,
      token: user.guid
    })
  };
}

const actions = { logout };

export default connect(select, actions)(AttractConnectRoute);
