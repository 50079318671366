import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import Downshift from "downshift";
import { isEqual } from "lodash";
import { List } from "react-virtualized";
import Rect from "@reach/rect";
import IconClose from "../icons/IconClose";
import IconDropdownArrowDown from "../icons/IconDropdownArrowDown";
import IconDropdownArrowUp from "../icons/IconDropdownArrowUp";
import { getFilteredItems } from "../select/utils";
import {
  Container,
  ContainerInner,
  Label,
  InputContainer,
  InputContainerIcons,
  Input,
  Menu,
  Item,
  Selected,
  SelectedItem,
  SelectedItemRemove,
  SelectedItemInput
} from "./styled/select";

class Select extends Component {
  selectedRef = createRef();
  inputRef = createRef();

  state = {
    value: this.props.value,
    selectedItems: this.props.selectedItems,
    isInputFocused: this.props.autoFocus
  };

  render() {
    const {
      items,
      label,
      placeholder,
      inputId,
      threshold,
      persistInputValue,
      sortItems,
      minNumberOfItems,
      className: styledComponentClassName,
      disabled,
      defaultHighlightedIndex,
      rowHeight,
      searchable,
      multi,
      typeahead,
      arrows,
      closeArrow,
      openArrow
    } = this.props;
    const { value, selectedItems, isInputFocused } = this.state;

    return (
      <Downshift
        value={persistInputValue ? value : undefined}
        selectedItem={persistInputValue ? value : undefined}
        defaultHighlightedIndex={defaultHighlightedIndex}
        stateReducer={this.handleStateReducer}
        onChange={this.handleOnSelect}
        onInputValueChange={this.handleOnInputChange}
        onStateChange={this.handleOnStateChange}>
        {({
          getRootProps,
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          openMenu,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem
        }) => {
          const initialFilteredItems = getFilteredItems(
            items,
            selectedItems,
            inputValue,
            searchable,
            multi,
            typeahead
          );
          const filteredItems = sortItems
            ? initialFilteredItems.sort()
            : initialFilteredItems;
          const selectedItemsCount = selectedItems.length;
          const hasSelectedItems = !!selectedItemsCount;
          const hasFilters = !!filteredItems.length;
          const showRemove = selectedItemsCount > minNumberOfItems;
          const isPastThreshold = (value || "").length > threshold;
          const isMenuOpen = isOpen && hasFilters && isPastThreshold;
          const className = `${styledComponentClassName} ${
            isMenuOpen ? "is-open" : ""
          } ${isInputFocused ? "is-focused" : ""}`;

          return (
            // * Notes: We can't pass `isOpen` through components when creating with `styled()`.
            // * We append a class to the container so we can modify styles when the menu is opened in other files.
            // *
            // * The team may want to talk about different ways to apply style to this component.
            // * Possibly have "variations" of the component.
            // * SO Question: https://stackoverflow.com/questions/54793702/is-there-a-way-to-pass-props-down-when-extending-styles-in-styled-components
            <Container {...getRootProps()}>
              {label && <Label {...getLabelProps()}>{label}</Label>}
              <ContainerInner
                className={className}
                isInputFocused={isInputFocused}>
                <Rect>
                  {({ ref, rect }) => (
                    <div ref={ref}>
                      <InputContainer>
                        <Selected
                          ref={this.selectedRef}
                          hasSelectedItems={hasSelectedItems}>
                          {selectedItems.map((item, index) => (
                            <SelectedItem
                              key={index}
                              hasSelectedItems={hasSelectedItems}>
                              {item}
                              {showRemove && (
                                <SelectedItemRemove
                                  disabled={disabled}
                                  onClick={() => this.handleOnItemRemove(item)}>
                                  <IconClose />
                                </SelectedItemRemove>
                              )}
                            </SelectedItem>
                          ))}
                          <SelectedItemInput>
                            <Input
                              data-testid="select-input"
                              ref={this.inputRef}
                              {...getInputProps({
                                value: inputValue,
                                readOnly: !searchable && !typeahead,
                                placeholder:
                                  multi && hasSelectedItems ? "" : placeholder,
                                disabled,
                                autoFocus: isInputFocused,
                                title: inputValue,
                                onKeyDown: this.handleOnInputKeyDown,
                                onClick: isPastThreshold ? openMenu : undefined,
                                onFocus: this.handleOnInputFocus,
                                onBlur: this.handleOnInputBlur
                              })}
                              {...(!!inputId && {
                                id: inputId,
                                tabIndex: -1
                              })}
                            />
                          </SelectedItemInput>
                        </Selected>
                        {arrows && (
                          <InputContainerIcons>
                            {!isMenuOpen && closeArrow}
                            {isMenuOpen && openArrow}
                          </InputContainerIcons>
                        )}
                      </InputContainer>
                      <Menu {...getMenuProps()}>
                        {isMenuOpen && (
                          <List
                            style={{ minWidth: rect && rect.width }}
                            scrollToIndex={highlightedIndex || 0}
                            rowCount={filteredItems.length}
                            width={
                              process.env.NODE_ENV === "test"
                                ? 300
                                : rect
                                ? rect.width
                                : 0
                            }
                            height={300}
                            rowHeight={rowHeight}
                            rowRenderer={({ index, style }) => {
                              const item = filteredItems[index];
                              const isHighlighted = highlightedIndex === index;
                              const isSelected = selectedItem === item;

                              return (
                                <Item
                                  data-testid={`select-item-${index}`}
                                  key={item}
                                  {...getItemProps({
                                    key: index,
                                    index,
                                    item,
                                    style,
                                    className: `${
                                      isHighlighted ? "is-highlighted" : ""
                                    } ${isSelected ? "is-selected" : ""}`
                                  })}>
                                  {item}
                                </Item>
                              );
                            }}
                          />
                        )}
                      </Menu>
                    </div>
                  )}
                </Rect>
              </ContainerInner>
            </Container>
          );
        }}
      </Downshift>
    );
  }

  componentDidUpdate(_, prevState) {
    const { value, selectedItems } = this.state;
    const hasDifferentValue = prevState.value !== value && value;
    const hasDifferentSelectedItems = !isEqual(
      prevState.selectedItems,
      selectedItems
    );
    const isBluring = prevState.isInputFocused && !this.state.isInputFocused;

    if (this.state.isInputFocused || this.props.autoFocus) {
      this.setScrollPosition();
    }

    if (hasDifferentValue || hasDifferentSelectedItems) {
      this.setScrollPosition();
      this.props.onChange(value, selectedItems);
    }

    if (isBluring) {
      this.props.onBlur(value, selectedItems);
    }
  }

  handleOnInputChange = (value) => {
    this.setState({ value });
  };

  handleOnInputKeyDown = (e) => {
    if (e.key === "ArrowLeft" && !e.target.selectionStart) {
      this.selectedRef.current.scrollLeft -= 20;
    } else if (e.key === "ArrowRight") {
      this.selectedRef.current.scrollLeft += 20;
    }
  };

  handleOnSelect = (value) => {
    if (!this.props.multi) {
      return this.props.onSelect(this.state.value, this.state.selectedItems);
    }

    this.setState(
      ({ selectedItems }) => {
        return {
          value: "",
          selectedItems: [...selectedItems, value]
        };
      },
      () => {
        this.props.onSelect(this.state.value, this.state.selectedItems);
      }
    );
  };

  // * Notes: Downshift doesn't provide an `onClose` handler so we use
  // * the `onStateChange` handler to check when we closed the menu.
  handleOnStateChange = (_, stateAndHelpers) => {
    if (!stateAndHelpers.isOpen) {
      stateAndHelpers.closeMenu(() => {
        this.props.onClose(this.state.value, this.state.selectedItems);
      });
    }
  };

  handleOnItemRemove = (item) => {
    if (!this.props.multi) return;

    this.setState(
      ({ selectedItems }) => {
        return {
          selectedItems: selectedItems.filter(
            (selectedItem) => selectedItem !== item
          )
        };
      },
      () => {
        this.props.onRemove(this.state.value, this.state.selectedItems);
      }
    );
  };

  handleStateReducer = (state, changes) => {
    if (!this.props.multi) return changes;

    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          highlightedIndex: state.highlightedIndex,
          isOpen: true,
          inputValue: ""
        };
      case Downshift.stateChangeTypes.mouseUp:
        return {
          ...changes,
          inputValue: ""
        };
      default:
        return changes;
    }
  };

  handleOnInputFocus = () => {
    this.setState({ isInputFocused: true });
  };

  handleOnInputBlur = () => {
    this.setState({ isInputFocused: false });
  };

  setScrollPosition = () => {
    if (!this.props.multi) return;

    this.inputRef.current.focus();
    this.selectedRef.current.scrollTo(
      this.selectedRef.current.getBoundingClientRect().right * 1000,
      0
    );
  };
}

Select.propTypes = {
  value: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  inputId: PropTypes.string,
  persistInputValue: PropTypes.bool,
  sortItems: PropTypes.bool,
  autoFocus: PropTypes.bool,
  threshold: PropTypes.number,
  defaultHighlightedIndex: PropTypes.number,
  rowHeight: PropTypes.number,
  searchable: PropTypes.bool,
  multi: PropTypes.bool,
  typeahead: PropTypes.bool,
  className: PropTypes.string,
  arrows: PropTypes.bool,
  minNumberOfItems: PropTypes.number,
  openArrow: PropTypes.element,
  closeArrow: PropTypes.element,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
  onRemove: PropTypes.func,
  onBlur: PropTypes.func
};

Select.defaultProps = {
  value: "",
  items: [],
  selectedItems: [],
  label: "",
  placeholder: "Select an Option",
  persistInputValue: true,
  sortItems: false,
  autoFocus: false,
  threshold: -1,
  defaultHighlightedIndex: null,
  rowHeight: 32,
  searchable: false,
  multi: false,
  typeahead: false,
  className: "",
  arrows: true,
  minNumberOfItems: -1,
  openArrow: <IconDropdownArrowUp />,
  closeArrow: <IconDropdownArrowDown />,
  onChange: function () {},
  onSelect: function () {},
  onClose: function () {},
  onRemove: function () {},
  onBlur: function () {}
};

export default Select;
