import { connect } from "react-redux";
import { ListingDescription } from "@wrstudios/components";

function select({ listings: { byId } }, { listingId }) {
  const { description } = byId[listingId] || {};

  return {
    description
  };
}

export default connect(select)(ListingDescription);
