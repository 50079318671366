import React, { Children } from "react";
import { Container, Column } from "./styled/three-column-layout";

function ThreeColumnLayout({ children }) {
  return (
    <Container>
      {Children.map(children, (child) => {
        if (!child) {
          return null;
        }
        return <Column>{child}</Column>;
      })}
    </Container>
  );
}

export default ThreeColumnLayout;
