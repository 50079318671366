import { connect } from "react-redux";
import PokerChipMore from "../PokerChipMore";

function select({ user }) {
  return {
    isAgent: user.isAgent || false
  };
}

export default connect(select)(PokerChipMore);
