import { buildRailsApiClient } from ".";

// * Should be one of these for each field in the graph
export const streamInvitationFields = `
  id
  stream_id
  email
  invitee_name
  frequency
  guid
`;

export async function inviteClients({
  streamId,
  agentId,
  invitations,
  railsApiToken
}) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const mutation = /* GraphQL */ `
    mutation inviteClients($stream_id: ID!, $agent_id: ID, $invitations: [InvitationParamsType]) {
      inviteClients(stream_id: $stream_id, agent_id: $agent_id, invitations: $invitations) {
        id
        name
        email
        avatar
        created_at
        updated_at
        primary_photo
        social_profiles {
          username
          url
          service
        }
        social_data_status
        pending
        last_invitation {
          ${streamInvitationFields}
        }
        shared_clients {
          id
          name
          email
          avatar
          primary_photo
          social_profiles {
            username
            url
            service
          }
          social_data_status
          pending
        }
      }
    }
  `;
  const variables = { stream_id: streamId, agent_id: agentId, invitations };
  const { inviteClients } = await railsApiClient.request(mutation, variables);

  return inviteClients;
}
