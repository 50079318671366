import React from "react";
import PropTypes from "prop-types";
import IconHeart from "../icons/IconHeart";
import { Container } from "./styled/stream-item-likes";

function StreamItemLikes({ likes, isHidden }) {
  if (isHidden) return null;

  return (
    <Container>
      <IconHeart />
      <div>
        {likes.length === 1 && <strong>{likes[0]}</strong>}
        {likes.length === 2 && (
          <>
            <strong>{likes[0]}</strong> and <strong>{likes[1]}</strong>
          </>
        )}
        {likes.length >= 3 && (
          <span>
            {likes.slice(0, likes.length - 1).map((name, index) => (
              <span key={index}>
                <strong>{name}</strong>,{" "}
              </span>
            ))}
            and <strong>{likes.slice(likes.length - 1, likes.length)}</strong>
          </span>
        )}{" "}
        liked this property.
      </div>
    </Container>
  );
}

StreamItemLikes.propTypes = {
  likes: PropTypes.array.isRequired,
  isHidden: PropTypes.bool.isRequired
};

export default StreamItemLikes;
