import React from "react";

function IconSaveStream(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M18.479 21.6h-2.721A4.798 4.798 0 0 1 11.6 24a4.798 4.798 0 0 1-4.158-2.4H18.48zm2.721-2.4H2v-2.4c.796-.05 1.272-.17 1.427-.357.943-1.14.973-4.034.973-6.843 0-5.237 4.8-7.2 7.2-7.2 2.4 0 7.2 1.976 7.2 7.2 0 2.8.03 5.695.975 6.84.156.19.63.31 1.425.36v2.4zM10.4 0h2.4v4.8h-2.4V0z" />
    </svg>
  );
}

export default IconSaveStream;
