import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  width: 50%;
  position: sticky;
  top: 6rem;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  right: 0;

  @media (max-width: 1024px) {
    width: 40%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.page.twoColumn}px) {
    width: 50%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    display: none;
  }

  .mapboxgl-ctrl-bottom-right {
    @media (max-width: 1440px) {
      bottom: ${({ isEditingCriteria }) => isEditingCriteria && "8rem"};
    }
  }
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
`;

export const LoadingCluster = styled.div`
  padding-top: 5.5rem;
  text-align: center;
  height: 13.6rem;
  width: 26rem;

  svg {
    animation-name: ${rotate};
    animation-duration: 600ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
`;

export const ClickContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

export const ClickTarget = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  top: 0px;
`;
