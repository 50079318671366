import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { timer } from "../../../utils/timer";
import FormGroup from "../../common/FormGroup";
import Label from "../../common/Label";
import Input from "../../common/Input";
import Flash from "../../common/Flash";
import Select from "../../select/Select";
import { findOptionByLabel, findOptionByValue } from "../../select/utils";
import { Form, Button } from "./styled/settings-route";

class SettingsRouteMLS extends Component {
  state = {
    state: null,
    mlsCode: null,
    mlsUserId: null,
    mlsUserPassword: ""
  };

  static getDerivedStateFromProps(props, state) {
    return {
      state: state.state === null ? props.state : state.state,
      mlsCode: state.mlsCode === null ? props.mlsCode : state.mlsCode,
      mlsUserId: state.mlsUserId === null ? props.mlsUserId : state.mlsUserId,
      mlsUserPassword: state.mlsUserPassword
    };
  }

  render() {
    if (!this.props.isAgent || this.props.isSSOUser) {
      return <Redirect to="/settings" />;
    }

    const {
      states,
      statesLabels,
      mlses,
      isFetching,
      hasFetched,
      errors
    } = this.props;
    const { state, mlsCode, mlsUserId, mlsUserPassword } = this.state;
    const isDisabled = this.getIsDisabled();

    // * Notes: Transform filtered mls hash to work with our dropdown
    const filteredMLSes = mlses.filter((mls) => mls.state[state]);
    const filteredMLSesValues = filteredMLSes.map(({ name }) => name);
    const mlsLabel = (findOptionByValue(mlsCode, mlses, "key") || {}).label;

    return (
      <Form onSubmit={this.handleOnSubmit}>
        <FormGroup>
          <Select
            key={state || states}
            label="State / Province"
            placeholder="Select a State / Province"
            value={state}
            items={statesLabels}
            onChange={this.handleOnStatesChange}
          />
        </FormGroup>
        <FormGroup>
          <Select
            key={(filteredMLSes[0] || {}).key}
            label="MLS Name"
            placeholder="Select an MLS name"
            value={mlsLabel}
            items={filteredMLSesValues}
            onChange={this.handleOnMLSChange}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="mls-id">MLS User ID</Label>
          <Input
            id="mls-id"
            name="mlsUserId"
            placeholder=""
            value={mlsUserId}
            onChange={this.handleOnChange}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="mls-password">MLS User Password</Label>
          <Input
            type="password"
            id="mls-password"
            name="mlsUserPassword"
            placeholder=""
            value={mlsUserPassword}
            onChange={this.handleOnChange}
          />
        </FormGroup>
        {errors.map(({ message }, index) => (
          <FormGroup key={index}>
            <Flash status="error">{message}</Flash>
          </FormGroup>
        ))}
        {hasFetched && (
          <FormGroup>
            <Flash status="success">MLS credentials updated</Flash>
          </FormGroup>
        )}
        <Button disabled={isDisabled}>
          {!isFetching && "Save MLS Credentials"}
          {isFetching && "Saving MLS Credentials"}
        </Button>
      </Form>
    );
  }

  componentDidUpdate() {
    this.handleOnSuccess();
  }

  componentWillUnmount() {
    this.props.resetUserMLSNetwork();
  }

  handleOnChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleOnStatesChange = (state) => {
    const option = findOptionByLabel(state, this.props.states);

    this.setState({ state: option.label, mlsCode: "" });
  };

  handleOnMLSChange = (mls) => {
    const option = findOptionByLabel(mls, this.props.mlses);

    this.setState({ mlsCode: option.key });
  };

  handleOnSubmit = (e) => {
    e.preventDefault();

    this.props.updateUserMLSCredentials({
      mlsCode: this.state.mlsCode,
      mlsUserId: this.state.mlsUserId,
      mlsUserPassword: this.state.mlsUserPassword
    });
  };

  handleOnSuccess = async () => {
    if (this.props.hasFetched) {
      await timer(2000);

      this.props.resetUserMLSNetwork();
    }
  };

  getIsDisabled = () => {
    const hasMLSCodeChanged = this.state.mlsCode === this.props.mlsCode;
    const hasMLSCode = !!this.state.mlsCode;
    const hasMLSUserId = !!this.state.mlsUserId;
    const hasMLSUserIdChanged = this.state.mlsUserId === this.props.mlsUserId;
    const hasMLSUserPassword = !!this.state.mlsUserPassword;

    return (
      this.props.isFetching ||
      !hasMLSUserId ||
      !hasMLSCode ||
      (hasMLSCodeChanged && hasMLSUserIdChanged && !hasMLSUserPassword)
    );
  };
}

SettingsRouteMLS.propTypes = {
  state: PropTypes.string,
  states: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  statesLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  mlses: PropTypes.array.isRequired,
  isAgent: PropTypes.bool.isRequired,
  isSSOUser: PropTypes.bool,
  mlsCode: PropTypes.string.isRequired,
  mlsUserId: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  hasFetched: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired
    })
  ).isRequired,
  updateUserMLSCredentials: PropTypes.func.isRequired,
  resetUserMLSNetwork: PropTypes.func.isRequired
};

export default SettingsRouteMLS;
