import { connect } from "react-redux";
import ActivityAlert from "../ActivityAlert";

function select({ activities, user }, { activityId }) {
  const activity = activities.byId[activityId] || { stream: {} };
  const listingIds = activity.stream.listingIds || [];
  const hasListingIds = !!listingIds.length;

  return {
    hasListingIds,
    isAgent: user.isAgent
  };
}

export default connect(select)(ActivityAlert);
