import { connect } from "react-redux";
import { sortClients } from "../../../actions/client";
import ClientCardsSort from "../ClientCardsSort";

function select({ clients: { sortTerm } }) {
  return {
    sortTerm
  };
}

const actions = {
  sortClients
};

export default connect(select, actions)(ClientCardsSort);
