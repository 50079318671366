import { uniq } from "lodash";
import {
  getInitials,
  getDateLong,
  getDateTime,
  getTimeAgo
} from "@wrstudios/utils";
import { getInitialsColor } from "@wrstudios/theme";
import { transformSocialData } from "./client";

export function transformStats(stats) {
  return {
    views: stats.views_count,
    likes: stats.likes_count,
    alerts: stats.alerts_count,
    comments: stats.comments_count
  };
}

export function transformActivities(activities) {
  const transformedActivities = activities.reduce(
    (state, activity) => {
      const transformedActivity = transformActivity(activity);

      if (!transformedActivity) {
        return state;
      }

      return {
        order: [...state.order, transformedActivity.id],
        byId: { ...state.byId, [transformedActivity.id]: transformedActivity },
        listingIds: [
          ...state.listingIds,
          ...(transformedActivity.stream.listingIds || [])
        ]
      };
    },
    { order: [], byId: {}, listingIds: [] }
  );

  return {
    ...transformedActivities,
    listingIds: uniq(transformedActivities.listingIds)
  };
}

export function transformActivity(activity) {
  const client = activity.user || {};
  const clientSocialData = transformSocialData(client);

  const baseProps = {
    client: {
      id: client.id,
      name: client.name,
      email: client.email,
      avatar: client.avatar || clientSocialData.avatar || "",
      initials: getInitials(client.name || client.email),
      initialsColor: getInitialsColor(client.name || client.email)
    },
    updatedAtAgo: getTimeAgo(activity.updated_at),
    updatedAtDate: getDateLong(activity.updated_at),
    updatedAtTime: getDateTime(activity.updated_at)
  };

  switch (activity.event_type) {
    case "StreamItemView":
    case "Comment":
    case "ActsAsVotable::Vote":
    case "Recommendation":
      if (!activity.stream_item) {
        return null;
      }

      if (!activity.stream_item.item_id) {
        return null;
      }

      const eventType = getEventType(activity.event_type);

      return {
        ...baseProps,
        id: `${eventType}-${activity.event_id}`,
        type: eventType,
        comment: (activity.comment || {}).content || "",
        recommendationMessage: (activity.recommendation || {}).message || "",
        stream: {
          id: activity.stream_item.stream.id,
          title: activity.stream_item.stream.name,
          listingIds: [activity.stream_item.item_id]
        },
        streamItem: {
          id: activity.stream_item.id
        }
      };
    case "StreamAlert":
      if (!activity.stream.id) {
        return null;
      }

      if (activity.stream_alert.listing_ids_array.length === 0) {
        return null;
      }

      if (activity.stream_alert.delivery_method === "push") {
        return null;
      }

      return {
        ...baseProps,
        id: `${activity.stream_alert.delivery_method}-${activity.event_id}`,
        type: activity.stream_alert.delivery_method,
        stream: {
          id: activity.stream.id,
          title: activity.stream.name,
          listingIds: activity.stream_alert.listing_ids_array || [],
          streamItemIds: (
            activity.stream_alert.stream_item_ids || []
          ).map((streamItemId) => String(streamItemId))
        }
      };
    case "Invitation":
      if (true) {
        return null; // this UI is pushed to phase 2
      }

      if (!activity.stream.id) {
        return null;
      }

      return {
        ...baseProps,
        id: `invitation-${activity.event_id}`,
        type: "invitation",
        inviteeName: activity.invitee_name,
        stream: {
          id: activity.stream.id,
          title: activity.stream.name
        }
      };
    default:
      return null;
  }
}

function getEventType(eventType) {
  switch (eventType) {
    case "StreamItemView":
      return "view";
    case "Comment":
      return "comment";
    case "ActsAsVotable::Vote":
      return "like";
    case "Recommendation":
      return "recommend";
    default:
      console.warn(`unknown event type [${eventType}]`);
      return "";
  }
}

export function isCurrentlyViewedClient(activity) {
  const pathId = window.location.pathname.split("/").pop();
  return activity.client.id === pathId;
}
