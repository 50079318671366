import { combineReducers } from "redux";
import { activities, initial as initialActivities } from "./activities";
import { application, initial as initialApplication } from "./application";
import { billing, initial as initialBilling } from "./billing";
import { cas, initial as initialCAS } from "./cas";
import { clients, initial as initialClients } from "./clients";
import {
  clientStreams,
  initial as initialClientStreams
} from "./clientStreams";
import { invitations, initial as initialInvitations } from "./invitations";
import { listings, initial as initialListings } from "./listings";
import { pins, initial as initialPins } from "./pins";
import { login, initial as initialLogin } from "./login";
import { mls, initial as initialMls } from "./mls";
import { network } from "./network";
import { stream, initial as initialStream } from "./stream";
import { streamItems, initial as initialStreamItems } from "./streamItems";
import { streams, initial as initialStreams } from "./streams";
import {
  subscriptions,
  initial as initialSubscriptions
} from "./subscriptions";
import { user, initial as initialUser } from "./user";

export const initialState = {
  activities: initialActivities,
  application: initialApplication,
  billing: initialBilling,
  cas: initialCAS,
  clients: initialClients,
  clientStreams: initialClientStreams,
  invitations: initialInvitations,
  listings: initialListings,
  pins: initialPins,
  login: initialLogin,
  mls: initialMls,
  stream: initialStream,
  streamItems: initialStreamItems,
  streams: initialStreams,
  subscriptions: initialSubscriptions,
  user: initialUser
};

export default combineReducers({
  activities,
  application,
  billing,
  cas,
  clients,
  clientStreams,
  invitations,
  listings,
  pins,
  login,
  mls,
  network,
  stream,
  streamItems,
  streams,
  subscriptions,
  user
});
