import { connect } from "react-redux";
import { ListingCard } from "@wrstudios/components";

function select({ listings }, ownProps) {
  const listing = listings.byId[ownProps.listingId] || {};

  return {
    url: ownProps.url,
    photos: listing.photos || [],
    address: listing.address,
    city: listing.city,
    state: listing.state,
    zip: listing.zip,
    built: listing.built,
    statusLabel: listing.statusLabel,
    statusValue: listing.statusValue,
    acresFormatted: listing.acresFormatted,
    priceFormatted: listing.priceFormatted,
    bedsFormatted: listing.bedsFormatted,
    bathsFormatted: listing.bathsFormatted,
    sqftFormatted: listing.sqftFormatted,
    updatedAtAgo: listing.updatedAtAgo,
    handleMouseEnter: ownProps.handleMouseEnter,
    handleMouseLeave: ownProps.handleMouseLeave,
    isHidden: ownProps.isHidden,
    footer: ownProps.footer,
    changes: listing.historyRaw
  };
}

const actions = {};

export default connect(select, actions)(ListingCard);
