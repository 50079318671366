import React from "react";

function IconTextMessage(props) {
  return (
    <svg height="20" width="20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M2.997 14.47L2 17.462l4.457-1.486c.179.016.36.024.543.024h6a6 6 0 1 0 0-12H7a6 6 0 0 0-4.003 10.47z"
          stroke="currentColor"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <g fill="currentColor">
          <circle cx="10" cy="10" r="1" />
          <circle cx="6" cy="10" r="1" />
          <circle cx="14" cy="10" r="1" />
        </g>
      </g>
    </svg>
  );
}

export default IconTextMessage;
