import styled from "styled-components";
import { Button as BaseButton } from "@wrstudios/components";
import { default as BasePokerChips } from "../../../poker-chips/PokerChips";

export const ShrinkWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

export const Container = styled(BaseButton)`
  line-height: 1.6rem;
  padding-left: 1.1rem;
  padding-right: 1.1rem;
  cursor: pointer;

  svg {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.8rem;
    fill: ${({ theme }) => theme.colors.streamsBlue};
  }
`;

export const PokerChips = styled(BasePokerChips)`
  margin: 0 0.6rem 0 auto;
`;
