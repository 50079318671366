import React, { Component } from "react";
import PropTypes from "prop-types";
import ResourceLoader from "../../common/ResourceLoader";
import Listing from "../../listing/connected/Listing";

class ListingRoute extends Component {
  render() {
    return (
      <ResourceLoader
        isLoading={this.props.isFetching}
        notFound={this.props.notFound}
        hasError={this.props.hasErrors}>
        <Listing listingId={this.props.listingId} />
      </ResourceLoader>
    );
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isShowingTimeAvailable || !this.props.isListingActive) {
      return;
    }

    if (!prevProps.hasListing && this.props.hasListing) {
      this.props.getShowingTimeUrl(this.props.listingId);
    }
  }
}

ListingRoute.propTypes = {
  listingId: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  hasListing: PropTypes.bool.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  notFound: PropTypes.bool.isRequired,
  mlsConfigsLoaded: PropTypes.bool.isRequired,
  isListingActive: PropTypes.bool.isRequired,
  isShowingTimeAvailable: PropTypes.bool.isRequired,
  getShowingTimeUrl: PropTypes.func.isRequired
};

export default ListingRoute;
