import React from "react";
import PropTypes from "prop-types";
import IconCheck from "../icons/IconCheck";
import { Container, Input, Marker, Label } from "./styled/checkbox";

function Checkbox({ className, size, children, disabled, ...rest }) {
  return (
    <Container className={className} disabled={disabled}>
      <Input type="checkbox" {...rest} disabled={disabled} />
      <Marker size={size}>
        <IconCheck />
      </Marker>
      {children && <Label size={size}>{children}</Label>}
    </Container>
  );
}

Checkbox.propTypes = {
  size: PropTypes.oneOf(["small", "default"]),
  className: PropTypes.string
};

Checkbox.defaultProps = {
  size: "default"
};

export default Checkbox;
