import { formatNumber } from "@wrstudios/utils";

/**
 * Gets the modal position. If the modal goes outside of the window width,
 * it'll recalculate to be within the window.
 *
 * @param {Object} buttonRect
 * @param {Object} modalRect
 */
export function getModalPosition(buttonRect, modalRect) {
  const position = {
    top: buttonRect ? buttonRect.bottom : 0,
    left: buttonRect ? buttonRect.left : 0
  };

  if (buttonRect && modalRect) {
    const windowWidth = window.document.body.getBoundingClientRect().width;
    const modalPositionWithWidth = buttonRect.left + modalRect.width;

    if (modalPositionWithWidth > windowWidth) {
      const diff = modalPositionWithWidth - windowWidth;

      position.left = buttonRect.left - diff;
    }
  }

  return position;
}

/**
 * Constructs an array of human readable labels for the given filters.
 *
 * @param {Array} filters
 * @param {Object} fieldsById
 */
export function getSelectedFiltersWithLabels(filters, fieldsById) {
  return Object.entries(filters)
    .map(([key, filter]) => {
      const field = fieldsById[key];

      if (!field) return false;

      let value = "";

      if (filter.should) {
        value = filter.should.join(", ");
      } else if (filter.eq) {
        value = Array.isArray(filter.eq) ? filter.eq.join(", ") : filter.eq;
      } else if (filter.gte && filter.lte) {
        value = `Between ${getFormattedValue(
          filter.gte,
          field.colType,
          field.key
        )} and ${getFormattedValue(filter.lte, field.colType, field.key)}`;
      } else if (filter.gte) {
        value = `At Least ${getFormattedValue(
          filter.gte,
          field.colType,
          field.key
        )}`;
      } else if (filter.lte) {
        value = `Up to ${getFormattedValue(
          filter.lte,
          field.colType,
          field.key
        )}`;
      }

      return {
        ...field,
        value: value ? `${field.name}: ${value}` : ""
      };
    })
    .filter(({ value }) => !!value);
}

/**
 * Gets the formatted value for a specific field.
 *
 * @param {String|Number} value
 * @param {String} type
 * @param {String} key
 */
export function getFormattedValue(value, type, key) {
  const shouldFormat = shouldFormatField(key);

  if (type === "float") {
    const number = formatNumber(value, shouldFormat ? "0,0.[0000]" : "0");

    return number;
  } else if (type === "long" || type === "integer") {
    const number = formatNumber(value, shouldFormat ? "0,0" : "0");

    return number;
  }

  return value;
}

/**
 * Gets the formatted value for a specific field. This
 * values is used for forge.
 *
 * @param {String|Number} value
 * @param {String} type
 */
export function getFormattedRawValue(value, type) {
  if (!value) return value;

  if (type === "float") {
    const number = formatNumber(value, "0.[0000]");

    return number;
  } else if (type === "long" || type === "integer") {
    const number = formatNumber(value, "0");

    return number;
  }

  return value;
}

/**
 * Determines if we should format a specific filter
 *
 * @param {String} key
 */
function shouldFormatField(key) {
  return !["year_built"].includes(key);
}

/**
 * Builds a friendly label for the filters.
 *
 * @param {min} String the min range
 * @param {max} String the max range
 * @param {suffix} String a suffix to be appended at the end of the label
 */
export function buildMinMaxLabel(min, max, suffix = "") {
  const purgedMin = Boolean(Number(formatNumber(min, "0")));
  const purgedMax = Boolean(Number(formatNumber(max, "0")));
  const label =
    purgedMin && purgedMax
      ? `${min} - ${max}`
      : purgedMin && !purgedMax
      ? `${min}+`
      : !purgedMin && purgedMax
      ? `Under ${max}`
      : "";
  if (!label) return "";
  return `${label} ${suffix}`.trim();
}
