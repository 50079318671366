import { RouteContainer } from "@wrstudios/components";
import { breakpoints, colors } from "@wrstudios/theme";
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  position: relative;
  height: ${({ isShowingFilters }) =>
    isShowingFilters ? "calc(100vh - 12rem)" : "auto"};

  ${({ hasOnboarded }) =>
    hasOnboarded === false &&
    css`
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
      }
    `}
`;

export const ContentContainer = styled(RouteContainer)`
  position: relative;
  width: ${({ isMapShowing }) => (isMapShowing ? 50 : 100)}%;
  height: ${({ isShowingFilters }) => (isShowingFilters ? "100%" : "auto")};
  margin: 0 auto;
  padding-top: 2.6rem;
  padding-bottom: ${({ isEditingCriteria }) =>
    isEditingCriteria ? "11.4rem" : "2.4rem"};
  background-color: ${({ isShowingFilters }) =>
    isShowingFilters ? colors.white : "transparent"};
  overflow: ${({ isShowingFilters }) => (isShowingFilters ? "hidden" : "auto")};

  @media (max-width: 1024px) {
    width: ${({ isMapShowing }) => (isMapShowing ? 60 : 100)}%;
  }

  @media (max-width: ${breakpoints.md}px) {
    width: ${({ isMapShowing }) => (isMapShowing ? 50 : 100)}%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
    padding: 0 1.6rem ${({ isEditingCriteria }) => isEditingCriteria && "11rem"};
  }
`;

// * Notes: This is to help the calculation of the map container
export const Filters = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 1.4rem;
`;

export const MobileHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.6rem 0;

  > * + * {
    margin-top: 1.6rem;
  }
`;

export const StreamInviteLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 3.6rem;
  margin-top: 0.8rem;

  @media (max-width: ${breakpoints.md}px) {
    margin-top: 1rem;
  }
`;

export const NameGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -0.5rem;
`;

export const NameCell = styled.div`
  padding: 0 0.5rem;
  &:first-child {
    flex-grow: 1;
  }
  &:last-child {
    flex-shrink: 0;

    @media (max-width: ${breakpoints.md - 1}px) {
      display: none;
    }
  }
`;
