import { connect } from "react-redux";
import {
  updateUserProfile,
  resetUserProfileNetwork
} from "../../../../actions/user";
import SettingsRouteProfile from "../SettingsRouteProfile";

function select({
  user: {
    name,
    email,
    phone,
    officePhone,
    city,
    state,
    company,
    website,
    license,
    timezone,
    avatar,
    profileNetwork: { isFetching, hasFetched, errors },
    isAgent
  },
  application: { states, timezones }
}) {
  const statesAsArray = Object.values(states.byId);
  const statesLabels = statesAsArray.map(({ label }) => label);
  const timezonesAsArray = Object.values(timezones.byId);
  const timezonesLabels = timezonesAsArray.map(({ label }) => label);

  return {
    isAgent,
    name,
    email,
    phone,
    officePhone,
    city,
    state,
    company,
    website,
    license,
    timezone,
    avatar,
    states: statesAsArray,
    statesLabels,
    timezones: timezonesAsArray,
    timezonesLabels,
    isFetching,
    hasFetched,
    errors
  };
}

const actions = {
  updateUserProfile,
  resetUserProfileNetwork
};

export default connect(select, actions)(SettingsRouteProfile);
