export const initial = {
  byId: {},
  filteredIds: [],
  page: 1,
  limit: 17,
  searchTerm: "",
  sortTerm: "updated_at",
  sortDir: "desc",
  count: 0,
  isFetching: true,
  hasFetched: false
};

export function streams(state = initial, { type, payload }) {
  switch (type) {
    // Streams
    case "GET_STREAMS_INITIATED":
      return {
        ...state,
        page: payload.page,
        limit: payload.limit,
        isFetching: true,
        hasFetched: false,
        errors: []
      };
    case "SORT_STREAMS_INITIATED":
      return {
        ...state,
        isFetching: true,
        hasFetched: false,
        errors: [],
        sortTerm: payload.sortTerm,
        sortDir: payload.sortDir,
        page: payload.page,
        limit: payload.limit
      };
    case "SEARCH_STREAMS_INITIATED":
      return {
        ...state,
        isFetching: true,
        hasFetched: false,
        errors: [],
        searchTerm: payload.searchTerm,
        page: payload.page,
        limit: payload.limit
      };
    case "GET_STREAMS_SUCCEEDED":
    case "SORT_STREAMS_SUCCEEDED":
    case "SEARCH_STREAMS_SUCCEEDED":
      return {
        ...state,
        byId: { ...state.byId, ...payload.streams },
        filteredIds: payload.streamIds,
        count: payload.count,
        isFetching: false,
        hasFetched: true,
        errors: []
      };
    case "GET_STREAMS_FAILED":
      return {
        ...state,
        isFetching: false,
        hasFetched: false,
        errors: payload.errorsArray
      };
    case "SET_STREAMS_SORT_TERM":
      return {
        ...state,
        sortTerm: payload.sortTerm,
        sortDir: payload.sortDir,
        page: initial.page
      };
    case "SET_STREAMS_SEARCH_TERM":
      return { ...state, searchTerm: payload.searchTerm };

    // TODO: Look in to this more tomorrow.
    case "RESET_STREAMS":
      return {
        ...state,
        isFetching: true,
        hasFetched: false,
        sortTerm: initial.sortTerm,
        sortDir: initial.sortDir,
        searchTerm: initial.searchTerm,
        page: initial.page,
        limit: initial.limit
      };

    // Stream
    case "GET_STREAM_SUCCEEDED":
    case "GET_STREAM_SUBSCRIPTION_SUCCEEDED":
      return {
        ...state,
        byId: { ...state.byId, [payload.stream.id]: payload.stream }
      };
    case "UPDATE_STREAM_SUCCEEDED":
    case "RENAME_STREAM_SUCCEEDED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.stream.id]: {
            ...state.byId[payload.stream.id],
            ...payload.stream
          }
        }
      };
    case "CREATE_STREAM_SUCCEEDED":
      return {
        ...state,
        byId: { ...state.byId, [payload.stream.id]: payload.stream },
        count: payload.count
      };
    case "DELETE_STREAM_INITIATED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: { ...state.byId[payload.id], isDeleting: true }
        }
      };
    case "SET_PRIMARY_ALERT_TYPE_RESOLVED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.stream.id]: {
            ...state.byId[payload.stream.id],
            subscriptions: {
              ...state.byId[payload.stream.id].subscriptions,
              ...payload.subscriptions.subscriptions
            },
            primaryAlertType: payload.stream.primaryAlertType
          }
        }
      };
    case "CREATE_RECOMMENDATION_RESOLVED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.streamId]: {
            ...state.byId[payload.streamId],
            recommendedCount: payload.recommendedCount
          }
        }
      };
    case "DELETE_RECOMMENDATION_RESOLVED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.streamId]: {
            ...state.byId[payload.streamId],
            recommendedCount: state.byId[payload.streamId].recommendedCount - 1
          }
        }
      };
    case "DELETE_STREAM_SUCCEEDED":
      return {
        ...state,
        byId: { ...state.byId, ...payload.streams },
        filteredIds: payload.streamIds,
        count: payload.count,
        page: payload.page
      };
    case "DELETE_STREAM_FAILED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: { ...state.byId[payload.id], isDeleting: false }
        }
      };

    // Stream Invite
    case "SEND_STREAM_INVITE_SUCCEEDED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.stream.id]: { ...state.byId[payload.stream.id] }
        }
      };
    case "INVITE_CLIENTS_RESOLVED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.streamId]: {
            ...state.byId[payload.streamId],
            invitationIds: [
              ...state.byId[payload.streamId].invitationIds,
              ...payload.invitationIds
            ]
          }
        }
      };
    case "DELETE_INVITATION_SUCCEEDED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.streamId]: {
            ...state.byId[payload.streamId],
            invitationIds: (
              state.byId[payload.streamId].invitationIds || []
            ).filter((invitationId) => invitationId !== payload.invitationId)
          }
        }
      };

    // Stream Subscription
    case "CREATE_STREAM_SUBSCRIPTION_SUCCESS":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.stream.id]: {
            ...state.byId[payload.stream.id],
            subscriptionIds: [
              ...state.byId[payload.stream.id].subscriptionIds,
              payload.subscription.id
            ]
          }
        }
      };
    case "ADD_CLIENTS_FOR_CURATION_RESOLVED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.streamId]: {
            ...state.byId[payload.streamId],
            subscriptions: {
              ...state.byId[payload.streamId].subscriptions,
              ...payload.subscriptions
            },
            subscriptionIds: [
              ...state.byId[payload.streamId].subscriptionIds,
              ...payload.subscriptionIds
            ],
            subscribers: [
              ...state.byId[payload.streamId].subscribers,
              ...payload.subscribers
            ]
          }
        }
      };
    case "DELETE_STREAM_SUBSCRIPTION_INITIATED":
    case "DELETE_STREAM_SUBSCRIPTION_FAILED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.stream.id]: {
            ...state.byId[payload.stream.id],
            subscriptionIds: payload.stream.subscriptionIds,
            clientIds: payload.stream.clientIds,
            subscribers: payload.stream.subscribers
          }
        }
      };

    // Stream Item
    case "UPDATE_STREAM_ITEM_LIKE_SUCCEEDED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.streamId]: {
            ...state.byId[payload.streamId],
            likedCount: payload.likedCount
          }
        }
      };
    case "CREATE_STREAM_ITEM_COMMENT_SUCCEEDED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.streamId]: {
            ...state.byId[payload.streamId],
            commentedCount: payload.commentedCount
          }
        }
      };
    case "UPDATE_STREAM_ITEM_HIDE_SUCCEEDED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.streamId]: {
            ...state.byId[payload.streamId],
            hiddenCount: payload.hiddenCount
          }
        }
      };

    // Misc
    case "UPDATE_USER_MLS_SUCCEEDED":
      return {
        ...initial,
        limit: state.limit,
        sortTerm: state.sortTerm,
        sortDir: state.sortDir
      };
    case "CLEAR_API_TOKEN":
      return initial;
    default:
      return state;
  }
}
