import React from "react";

function IconAvatarRecommend(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx="8" cy="8" r="8" fill="#F6A807" />
        <circle cx="8" cy="8" r="8" fill="#000" fillOpacity=".1" />
        <path
          fill="#FFF"
          d="M8 11l-3.53 1.85.68-3.92-2.86-2.78 3.95-.58L8 2l1.76 3.57 3.95.58-2.86 2.78.68 3.92z"
        />
      </g>
    </svg>
  );
}

export default IconAvatarRecommend;
