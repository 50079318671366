import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: ${({ isAuthed }) => (isAuthed ? "6rem" : 0)};
  left: 0;
  right: 0;
  bottom: 0;
`;

export const Arrow = styled.span`
  svg {
    width: 2.8rem;
    height: 2.8rem;
  }
`;
