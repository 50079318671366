import qs from "qs";
import { connect } from "react-redux";
import { logout } from "../../../../actions/app";
import { connectCASAccount } from "../../../../actions/cas";
import CASConnectRoute from "../CASConnectRoute";

function select(
  {
    cas: {
      redirectUrl,
      connectNetwork: { isFetching, hasFetched, errors }
    },
    user: { email }
  },
  { location: { search } }
) {
  const { jwt, redirect } = qs.parse(search, { ignoreQueryPrefix: true });
  const hasErrors = !!errors.length;

  return {
    jwt,
    redirect, // * Notes: This is the redirect url from cas
    redirectUrl, // * Notes: This is the redirect url from graphql
    email,
    isFetching,
    hasFetched,
    hasErrors,
    errors
  };
}

const actions = {
  logout,
  connectCASAccount
};

export default connect(select, actions)(CASConnectRoute);
