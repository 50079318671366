import React from "react";
import PropTypes from "prop-types";
import IconHeart from "../icons/IconHeart";
import { Container } from "./styled/stream-item-like";

function StreamItemLike({ streamItemId, liked, setStreamItemLike }) {
  return (
    <Container
      active={liked}
      onClick={() => setStreamItemLike({ streamItemId, liked: !liked })}>
      <IconHeart />
    </Container>
  );
}

StreamItemLike.propTypes = {
  streamItemId: PropTypes.string.isRequired,
  liked: PropTypes.bool.isRequired,
  setStreamItemLike: PropTypes.func.isRequired
};

export default StreamItemLike;
