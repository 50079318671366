export const initial = {
  isLoadingActivities: false,
  loadingActivitiesError: null,
  isComplete: false,
  isLoading: false,
  limit: 30,
  page: 0,
  filter: null,
  byId: {},
  order: [],
  paginationByClientId: {},
  stats: {
    clients: 0,
    alerts: 0,
    likes: 0
  }
};

export function activities(state = initial, { type, payload }) {
  switch (type) {
    case "GET_ACTIVITY_STATS_SUCCEEDED":
      return { ...state, stats: payload };
    case "GET_ACTIVITIES_INITIATED":
      return { ...state, isLoading: true };
    case "GET_ACTIVITIES_SUCCEEDED":
      return {
        ...state,
        page: payload.page,
        byId: { ...state.byId, ...payload.byId },
        order: [...state.order, ...payload.order],
        isComplete: payload.isComplete,
        isLoading: false
      };
    case "RESET_ACTIVITIES": {
      return {
        ...state,
        page: initial.page,
        byId: initial.byId,
        order: initial.order,
        isComplete: false,
        isLoading: false
      };
    }
    case "GET_CLIENT_ACTIVITIES_SUCCEEDED":
      return {
        ...state,
        paginationByClientId: {
          ...state.paginationByClientId,
          [payload.clientId]: {
            isComplete: payload.isComplete,
            page: payload.page,
            order: [
              ...((state.paginationByClientId[payload.clientId] || {}).order ||
                []),
              ...payload.order
            ]
          }
        },
        byId: { ...state.byId, ...payload.byId }
      };
    case "RESET_ACTIVIES_FOR_CLIENT":
      return {
        ...state,
        paginationByClientId: {
          ...state.paginationByClientId,
          [payload.clientId]: {
            isComplete: false,
            page: 1,
            order: []
          }
        }
      };
    case "GET_ACTIVITIES_FAILED":
      return {
        ...state,
        isLoadingActivities: false,
        loadingActivitiesError: payload
      };
    case "SET_ACTIVITIES_FILTER":
      return { ...state, filter: payload, page: 0, order: initial.order };
    case "UPDATE_USER_MLS_SUCCEEDED":
      return {
        ...initial,
        limit: state.limit
      };
    case "CLEAR_API_TOKEN":
      return initial;
    default:
      return state;
  }
}
