import { connect } from "react-redux";
import { emitAction } from "../../../utils/redux";
import { getPinsFromForge, getListingsForPin } from "../../../actions/listing";
import {
  setStreamMapShowing,
  buildLocationFilters,
  removeStreamFilter
} from "../../../actions/stream";
import {
  getAreasFromFilters,
  getListingsFromListingIds,
  getListingsFromStreamItemIds
} from "../../../modules/mapping";
import { countFilters } from "../../../modules/stream";
import StreamMap from "../StreamMap";

function select({
  stream: {
    isMapShowing,
    filteredListingIds,
    filteredStreamItemIds,
    listingUnderPointer,
    filters,
    isCreating,
    isEditingCriteria
  },
  user,
  streamItems,
  listings,
  pins
}) {
  const mapListings = isEditingCriteria
    ? getListingsFromListingIds({ filteredListingIds, listings })
    : getListingsFromStreamItemIds({
        filteredStreamItemIds,
        streamItems,
        listings
      });

  return {
    isMapShowing,
    isCreating,
    isEditingCriteria,
    listingUnderPointer,
    mapListings,
    listingsById: listings.byId,
    showListings: countFilters(filters.byId) > 0,
    mapAreas: getAreasFromFilters(filters),
    mapPins: Object.values(pins.byId),
    casJwt: user.casJwt
  };
}

const actions = {
  setStreamMapShowing,
  buildLocationFilters,
  removeStreamFilter,
  getListingsForPin,
  getPinsFromForge,
  emitAction
};

export default connect(select, actions)(StreamMap);
