import { connect } from "react-redux";
import { updateStreamSubscriptionFrequency } from "../../../../actions/stream";
import StreamInviteMyAlerts from "../StreamInviteMyAlerts";
import { emailFrequencies, smsFrequencies } from "../utils";

function select(
  { user, stream: { alertsTemplate }, subscriptions },
  { streamId }
) {
  const subscription =
    Object.values(subscriptions.byId).filter(
      (sub) => sub.userId === user.id && sub.streamId === streamId
    )[0] || {};
  const { id: subscriptionId, emailFrequency, smsFrequency } = subscription;

  return {
    user,
    streamId,
    subscriptionId,
    emailFrequency: emailFrequency
      ? emailFrequency
      : alertsTemplate === "curated"
      ? emailFrequencies.REAL_TIME
      : emailFrequencies.NEVER,
    smsFrequency: smsFrequency || smsFrequencies.NEVER
  };
}

const actions = { updateStreamSubscriptionFrequency };

export default connect(select, actions)(StreamInviteMyAlerts);
