import styled from "styled-components";
import { Button as BaseButton } from "@wrstudios/components";
import { Icon } from "../../styled/activity-avatar";

export const Container = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    align-items: flex-start;
  }
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Image = styled.div`
  margin-right: 1.6rem;
  position: relative;

  ${Icon} {
    transform: translateX(20%);
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

export const Content = styled.div`
  font-size: 1.4rem;
  line-height: 2.1rem;
  margin-right: 1.6rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    margin: 0 0 1rem;
  }
`;

export const Action = styled.div`
  flex-shrink: 0;
  margin-left: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    margin-left: 0;
  }
`;

export const Button = styled(BaseButton)`
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding: 0;
  border: 0;
`;
