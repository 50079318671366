import React from "react";

function IconShare(props) {
  return (
    <svg width={20} height={20} {...props}>
      <g strokeWidth={1.5} fill="none" fillRule="evenodd" strokeLinecap="round">
        <path d="M10 1v10.45M10 1L7 4m3-3l3 3" />
        <path d="M13 7h3v11H4V7h3" strokeLinejoin="round" />
      </g>
    </svg>
  );
}

export default IconShare;
