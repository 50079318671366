import { connect } from "react-redux";
import { getPublicStreamItem } from "../../../../actions/streamItem";
import PublicStreamItemRoute from "../PublicStreamItemRoute";

function select({ application, user, mls, streamItems }, { match }) {
  const streamItemId = match.params.streamItemId;
  const streamItem = streamItems.byId[streamItemId] || {};
  const hasStreamItem = !!Object.values(streamItem).length;
  const isFetching = streamItems.getItemNetwork.isFetching && !hasStreamItem;
  const hasFetched = streamItems.getItemNetwork.hasFetched || hasStreamItem;
  const hasErrors = !!streamItems.getItemNetwork.errors.length;
  const notFound = hasFetched && !hasStreamItem;
  const isAuthedForge = !!application.forgeToken;
  const mlsConfigsLoaded = !!mls.photoProxyStrategy;
  const hasMlsCode = !!user.mlsCode;

  return {
    itemId: streamItem.itemId || "",
    type: streamItem.type || "",
    streamItemId,
    hasMlsCode,
    mlsConfigsLoaded,
    isAuthedForge,
    isFetching,
    notFound,
    hasErrors
  };
}

const actions = {
  getPublicStreamItem
};

export default connect(select, actions)(PublicStreamItemRoute);
