import { connect } from "react-redux";
import { deleteStreamSubscription } from "../../../../actions/stream";
import { emailFrequencies, smsFrequencies } from "../utils";
import StreamInviteSubscribedClient from "../StreamInviteSubscribedClient";

function select({ subscriptions, clients, user }, { subscriptionId }) {
  const subscription = subscriptions.byId[subscriptionId] || {};
  const client = clients.byId[subscription.userId] || {};

  return {
    client,
    clientId: client.id || "",
    hasClient: !!Object.values(client).length,
    emailFrequency: subscription.emailFrequency || emailFrequencies.NEVER,
    smsFrequency: subscription.smsFrequency || smsFrequencies.NEVER,
    isAgent: user.isAgent || false
  };
}

const actions = {
  deleteStreamSubscription
};

export default connect(select, actions)(StreamInviteSubscribedClient);
