import { connect } from "react-redux";
import { getStreamItemsByIds } from "../../../../actions/streamItem";
import LikedRoute from "../LikedRoute";

function select({
  user: { likedStreamItemIds },
  listings: { isLoading, hasLoaded },
  streamItems: { byId }
}) {
  return {
    likedStreamItemIds,
    isLoading: !likedStreamItemIds.every((id) => byId[id]),
    hasLoaded
  };
}

const actions = { getStreamItemsByIds };

export default connect(select, actions)(LikedRoute);
