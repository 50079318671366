import React from "react";

function IconCommentCircle(props) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <circle fill="#12B664" opacity=".1" cx="24" cy="24" r="24" />
        <path d="M10 10h28v28H10z" />
        <path
          d="M32.45 28.35L34 33l-4.65-1.55A9.953 9.953 0 0 1 24 33c-5.523 0-10-4.477-10-10s4.477-10 10-10 10 4.477 10 10a9.953 9.953 0 0 1-1.55 5.35z"
          stroke="#12B664"
          strokeWidth="1.5"
          fill="#12B664"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default IconCommentCircle;
