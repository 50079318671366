import {
  getClients as getClientsAPI,
  searchClients as searchClientsAPI,
  inviteClient as inviteClientAPI
} from "../modules/railsApi";
import {
  getClientById,
  getClientSocialDataAPI,
  getClientStreamsAPI,
  getClientLikedListingsAPI,
  getClientFeedEvents,
  deleteClient as deleteClientAPI
} from "../modules/railsApi";
import { resendInvitation as resendInvitationAPI } from "../modules/railsApi";
import { transformClients, transformClient } from "../modules/client";
import {
  transformStreams,
  transformStreamSubscriptions
} from "../modules/stream";
import { transformStreamItems } from "../modules/streamItem";
import { transformActivities } from "../modules/activity";
import { getForgeListingsByIds } from "../modules/forge";
import { transformListings } from "../modules/listing";
import { transformInvitations } from "../modules/invitation";
import { inviteClients as inviteClientsAPI } from "../api/invitation";
import {
  getClientRecommendedListingsAPI,
  addClientsForCuration
} from "../api/client";

export function getClients({ page, limit }) {
  return async (dispatch, getState) => {
    try {
      const { application, clients } = getState();

      // If we get clients while searching (most likely from pagination), call search clients with sorting
      if (clients.searchTerm) {
        return dispatch(
          searchClients({
            page,
            limit,
            searchTerm: clients.searchTerm,
            sortTerm: clients.sortTerm,
            sortDir: clients.sortDir
          })
        );
      }

      dispatch({ type: "GET_CLIENTS_INITIATED", payload: { page, limit } });

      const { currentUser, clients: clientsData } = await getClientsAPI({
        page,
        limit,
        sortTerm: clients.sortTerm,
        sortDir: clients.sortDir,
        railsApiToken: application.railsApiToken
      });
      const transformedClients = transformClients(clientsData);

      dispatch({
        type: "GET_CLIENTS_SUCCEEDED",
        payload: { ...transformedClients, count: currentUser.clients_count }
      });
    } catch (error) {
      dispatch({ type: "GET_CLIENTS_FAILED", payload: { error } });
    }
  };
}

export function sortClients({ sortTerm, sortDir }) {
  return async (dispatch, getState) => {
    const { application, clients } = getState();

    // If we sort while searching, call search clients with sorting
    if (clients.searchTerm) {
      return dispatch(
        searchClients({ searchTerm: clients.searchTerm, sortTerm, sortDir })
      );
    }

    dispatch({
      type: "SORT_CLIENTS_INITIATED",
      payload: { page: 1, sortTerm, sortDir }
    });

    try {
      const { currentUser, clients: clientsData } = await getClientsAPI({
        page: 1,
        limit: clients.limit,
        sortTerm,
        sortDir,
        railsApiToken: application.railsApiToken
      });
      const transformedClients = transformClients(clientsData);

      dispatch({
        type: "SORT_CLIENTS_SUCCEEDED",
        payload: { ...transformedClients, count: currentUser.clients_count }
      });
    } catch (error) {
      dispatch({ type: "SORT_CLIENTS_FAILED", payload: { error } });
    }
  };
}

export function searchClients({ page, limit, searchTerm, sortTerm, sortDir }) {
  return async (dispatch, getState) => {
    const { application, clients } = getState();

    dispatch({
      type: "SEARCH_CLIENTS_INITIATED",
      payload: {
        page: page || 1,
        searchTerm,
        sortTerm: sortTerm || clients.sortTerm,
        sortDir: sortDir || clients.sortDir
      }
    });

    try {
      const clientsData = await searchClientsAPI({
        page: page || 1,
        limit: limit || clients.limit,
        searchTerm,
        sortTerm: sortTerm || clients.sortTerm,
        sortDir: sortDir || clients.sortDir,
        railsApiToken: application.railsApiToken
      });
      const transformedClients = transformClients(clientsData.results);

      dispatch({
        type: "SEARCH_CLIENTS_SUCCEEDED",
        payload: { ...transformedClients, clientsCount: clientsData.count }
      });
    } catch (error) {
      dispatch({ type: "SEARCH_CLIENTS_FAILED", payload: { error } });
    }
  };
}

export function resetClients() {
  return { type: "RESET_CLIENTS" };
}

export function getClient(id) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken }
    } = getState();

    dispatch({ type: "GET_CLIENT_INITIATED" });

    try {
      const clientData = await getClientById({ id, railsApiToken });
      const transformedClientData = transformClient(clientData || {});

      dispatch({
        type: "GET_CLIENT_SUCCEEDED",
        payload: transformedClientData
      });
    } catch (error) {
      dispatch({
        type: "GET_CLIENT_FAILED",
        payload: {
          error,
          errorsArray: (error.response || {}).errors || []
        }
      });
    }
  };
}

export function getClientSocialData(id) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken }
    } = getState();

    dispatch({ type: "GET_CLIENT_SOCIAL_DATA_INITIATED" });

    try {
      const clientData = await getClientSocialDataAPI({ id, railsApiToken });

      dispatch({
        type: "GET_CLIENT_SOCIAL_DATA_SUCCEEDED",
        payload: transformClient(clientData)
      });
    } catch (error) {
      dispatch({ type: "GET_CLIENT_SOCIAL_DATA_FAILED", payload: { error } });
    }
  };
}

export function addClient(client) {
  const transformedClient = transformClient(client);

  return { type: "ADD_CLIENT", payload: transformedClient };
}

export function updateClientName({ id, name }) {
  return { type: "UPDATE_CLIENT_NAME", payload: { id, name } };
}

export function getActivitiesForClient(clientId) {
  return async (dispatch, getState) => {
    const {
      mls,
      user: { mlsCode },
      activities: { limit, paginationByClientId },
      application: { forgeToken, railsApiToken }
    } = getState();
    const nextPage = paginationByClientId[clientId]
      ? paginationByClientId[clientId].page + 1
      : 1;

    dispatch({ type: "GET_CLIENT_ACTIVITIES_INITIATED" });

    try {
      const activities = await getClientFeedEvents({
        clientId,
        limit,
        page: nextPage,
        railsApiToken
      });
      const { order, byId, listingIds } = transformActivities(activities);
      const isComplete = activities.length < limit;

      dispatch({
        type: "GET_CLIENT_ACTIVITIES_SUCCEEDED",
        payload: { page: nextPage, clientId, order, byId, isComplete }
      });

      if (listingIds.length > 0) {
        const {
          mls: {
            listings: { results }
          }
        } = await getForgeListingsByIds({
          ids: listingIds,
          mlsCode,
          forgeToken
        });
        const transformedListings = transformListings({
          listings: results,
          mls
        });
        dispatch({
          type: "GET_LISTINGS_SUCCEEDED",
          payload: {
            listings: transformedListings,
            listingIds: Object.keys(transformedListings)
          }
        });
      }
    } catch (error) {
      dispatch({ type: "GET_CLIENT_ACTIVITIES_FAILED", payload: { error } });
    }
  };
}

export function resetActivitiesForClient(clientId) {
  return { type: "RESET_ACTIVIES_FOR_CLIENT", payload: clientId };
}

export function getClientStreams(id) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken },
      streams: { page, limit },
      mls
    } = getState();

    dispatch({
      type: "GET_CLIENT_STREAMS_INITIATED",
      payload: { page, limit }
    });

    try {
      const streams = await getClientStreamsAPI({ id, railsApiToken });
      const transformedStreams = transformStreams({ streams, mls });

      dispatch({
        type: "GET_CLIENT_STREAMS_SUCCEEDED",
        payload: transformedStreams
      });
    } catch (error) {
      dispatch({
        type: "GET_CLIENT_STREAMS_FAILED",
        payload: { error, errorsArray: (error.response || {}).errors || [] }
      });
    }
  };
}

export function getClientLikedListings(id) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken },
      mls
    } = getState();

    dispatch({ type: "GET_CLIENT_LIKED_LISTINGS_INITIATED" });

    try {
      const likes = await getClientLikedListingsAPI({ id, railsApiToken });

      dispatch({
        type: "GET_CLIENT_LIKED_LISTINGS_SUCCEEDED",
        payload: {
          streamItems: transformStreamItems({ streamItems: likes, mls })
        }
      });
    } catch (error) {
      dispatch({
        type: "GET_CLIENT_LIKED_LISTINGS_FAILED",
        payload: { error }
      });
    }
  };
}

export function getClientRecommendedListings({ clientId }) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken },
      mls
    } = getState();

    dispatch({ type: "GET_CLIENT_RECOMMENDED_LISTINGS_INITIATED" });

    try {
      const recommendations = await getClientRecommendedListingsAPI({
        id: clientId,
        railsApiToken
      });
      const recommendedStreamItems = recommendations.map((r) => r.stream_item);
      const recommendedStreamItemIds = recommendations.map((r) =>
        r.stream_item_id.toString()
      );

      dispatch({
        type: "GET_CLIENT_RECOMMENDED_LISTINGS_SUCCEEDED",
        payload: {
          clientId,
          recommendedStreamItemIds,
          streamItems: transformStreamItems({
            streamItems: recommendedStreamItems,
            mls
          })
        }
      });
    } catch (error) {
      dispatch({
        type: "GET_CLIENT_RECOMMENDED_LISTINGS_FAILED",
        payload: { error }
      });
    }
  };
}

export function deleteClient({ clientId }) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken },
      clients: { byId, limit, searchTerm, sortTerm, sortDir, count }
    } = getState();

    dispatch({ type: "DELETE_CLIENT_INITIATED", payload: { id: clientId } });

    try {
      await deleteClientAPI({ clientId, railsApiToken });

      const newCount = count - 1 <= 0 ? 0 : count - 1;
      const newPage = newCount > 0 ? Math.ceil(newCount / limit) : 1;

      const { clients: clientsData } = await getClientsAPI({
        page: newPage,
        limit,
        searchTerm,
        sortTerm,
        sortDir,
        railsApiToken
      });
      const transformedClients = transformClients(clientsData);

      const { [clientId]: removedClient, ...newClients } = byId;

      dispatch({
        type: "DELETE_CLIENT_SUCCEEDED",
        payload: {
          clients: {
            ...newClients,
            ...transformedClients.clients
          },
          clientIds: transformedClients.clientIds,
          ...transformedClients,
          page: newPage,
          count: newCount
        }
      });
    } catch (error) {
      dispatch({
        type: "DELETE_CLIENT_FAILED",
        payload: { error, id: clientId }
      });
    }
  };
}

export function inviteClient({ agentId, name, email, message }) {
  return async (dispatch, getState) => {
    try {
      const { application, clients } = getState();
      const { railsApiToken } = application;

      dispatch({ type: "INVITE_CLIENT_INITIATED" });

      const client = await inviteClientAPI({
        agentId,
        name,
        email,
        message,
        railsApiToken
      });
      const transformedClient = transformClient(client);

      dispatch({
        type: "INVITE_CLIENT_SUCCEEDED",
        payload: {
          client: transformedClient,
          filteredIds: [transformedClient.id, ...clients.filteredIds].slice(
            0,
            clients.limit
          ),
          count: clients.count + 1
        }
      });
    } catch (error) {
      dispatch({ type: "INVITE_CLIENT_FAILED", payload: error });
    }
  };
}

export function inviteClients({ streamId, agentId, invitations }) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken }
    } = getState();

    dispatch({ type: "INVITE_CLIENTS_REQUEST" });

    try {
      const invitedClients = await inviteClientsAPI({
        streamId,
        agentId,
        invitations,
        railsApiToken
      });
      const clientInvitationsForStream = invitedClients
        .map((client) => client.last_invitation)
        .filter((invitation) => invitation.stream_id.toString() === streamId);
      const { invitations: transformedInvitations, invitationIds } =
        transformInvitations(clientInvitationsForStream);
      const { clients, clientIds } = transformClients(invitedClients);

      dispatch({
        type: "INVITE_CLIENTS_RESOLVED",
        payload: {
          streamId,
          clients,
          clientIds,
          invitations: transformedInvitations,
          invitationIds
        }
      });
    } catch (error) {
      dispatch({ type: "INVITE_CLIENTS_REJECTED", payload: { error } });
    }
  };
}

export function addClientsToCuratedStream({ streamId, clients }) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken }
    } = getState();

    dispatch({ type: "ADD_CLIENTS_FOR_CURATION_REQUEST" });

    try {
      const addedClients = await addClientsForCuration({
        streamId,
        clients,
        railsApiToken
      });
      const addedClientsStreamSubscriptions = addedClients
        .map((client) => client.stream_subscriptions)
        .flat()
        .filter((sub) => sub.stream_id.toString() === streamId);
      const {
        subscriptions: transformedStreamSubscriptions,
        subscriptionIds,
        subscribers
      } = transformStreamSubscriptions(addedClientsStreamSubscriptions);
      const { clients: transformedClients, clientIds } =
        transformClients(addedClients);

      dispatch({
        type: "ADD_CLIENTS_FOR_CURATION_RESOLVED",
        payload: {
          clientIds,
          clients: transformedClients,
          subscriptions: transformedStreamSubscriptions,
          subscriptionIds,
          subscribers,
          streamId
        }
      });
    } catch (error) {
      dispatch({
        type: "ADD_CLIENTS_FOR_CURATION_REJECTED",
        payload: { error }
      });
    }
  };
}

export function resendClientInvitation({ clientId, invitationId }) {
  return async (dispatch, getState) => {
    try {
      const { application } = getState();

      dispatch({
        type: "RESEND_CLIENT_INVITATION_INITIATED",
        payload: { clientId }
      });

      await resendInvitationAPI({
        invitationId,
        railsApiToken: application.railsApiToken
      });

      dispatch({
        type: "RESEND_CLIENT_INVITATION_SUCCEEDED",
        payload: { clientId }
      });
    } catch (error) {
      dispatch({
        type: "RESEND_CLIENT_INVITATION_FAILED",
        payload: { error, invitationId }
      });
    }
  };
}
