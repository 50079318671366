import styled from "styled-components";

export const Quote = styled.blockquote`
  display: inline;
  margin: 0;

  &:before,
  &:after {
    content: '"';
  }
`;
