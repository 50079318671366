import styled, { css } from "styled-components";

export const Container = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  opacity: ${({ disabled }) => (disabled ? "0.5" : 1)};
`;

function getSize(size) {
  return {
    small: "2rem",
    default: "2.4rem"
  }[size];
}

export const Marker = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => getSize(size)};
  height: ${({ size }) => getSize(size)};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.white};
  border: 0.2rem solid ${({ theme }) => theme.colors.greyLight};
  border-radius: 0.4rem;

  svg {
    opacity: 0;
  }
`;

function getFontSize(size) {
  return {
    small: css`
      font-size: 1.2rem;
      line-height: 1.8rem;
    `,
    default: css`
      font-size: 1.4rem;
      line-height: 2rem;
    `
  }[size];
}

export const Label = styled.span`
  display: inline-block;
  margin-left: 1rem;
  ${({ size }) => getFontSize(size)}
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;

  &:focus,
  &:active {
    + ${Marker} {
      outline: auto 0.5rem -webkit-focus-ring-color;
    }
  }

  &:checked {
    + ${Marker} {
      background-color: ${({ theme }) => theme.colors.streamsBlue};
      border-color: ${({ theme }) => theme.colors.streamsBlue};

      svg {
        opacity: 1;
      }
    }
  }
`;
