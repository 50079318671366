import { connect } from "react-redux";
import { getForgeListing } from "../../../actions/listing";
import { setAppHeaderSticky } from "../../../actions/app";
import Listing from "../Listing";

function select(
  {
    listings,
    streamItems,
    user: { isAgent },
    application: { railsApiToken },
    mls: { photoProxyStrategy }
  },
  { streamItemId, listingId }
) {
  const listing = listings.byId[listingId];
  const streamItem = streamItems.byId[streamItemId];

  return {
    streamId: streamItem && streamItem.streamId,
    streamItemId,
    listingId,
    hasListingData: listing !== undefined,
    pageTitle: (listing || {}).address || "Cloud Streams",
    isAgent,
    isAuthed: !!railsApiToken,
    mlsConfigsLoaded: !!photoProxyStrategy,
    isFullscreenMediaOpen: (listing || {}).isFullscreenMediaOpen
  };
}

const actions = {
  getForgeListing,
  setAppHeaderSticky
};

export default connect(select, actions)(Listing);
