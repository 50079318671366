import { Component } from "react";
import PropTypes from "prop-types";
import { resetScrollPosition } from "@wrstudios/utils";

class ScrollToTopOnRouteChange extends Component {
  render() {
    return this.props.children;
  }

  componentDidMount() {
    resetScrollPosition();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pathname !== this.props.pathname) {
      resetScrollPosition();
    }
  }
}

ScrollToTopOnRouteChange.propTypes = {
  pathname: PropTypes.string.isRequired
};

export default ScrollToTopOnRouteChange;
