import styled from "styled-components";
import { Slider, Items, Item } from "../../../common/styled/tags";

export const Container = styled.div`
  ${Slider} {
    display: block;
    overflow: auto;
  }

  ${Items} {
    display: block;
    margin: -0.1rem;
  }

  ${Item} {
    display: inline-flex;
    align-items: flex-start;
    margin: 0.1rem;
  }
`;

export const Grid = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -0.3rem;
`;

export const Cell = styled.div`
  padding: 0 0.4rem;
`;

export const Spacer = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
`;
