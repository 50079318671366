import {
  getActivityFeedEvents,
  getActivityFeedStats
} from "../modules/railsApi";
import { transformActivities, transformStats } from "../modules/activity";
import { getForgeListingsByIds } from "../modules/forge";
import { transformListings } from "../modules/listing";

export function getActivities() {
  return async (dispatch, getState) => {
    const {
      mls,
      user: { mlsCode },
      activities: { page, limit, filter },
      application: { forgeToken, railsApiToken }
    } = getState();
    const nextPage = page + 1;

    dispatch({ type: "GET_ACTIVITIES_INITIATED" });

    try {
      const activities = await getActivityFeedEvents({
        page: nextPage,
        limit,
        filter,
        railsApiToken
      });
      const { order, byId, listingIds } = transformActivities(activities);
      const isComplete = activities.length < limit;

      if (!listingIds.length) {
        dispatch({
          type: "GET_ACTIVITIES_SUCCEEDED",
          payload: { page: nextPage, order, byId, isComplete }
        });
      } else {
        const {
          mls: {
            listings: { results }
          }
        } = await getForgeListingsByIds({
          ids: listingIds,
          mlsCode,
          forgeToken
        });
        const transformedListings = transformListings({
          listings: results,
          mls
        });

        dispatch({
          type: "GET_ACTIVITIES_SUCCEEDED",
          payload: { page: nextPage, order, byId, isComplete }
        });

        dispatch({
          type: "GET_LISTINGS_SUCCEEDED",
          payload: {
            listings: transformedListings,
            listingIds: Object.keys(transformedListings)
          }
        });
      }
    } catch (error) {
      dispatch({ type: "GET_ACTIVITIES_FAILED", payload: { error } });
    }
  };
}

export function resetActivities() {
  return { type: "RESET_ACTIVITIES" };
}

export function setActivitiesFilter(filter) {
  return {
    type: "SET_ACTIVITIES_FILTER",
    payload: filter
  };
}

export function getActivityStats() {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken }
    } = getState();

    dispatch({ type: "GET_ACTIVITY_STATS_INITIATED" });

    try {
      const stats = await getActivityFeedStats(railsApiToken);
      const transformedStats = transformStats(stats);

      dispatch({
        type: "GET_ACTIVITY_STATS_SUCCEEDED",
        payload: transformedStats
      });
    } catch (error) {
      dispatch({ type: "GET_ACTIVITY_STATS_FAILED", payload: { error } });
    }
  };
}
