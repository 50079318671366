export const initial = {
  byId: {},
  filteredIds: [],
  page: 1,
  limit: 17,
  searchTerm: "",
  sortTerm: "updated_at",
  sortDir: "desc",
  count: 0,
  isFetching: true,
  hasFetched: false
};

export function clientStreams(state = initial, { type, payload }) {
  switch (type) {
    case "GET_CLIENT_STREAMS_INITIATED":
      return {
        ...state,
        page: payload.page,
        limit: payload.limit,
        isFetching: true,
        hasFetched: false,
        errors: []
      };
    case "GET_CLIENT_STREAMS_SUCCEEDED":
      return {
        ...state,
        byId: { ...state.byId, ...payload.streams },
        filteredIds: payload.streamIds,
        count: payload.count,
        isFetching: false,
        hasFetched: true,
        errors: []
      };
    case "GET_CLIENT_STREAMS_FAILED":
      return {
        ...state,
        isFetching: false,
        hasFetched: false,
        errors: payload.errorsArray
      };
    default:
      return state;
  }
}
