import React from "react";

function IconDelete(props) {
  return (
    <svg width={21} height={21} {...props}>
      <g transform="translate(2 2)" fill="none" fillRule="evenodd">
        <circle stroke="#89929D" strokeWidth={1.5} cx={8.5} cy={8.5} r={9.25} />
        <path
          d="M9.56 8.5l3.36 3.359-1.061 1.06L8.5 9.561l-3.359 3.358-1.06-1.06L7.439 8.5 4.081 5.141l1.06-1.06L8.5 7.439l3.359-3.358 1.06 1.06L9.561 8.5z"
          fill="#89929D"
        />
      </g>
    </svg>
  );
}

export default IconDelete;
