export const selectCardMonths = [
  { value: "1", label: "1 - January" },
  { value: "2", label: "2 - February" },
  { value: "3", label: "3 - March" },
  { value: "4", label: "4 - April" },
  { value: "5", label: "5 - May" },
  { value: "6", label: "6 - June" },
  { value: "7", label: "7 - July" },
  { value: "8", label: "8 - August" },
  { value: "9", label: "9 - September" },
  { value: "10", label: "10 - October" },
  { value: "11", label: "11 - November" },
  { value: "12", label: "12 - December" }
];

export const selectCardTypes = [
  { value: "visa", label: "Visa" },
  { value: "master", label: "Master Card" },
  { value: "american_express", label: "American Express" },
  { value: "discover", label: "Discover" }
];

export const selectCardYears = generateYearsFromThisYear().map((year) => {
  return { value: String(year), label: String(year) };
});

/**
 * Generates a list of years starting from the current year.
 *
 * @param {Number} amount The number of years you want in the list.
 */
function generateYearsFromThisYear(amount = 20) {
  const currentYear = new Date().getFullYear();
  const maxYear = currentYear + amount;
  const years = [];

  for (let i = currentYear; i <= maxYear; i++) {
    years.push(i);
  }

  return years;
}
