import styled from "styled-components";

export const Container = styled.div`
  padding: 5rem;
  text-align: center;
`;

export const Loading = styled.div`
  color: ${({ theme }) => theme.colors.grey};

  svg {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.5rem;
    fill: ${({ theme }) => theme.colors.grey};
  }
`;
