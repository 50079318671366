import React from "react";
import PropTypes from "prop-types";
import { PhotoCardLoading } from "@wrstudios/components";
import ThreeColumnLayout from "../common/ThreeColumnLayout";
import StreamCardCreate from "./StreamCardCreate";
import { Container } from "./styled/stream-cards-loading";

function StreamCardsLoading({ limit }) {
  return (
    <Container data-testid="loading">
      <ThreeColumnLayout>
        <StreamCardCreate />
        {new Array(limit).fill().map((_, index) => (
          <PhotoCardLoading key={index} />
        ))}
      </ThreeColumnLayout>
    </Container>
  );
}

StreamCardsLoading.propTypes = {
  limit: PropTypes.number.isRequired
};

export default StreamCardsLoading;
