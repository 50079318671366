import React, { Component } from "react";
import PropTypes from "prop-types";
import { LoadingSpinner } from "@wrstudios/components";
import { Container, Select, IndicatorLayout } from "./styled/stream-name-input";

class StreamNameInput extends Component {
  state = {
    value: ""
  };

  render() {
    const { name, hasName, autocomplete, isMapShowing, isSaving, inputId } =
      this.props;

    return (
      <Container isMapShowing={isMapShowing} onSubmit={this.handleOnSubmit}>
        <Select
          key={name || autocomplete}
          value={name}
          items={autocomplete}
          disabled={isSaving}
          hasName={hasName}
          placeholder="Name this Stream"
          inputId={inputId}
          persistInputValue={true}
          arrows={false}
          typeahead
          onChange={this.handleOnChange}
          onSelect={this.handleOnSelect}
          onBlur={this.handleNameChange}
        />
        {isSaving && (
          <IndicatorLayout>
            <LoadingSpinner />
          </IndicatorLayout>
        )}
      </Container>
    );
  }

  componentDidMount() {
    const input = document.querySelector(
      "[data-testid=stream-modal-name-input]"
    );
    if (input) {
      input.focus();
      input.setSelectionRange(0, input.value.length);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.isSaving &&
      !this.props.isLoading &&
      (prevProps.isSaving || prevProps.isLoading)
    ) {
      const input = document.querySelector("#stream-modal-name-input");
      if (input) {
        input.focus();
        input.setSelectionRange(0, input.value.length);
      }
    }
  }

  handleOnSubmit = (e) => {
    e.preventDefault();

    this.handleNameChange();
  };

  handleOnSelect = (value) => {
    this.setState({ value }, () => {
      this.props.createStream({
        name: this.state.value,
        filter: this.props.filters
      });
    });
  };

  handleOnChange = (value) => {
    this.setState({ value });
  };

  handleOnSelect = (value) => {
    this.setState({ value }, () => {
      this.handleNameChange();
    });
  };

  handleNameChange = () => {
    const {
      streamId,
      name,
      filters,
      hasFilters,
      hasStream,
      renameStream,
      createStream
    } = this.props;
    const { value } = this.state;

    if (!value || !hasFilters || value === name) return;

    hasStream
      ? renameStream({ id: streamId, name: value })
      : createStream({ name: value, filter: filters });
  };
}

StreamNameInput.propTypes = {
  inputId: PropTypes.string,
  streamId: PropTypes.string,
  hasStream: PropTypes.bool.isRequired,
  filters: PropTypes.array.isRequired,
  hasFilters: PropTypes.bool.isRequired,
  autocomplete: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  hasName: PropTypes.bool.isRequired,
  isMapShowing: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  createStream: PropTypes.func.isRequired,
  renameStream: PropTypes.func.isRequired
};

export default StreamNameInput;
