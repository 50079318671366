import { connect } from "react-redux";
import { searchClients } from "../../../actions/client";
import ClientCardsSearch from "../ClientCardsSearch";

function select({ clients }) {
  return {
    searchTerm: clients.searchTerm
  };
}

const actions = {
  searchClients
};

export default connect(select, actions)(ClientCardsSearch);
