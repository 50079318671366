export const initial = {
  byId: {},
  filteredIds: [],
  page: 1,
  limit: 17,
  sortTerm: "updated_at",
  sortDir: "desc",
  searchTerm: "",
  count: 0,
  isLoadingClients: true,
  hasLoadedClients: false,
  loadingClientsError: null,
  isGettingSocialData: false,
  hasSocialDataError: false,
  isLoadingClientActivities: false,
  loadingClientActivityError: null,
  isInvitingClient: false,
  inviteClientError: null,
  getClientNetwork: { isFetching: false, hasFetched: false, errors: [] }
};

export function clients(state = initial, { type, payload }) {
  switch (type) {
    // Clients
    case "GET_CLIENTS_INITIATED":
      return {
        ...state,
        isLoadingClients: true,
        hasLoadedClients: false,
        loadingClientsError: null,
        page: payload.page,
        limit: payload.limit
      };
    case "GET_CLIENTS_SUCCEEDED":
      return {
        ...state,
        isLoadingClients: false,
        hasLoadedClients: true,
        byId: { ...state.byId, ...payload.clients },
        filteredIds: payload.clientIds,
        count: payload.count
      };
    case "GET_CLIENTS_FAILED":
      return {
        ...state,
        isLoadingClients: false,
        loadingClientsError: payload.error
      };

    case "SORT_CLIENTS_INITIATED":
      return {
        ...state,
        isLoadingClients: true,
        hasLoadedClients: false,
        loadingClientsError: null,
        sortTerm: payload.sortTerm,
        sortDir: payload.sortDir,
        page: payload.page
      };
    case "SORT_CLIENTS_SUCCEEDED":
      return {
        ...state,
        isLoadingClients: false,
        hasLoadedClients: true,
        byId: payload.clients,
        filteredIds: payload.clientIds,
        count: payload.count
      };
    case "SORT_CLIENTS_FAILED":
      return {
        ...state,
        isLoadingClients: false,
        loadingClientsError: payload.error
      };

    case "SEARCH_CLIENTS_INITIATED":
      return {
        ...state,
        isLoadingClients: true,
        hasLoadedClients: false,
        loadingClientsError: null,
        page: payload.page,
        searchTerm: payload.searchTerm,
        sortTerm: payload.sortTerm,
        sortDir: payload.sortDir
      };
    case "SEARCH_CLIENTS_SUCCEEDED":
      return {
        ...state,
        isLoadingClients: false,
        hasLoadedClients: true,
        byId: payload.clients,
        count: payload.clientsCount,
        filteredIds: payload.clientIds
      };
    case "SEARCH_CLIENTS_FAILED":
      return {
        ...state,
        isLoadingClients: false,
        loadingClientsError: payload.error
      };

    case "SET_CLIENTS_SORT_TERM":
      return {
        ...state,
        sortTerm: payload.sortTerm,
        sortDir: payload.sortDir,
        page: 1
      };
    case "SET_CLIENTS_SEARCH_TERM":
      return { ...state, searchTerm: payload.searchTerm };

    case "RESET_CLIENTS":
      return {
        ...state,
        isLoadingClients: true,
        hasLoadedClients: false,
        sortTerm: initial.sortTerm,
        sortDir: initial.sortDir,
        searchTerm: initial.searchTerm,
        page: initial.page,
        limit: initial.limit
      };

    // Client
    case "GET_CLIENT_INITIATED":
      return {
        ...state,
        getClientNetwork: { isFetching: true, hasFetched: false, errors: [] }
      };
    case "GET_CLIENT_SUCCEEDED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: { ...(state.byId[payload.id] || {}), ...payload }
        },
        getClientNetwork: { isFetching: false, hasFetched: true, errors: [] }
      };
    case "GET_CLIENT_FAILED":
      return {
        ...state,
        getClientNetwork: {
          isFetching: false,
          hasFetched: false,
          errors: payload.errorsArray
        }
      };
    case "DELETE_CLIENT_INITIATED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: { ...state.byId[payload.id], isDeleting: true }
        }
      };
    case "DELETE_CLIENT_FAILED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: { ...state.byId[payload.id], isDeleting: false }
        }
      };
    case "DELETE_CLIENT_SUCCEEDED":
      return {
        ...state,
        byId: payload.clients,
        filteredIds: payload.clientIds,
        count: payload.count,
        searchCount: payload.searchCount,
        page: payload.page
      };
    case "ADD_CLIENT":
      return { ...state, byId: { ...state.byId, [payload.id]: payload } };
    case "UPDATE_CLIENT_NAME":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: { ...state.byId[payload.id], name: payload.name }
        }
      };
    case "GET_CLIENT_SOCIAL_DATA_INITIATED":
      return { ...state, isGettingSocialData: true, hasSocialDataError: false };
    case "GET_CLIENT_SOCIAL_DATA_SUCCEEDED":
      return {
        ...state,
        isGettingSocialData: false,
        hasSocialDataError: false,
        byId: {
          ...state.byId,
          [payload.id]: {
            ...(state.byId[payload.id] && state.byId[payload.id]),
            ...payload
          }
        }
      };
    case "GET_CLIENT_SOCIAL_DATA_FAILED":
      return { ...state, isGettingSocialData: false, hasSocialDataError: true };
    case "GET_CLIENT_ACTIVITIES_INITIATED":
      return { ...state, isLoadingClientActivities: true };
    case "GET_CLIENT_ACTIVITIES_SUCCEEDED":
      return {
        ...state,
        isLoadingClientActivities: false,
        loadingClientActivitiesError: null
      };
    case "GET_CLIENT_ACTIVITIES_FAILED":
      return {
        ...state,
        isLoadingClientActivities: false,
        loadingClientActivitiesError: payload.error
      };
    case "INVITE_CLIENT_INITIATED":
      return { ...state, isInvitingClient: true, inviteClientError: null };
    case "INVITE_CLIENT_SUCCEEDED":
      return {
        ...state,
        isInvitingClient: false,
        byId: { ...state.byId, [payload.client.id]: payload.client },
        filteredIds: payload.filteredIds,
        count: payload.count
      };
    case "INVITE_CLIENT_FAILED":
      return { ...state, inviteClientError: payload };
    case "RESEND_CLIENT_INVITATION_INITIATED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.clientId]: {
            ...state.byId[payload.clientId],
            isResending: true
          }
        }
      };
    case "RESEND_CLIENT_INVITATION_SUCCEEDED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.clientId]: {
            ...state.byId[payload.clientId],
            isResending: false,
            hasResent: true
          }
        }
      };
    case "RESEND_CLIENT_INVITATION_FAILED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.clientId]: {
            ...state.byId[payload.clientId],
            isResending: false,
            hasResendError: true
          }
        }
      };
    case "GET_CLIENT_RECOMMENDED_LISTINGS_SUCCEEDED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.clientId]: {
            ...state.byId[payload.clientId],
            recommendedStreamItemIds: payload.recommendedStreamItemIds
          }
        }
      };

    // Stream
    case "SEND_STREAM_INVITE_SUCCEEDED":
      return { ...state, byId: payload.clients };
    case "ADD_CLIENTS_FOR_CURATION_RESOLVED":
    case "INVITE_CLIENTS_RESOLVED":
      return {
        ...state,
        byId: { ...state.byId, ...payload.clients },
        filteredIds: [...payload.clientIds, ...state.filteredIds]
      };

    // Misc
    case "UPDATE_USER_MLS_SUCCEEDED":
      return initial;
    case "CLEAR_API_TOKEN":
      return initial;
    default:
      return state;
  }
}
