import { connect } from "react-redux";
import StreamFilterPriceOverlay from "../StreamFilterPriceOverlay";

function select({ stream: { filters } }) {
  const price = filters.byId.price || {};
  const min = price.gte;
  const max = price.lte;

  return {
    min,
    max
  };
}

export default connect(select)(StreamFilterPriceOverlay);
