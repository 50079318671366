import React from "react";
import PropTypes from "prop-types";
import { Markers } from "@wrstudios/components";
import { ListingSlider as Slider } from "@wrstudios/components";

function ListingSlider({ listingId, setFullscreenMediaDetails, ...props }) {
  return (
    <Slider
      {...props}
      subjectPropertyPin={<Markers.SubjectPropertyMarker as="div" />}
      onMapClick={() => {
        setFullscreenMediaDetails({
          id: listingId,
          open: true,
          tab: "map",
          photoIndex: 0
        });
      }}
      onPhotosClick={() => {
        setFullscreenMediaDetails({
          id: listingId,
          open: true,
          tab: "photos",
          photoIndex: 0
        });
      }}
      onPhotoClick={(index) => {
        setFullscreenMediaDetails({
          id: listingId,
          open: true,
          tab: "photos",
          photoIndex: index
        });
      }}
      onTourClick={() => {
        setFullscreenMediaDetails({
          id: listingId,
          open: true,
          tab: "tour",
          photoIndex: 0
        });
      }}
    />
  );
}

ListingSlider.propTypes = {
  listingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  setFullscreenMediaDetails: PropTypes.func.isRequired
};

export default ListingSlider;
