import { connect } from "react-redux";
import { ListingHeader } from "@wrstudios/components";

function select(
  { application, streamItems, listings },
  { streamItemId, listingId }
) {
  const isAuthed = !!application.railsApiToken && !!application.forgeToken;
  const streamItem = streamItems.byId[streamItemId] || {};
  const streamId = streamItem.streamId || "";
  const { address, subAddress, bedsFormatted, bathsFormatted, sqftFormatted } =
    listings.byId[listingId] || {};

  return {
    streamId,
    showBackButton: !!streamId && isAuthed,
    address,
    subAddress,
    bedsFormatted,
    bathsFormatted,
    sqftFormatted
  };
}

export default connect(select)(ListingHeader);
