import { connect } from "react-redux";
import { requestShowing, resetRequestShowing } from "../../../actions/listing";
import ListingRequestShowing from "../ListingRequestShowing";

function select({ listings, streamItems, user }, { streamItemId }) {
  const streamItem = streamItems.byId[streamItemId];
  return {
    user,
    listing: listings.byId[streamItem.itemId],
    network: listings.requestShowingNetwork
  };
}

const actions = {
  requestShowing,
  resetRequestShowing
};

export default connect(select, actions)(ListingRequestShowing);
