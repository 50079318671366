export const initial = {
  byId: {},
  getStreamSubscriptionNetwork: {
    isFetching: false,
    hasFetched: false,
    errors: []
  }
};

export function subscriptions(state = initial, { type, payload }) {
  switch (type) {
    case "GET_STREAM_SUCCEEDED":
    case "GET_STREAMS_SUCCEEDED":
    case "SET_PRIMARY_ALERT_TYPE_RESOLVED":
    case "ADD_CLIENTS_FOR_CURATION_RESOLVED":
    case "CREATE_STREAM_SUCCEEDED":
      return { ...state, byId: { ...state.byId, ...payload.subscriptions } };
    case "GET_STREAM_SUBSCRIPTION_INITIATED":
      return {
        ...state,
        getStreamNetwork: { isFetching: true, hasFetched: false, errors: [] }
      };
    case "GET_STREAM_SUBSCRIPTION_FAILED":
      return {
        ...state,
        getStreamSubscriptionNetwork: {
          isFetching: false,
          hasFetched: false,
          errors: payload.errorsArray
        }
      };
    case "GET_STREAM_SUBSCRIPTION_SUCCEEDED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.subscription.id]: payload.subscription
        },
        getStreamSubscriptionNetwork: {
          isFetching: false,
          hasFetched: true,
          errors: []
        }
      };
    case "CREATE_STREAM_SUBSCRIPTION_SUCCESS":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.subscription.id]: {
            ...state.byId[payload.subscription.id],
            ...payload.subscription
          }
        }
      };
    case "UPDATE_STREAM_SUBSCRIPTION_FREQUENCY_INITIATED":
    case "UPDATE_STREAM_SUBSCRIPTION_FREQUENCY_SUCCEEDED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: { ...state.byId[payload.id], ...payload }
        }
      };
    case "DELETE_STREAM_SUBSCRIPTION_INITIATED":
    case "DELETE_STREAM_SUBSCRIPTION_FAILED":
    case "UPDATE_STREAM_SUBSCRIPTION_FREQUENCY_FAILED":
      return { ...state, byId: payload.subscriptions };
    case "CLEAR_API_TOKEN":
      return initial;
    default:
      return state;
  }
}
