import React from "react";
import PropTypes from "prop-types";
import { STREAM_ITEM_FILTER_TYPES } from "../../modules/streamItem";
import { Container } from "./styled/stream-item-empty-state";
const { ALL, RECOMMENDED, LIKED, COMMENTED, HIDDEN } = STREAM_ITEM_FILTER_TYPES;

function StreamItemsEmptyState({ filter }) {
  return (
    <Container>
      {filter === ALL &&
        "No listings available. Try editing your search criteria."}
      {filter === RECOMMENDED &&
        "There are no recommended listings on this stream."}
      {filter === LIKED && "No one has liked a property on this stream."}
      {filter === COMMENTED && "No one has commented on this stream."}
      {filter === HIDDEN && "You have no hidden properties on this stream."}
    </Container>
  );
}

StreamItemsEmptyState.propTypes = {
  filter: PropTypes.oneOf(Object.values(STREAM_ITEM_FILTER_TYPES)).isRequired
};

export default StreamItemsEmptyState;
