import transition from "styled-transition-group";

export const Transition = transition.div`
  z-index: 2;
  &:enter {
    opacity: 0;
  }
  &:enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
  }
  &:exit {
    opacity: 1;
  }
  &:exit-active {
    opacity: 0;
    transition: opacity 200ms ease-in;
  }
`;
