import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import FormGroup from "../../common/FormGroup";
import Selector from "../../common/Selector";
import StreamFilter from "./StreamFilter";

class StreamFilterPropertyType extends Component {
  state = {
    selectedPropertyTypes: null,
    selectedPropertySubTypes: null
  };

  static getDerivedStateFromProps(props, state) {
    return {
      selectedPropertyTypes:
        state.selectedPropertyTypes === null && props.hasSelectedPropertyTypes
          ? props.selectedPropertyTypes
          : state.selectedPropertyTypes,
      selectedPropertySubTypes:
        state.selectedPropertySubTypes === null &&
        props.hasSelectedPropertySubTypes
          ? props.selectedPropertySubTypes
          : state.selectedPropertySubTypes
    };
  }

  render() {
    const selectedPropertyTypes = this.state.selectedPropertyTypes || [];
    const selectedPropertyTypesCount = selectedPropertyTypes.length;
    const selectedPropertySubTypes = this.state.selectedPropertySubTypes || [];
    const selectedPropertySubTypesCount = selectedPropertySubTypes.length;

    return (
      <StreamFilter
        hasSelectedValues={!!this.props.buttonLabel}
        label={this.props.buttonLabel || "Property Types"}
        onClear={this.handleOnClear}
        onClose={this.handleOnClose}>
        {() => (
          <>
            <FormGroup>
              <Selector
                label="Property Type"
                triggerLabel={`Select Types${
                  selectedPropertyTypesCount > 0
                    ? ` (${selectedPropertyTypesCount})`
                    : ""
                }`}
                options={this.props.propertyTypes}
                selectedOptions={this.state.selectedPropertyTypes || []}
                onChange={this.handleOnPropertyTypeChange}
              />
            </FormGroup>
            {this.props.hasPropertySubTypes && (
              <FormGroup>
                <Selector
                  label="Property Sub Type"
                  triggerLabel={`Select Sub Types${
                    selectedPropertySubTypesCount > 0
                      ? ` (${selectedPropertySubTypesCount})`
                      : ""
                  }`}
                  options={this.props.propertySubTypes}
                  selectedOptions={this.state.selectedPropertySubTypes || []}
                  onChange={this.handleOnPropertySubTypeChange}
                />
              </FormGroup>
            )}
          </>
        )}
      </StreamFilter>
    );
  }

  handleOnPropertyTypeChange = (e) => {
    const { value, checked } = e.target;

    this.setState(({ selectedPropertyTypes }) => {
      selectedPropertyTypes = selectedPropertyTypes || [];

      if (!checked) {
        return {
          selectedPropertyTypes: selectedPropertyTypes.filter(
            (propertyType) => propertyType !== value
          )
        };
      } else {
        return {
          selectedPropertyTypes: [...new Set([...selectedPropertyTypes, value])]
        };
      }
    });
  };

  handleOnPropertySubTypeChange = (e) => {
    const { value, checked } = e.target;

    this.setState(({ selectedPropertySubTypes }) => {
      selectedPropertySubTypes = selectedPropertySubTypes || [];

      if (!checked) {
        return {
          selectedPropertySubTypes: selectedPropertySubTypes.filter(
            (propertySubType) => propertySubType !== value
          )
        };
      } else {
        return {
          selectedPropertySubTypes: [
            ...new Set([...selectedPropertySubTypes, value])
          ]
        };
      }
    });
  };

  handleOnClear = () => {
    this.setState({
      selectedPropertyTypes: [],
      selectedPropertySubTypes: []
    });
  };

  handleOnClose = () => {
    const hasSelectedPropertyTypesChanged = !isEqual(
      this.state.selectedPropertyTypes,
      this.props.selectedPropertyTypes
    );
    const hasSelectedPropertyTypes =
      this.state.selectedPropertyTypes !== null &&
      !!this.state.selectedPropertyTypes.length;
    const hasSelectedPropertySubTypesChanged = !isEqual(
      this.state.selectedPropertySubTypes,
      this.props.selectedPropertySubTypes
    );
    const hasSelectedPropertySubTypes =
      this.state.selectedPropertySubTypes !== null &&
      !!this.state.selectedPropertySubTypes.length;

    if (!hasSelectedPropertyTypesChanged && !hasSelectedPropertySubTypesChanged)
      return;

    let filters = {};

    if (hasSelectedPropertyTypes) {
      filters = {
        ...filters,
        prop_type: {
          eq: this.state.selectedPropertyTypes
        }
      };
    }

    if (hasSelectedPropertySubTypes) {
      filters = {
        ...filters,
        prop_sub_type: {
          eq: this.state.selectedPropertySubTypes
        }
      };
    }

    this.props.replaceStreamFilters({ ...this.props.filters, ...filters });
  };
}

StreamFilterPropertyType.propTypes = {
  filters: PropTypes.object.isRequired,
  propertyTypes: PropTypes.array.isRequired,
  hasPropertySubTypes: PropTypes.bool.isRequired,
  selectedPropertyTypes: PropTypes.array,
  hasSelectedPropertyTypes: PropTypes.bool.isRequired,
  propertySubTypes: PropTypes.array.isRequired,
  selectedPropertySubTypes: PropTypes.array,
  hasSelectedPropertySubTypes: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  replaceStreamFilters: PropTypes.func.isRequired
};

export default StreamFilterPropertyType;
