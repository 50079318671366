import { connect } from "react-redux";
import { createStream, renameStream } from "../../../actions/stream";
import { generateAutocomplete } from "../utils";
import StreamName from "../StreamName";

function select({ streams, stream, listings }, { streamId }) {
  const streamById = streams.byId[streamId] || {};
  const hasStream = !!Object.values(streamById).length;
  const filters = Object.values(stream.filters.byId);
  const autocomplete = generateAutocomplete(stream.filters.byId);
  const isMapShowing = stream.isMapShowing;
  const isLoading = listings.isLoading;
  const isSaving = stream.isSaving;
  const hasFilters = !!filters.length;
  const name = streamById.name;
  const hasName = !!name;

  return {
    hasStream,
    filters,
    hasFilters,
    autocomplete,
    name: hasName ? name : "",
    hasName,
    isMapShowing,
    isLoading,
    isSaving
  };
}

const actions = {
  createStream,
  renameStream
};

export default connect(select, actions)(StreamName);
