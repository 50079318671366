import React from "react";
import PropTypes from "prop-types";
import IconComment from "../icons/IconComment";
import { Container, CommentCount } from "./styled/stream-item-comment";

function StreamItemComment({ commentsCount, hasComments, ...props }) {
  return (
    <Container {...props}>
      <IconComment />{" "}
      {hasComments && <CommentCount>{commentsCount}</CommentCount>}
    </Container>
  );
}

StreamItemComment.propTypes = {
  isLink: PropTypes.bool,
  streamItemId: PropTypes.string.isRequired,
  commentsCount: PropTypes.number,
  hasComments: PropTypes.bool.isRequired,
  hasCommentsHash: PropTypes.bool.isRequired
};

export default StreamItemComment;
