import { connect } from "react-redux";
import { setStreamMapShowing } from "../../../actions/stream";
import StreamMapToggle from "../StreamMapToggle";

function select({ stream: { isMapShowing } }) {
  return {
    isMapShowing
  };
}

const actions = {
  setStreamMapShowing
};

export default connect(select, actions)(StreamMapToggle);
