import { connect } from "react-redux";
import ActivityContent from "../ActivityContent";

function select({ activities }, { activityId }) {
  const activity = activities.byId[activityId] || {};

  return {
    updatedAtAgo: activity.updatedAtAgo || ""
  };
}

export default connect(select)(ActivityContent);
