import { pickBy, identity } from "lodash";
import { connect } from "react-redux";
import { getMLSFineTuningField } from "../../../../actions/mls";
import { replaceStreamFilters } from "../../../../actions/stream";
import StreamFilterAdvanced from "../StreamFilterAdvanced";

function select({ mls: { fineTuningFields }, stream: { filters } }) {
  const {
    byId: {
      city,
      area,
      zip,
      prop_type,
      prop_sub_type,
      price,
      price_list,
      beds,
      baths_search,
      sqft,
      status,
      location,
      ...additionalFilters
    }
  } = filters;
  const primaryFilters = pickBy(
    {
      city,
      area,
      zip,
      location,
      prop_type,
      price,
      price_list,
      beds,
      baths_search,
      sqft,
      status
    },
    identity
  );
  const fields = Object.values(fineTuningFields.byId);
  const fieldsLabels = fields.map(({ label }) => label);

  return {
    fields,
    fieldsLabels,
    fieldsById: fineTuningFields.byId,
    filters: additionalFilters,
    primaryFilters
  };
}

const actions = {
  replaceStreamFilters,
  getMLSFineTuningField
};

export default connect(select, actions)(StreamFilterAdvanced);
