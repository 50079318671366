import styled from "styled-components";
import {
  Button as BaseButton,
  RouteContainer as BaseRouteContainer
} from "@wrstudios/components";
import { default as BaseLink } from "../../../common/NavLink";

export const RouteContainer = styled(BaseRouteContainer)`
  max-width: ${({ small }) => (small ? 43.6 : 68.2)}rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.4rem;
`;

export const Title = styled.h2`
  ${({ theme }) => theme.text.h2};
`;

export const Invalid = styled.h2`
  background-color: ${({ theme }) => theme.colors.red};
  padding: 0.8rem 1.4rem;
  border-radius: 0.4rem;
  text-align: center;
  margin-top: 2.4rem;
  font-size: 1.6rem;
  font-weight: 400;
  color: white;
`;

export const SubTitle = styled.h3`
  ${({ theme }) => theme.text.h3};
  margin-bottom: ${({ noMargin }) => (noMargin ? "0px" : "2.4rem")};
`;

export const Body = styled.div`
  padding: 2.5rem 2.4rem 3.1rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.greyLighter};
  border-radius: 0.4rem;
  background-color: ${({ theme }) => theme.colors.white};

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    margin-top: 2rem;
  }
`;

export const Text = styled.p`
  ${({ theme }) => theme.text.medium};
  margin: 0 0 1.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const Form = styled.form``;

export const Group = styled.div`
  margin-bottom: 5rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const Navigation = styled.nav`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 2.4rem;
`;

export const ExternalLink = styled.a`
  flex-shrink: 0;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  margin: 0 1.2rem;
  color: ${({ theme }) => theme.colors.grey};
  border-bottom: 0.2rem solid transparent;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &.active {
    border-color: currentColor;
  }
`;

export const Link = styled(BaseLink)`
  flex-shrink: 0;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  margin: 0 1.2rem;
  color: ${({ theme }) => theme.colors.grey};
  border-bottom: 0.2rem solid transparent;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &.active {
    border-color: currentColor;
  }
`;

export const Button = styled(BaseButton)`
  margin-top: 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    width: 100%;
  }
`;
