import "@helix/styles/dist/helix.css";
import { HelixThemeProvider } from "@helix/theming";
import { getCurrentUrl, navigateTo } from "@wrstudios/utils";
import "normalize.css";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router-dom";
import LoginRoute from "../routes/login/connected/LoginRoute";
import RequestPasswordResetRoute from "../routes/login/connected/RequestPasswordResetRoute";
import ResetPasswordRoute from "../routes/login/connected/ResetPasswordRoute";
import DevelopersRoute from "../routes/static/connected/Developers";
import PublicStreamItemRoute from "../routes/streams/connected/PublicStreamItemRoute";
import { emailFrequencies } from "../stream/invite/utils";
import LoggedOutApp from "./LoggedOutApp";
import AuthedApp from "./connected/AppAuthed";
import { Container } from "./styled/app";
import { GlobalStyles } from "./styled/global";

class App extends Component {
  render() {
    const { isAppScrollable, isAuthed, urlToken } = this.props;

    return (
      <Container isScrollable={isAppScrollable}>
        <Helmet>
          <title>Cloud Streams</title>
        </Helmet>
        <GlobalStyles />
        <HelixThemeProvider>
          {isAuthed ? (
            <Route component={AuthedApp} />
          ) : (
            <LoggedOutApp hasUrlToken={!!urlToken}>
              <Switch>
                <Route
                  component={PublicStreamItemRoute}
                  path="/stream_items/:streamItemId"
                />
                <Route
                  component={ResetPasswordRoute}
                  path="/password/edit/:resetToken"
                />
                <Route
                  component={RequestPasswordResetRoute}
                  path="/password/forgot"
                />
                <Route component={DevelopersRoute} path="/developers" />
                <Route component={LoginRoute} />
              </Switch>
            </LoggedOutApp>
          )}
        </HelixThemeProvider>
      </Container>
    );
  }

  componentDidMount() {
    this.props.handleAppMount();
    this.loginIfUrlTokenPresent();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isAuthed && this.props.isAuthed) {
      const currentUrl = getCurrentUrl({
        paramsToRemove: ["token", "impersonate"]
      });

      navigateTo(currentUrl, { replace: true });
    }

    if (prevProps.isAuthed && !this.props.isAuthed) {
      navigateTo("/login", { replace: true });
    }
  }

  loginIfUrlTokenPresent = () => {
    const {
      urlToken,
      isImpersonating,
      isNewUser,
      isSSOUser,
      clientId,
      agentId,
      emailFrequency,
      invitationGuid,
      streamSubscriptionId,
      setInvitationAcceptanceCredentials,
      login
    } = this.props;

    if (isNewUser) {
      setInvitationAcceptanceCredentials({
        token: urlToken,
        clientId,
        agentId,
        emailFrequency,
        invitationGuid,
        streamSubscriptionId,
        isImpersonating: false
      });
    }

    if (urlToken) {
      login({ token: urlToken, isImpersonating, isSSOUser });
    }
  };
}

App.defaultProps = {
  isAppScrollable: true
};

App.propTypes = {
  urlToken: PropTypes.string,
  isNewUser: PropTypes.bool,
  isSSOUser: PropTypes.bool,
  isAuthed: PropTypes.bool.isRequired,
  clientId: PropTypes.string,
  agentId: PropTypes.string,
  invitationGuid: PropTypes.string,
  streamSubscriptionId: PropTypes.string,
  isAppScrollable: PropTypes.bool,
  isImpersonating: PropTypes.bool.isRequired,
  emailFrequency: PropTypes.oneOf(Object.values(emailFrequencies)),
  handleAppMount: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  setInvitationAcceptanceCredentials: PropTypes.func.isRequired
};

export default App;
