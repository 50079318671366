import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "@wrstudios/components";
import { timer } from "../../utils/timer";
import Input from "./Input";
import Clipboard from "react-copy-to-clipboard";
import { Container } from "./styled/input-copy";

class InputCopy extends Component {
  state = {
    copied: false
  };

  render() {
    return (
      <Container>
        <Input id={this.props.id} value={this.props.value} readOnly />
        <Clipboard text={this.props.value} onCopy={this.handleOnCopy}>
          <Button>
            {this.state.copied ? this.props.copiedText : this.props.copyText}
          </Button>
        </Clipboard>
      </Container>
    );
  }

  componentDidUpdate(_, prevState) {
    if (!prevState.copied && this.state.copied) {
      this.setLabel();
    }
  }

  handleOnCopy = () => {
    this.setState({ copied: true });
  };

  setLabel = async () => {
    await timer(this.props.copyTextDelay);

    this.setState({ copied: false });
  };
}

InputCopy.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string.isRequired,
  copyText: PropTypes.string.isRequired,
  copiedText: PropTypes.string.isRequired,
  copyTextDelay: PropTypes.number.isRequired
};

InputCopy.defaultProps = {
  copyText: "Copy",
  copiedText: "Copied!",
  copyTextDelay: 2000
};

export default InputCopy;
