import { getInitials } from "@wrstudios/utils";
import { getInitialsColor } from "@wrstudios/theme";
import { transformClients } from "./client";
import { transformSocialData } from "./client";

export function transformUser(user) {
  const socialData = transformSocialData(user);
  const isAgent = user.type === "Agent";
  const isClient = user.type === "Client";
  const agents = transformAgents(user.agents || []);

  return {
    id: user.id || "",
    name: user.name || "",
    initials: getInitials(user.name || user.email),
    initialsColor: getInitialsColor(user.name || user.email),
    email: user.email || "",
    phone: user.phone_number || "",
    officePhone: user.business_phone || "",
    city: user.city || "",
    state: user.state || "",
    company: user.company_name || "",
    website: user.website || "",
    license: user.license_number || "",
    timezone: user.time_zone || "",
    avatar: user.avatar || socialData.avatar || "",
    type: user.type || "",
    isCASUser: !!user.cas_user_id,
    casUserId: user.cas_user_id ? user.cas_user_id + "" : "",
    casJwt: user.cas_jwt || "",
    lwaId: user.lwa_id || "",
    lwaJwt: user.lwa_jwt || "",
    isAgent,
    isClient,
    isSubscribedToClientDigest: !!user.client_digest,
    criteriaOnboarding: user.criteria_onboarding || false,
    streamsCount: user.streams_count || 0,
    clientsCount: user.clients_count || 0,
    sharedClients: transformClients(user.shared_clients || []),
    guid: user.authentication_token || "",
    primaryAgent: isAgent
      ? null
      : transformPrimaryAgent(user.primary_agent || {}),
    likedStreamItemIds: (user.liked_stream_items || []).map((item) => item.id),
    agents,
    activeAgentId: (agents[0] && agents[0].id) || ""
  };
}

function transformAgents(agents) {
  return agents.map((agent) => ({
    id: agent.id || "",
    name: agent.name || "",
    initials: getInitials(agent.name),
    email: agent.email || "",
    company: agent.company_name || "",
    mls: agent.mls_name || "",
    avatar: agent.avatar || "",
    mlsCode: (agent.mls_credential || {}).code || "",
    phone: agent.phone_number || ""
  }));
}

function transformPrimaryAgent(agent = {}) {
  // TODO: add business address [blocker on #164557283]
  return {
    id: agent.id || "",
    avatar: agent.avatar || "",
    name: agent.name || "",
    initials: getInitials(agent.name || agent.email),
    initialsColor: getInitialsColor(agent.name || agent.email),
    company: agent.company_name || "",
    address: agent.street_address || "",
    city: agent.city || "",
    state: agent.state || "",
    zip: agent.postal_code || "",
    officePhone: agent.business_phone || "",
    phone: agent.phone_number || "",
    email: agent.email || ""
  };
}

export function transformUserAvatar(avatar) {
  return {
    id: avatar.id,
    avatar: avatar.avatar_url
  };
}

export function transformUserMLS(mlsCredential) {
  return {
    credentialId: mlsCredential.id,
    isValid: mlsCredential.is_valid,
    code: mlsCredential.code,
    name: mlsCredential.name
  };
}

export function getSocialUsername(profile) {
  const { username, url, type } = profile;
  if (username) {
    return `@${username}`;
  } else if (url) {
    let urlParts = url.split("/");
    return `@${urlParts[urlParts.length - 1]}`;
  } else {
    return `${type} Account`;
  }
}
