import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { Container } from "./styled/tab";

class Tab extends Component {
  ref = createRef();

  render() {
    const {
      tabId,
      tabPanelId,
      isActive,
      isHighlighted,
      onFocus,
      onClick,
      children,
      ...rest
    } = this.props;

    return (
      <Container
        ref={this.ref}
        id={tabId}
        role="tab"
        aria-controls={tabPanelId}
        aria-selected={isActive}
        tabIndex={isActive ? 0 : -1}
        isActive={isActive}
        onFocus={this.handleOnFocus}
        onClick={this.handleOnClick}
        {...rest}>
        {children}
      </Container>
    );
  }

  componentDidMount() {
    if (this.props.isActive) {
      this.props.updateActivePanelIndex(this.props.index);
    }
  }

  componentDidUpdate() {
    if (this.props.isHighlighted) {
      this.ref.current.focus();
    }
  }

  handleOnFocus = (e) => {
    if (this.props.isActive) {
      this.props.resetHighlightIndexToActivePanelIndex();
    }

    this.props.onFocus();
  };

  handleOnClick = (e) => {
    this.props.updateActivePanelIndex(this.props.index);
    this.props.onClick(e);
  };
}

Tab.propTypes = {
  index: PropTypes.number,
  tabId: PropTypes.string,
  tabPanelId: PropTypes.string,
  isActive: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  updateActivePanelIndex: PropTypes.func,
  resetHighlightIndexToActivePanelIndex: PropTypes.func,
  onFocus: PropTypes.func,
  onClick: PropTypes.func
};

Tab.defaultProps = {
  onFocus: function () {},
  onClick: function () {}
};

export default Tab;
