import styled from "styled-components";

const Truncate = styled.span`
  flex-grow: 1;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Container = styled(Truncate)`
  flex-grow: 0;
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin-left: auto;
`;
