import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import IconSearch from "../icons/IconSearch";
import { Container, Button, Input, Bar } from "./styled/client-cards-search";

class ClientCardsSearch extends Component {
  inputRef = createRef();

  state = {
    focusInput: false,
    value: this.props.searchTerm || ""
  };

  componentDidUpdate() {
    if (this.state.focusInput) {
      this.inputRef.current.focus();
    }
  }

  render() {
    return (
      <Container
        data-testid="clients-search-form"
        hasValue={!!this.state.value}
        onSubmit={this.handleOnSubmit}>
        <Button type="button" onClick={this.handleOnClick}>
          <IconSearch />
        </Button>
        <Input
          data-testid="clients-search-input"
          ref={this.inputRef}
          value={this.state.value}
          placeholder="Search Clients"
          onChange={this.handleOnChange}
          onBlur={this.handleOnBlur}
        />
        <Bar />
      </Container>
    );
  }

  handleOnSubmit = (e) => {
    e.preventDefault();

    if (this.props.searchTerm !== this.state.value) {
      this.props.searchClients({ searchTerm: this.state.value });
    }
  };

  handleOnClick = () => {
    this.setState({ focusInput: true });
  };

  handleOnBlur = () => {
    this.setState({ focusInput: false });
  };

  handleOnChange = (e) => {
    this.setState({ value: e.target.value });
  };
}

ClientCardsSearch.propTypes = {
  searchTerm: PropTypes.string,
  searchClients: PropTypes.func.isRequired
};

export default ClientCardsSearch;
