import { currencyShort, pluralize } from "@wrstudios/utils";

/**
 * Grabs the first location of a stream filter. Used in autocompletes.
 *
 * @param {Object} filters
 */
function generateLocations({ city = {}, zip = {}, area = {}, location = {} }) {
  const parsedLocations = JSON.parse(location.within || "[]");
  const customAreasLabels = parsedLocations.map(
    (_, index) => `Custom Area ${index + 1}`
  );
  const hasCustomAreasLabels = !!customAreasLabels.length;

  // prettier-ignore
  const locations = hasCustomAreasLabels ? customAreasLabels : [
    ...(city.should ? city.should : []),
    ...(zip.should ? zip.should : []),
    ...(area.should ? area.should : [])
  ]

  return locations[0];
}

/**
 * Grabs the price of a stream filter. Used in autocompletes.
 *
 * @param {Object} filters
 */
function generatePrice({ price = {} }) {
  const min = price.gte ? currencyShort(price.gte) : "";
  const max = price.lte ? currencyShort(price.lte) : "";

  return [min, max].filter(Boolean).join("-");
}

/**
 * Grabs the beds of a stream filter. Used in autocompletes.
 *
 * @param {Object} filters
 */
function generateBeds({ beds = {} }) {
  if (!Number(beds.gte)) return;

  return pluralize("Bed", Number(beds.gte), true);
}

/**
 * Grabs the baths of a stream filter. Used in autocompletes.
 *
 * @param {Object} filters
 */
function generateBaths({ baths_search = {} }) {
  if (!Number(baths_search.gte)) return;

  return pluralize("Bath", Number(baths_search.gte), true);
}

/**
 * Grabs the sqft of a stream filter. Used in autocompletes.
 *
 * @param {Object} filters
 */
function generateSqft({ sqft = {} }) {
  if (!Number(sqft.gte)) return;

  return `${sqft.gte}+ sqft`;
}

/**
 * Generates the name auto complete items.
 *
 * @param {Object} filters
 */
export function generateAutocomplete(filters) {
  const location = generateLocations(filters);
  const prices = generatePrice(filters);
  const beds = generateBeds(filters);
  const baths = generateBaths(filters);
  const sqft = generateSqft(filters);

  let autocomplete = [];

  if (location) autocomplete = [...autocomplete, `${location} homes`];
  if (prices) autocomplete = [...autocomplete, `${location} ${prices}`];
  if (beds) autocomplete = [...autocomplete, `${location} with ${beds}`];
  if (baths) autocomplete = [...autocomplete, `${location} with ${baths}`];
  if (sqft) autocomplete = [...autocomplete, `${location} with ${sqft}`];

  return autocomplete;
}
