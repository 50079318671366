import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4.8rem 1.6rem 5.6rem;
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 2.2rem;
  border-radius: 0.4rem;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.greyLighter};
  color: ${({ theme }) => theme.colors.greyDark};
`;
