import React from "react";
import UserPhoneVerify from "../../user/connected/UserPhoneVerify";
import IconKeyboardArrowLeft from "../../icons/IconKeyboardArrowLeft";
import StreamEmailFrequency from "../StreamEmailFrequency";
import StreamSmsFrequency from "../StreamSmsFrequency";
import {
  Container,
  SectionHeading,
  UserLayout,
  CurrentUser,
  Avatar,
  FrequenciesLayout,
  VerifyStep,
  Actions,
  Back
} from "./styled/stream-invite-my-alerts";
import { useDispatch } from "react-redux";

function StreamInviteMyAlerts({
  user,
  streamId,
  subscriptionId,
  emailFrequency,
  smsFrequency,
  updateStreamSubscriptionFrequency
}) {
  const dispatch = useDispatch();

  return (
    <Container>
      <SectionHeading>My Alerts</SectionHeading>
      <UserLayout>
        <CurrentUser>
          <Avatar size="sm" initials={user.initials} avatarUrl={user.avatar} />
          {user.name}
        </CurrentUser>
        <FrequenciesLayout>
          <StreamEmailFrequency
            initialValue={emailFrequency}
            showLabel={false}
            onSelect={(newEmailFrequency) =>
              updateStreamSubscriptionFrequency({
                emailFrequency: newEmailFrequency,
                smsFrequency,
                subscriptionId,
                streamId
              })
            }
          />
          <StreamSmsFrequency
            phone={user.phone}
            initialValue={smsFrequency}
            showLabel={false}
            onClose={() => dispatch({ type: "OPEN_USER_VERIFY_MODAL" })}
            onSelect={(newSmsFrequency) => {
              updateStreamSubscriptionFrequency({
                smsFrequency: newSmsFrequency,
                emailFrequency,
                subscriptionId,
                streamId
              });
            }}
            renderVerifyComponent={({ onVerifySuccess, onClose }) => (
              <VerifyStep onWheel={(e) => e.stopPropagation()}>
                <UserPhoneVerify
                  onClose={() => {
                    dispatch({ type: "CLOSE_USER_VERIFY_MODAL" });
                    onClose();
                  }}
                  onSuccess={onVerifySuccess}
                />
                <Actions>
                  <Back
                    onClick={() => {
                      dispatch({ type: "CLOSE_USER_VERIFY_MODAL" });
                      onClose();
                    }}>
                    <IconKeyboardArrowLeft /> Back to alert options
                  </Back>
                </Actions>
              </VerifyStep>
            )}
          />
        </FrequenciesLayout>
      </UserLayout>
    </Container>
  );
}

export default StreamInviteMyAlerts;
