export const initial = {
  byId: {}
};

export function pins(state = initial, { type, payload }) {
  switch (type) {
    case "GET_FORGE_PINS_SUCCEEDED":
      return { ...state, byId: { ...state.byId, ...payload } };
    case "UPDATE_USER_MLS_SUCCEEDED":
    case "CLEAR_API_TOKEN":
    case "RESET_FORGE_PINS":
      return initial;
    default:
      return state;
  }
}
