import React, { Component } from "react";
import PropTypes from "prop-types";
import { formatNumber } from "@wrstudios/utils";
import Label from "../../common/Label";
import Input from "../../common/Input";
import { Grid, Cell, Spacer } from "./styled/stream-filter-price-overlay";

class StreamFilterPriceOverlay extends Component {
  state = {
    min: null,
    max: null
  };

  static getDerivedStateFromProps(props, state) {
    return {
      min:
        state.min === null &&
        typeof props.min !== "undefined" &&
        props.min !== "0"
          ? props.min
          : state.min,
      max:
        state.max === null &&
        typeof props.max !== "undefined" &&
        props.max !== "0"
          ? props.max
          : state.max
    };
  }

  render() {
    let minFormatted = formatNumber(this.state.min, "$0,0");
    if (minFormatted === "$0" || minFormatted === "$") minFormatted = "";

    let maxFormatted = formatNumber(this.state.max, "$0,0");
    if (maxFormatted === "$0" || maxFormatted === "$") maxFormatted = "";

    return (
      <>
        <Label htmlFor="price-min">Price Range</Label>
        <Grid>
          <Cell>
            <Input
              id="price-min"
              name="min"
              value={minFormatted}
              placeholder="No min"
              onChange={this.handleOnChange}
            />
          </Cell>
          <Cell>
            <Spacer>-</Spacer>
          </Cell>
          <Cell>
            <Input
              name="max"
              value={maxFormatted}
              placeholder="No Max"
              onChange={this.handleOnChange}
            />
          </Cell>
        </Grid>
      </>
    );
  }

  componentDidMount() {
    if (this.state.min !== null || this.state.max !== null) {
      this.sendFilter();
    }
  }

  componentDidUpdate(_, prevState) {
    if (
      (prevState.min === null && this.state.min !== null) ||
      (prevState.max === null && this.state.max !== null)
    ) {
      this.sendFilter();
    }
  }

  handleOnChange = (e) => {
    let formattedValue = formatNumber(e.target.value, "$0,0");
    if (formattedValue === "$0" || formattedValue === "$") formattedValue = "";
    if (formattedValue.startsWith("-"))
      formattedValue = formattedValue.substr(1);

    this.setState({ [e.target.name]: formattedValue }, this.sendFilter);
  };

  sendFilter = () => {
    let price = {};

    if (this.state.min) {
      price = {
        ...price,
        field: "price",
        gte: String(formatNumber(this.state.min, "0"))
      };
    }

    if (this.state.max) {
      price = {
        ...price,
        field: "price",
        lte: String(formatNumber(this.state.max, "0"))
      };
    }

    this.props.onChange({ price });
  };
}

StreamFilterPriceOverlay.propTypes = {
  min: PropTypes.string,
  max: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default StreamFilterPriceOverlay;
