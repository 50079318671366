import { getBaseUrl } from "@wrstudios/utils";
import { connect } from "react-redux";
import { logout } from "../../../actions/app";
import LegacyNavigation from "../LegacyNavigation";

function select({
  application: { isAppHeaderSticky, railsApiToken, forgeToken },
  user
}) {
  return {
    isAuthed: !!railsApiToken && !!forgeToken,
    isClient: user.isClient,
    showLikedNavLink: user.isClient && user.likedStreamItemIds.length > 0,
    isAppHeaderSticky,
    userId: user.id,
    userEmail: user.email,
    userAvatar: user.avatar,
    userName: user.name,
    casUserId: user.casUserId,
    casJwt: user.casJwt,
    lwaJwt: user.lwaJwt,
    lwaid: user.lwaId,
    mlsCode: user.mlsCode,
    isImpersonating: user.isImpersonating,
    adminUrl: `${getBaseUrl()}/admin/users/${user.id}`
  };
}

const actions = {
  logout
};

export default connect(select, actions)(LegacyNavigation);
