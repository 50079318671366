import React, { Component } from "react";
import PropTypes from "prop-types";
import { resetScrollPosition } from "@wrstudios/utils";
import FadeTransition from "../common/FadeTransition";
import ThreeColumnLayout from "../common/ThreeColumnLayout";
import Pagination from "../common/Pagination";
import CardCreate from "../common/CardCreate";
import ClientCardsLoading from "./ClientCardsLoading";
import ClientCard from "./connected/ClientCard";
import ClientInviteModal from "./connected/ClientInviteModal";

class ClientCards extends Component {
  state = {
    showCreateModal: false
  };

  render() {
    const { page, limit, totalCount, hasLoaded, clientIds } = this.props;

    return (
      <>
        <ThreeColumnLayout>
          <CardCreate text="Add a Client" onClick={this.handleModalOpen} />
          {clientIds.map((clientId) => (
            <ClientCard key={clientId} clientId={clientId} />
          ))}
        </ThreeColumnLayout>
        <FadeTransition mountsOn={!hasLoaded}>
          <ClientCardsLoading limit={limit + 1} />
        </FadeTransition>
        {totalCount > limit && (
          <Pagination
            currentPage={page}
            pageSize={limit}
            totalCount={totalCount}
            showInfo={false}
            showLimitSelection={false}
            handleClickPage={this.handleClickPage}
          />
        )}
        {this.state.showCreateModal && (
          <ClientInviteModal onClose={this.handleModalClose} />
        )}
      </>
    );
  }

  componentDidMount() {
    if (this.props.hasLoaded) return;
    this.props.getClients({ page: this.props.page, limit: this.props.limit });
  }

  componentWillUnmount() {
    if (this.props.page !== 1) {
      this.props.resetClients();
    }
  }

  handleModalOpen = () => {
    this.setState({ showCreateModal: true });
  };

  handleModalClose = () => {
    this.setState({ showCreateModal: false });
  };

  handleClickPage = (page) => {
    this.props.getClients({ page, limit: this.props.limit });
    resetScrollPosition();
  };
}

ClientCards.propTypes = {
  clientIds: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  getClients: PropTypes.func.isRequired,
  resetClients: PropTypes.func.isRequired
};

export default ClientCards;
