import { connect } from "react-redux";
import { MlsDisclaimer } from "@wrstudios/components";

function select({ mls }) {
  return {
    disclaimer: mls.disclaimer
  };
}

export default connect(select)(MlsDisclaimer);
