import React, { Component } from "react";
import PropTypes from "prop-types";
import { AnimatePresence, motion } from "framer-motion";
import ClientLikedListingsLoading from "./ClientLikedListingsLoading";
import StreamItem from "../stream/connected/StreamItem";
import { CardLayout, Empty } from "../routes/clients/styled/client-route";

class ClientLikedListings extends Component {
  render() {
    const { client, isLoading, likedItemIds, hasStreamItems } = this.props;
    return (
      <>
        <AnimatePresence>
          {isLoading && (
            <motion.div key="loading-state" {...animationConfig}>
              <ClientLikedListingsLoading />
            </motion.div>
          )}
          {!isLoading && !hasStreamItems && (
            <motion.div key="empty-state" {...animationConfig}>
              <Empty>
                {client.name.split(" ")[0]} has not liked any listings.
              </Empty>
            </motion.div>
          )}
          {!isLoading && hasStreamItems && (
            <motion.div key="data-state" {...animationConfig}>
              <CardLayout>
                {likedItemIds.map((likedItemId, index) => (
                  <StreamItem streamItemId={likedItemId} key={index} />
                ))}
              </CardLayout>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  }

  componentDidMount() {
    this.props.getClientLikedListings(this.props.clientId);
  }
}

ClientLikedListings.propTypes = {
  client: PropTypes.shape({
    name: PropTypes.string
  }),
  isLoading: PropTypes.bool.isRequired,
  hasStreamItems: PropTypes.bool.isRequired,
  likedItemIds: PropTypes.array,
  clientId: PropTypes.string.isRequired,
  getClientLikedListings: PropTypes.func.isRequired
};

export default ClientLikedListings;

const animationConfig = {
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
};
