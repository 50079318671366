import React from "react";
import { Link } from "@wrstudios/components";
import IconComputer from "../icons/IconComputer";
import { Container, Title, Text } from "./styled/resource-not-found";

function ResourceNotFound() {
  return (
    <Container>
      <Title>Page Not Found</Title>
      <Text>
        Awkward... wanna <Link to="/">go home</Link>?
      </Text>
      <IconComputer />
    </Container>
  );
}

export default ResourceNotFound;
