import React, { Component } from "react";
import PropTypes from "prop-types";
import FormGroup from "../../common/FormGroup";
import Label from "../../common/Label";
import Input from "../../common/Input";
import Tags from "../../common/Tags";
import Select from "../../select/Select";
import { findOptionByLabel } from "../../select/utils";
import { getSelectedFiltersWithLabels, getFormattedRawValue } from "./utils";
import {
  Container,
  Grid,
  Cell,
  Spacer
} from "./styled/stream-filter-advanced-overlay";

class StreamFilterAdvancedOverlay extends Component {
  state = {
    selectedOption: {},
    selectedFilters: null,
    selectedFiltersAsTags: [],
    singleDropdownValue: "",
    multiDropdownValues: [],
    rangeValues: {},
    label: "More Filters"
  };

  static getDerivedStateFromProps(props, state) {
    const selectedOptionFromProps =
      props.fieldsById[state.selectedOption.key] || {};
    const selectedOption = {
      ...state.selectedOption,
      ...selectedOptionFromProps
    };

    // Do we have filters? If we do, set selected filters and filters as tags.
    const hasStateSelectedFilters = state.selectedFilters !== null;
    const hasPropsSelectedFilters = !!Object.values(props.filters).length;
    const selectedFilters =
      !hasStateSelectedFilters && hasPropsSelectedFilters
        ? props.filters
        : state.selectedFilters;
    const selectedFiltersAsTags = getSelectedFiltersWithLabels(
      selectedFilters || [],
      props.fieldsById
    );
    const hasSelectedFilters = !!Object.values(selectedFilters || {}).length;

    // Do we have filters for the selected option?
    const selectedOptionSelectedFilters = hasSelectedFilters
      ? selectedFilters[selectedOption.key]
      : {};
    const hasSelectedOptionSelectedFilters = !!Object.values(
      selectedOptionSelectedFilters || {}
    ).length;

    // Multi dropdown values
    const multiDropdownFilters = hasSelectedOptionSelectedFilters
      ? selectedOptionSelectedFilters.eq ||
        selectedOptionSelectedFilters.should ||
        []
      : [];

    // Range values
    const hasLocalRangeValues = !!Object.values(state.rangeValues).length;
    let rangeValues = {};

    if (!hasLocalRangeValues && hasSelectedOptionSelectedFilters) {
      rangeValues = {
        gte: selectedOptionSelectedFilters.gte,
        lte: selectedOptionSelectedFilters.lte
      };
    } else if (hasLocalRangeValues && hasSelectedOptionSelectedFilters) {
      rangeValues = { gte: state.rangeValues.gte, lte: state.rangeValues.lte };
    }

    return {
      selectedOption,
      selectedFilters,
      selectedFiltersAsTags,
      multiDropdownFilters,
      rangeValues
    };
  }

  render() {
    const { fieldsLabels } = this.props;
    const {
      selectedOption,
      selectedFiltersAsTags,
      singleDropdownValue,
      multiDropdownFilters,
      rangeValues
    } = this.state;
    const hasSelectedOption = !!Object.values(selectedOption || {}).length;
    const hasSelectedFiltersAsTags = !!selectedFiltersAsTags.length;
    const selectedFiltersAsTagsKey = selectedFiltersAsTags
      .map(({ value }) => value)
      .join("-");

    return (
      <Container>
        <FormGroup>
          <Select
            key={selectedOption.key}
            label="Fine Tuning Options"
            value={selectedOption.label}
            items={fieldsLabels}
            onChange={this.handleOnCategoryChange}
            sortItems={true}
          />
        </FormGroup>
        {hasSelectedOption && (
          <FormGroup>
            <Label htmlFor={`select-${selectedOption.key}`}>
              {selectedOption.isRange ? "Enter" : "Choose"}{" "}
              {selectedOption.name}
            </Label>
            {selectedOption.isRange && (
              <Grid>
                <Cell>
                  <Input
                    id={`select-${selectedOption.key}`}
                    name="gte"
                    value={rangeValues.gte || ""}
                    placeholder="No min"
                    onChange={this.handleOnInputChange}
                    onBlur={this.handleOnInputBlur}
                  />
                </Cell>
                <Cell>
                  <Spacer>-</Spacer>
                </Cell>
                <Cell>
                  <Input
                    name="lte"
                    value={rangeValues.lte || ""}
                    placeholder="No Max"
                    onChange={this.handleOnInputChange}
                    onBlur={this.handleOnInputBlur}
                  />
                </Cell>
              </Grid>
            )}
            {selectedOption.isMulti && (
              <Select
                key={selectedOption.values}
                selectedItems={multiDropdownFilters}
                items={selectedOption.values}
                multi
                searchable
                onChange={this.handleOnMultiChange}
              />
            )}
            {selectedOption.isSingle && (
              <Select
                key={selectedOption.values}
                value={singleDropdownValue}
                items={selectedOption.values}
                onChange={this.handleOnSingleChange}
              />
            )}
          </FormGroup>
        )}
        {hasSelectedFiltersAsTags && (
          <FormGroup>
            <Label>Selected Options</Label>
            <Tags
              key={selectedFiltersAsTagsKey}
              tags={selectedFiltersAsTags}
              showInput={false}
              onChange={this.handleOnTagsChange}
            />
          </FormGroup>
        )}
      </Container>
    );
  }

  componentDidMount() {
    if (this.state.selectedFilters !== null) {
      this.sendFilter();
    }
  }

  componentDidUpdate(_, prevState) {
    const { selectedOption } = this.state;
    const hasSelectedOption = !!Object.values(selectedOption).length;
    const hasSelectedOptionChanged =
      prevState.selectedOption.key !== selectedOption.key;

    if (
      hasSelectedOption &&
      hasSelectedOptionChanged &&
      !selectedOption.isRange
    ) {
      this.props.getMLSFineTuningField(selectedOption.key);
    }

    if (
      prevState.selectedFilters === null &&
      this.state.selectedFilters !== null
    ) {
      this.sendFilter();
    }
  }

  handleOnCategoryChange = (category) => {
    const option = findOptionByLabel(category, this.props.fields);

    this.setState({ selectedOption: option });
  };

  handleOnInputChange = (e) => {
    const { name, value } = e.target;

    this.setState(({ selectedOption, selectedFilters, rangeValues }) => {
      selectedFilters = selectedFilters || {};

      const newRangeValues = { ...rangeValues, [name]: value };
      const selectedFilter = selectedFilters[selectedOption.key] || {};
      const removedFieldName = !value ? name : "";
      let {
        [removedFieldName]: removedField,
        field,
        ...fields
      } = selectedFilter;

      const valueFormatted = getFormattedRawValue(
        value,
        selectedOption.colType
      );
      if (valueFormatted) {
        fields = { ...fields, [name]: valueFormatted };
      }

      const hasFields = !!Object.values(fields).length;
      let newSelectedFilters = {
        ...selectedFilters,
        [selectedOption.key]: fields
      };

      // We need to remove the filter if both fields are blank.
      if (!hasFields) {
        const {
          [selectedOption.key]: removedFilter,
          ...filters
        } = newSelectedFilters;
        newSelectedFilters = filters;
      }

      return {
        rangeValues: newRangeValues,
        selectedFilters: newSelectedFilters
      };
    }, this.sendFilter);
  };

  handleOnInputBlur = () => {
    this.setState(({ selectedOption, rangeValues }) => {
      let newRangeValues = {};

      if (rangeValues.gte) {
        newRangeValues = {
          ...newRangeValues,
          gte: getFormattedRawValue(rangeValues.gte, selectedOption.colType)
        };
      }

      if (rangeValues.lte) {
        newRangeValues = {
          ...newRangeValues,
          lte: getFormattedRawValue(rangeValues.lte, selectedOption.colType)
        };
      }

      return {
        rangeValues: newRangeValues
      };
    });
  };

  handleOnMultiChange = (_, values) => {
    this.setState(({ selectedOption, selectedFilters }) => {
      selectedFilters = selectedFilters || {};

      const {
        [selectedOption.key]: removedFilter,
        ...filters
      } = selectedFilters;
      const hasValues = !!values.length;

      if (!hasValues) {
        return { selectedFilters: filters };
      }

      return {
        selectedFilters: {
          ...filters,
          [selectedOption.key]: { field: selectedOption.key, eq: values }
        }
      };
    }, this.sendFilter);
  };

  handleOnSingleChange = (value) => {
    this.setState(({ selectedOption, selectedFilters }) => {
      selectedFilters = selectedFilters || {};

      const {
        [selectedOption.key]: removedFilter,
        ...filters
      } = selectedFilters;
      const hasValue = !!value.length;

      if (!hasValue) {
        return { selectedFilters: filters };
      }

      return {
        selectedFilters: {
          ...filters,
          [selectedOption.key]: { field: selectedOption.key, eq: [value] }
        }
      };
    }, this.sendFilter);
  };

  handleOnTagsChange = (removedTag) => {
    this.setState(
      ({ selectedFilters }) => {
        selectedFilters = selectedFilters || {};

        const { [removedTag.key]: removedFilter, ...filters } = selectedFilters;

        return {
          selectedFilters: filters
        };
      },
      () => this.props.onChange(this.state.selectedFilters, removedTag.key)
    );
  };

  sendFilter = () => {
    this.props.onChange(this.state.selectedFilters);
  };
}

StreamFilterAdvancedOverlay.propTypes = {
  fields: PropTypes.array.isRequired,
  fieldsLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  fieldsById: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  primaryFilters: PropTypes.object.isRequired,
  getMLSFineTuningField: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default StreamFilterAdvancedOverlay;
