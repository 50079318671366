import { connect } from "react-redux";
import { replaceStreamFilters } from "../../../../actions/stream";
import StreamFilterPropertyType from "../StreamFilterPropertyType";

function select({ mls, stream }) {
  const { prop_type, prop_sub_type, ...filters } = stream.filters.byId;
  const propertyTypes = (mls.standardFields.byId.prop_type
    ? mls.standardFields.byId.prop_type.values
    : []
  ).map((type) => ({ label: type, value: type }));
  const selectedPropertyTypes = (prop_type || {}).eq || null;
  const firstSelectedPropertyType = (selectedPropertyTypes || [])[0];
  const hasSelectedPropertyTypes = !!(selectedPropertyTypes || []).length;
  const propertySubTypes = (mls.standardFields.byId.prop_sub_type
    ? mls.standardFields.byId.prop_sub_type.values
    : []
  ).map((type) => ({ label: type, value: type }));
  const hasPropertySubTypes = !!propertySubTypes.length;
  const selectedPropertySubTypes = (prop_sub_type || {}).eq || null;
  const firstSelectedPropertySubType = (selectedPropertySubTypes || [])[0];
  const hasSelectedPropertySubTypes = !!(selectedPropertySubTypes || []).length;
  const totalTypesSelected =
    (selectedPropertyTypes || []).length +
    (selectedPropertySubTypes || []).length;
  const buttonLabel =
    totalTypesSelected > 1
      ? `Property Types (${totalTypesSelected})`
      : firstSelectedPropertyType || firstSelectedPropertySubType || "";

  return {
    filters,
    propertyTypes,
    hasPropertySubTypes,
    selectedPropertyTypes,
    hasSelectedPropertyTypes,
    propertySubTypes,
    selectedPropertySubTypes,
    hasSelectedPropertySubTypes,
    buttonLabel
  };
}

const actions = {
  replaceStreamFilters
};

export default connect(select, actions)(StreamFilterPropertyType);
