import styled from "styled-components";

export const Container = styled.div`
  position: relative;
`;

export const Message = styled.div`
  transform: translate(-50%, 100%);
  position: absolute;
  bottom: -1rem;
  left: 50%;

  &:before {
    content: "";
    width: 1.2rem;
    height: 1.2rem;
    background-color: ${({ theme, color }) => color || theme.colors.greyDark};
    border-radius: 0.2rem;
    transform: translate(-50%, -50%) rotate(45deg);
    position: absolute;
    top: 0.2rem;
    left: 50%;
  }
`;

export const MessageInner = styled.div`
  font-size: 1.1rem;
  line-height: 1.5rem;
  padding: 0.7rem 0.8rem;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, color }) => color || theme.colors.greyDark};
  border-radius: 0.3rem;
  position: relative;
`;
