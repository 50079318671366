import React from "react";
import PropTypes from "prop-types";
import IconError from "../icons/IconError";
import { Container } from "./styled/flash";

function Flash({ status, children, ...rest }) {
  return (
    <Container status={status} {...rest}>
      <IconError />
      {children}
    </Container>
  );
}

Flash.propTypes = {
  status: PropTypes.oneOf(["info", "success", "error"]).isRequired
};

Flash.defaultProps = {
  status: "info"
};

export default Flash;
