import styled from "styled-components";

export const Title = styled.h1`
  ${({ theme }) => theme.text.h1};

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    width: 100%;
    text-align: center;
  }
`;

export const Body = styled.div`
  position: relative;
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }
`;

export const CardLayout = styled.div`
  display: grid;
  grid-template-columns: 33.333fr 33.333fr 33.333fr;
  grid-column-gap: 2.4rem;
  grid-row-gap: 2.4rem;

  @media (max-width: 700px) {
    grid-template-columns: 100fr;
  }
`;

export const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1 !important;
`;
