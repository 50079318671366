import { connect } from "react-redux";
import { replaceStreamFilters } from "../../../../actions/stream";
import StreamFilterPrice from "../StreamFilterPrice";

function select({ stream }) {
  const { price = {}, ...filters } = stream.filters.byId;
  const min = price.gte;
  const max = price.lte;

  return {
    filters,
    min,
    max
  };
}

const actions = {
  replaceStreamFilters
};

export default connect(select, actions)(StreamFilterPrice);
