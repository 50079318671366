import React, { Component, Children, cloneElement } from "react";
import PropTypes from "prop-types";

class TabList extends Component {
  state = {
    highlightedIndex: null
  };

  componentDidUpdate(prevProps) {
    const { activePanelIndex } = this.props;

    if (prevProps.activePanelIndex !== activePanelIndex) {
      this.setState({ highlightedIndex: activePanelIndex });
    }
  }

  render() {
    const {
      tabId,
      tabPanelId,
      isPanelActive,
      activePanelIndex,
      updateActivePanelIndex,
      children,
      ...rest
    } = this.props;

    return (
      <div
        role="tablist"
        aria-label="Tab List"
        onKeyDown={this.handleOnKeyDown}
        {...rest}>
        {Children.map(children, (child, index) => {
          const isActive = activePanelIndex === index;
          const isHighlighted = this.state.highlightedIndex === index;

          return cloneElement(child, {
            index,
            tabId,
            tabPanelId,
            isActive,
            isHighlighted,
            updateActivePanelIndex,
            resetHighlightIndexToActivePanelIndex: this
              .handleResetHighlightIndexToActivePanelIndex,
            ...child.props
          });
        })}
      </div>
    );
  }

  handleOnKeyDown = (e) => {
    if (e.key === "ArrowRight") {
      e.preventDefault();

      this.setState(({ highlightedIndex }, { children }) => {
        const childrenLength = Children.toArray(children).length;
        const offset = highlightedIndex + childrenLength + 1;

        return { highlightedIndex: offset % childrenLength };
      });
    } else if (e.key === "ArrowLeft") {
      e.preventDefault();

      this.setState(({ highlightedIndex }, { children }) => {
        const childrenLength = Children.toArray(children).length;
        const offset = highlightedIndex + childrenLength - 1;

        return { highlightedIndex: offset % childrenLength };
      });
    } else if (e.key === "Home") {
      e.preventDefault();

      this.setState({ highlightedIndex: 0 });
    } else if (e.key === "End") {
      e.preventDefault();

      this.setState((_, { children }) => ({
        highlightedIndex: Children.toArray(children).length - 1
      }));
    }
  };

  handleResetHighlightIndexToActivePanelIndex = () => {
    this.setState((_, { activePanelIndex }) => ({
      highlightedIndex: activePanelIndex
    }));
  };
}

TabList.propTypes = {
  tabId: PropTypes.string,
  tabPanelId: PropTypes.string,
  isPanelActive: PropTypes.bool,
  activePanelIndex: PropTypes.number,
  updateActivePanelIndex: PropTypes.func
};

export default TabList;
