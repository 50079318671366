import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Client,
  Avatar,
  Details,
  Name,
  Controls
} from "./styled/stream-invite-client";

function StreamInviteClient({
  name,
  initials,
  initialsColor,
  email,
  avatarUrl,
  autoFocusNewClient,
  isFakeClient,
  isCurrentUser,
  children,
  ...rest
}) {
  return (
    <Container {...rest}>
      <Client>
        <Avatar
          avatarUrl={avatarUrl}
          initials={initials}
          initialsColor={initialsColor}
          size="sm"
        />
        <Details>
          <Name tooltip={name || email}>{name || email}</Name>
        </Details>
      </Client>
      {children && <Controls>{children}</Controls>}
    </Container>
  );
}

StreamInviteClient.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  initials: PropTypes.string,
  initialsColor: PropTypes.string,
  email: PropTypes.string,
  avatarUrl: PropTypes.string,
  autoFocusNewClient: PropTypes.bool,
  isFakeClient: PropTypes.bool,
  isCurrentUser: PropTypes.bool,
  updateClientName: PropTypes.func
};

export default StreamInviteClient;
