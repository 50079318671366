import React, { Component } from "react";
import PropTypes from "prop-types";
import { getWindowScrollPosition, navigateTo } from "@wrstudios/utils";
import { breakpoints } from "@wrstudios/theme";
import { Markers, SearchMap } from "@wrstudios/components";
import Media from "../../utils/media";
import IconLoading from "../icons/IconLoading";
import { filterPins } from "../../modules/mapping";

import {
  Container,
  LoadingCluster,
  ClickContainer,
  ClickTarget
} from "./styled/stream-map";

class StreamMap extends Component {
  state = {
    zoom: 0,
    mapOffset: 0
  };

  minZoom = 15;
  precision = 9;
  headerHeight = 60;
  filterHeight = 60;

  render() {
    return (
      <Container
        isOpen={this.props.isMapShowing}
        isEditingCriteria={this.props.isEditingCriteria}
        style={{ height: `calc(100vh - ${this.state.mapOffset}px)` }}>
        {this.props.isMapShowing && (
          <SearchMap
            env={process.env.NODE_ENV}
            casJwt={this.props.casJwt}
            mapboxApiToken={process.env.REACT_APP_MAPBOX_API_TOKEN}
            markers={this.getAllMarkers()}
            areas={this.props.mapAreas}
            priorityId={this.props.listingUnderPointer}
            onBoundsUpdate={this.onBoundsUpdate}
            isAutoZoomDisabled={this.state.zoom >= this.minZoom}
            onAreaCreate={this.onAreaCreateUpdateDelete}
            onAreaUpdate={this.onAreaCreateUpdateDelete}
            onAreaDelete={this.onAreaCreateUpdateDelete}
            isDrawSearchEnabled={true}
          />
        )}
      </Container>
    );
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleOnScroll);

    this.media = new Media(`(min-width: ${breakpoints.md - 1}px)`);
    this.media.addListener(this.displayMap);

    this.setMapOffset();
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.animationFrame);
    window.removeEventListener("scroll", this.handleOnScroll);

    this.media.removeListener(this.displayMap);
  }

  getAllMarkers = () => {
    if (!this.props.showListings) {
      return [];
    }
    const pinsAndClusters = this.getMarkersForPinsAndClusters();
    const listingMarkers = this.getMarkersForListings();
    return [...pinsAndClusters, ...listingMarkers];
  };

  getMarkersForListings = () => {
    const { mapListings, listingUnderPointer } = this.props;

    return mapListings.map((listing) => ({
      id: listing.id,
      lat: listing.latitude,
      lon: listing.longitude,
      component: (
        <Markers.ListingMarker
          price={listing.priceFormattedShort}
          statusValue={listing.statusValue}
          isHighlighted={listing.id === listingUnderPointer}
        />
      ),
      popupComponent: this.getListingPopup({ listing })
    }));
  };

  getMarkersForPinsAndClusters = () => {
    const { getListingsForPin } = this.props;
    const { mapPins, mapListings, listingsById } = this.props;
    const filteredPins = filterPins({ mapPins, mapListings });

    return filteredPins.map((pin) => {
      const listingIds = pin.records.map(({ id }) => id);
      const onClickMarker = () => getListingsForPin(listingIds);

      if (listingIds.length === 1) {
        const listing = listingsById[listingIds[0]] || {};

        return {
          id: pin.id,
          lat: pin.lat,
          lon: pin.lon,
          component: (
            <Markers.PinMarker
              onClick={onClickMarker}
              statusValue={pin.records[0].statusValue}
            />
          ),
          popupComponent: this.getListingPopup({ listing, isPin: true })
        };
      } else {
        const listings = listingIds.map((id) => listingsById[id] || {});
        const validListings = listings.filter(({ id }) => !!id);
        const onClickClusterPopup = ({ target }) => {
          if (!target.id) {
            return;
          }
          const listing = listingsById[target.id] || {};
          this.onClickListing({ listing, isPin: true });
        };

        return {
          id: pin.id,
          lat: pin.lat,
          lon: pin.lon,
          component: (
            <Markers.ClusterMarker onClick={onClickMarker}>
              {pin.records.length}
            </Markers.ClusterMarker>
          ),
          popupComponent: (
            <Markers.ClusterPopup onClick={onClickClusterPopup}>
              {validListings.length < 1 ? (
                <LoadingCluster>
                  <IconLoading />
                </LoadingCluster>
              ) : (
                validListings.map((listing) => (
                  <ClickContainer key={listing.id}>
                    <Markers.ClusterListing
                      id={listing.id}
                      zip={listing.zip}
                      city={listing.city}
                      state={listing.state}
                      street={listing.address}
                      status={listing.statusLabel}
                      statusValue={listing.statusValue}
                      photo={(listing.photos || [])[0]}
                      price={listing.price}
                    />
                    <ClickTarget id={listing.id} />
                  </ClickContainer>
                ))
              )}
            </Markers.ClusterPopup>
          )
        };
      }
    });
  };

  getListingPopup = ({ listing, isPin }) => {
    return (
      <Markers.ListingPopup
        id={listing.id}
        onClick={() => this.onClickListing({ listing, isPin })}
        priceFormatted={listing.price}
        statusLabel={listing.statusLabel}
        statusValue={listing.statusValue}
        photos={listing.photos}
        address={listing.address}
        city={listing.city}
        state={listing.state}
        zip={listing.zip}
        offsetY={-3}
      />
    );
  };

  onClickListing = ({ listing, isPin }) => {
    if (this.props.isCreating || isPin) {
      navigateTo(`/listings/${listing.id}`);
    } else {
      if (!listing.hasStreamItemId) return;
      navigateTo(`/stream_items/${listing.streamItem.id}`);
    }
  };

  onAreaCreateUpdateDelete = ({ draw }) => {
    const featureCollection = draw.getAll();
    if (featureCollection.features.length === 0) {
      this.props.removeStreamFilter("location");
    } else {
      this.props.buildLocationFilters(featureCollection.features);
    }
  };

  onBoundsUpdate = ({ mapBounds, zoom }) => {
    if (zoom >= this.minZoom) {
      this.props.getPinsFromForge({ mapBounds, precision: this.precision });
    }

    if (zoom < this.minZoom && zoom < this.state.zoom) {
      this.props.emitAction({ type: "RESET_FORGE_PINS" });
    }

    this.setState({ zoom });
  };

  handleOnScroll = () => {
    this.setMapOffset();
  };

  setMapOffset = () => {
    this.animationFrame = window.requestAnimationFrame(() => {
      this.setState({ mapOffset: this.getMapOffset() });
    });
  };

  // * This is here mainly because we have a header that can hide
  // * so we need to dynamically resize the map container.
  getMapOffset = () => {
    const windowOffsetTop = getWindowScrollPosition().top;

    let mapOffset = this.headerHeight + this.filterHeight - windowOffsetTop;

    // We don't want to go past the sticky filter bar.
    if (mapOffset <= this.filterHeight) {
      mapOffset = this.filterHeight;
    }

    return mapOffset;
  };

  displayMap = (e) => {
    this.props.setStreamMapShowing(e.matches);
  };
}

StreamMap.propTypes = {
  isMapShowing: PropTypes.bool.isRequired,
  isEditingCriteria: PropTypes.bool,
  casJwt: PropTypes.string,
  isCreating: PropTypes.bool,
  showListings: PropTypes.bool,
  listingsById: PropTypes.object,
  mapListings: PropTypes.array,
  mapPins: PropTypes.array,
  mapAreas: PropTypes.array,
  listingUnderPointer: PropTypes.string,
  setStreamMapShowing: PropTypes.func.isRequired,
  buildLocationFilters: PropTypes.func,
  removeStreamFilter: PropTypes.func,
  getListingsForPin: PropTypes.func,
  getPinsFromForge: PropTypes.func,
  emitAction: PropTypes.func
};

export default StreamMap;
