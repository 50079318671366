import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import { AriaMessage } from "@wrstudios/components";
import IconClose from "../icons/IconClose";
import { Container, Slider, Items, Item, Remove, Input } from "./styled/tags";

// TODO: This needs to be reworked to have an autocomplete.
class Tags extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tagsHash: this.getTagsAsHash(this.props.tags),
      tagsValues: this.getTagsValues(this.props.tags),
      tag: "",
      removedTag: "",
      isFocused: false
    };
  }

  sliderRef = createRef();
  inputRef = createRef();

  render() {
    const { showInput, placeholder, onSubmit, onChange, ...rest } = this.props;
    const { tag, tagsValues } = this.state;
    const hasTags = !!tagsValues.length;

    return (
      <Container
        as={showInput ? "form" : "div"}
        isFocused={this.state.isFocused}
        isShowingInput={showInput}
        onSubmit={this.handleOnSubmit}
        {...rest}>
        <Slider ref={this.sliderRef}>
          {hasTags && (
            <Items>
              {tagsValues.map((tag, index) => (
                <Item key={index}>
                  {tag}
                  <Remove
                    type="button"
                    onClick={() => this.handleOnRemove(tag)}>
                    <IconClose />
                    <AriaMessage>Remove tag with label {tag}</AriaMessage>
                  </Remove>
                </Item>
              ))}
            </Items>
          )}
          {showInput && (
            <Input
              ref={this.inputRef}
              type="text"
              value={tag}
              placeholder={!hasTags ? placeholder : ""}
              onFocus={this.handleOnFocus}
              onBlur={this.handleOnBlur}
              onChange={this.handleOnChange}
            />
          )}
        </Slider>
      </Container>
    );
  }

  componentDidMount() {
    this.setScrollPosition();
  }

  componentDidUpdate(_, prevState) {
    this.setScrollPosition();

    if (!isEqual(prevState.tagsHash, this.state.tagsHash)) {
      this.props.onChange(
        this.state.removedTag,
        Object.values(this.state.tagsHash),
        Object.values(prevState.tagsHash)
      );
    }
  }

  // TODO: This will need to be reworked to work with the auto complete to have an
  // TODO: an option to only have options that are in the auto complete dropdown.
  handleOnSubmit = (e) => {
    e.preventDefault();

    const { tag, tagsHash } = this.state;

    if (!tag) return;

    const tagsValues = this.getTagsValues(Object.values(tagsHash));

    if (tagsValues.includes(tag.toLowerCase()))
      return this.setState({ tag: "" });

    this.setState(
      ({ tagsHash }) => {
        return {
          tagsHash: { ...tagsHash, [tag]: { value: tag } },
          tag: ""
        };
      },
      () => {
        this.setState(({ tagsHash }) => ({
          tagsValues: this.getTagsValues(Object.values(tagsHash))
        }));
      }
    );
  };

  handleOnFocus = () => {
    this.setState({ isFocused: true });
  };

  handleOnBlur = () => {
    this.setState({ isFocused: false });
  };

  handleOnChange = (e) => {
    this.setState({ tag: e.target.value });
  };

  handleOnRemove = (tag) => {
    const { [tag]: removedTag, ...newTags } = this.state.tagsHash;
    const tagsValues = Object.values(newTags).map((tag) => {
      if (tag.value) return tag.value;

      return tag;
    });

    this.setState({
      tagsHash: newTags,
      tagsValues,
      removedTag: removedTag || ""
    });
  };

  setScrollPosition = () => {
    if (!this.props.showInput) return;

    this.sliderRef.current.scrollTo(
      this.sliderRef.current.getBoundingClientRect().right * 2,
      0
    );
  };

  getTagsAsHash = (tags) => {
    return tags.reduce((tags, tag) => {
      return { ...tags, [tag.value]: tag };
    }, {});
  };

  getTagsValues = (tags) => {
    return tags.map((tag) => tag.value).filter(Boolean);
  };
}

Tags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired
    })
  ),
  showInput: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
};

Tags.defaultProps = {
  tags: [],
  showInput: true,
  placeholder: "",
  onChange: function () {}
};

export default Tags;
