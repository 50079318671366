import React from "react";
import PropTypes from "prop-types";
import { Modal } from "@wrstudios/components";
import IconClose from "../icons/IconClose";
import UserPhoneVerify from "./connected/UserPhoneVerify";
import {
  ModalContent,
  ModalBody,
  Close
} from "./styled/user-phone-verify-modal";

function UserPhoneVerifyModal({
  phone,
  showVerifyCodeStep,
  onSuccess,
  onClose
}) {
  return (
    <Modal onClose={onClose}>
      <ModalContent>
        <ModalBody>
          <Close onClick={onClose}>
            <IconClose />
          </Close>
          <UserPhoneVerify
            modal
            phone={phone}
            showVerifyCodeStep={showVerifyCodeStep}
            onSuccess={onSuccess}
            onClose={onClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

UserPhoneVerifyModal.propTypes = {
  phone: PropTypes.string,
  showVerifyCodeStep: PropTypes.bool,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default UserPhoneVerifyModal;
