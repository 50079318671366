import { connect } from "react-redux";
import { getClients } from "../../../../actions/client";
import StreamInvite from "../StreamInvite";

function select(
  { stream: { getStreamNetwork }, streams, clients, invitations, user },
  { streamId }
) {
  const stream = streams.byId[streamId] || {};
  const subscribers = stream.subscribers || [];
  const invitedClients = (stream.invitationIds || []).map((id) => ({
    ...invitations.byId[id],
    isInvitee: true
  }));
  const agent = user.isClient ? user.primaryAgent || {} : user;
  const agentSubscriber = subscribers.find(
    (subscriber) => subscriber.email === agent.email
  );
  const subscribersWithoutAgent = subscribers.filter(
    (subscriber) => subscriber.email !== agent.email
  );
  const subscribersWithAgentAtEndOfList = [
    ...subscribersWithoutAgent,
    agentSubscriber,
    ...invitedClients
  ].filter(Boolean);

  return {
    hasLoadedStream: getStreamNetwork.hasFetched,
    clients: subscribersWithAgentAtEndOfList,
    isAgent: user.isAgent,
    page: clients.page,
    limit: clients.limit
  };
}

const actions = {
  getClients
};

export default connect(select, actions)(StreamInvite);
