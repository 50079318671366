import { connect } from "react-redux";
import Activity from "../Activity";

function select({ activities }, { activityId }) {
  const activity = activities.byId[activityId] || {};

  return {
    type: activity.type || ""
  };
}

export default connect(select)(Activity);
