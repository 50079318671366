import { IconAlert } from "@wrstudios/icons";
import PropTypes from "prop-types";
import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { useTheme } from "styled-components";
import IconFilterList from "../../icons/IconFilterList";
import IconSaveStream from "../../icons/IconSaveStream";
import StreamFilterMore from "./connected/StreamFilterAdvanced";
import StreamFilterBaths from "./connected/StreamFilterBaths";
import StreamFilterBeds from "./connected/StreamFilterBeds";
import StreamFilterLocations from "./connected/StreamFilterLocations";
import StreamFilterOnboarding from "./connected/StreamFilterOnboarding";
import StreamFilterPrice from "./connected/StreamFilterPrice";
import StreamFilterPropertyType from "./connected/StreamFilterPropertyType";
import StreamFilterSqft from "./connected/StreamFilterSqft";
import {
  Cell,
  Container,
  Filter,
  Filters,
  FiltersButton,
  FiltersButtonLabel,
  Grid,
  MobileStickyFooter,
  SaveButton
} from "./styled/stream-filters";

function StreamFilters({
  isSaving,
  isUpdating,
  isShowingFilters,
  hasStream,
  hasEdited,
  shouldShowOnboarding,
  setShowStreamFilters,
  autogeneratedStreamName,
  filters,
  createStream,
  updateStream,
  streamId,
  streamName,
  canUpdateOrSave
}) {
  const theme = useTheme();
  const filtersRef = useRef();
  const saveButtonRef = useRef();
  const filterCollisionBreakpointRef = useRef();
  const saveButtonCollisionBreakpointRef = useRef();
  const [hideFilters, setHideFilters] = useState(false);
  const [hideSaveButton, setHideSaveButton] = useState(false);

  function handleSave() {
    createStream({
      name: autogeneratedStreamName,
      filter: filters
    });
  }

  function handleUpdate() {
    updateStream({ id: streamId, name: streamName, filter: filters });
  }

  const handleFiltersCollision = useCallback(() => {
    const saveButtonRect = saveButtonRef.current.getBoundingClientRect();
    let hideFilters = false;
    const buffer = 10;

    if (filterCollisionBreakpointRef.current) {
      if (
        filterCollisionBreakpointRef.current + buffer <
        saveButtonRect.right
      ) {
        filterCollisionBreakpointRef.current = saveButtonRect.right;
      }

      hideFilters =
        filterCollisionBreakpointRef.current + buffer > window.innerWidth;
    }
    // If we don't have a collision breakpoint, we'll figure out that.
    // Once we have one we'll check thw window inner width against that
    // so we don't have a bobbing effect.
    else {
      hideFilters = saveButtonRect.right + buffer > window.innerWidth;

      if (hideFilters) {
        // Save the point where we collide with the edge of the page.
        filterCollisionBreakpointRef.current = saveButtonRect.right;
      }
    }

    setHideFilters(hideFilters);
  }, []);

  const handleSaveButtonCollision = useCallback(() => {
    const buffer = 10;

    if (!hideFilters) {
      setHideSaveButton(false);
      return;
    }

    const saveButtonRect = saveButtonRef.current.getBoundingClientRect();
    let hideSaveButton = false;

    if (saveButtonCollisionBreakpointRef.current) {
      hideSaveButton =
        saveButtonCollisionBreakpointRef.current + buffer > window.innerWidth;
    }
    // If we don't have a collision breakpoint, we'll figure out that.
    // Once we have one we'll check thw window inner width against that
    // so we don't have a bobbing effect.
    else {
      hideSaveButton = saveButtonRect.right + buffer > window.innerWidth;

      if (hideSaveButton) {
        // Save the point where we collide with the edge of the page.
        saveButtonCollisionBreakpointRef.current = saveButtonRect.right;
      }
    }

    setHideSaveButton(
      hideSaveButton ||
        theme.breakpoints.page.oneColumn + buffer > window.innerWidth
    );
  }, [hideFilters, theme.breakpoints.page.oneColumn]);

  useLayoutEffect(() => {
    handleFiltersCollision();
    handleSaveButtonCollision();
    window.addEventListener("resize", handleFiltersCollision);
    window.addEventListener("resize", handleSaveButtonCollision);
    return () => {
      window.removeEventListener("resize", handleFiltersCollision);
      window.removeEventListener("resize", handleSaveButtonCollision);
    };
  }, [filters, handleFiltersCollision, handleSaveButtonCollision]);

  return (
    <>
      <Container>
        <Grid>
          <Cell hideSaveButton={hideSaveButton}>
            <StreamFilterLocations key={JSON.stringify(filters)} />
          </Cell>
          <Cell>
            {shouldShowOnboarding && <StreamFilterOnboarding />}
            <Filters
              ref={filtersRef}
              shouldShowOnboarding={shouldShowOnboarding}>
              {!hideFilters && (
                <>
                  <Filter>
                    <StreamFilterPropertyType />
                  </Filter>
                  <Filter>
                    <StreamFilterPrice />
                  </Filter>
                  <Filter>
                    <StreamFilterBeds />
                  </Filter>
                  <Filter>
                    <StreamFilterBaths />
                  </Filter>
                  <Filter>
                    <StreamFilterSqft />
                  </Filter>
                  <Filter>
                    <StreamFilterMore />
                  </Filter>
                </>
              )}
              {hideFilters && (
                <Filter>
                  <FiltersButton
                    shouldShowOnboarding={shouldShowOnboarding}
                    isActive={isShowingFilters}
                    isVisible={hideFilters}
                    onClick={() => setShowStreamFilters(!isShowingFilters)}>
                    <IconFilterList />
                    <FiltersButtonLabel>Filters</FiltersButtonLabel>
                  </FiltersButton>
                </Filter>
              )}
              <div ref={saveButtonRef}>
                {!hideSaveButton && (!hasStream || canUpdateOrSave) && (
                  <Filter>
                    <SaveButton onClick={hasEdited ? handleUpdate : handleSave}>
                      {!hasEdited && <IconAlert />}
                      {hasEdited
                        ? isUpdating
                          ? "Updating..."
                          : "Update Stream"
                        : isSaving
                        ? "Setting up..."
                        : "Set up Alerts"}
                    </SaveButton>
                  </Filter>
                )}
              </div>
            </Filters>
          </Cell>
        </Grid>
      </Container>
      {(!hasStream || canUpdateOrSave) && (
        <MobileStickyFooter show={hideSaveButton}>
          <SaveButton onClick={hasEdited ? handleUpdate : handleSave}>
            {!hasEdited && <IconSaveStream />}
            {hasEdited
              ? isUpdating
                ? "Updating Stream..."
                : "Update Stream"
              : isSaving
              ? "Saving Stream..."
              : "Save Stream"}
          </SaveButton>
        </MobileStickyFooter>
      )}
    </>
  );
}

StreamFilters.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  isClient: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  isShowingFilters: PropTypes.bool.isRequired,
  setShowStreamFilters: PropTypes.func.isRequired,
  shouldShowOnboarding: PropTypes.bool.isRequired,
  primaryAlertType: PropTypes.oneOf(["curated", "automatic", "self-serve"])
  // TODO: ...updates
};

export default StreamFilters;
