import qs from "qs";
import { createLogger } from "redux-logger";
import {
  createStore as reduxCreateStore,
  applyMiddleware,
  compose
} from "redux";
import { readFromStorage, writeToStorage } from "../utils/persist";
import { handleInvalidToken } from "./authMiddleware";
import thunk from "redux-thunk";
import { throttle } from "lodash";
import reducers from "../reducers";

const isDev = process.env.NODE_ENV === "development";
const isTest = process.env.NODE_ENV === "test";

const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
const _initialState = params.token ? {} : readFromStorage();

const reduxDevToolsExtensionCompose =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers =
  isDev && reduxDevToolsExtensionCompose
    ? reduxDevToolsExtensionCompose
    : compose;

const logger = createLogger({ level: "info", collapsed: true });
const middleware = isTest
  ? applyMiddleware(thunk, handleInvalidToken)
  : applyMiddleware(thunk, handleInvalidToken, logger);

export function createStore(initialState = _initialState) {
  const store = reduxCreateStore(
    reducers,
    initialState,
    composeEnhancers(middleware)
  );
  store.subscribe(throttle(() => writeToStorage(store), 500));
  return store;
}
