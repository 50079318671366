import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { STREAM_ITEM_FILTER_TYPES } from "../../modules/streamItem";
import { Tabs, TabList, Tab } from "../tabs";

class StreamTabs extends Component {
  state = {
    filter: this.props.filter
  };

  render() {
    const {
      recommendedCount,
      likedCount,
      commentedCount,
      hiddenCount
    } = this.props;

    return (
      <Container>
        <ScrollableContainer>
          <Tabs>
            <TabList>
              <Tab
                name={STREAM_ITEM_FILTER_TYPES.ALL}
                title="All"
                isActive={this.state.filter === STREAM_ITEM_FILTER_TYPES.ALL}
                onClick={this.handleOnClick}>
                All
              </Tab>
              <Tab
                name={STREAM_ITEM_FILTER_TYPES.RECOMMENDED}
                title="Recommended"
                isActive={
                  this.state.filter === STREAM_ITEM_FILTER_TYPES.RECOMMENDED
                }
                onClick={this.handleOnClick}>
                Recommended {recommendedCount}
              </Tab>
              <Tab
                name={STREAM_ITEM_FILTER_TYPES.LIKED}
                title="Liked"
                isActive={this.state.filter === STREAM_ITEM_FILTER_TYPES.LIKED}
                onClick={this.handleOnClick}>
                Liked {likedCount}
              </Tab>
              <Tab
                name={STREAM_ITEM_FILTER_TYPES.COMMENTED}
                title="Commented"
                isActive={
                  this.state.filter === STREAM_ITEM_FILTER_TYPES.COMMENTED
                }
                onClick={this.handleOnClick}>
                Commented {commentedCount}
              </Tab>
              <Tab
                name={STREAM_ITEM_FILTER_TYPES.HIDDEN}
                title="Hidden"
                isActive={this.state.filter === STREAM_ITEM_FILTER_TYPES.HIDDEN}
                onClick={this.handleOnClick}>
                Hidden {hiddenCount}
              </Tab>
            </TabList>
          </Tabs>
        </ScrollableContainer>
      </Container>
    );
  }

  handleOnClick = (e) => {
    if (this.state.filter === e.target.name) return;

    this.setState({ filter: e.target.name });

    const { streamId, limit } = this.props;
    this.props.getStreamItems({
      streamId,
      page: 1,
      limit,
      filter: e.target.name
    });
  };
}

StreamTabs.propTypes = {
  streamId: PropTypes.string.isRequired,
  likedCount: PropTypes.number.isRequired,
  commentedCount: PropTypes.number.isRequired,
  recommendedCount: PropTypes.number.isRequired,
  hiddenCount: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  filter: PropTypes.string.isRequired,
  getStreamItems: PropTypes.func.isRequired
};

const Container = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const ScrollableContainer = styled.div`
  display: flex;
  white-space: nowrap;
  -ms-overflow-style: none;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 0 !important;
  }

  > * > * > *:last-of-type {
    margin-right: 4rem;
  }
`;

export default StreamTabs;
