import React from "react";

function IconAvatarAlert(props) {
  return (
    <svg
      width="16"
      height="16"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}>
      <defs>
        <path
          d="M9.732 12a2 2 0 0 1-3.464 0h3.464zM12 11H4v-1c.332-.021.53-.07.594-.149C4.988 9.376 5 8.171 5 7c0-2.182 2-3 3-3s3 .823 3 3c0 1.166.013 2.373.406 2.85.065.08.263.13.594.15v1zM7.5 3h1v2h-1V3z"
          id="a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle fill="#0AA1DC" cx="8" cy="8" r="8" />
        <circle fillOpacity=".1" fill="#000" cx="8" cy="8" r="8" />
        <use fill="#FFF" fillRule="nonzero" xlinkHref="#a" />
      </g>
    </svg>
  );
}

export default IconAvatarAlert;
