import styled from "styled-components";
import { Modal } from "@wrstudios/components";
import { default as BaseTitle } from "../../../common/Title";
import { default as BaseTip } from "../../../common/Tip";

export const Copy = styled.div`
  margin-top: 1.5rem;
`;

export const Preview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 0;
`;

export const Image = styled.img`
  max-width: 100%;
  ${({ theme }) => theme.shadows.overlay};
`;

export const Link = styled.a``;

export const ButtonLink = styled.a`
  ${({ theme }) => theme.buttons.base};
  ${({ theme }) => theme.buttons.primary};
  margin-top: 1.5rem;
`;

export const Section = styled.div`
  margin: 6rem 0 2rem;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ShareButtonGroup = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 130px 130px;
  grid-template-rows: 120px 120px;
`;

export const Container = styled.div`
  margin: 3rem 0;
`;

export const Title = styled(BaseTitle)`
  margin-bottom: 3rem;
  text-align: center;
`;

export const SectionNotifications = styled(Section)`
  max-width: 46rem;
  margin-left: auto;
  margin-right: auto;
`;

export const Header = styled.h2`
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 3.3rem;
  margin: 0 0 2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
  text-align: center;
`;

export const Text = styled.p`
  font-size: ${({ normal }) => (normal ? "inherit" : "1.3rem")};
  line-height: ${({ normal }) => (normal ? "2.4rem" : "2rem")};
  margin-top: 2rem;
  text-align: ${({ align = "left" }) => align};
`;

export const ImageContainer = styled.span`
  display: block;
  max-width: 36rem;
  margin: 2rem auto;
  border-radius: 0.5rem;
  ${({ theme }) => theme.styleGuide.shadows.raised};
  transition: box-shadow 0.2s;
  overflow: hidden;

  &:hover {
    ${({ theme }) => theme.styleGuide.shadows.overlay};
  }
`;

export const Tip = styled(BaseTip)`
  max-width: 40rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[600]};
`;

export const Grid = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -1rem;
`;

export const Cell = styled.div`
  padding: 1rem;

  @media (max-width: 767px) {
    width: 50%;
  }
`;

export const ShareButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13rem;
  height: 12rem;
  padding: 0.24rem;
  ${({ theme }) => theme.layers.raised};
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.greyMedium};
  transition: box-shadow 0.2s;
  text-decoration: none;
  text-align: center;
  cursor: pointer;

  &:hover {
    ${({ theme }) => theme.layers.overlay};
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const ShareButtonIcon = styled.span`
  display: block;
  text-align: center;

  svg {
    width: 3.6rem;
    height: 3.6rem;
  }
`;

export const ShareButtonIconEmbed = styled(ShareButtonIcon)`
  svg {
    fill: ${({ theme }) => theme.colors.attractRed};
  }
`;

export const ShareButtonText = styled.span`
  display: block;
  margin-top: 1.5rem;
`;

export const ModalDescription = styled.p`
  margin: 0 0 16px;
`;

export const ModalContent = styled(Modal.Content)`
  width: 100%;
  max-width: 60rem;
`;

export const ModalFooter = styled(Modal.Footer)`
  text-align: right;
`;
