import { connect } from "react-redux";
import { replaceStreamFilters } from "../../../../actions/stream";
import StreamFilterBaths from "../StreamFilterBaths";

function select({ stream }) {
  const { baths_search = {}, ...filters } = stream.filters.byId;
  const min = baths_search.gte;
  const max = baths_search.lte;

  return {
    filters,
    min,
    max
  };
}

const actions = {
  replaceStreamFilters
};

export default connect(select, actions)(StreamFilterBaths);
