import React from "react";
import PropTypes from "prop-types";
import { AriaMessage } from "@wrstudios/components";
import { Tooltip, TooltipMessage } from "../common/Tooltip";
import { Container, Avatar, Body, Name, Message } from "./styled/poker-chip";

function PokerChip({ isAgent, client, size, onClick, ...props }) {
  if (!isAgent) {
    return (
      <Container {...props} title={client.name}>
        <Body isAgent={false}>
          <Avatar
            initials={client.initials}
            initialsColor={client.initialsColor}
            avatarUrl={client.avatarUrl}
            size={size}
            isInvitee={client.isInvitee}
          />
          <AriaMessage>Profile link to {client.name}</AriaMessage>
        </Body>
      </Container>
    );
  }

  return (
    <Container {...props} title={client.name}>
      {!onClick && (
        <>
          <Avatar
            initials={client.initials}
            initialsColor={client.initialsColor}
            avatarUrl={client.avatarUrl}
            size={size}
            isInvitee={client.isInvitee}
          />
          <AriaMessage>Profile link to {client.name}</AriaMessage>
        </>
      )}
      {onClick && (
        <Body isAgent={true} onClick={onClick}>
          <Tooltip color={client.initialsColor}>
            <Avatar
              initials={client.initials}
              initialsColor={client.initialsColor}
              avatarUrl={client.avatarUrl}
              size={size}
              isInvitee={client.isInvitee}
            />
            <AriaMessage>Profile link to {client.name}</AriaMessage>
            {client.isInvitee && (
              <TooltipMessage>
                <Name>{client.name}</Name>
                <Message>Invitation Sent</Message>
              </TooltipMessage>
            )}
          </Tooltip>
        </Body>
      )}
    </Container>
  );
}

PokerChip.propTypes = {
  isAgent: PropTypes.bool.isRequired,
  client: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    initials: PropTypes.string,
    initialsColor: PropTypes.string,
    avatarUrl: PropTypes.string,
    isInvitee: PropTypes.bool,
    tip: PropTypes.string
  }).isRequired,
  size: PropTypes.string,
  onClick: PropTypes.func
};

export default PokerChip;
