import React, { Component } from "react";
import PropTypes from "prop-types";
import { colors } from "@wrstudios/theme";
import { Container } from "./styled/VideoWistia";

//https://wistia.com/support/developers/player-api#get-started
class VideoWistia extends Component {
  render() {
    const {
      id,
      autoplay,
      popover,
      popoverAnimateThumbnail,
      playerColor,
      buttonBackground,
      controlsVisibleOnLoad,
      onReady,
      onPlay,
      onPause,
      onEnd,
      ...rest
    } = this.props;
    const classes = [
      `wistia_embed wistia_async_${id}`,
      `autoPlay=${autoplay}`,
      `popover=${popover}`,
      `popoverAnimateThumbnail=${popoverAnimateThumbnail}`,
      `playerColor=${playerColor}`,
      `buttonBackground=${buttonBackground}`,
      `controlsVisibleOnLoad=${controlsVisibleOnLoad}`
    ];

    return (
      <Container {...rest}>
        <div className={classes.join(" ")} />
      </Container>
    );
  }

  componentDidMount() {
    window._wq = window._wq || [];
    window._wq = [...window._wq, { id: this.props.id, onReady: this.onReady }];
  }

  componentWillUnmount() {
    this.hook("unbind");
    window._wq = window._wq.filter((video) => video.id !== this.props.id);
  }

  hook = (event = "bind") => {
    if (this.video) {
      const { onPlay, onPause, onEnd } = this.props;

      this.video[event]("play", onPlay);
      this.video[event]("pause", onPause);
      this.video[event]("end", onEnd);
    }
  };

  onReady = (video) => {
    this.video = video;

    this.props.onReady();

    this.hook("bind");
  };
}

VideoWistia.propTypes = {
  id: PropTypes.string,
  autoplay: PropTypes.bool,
  popover: PropTypes.bool,
  popoverAnimateThumbnail: PropTypes.bool,
  onReady: PropTypes.func,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onEnd: PropTypes.func
};

VideoWistia.defaultProps = {
  autoplay: false,
  popover: false,
  popoverAnimateThumbnail: false,
  playerColor: colors.streams[500],
  buttonBackground: colors.streams[500],
  controlsVisibleOnLoad: true,
  onReady: function () {},
  onPlay: function () {},
  onPause: function () {},
  onEnd: function () {}
};

export default VideoWistia;
