import React from "react";

function IconAvatarComment(props) {
  return (
    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle fill="#00CA72" cx="8" cy="8" r="8" />
        <circle fillOpacity=".1" fill="#000" cx="8" cy="8" r="8" />
        <path
          d="M11.89 10.264l.61 2.236-2.236-.61a4.5 4.5 0 1 1 1.626-1.626z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
}

export default IconAvatarComment;
