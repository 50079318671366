import React from "react";
import PropTypes from "prop-types";
import { Container, Text } from "./styled/card-create";

function CardCreate({ text, ...props }) {
  return (
    <Container {...props}>
      {/* This svg lives here as it's a one-off icon and we already have an add icon. */}
      {/* The icon under the icons folder is a material icon. This icon is home-grown. */}
      <svg width="31" height="30" xmlns="http://www.w3.org/2000/svg">
        <g fillRule="evenodd">
          <path d="M14 0h3v30h-3z" />
          <path d="M30.5 13.5v3H.5v-3z" />
        </g>
      </svg>
      <Text>{text}</Text>
    </Container>
  );
}

CardCreate.propTypes = {
  text: PropTypes.string.isRequired
};

export default CardCreate;
