import { connectCASAccount as connectCASAccountAPI } from "../modules/railsApi";
import { transformCASAccount } from "../modules/cas";

export function connectCASAccount({ jwt, redirectUrl }) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken }
    } = getState();

    dispatch({ type: "CONNECT_CAS_ACCOUNT_INITIATED" });

    try {
      const { connectCasAccount } = await connectCASAccountAPI({
        jwt,
        redirectUrl,
        railsApiToken
      });

      dispatch({
        type: "CONNECT_CAS_ACCOUNT_SUCCEEDED",
        payload: transformCASAccount(connectCasAccount)
      });
    } catch (error) {
      dispatch({
        type: "CONNECT_CAS_ACCOUNT_FAILED",
        payload: { error, errorsArray: (error.response || {}).errors || [] }
      });
    }
  };
}
