import { connect } from "react-redux";
import SettingsRoute from "../SettingsRoute";

function select({ user }, { location }) {
  return {
    lwaJwt: user.lwaJwt,
    hasLwaId: !!user.lwaId,
    isAgent: user.isAgent,
    isProfileRoute: location.pathname === "/settings",
    isMlsCredentialValid: user.isMlsCredentialValid,
    isSSOUser: user.isSSOUser
  };
}

export default connect(select)(SettingsRoute);
