import React from "react";

function IconAvatarView(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}>
      <path fill="#00C9C7" d="M8 16A8 8 0 108 0a8 8 0 000 16z" />
      <path fill="#000" fillOpacity=".1" d="M8 16A8 8 0 108 0a8 8 0 000 16z" />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M8 4a7.12 7.12 0 00-5.96 3.84.39.39 0 000 .32A7.12 7.12 0 008 12a7.12 7.12 0 005.96-3.84.39.39 0 000-.32A7.12 7.12 0 008 4zM5.8 7.94c0-1.17.98-2.11 2.2-2.11 1.22 0 2.2.94 2.2 2.1 0 1.17-.98 2.12-2.2 2.12-1.22 0-2.2-.95-2.2-2.11z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default IconAvatarView;
