import React, { Component } from "react";
import PropTypes from "prop-types";
import Media from "react-media";
import Checkbox from "../../common/Checkbox";
import StreamEmailFrequency from "../../stream/StreamEmailFrequency";
import StreamSmsFrequency from "../../stream/StreamSmsFrequency";
import UserPhoneVerifyModal from "../../user/UserPhoneVerifyModal";
import ToastNotification from "../../toast/ToastNotification";
import {
  RouteContainer,
  Title,
  Flash,
  Subscriptions,
  SubTitle,
  SubscriptionRow,
  FrequenciesLayout,
  AlertsTable,
  TableHead,
  TableCell,
  StreamLink,
  StreamLocations,
  StreamCriteria,
  FrequencyCellLayout,
  EmailSection,
  EmailList,
  EmailSubscription
} from "./styled/subscriptions-route";
import { notify } from "../../toast/notify";
class ManageSubscriptionsRoute extends Component {
  constructor(props) {
    super(props);
    this.emailFrequencyOnSelect = this.emailFrequencyOnSelect.bind(this);
    this.smsFrequencyOnSelect = this.smsFrequencyOnSelect.bind(this);
  }

  emailFrequencyOnSelect = async (stream, newEmailFrequency) => {
    const { status, subscription } =
      await this.props.updateStreamSubscriptionFrequency({
        emailFrequency: newEmailFrequency || stream.emailFrequency,
        smsFrequency: stream.smsFrequency,
        subscriptionId: stream.subscriptionId,
        streamId: stream.streamId
      });

    let message = null;
    if (status === "success") {
      switch (subscription.emailFrequency) {
        case "never":
          message = (
            <div>
              <b>Success.</b> You will no longer receive alerts via email for{" "}
              {stream.name}.
            </div>
          );
          break;

        case "weekly":
        case "realtime":
        case "daily":
          message = (
            <div>
              <b>Success.</b> Email Alerts set to {subscription.emailFrequency}{" "}
              for {stream.name}.
            </div>
          );
          break;

        default:
          message = (
            <div>
              <b>Error.</b> No appropriate settings found for the selection.
            </div>
          );
          break;
      }
    } else if (status === "error") {
      message = (
        <div>
          <b>Error.</b> An Error Occured while changing the subscription
          frequency.
        </div>
      );
    } else {
      message = (
        <div>
          <b>Error.</b> No appropriate settings found for the selection.
        </div>
      );
    }
    notify(status, message);
  };

  smsFrequencyOnSelect = async (stream, newSmsFrequency) => {
    const { status, subscription } =
      await this.props.updateStreamSubscriptionFrequency({
        emailFrequency: stream.emailFrequency,
        smsFrequency: newSmsFrequency || stream.smsFrequency,
        subscriptionId: stream.subscriptionId,
        streamId: stream.streamId
      });

    let message = null;
    if (status === "success") {
      switch (subscription.smsFrequency) {
        case "none":
          message = (
            <div>
              <b>Success.</b> You will no longer receive alerts via text for{" "}
              {stream.name}.
            </div>
          );
          break;

        case "new_only":
          message = (
            <div>
              <b>Success.</b> Text Alerts set to realtime for {stream.name}.
            </div>
          );
          break;

        default:
          message = (
            <div>
              <b>Error.</b> No appropriate settings found for the selection.
            </div>
          );
          break;
      }
    } else if (status === "error") {
      message = (
        <div>
          <b>Error.</b> An Error Occured while changing the subscription
          frequency.
        </div>
      );
    } else {
      message = (
        <div>
          <b>Error.</b> No appropriate settings found for the selection.
        </div>
      );
    }
    notify(status, message);
  };
  render() {
    const {
      phone,
      shouldShowUnsubscribeFlash,
      isSubscribedToClientDigest,
      updateClientDigestSubscription
    } = this.props;
    return (
      <RouteContainer>
        <Title>Your Subscriptions</Title>
        {shouldShowUnsubscribeFlash && (
          <Flash status="success">
            You have successfully unsubscribed from&nbsp;
            <strong>Streams Weekly Update</strong>
          </Flash>
        )}
        <Subscriptions>
          <SubTitle>Streams Alerts</SubTitle>
          <Media query="(max-width: 576px)">
            {(isMobile) =>
              isMobile ? (
                this.props.streams.map((stream) => (
                  <SubscriptionRow key={stream.streamId}>
                    <StreamLink to={`/streams/${stream.streamId}`}>
                      {stream.name}
                    </StreamLink>
                    {!!stream.locationsFormatted && (
                      <StreamLocations>
                        {stream.locationsFormatted}
                      </StreamLocations>
                    )}
                    {!!stream.criteria && (
                      <StreamCriteria>{stream.criteria}</StreamCriteria>
                    )}
                    <FrequenciesLayout>
                      <StreamEmailFrequency
                        initialValue={stream.emailFrequency}
                        showLabel={false}
                        onSelect={async (newEmailFrequency) => {
                          this.emailFrequencyOnSelect(
                            stream,
                            newEmailFrequency
                          );
                        }}
                      />
                      <StreamSmsFrequency
                        phone={phone}
                        initialValue={stream.smsFrequency}
                        showLabel={false}
                        onSelect={(newSmsFrequency) =>
                          this.smsFrequencyOnSelect(stream, newSmsFrequency)
                        }
                        renderVerifyComponent={({
                          phone,
                          onClose,
                          onVerifySuccess
                        }) => (
                          <>
                            <UserPhoneVerifyModal
                              phone={phone}
                              onSuccess={onVerifySuccess}
                              onClose={onClose}
                            />
                          </>
                        )}
                      />
                    </FrequenciesLayout>
                  </SubscriptionRow>
                ))
              ) : (
                <AlertsTable>
                  <thead>
                    <tr>
                      <TableHead>Stream</TableHead>
                      <TableHead align="right">Email</TableHead>
                      <TableHead align="right">Text</TableHead>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.streams.map((stream) => (
                      <tr key={stream.streamId}>
                        <TableCell>
                          <StreamLink to={`/streams/${stream.streamId}`}>
                            {stream.name}
                          </StreamLink>
                          {!!stream.locationsFormatted && (
                            <StreamLocations>
                              {stream.locationsFormatted}
                            </StreamLocations>
                          )}
                          {!!stream.criteria && (
                            <StreamCriteria>{stream.criteria}</StreamCriteria>
                          )}
                        </TableCell>
                        <TableCell width="20%">
                          <FrequencyCellLayout>
                            <StreamEmailFrequency
                              initialValue={stream.emailFrequency}
                              showLabel={false}
                              onSelect={(newEmailFrequency) =>
                                this.emailFrequencyOnSelect(
                                  stream,
                                  newEmailFrequency
                                )
                              }
                            />
                          </FrequencyCellLayout>
                        </TableCell>
                        <TableCell width="20%">
                          <FrequencyCellLayout>
                            <StreamSmsFrequency
                              phone={phone}
                              initialValue={stream.smsFrequency}
                              showLabel={false}
                              onSelect={(newSmsFrequency) =>
                                this.smsFrequencyOnSelect(
                                  stream,
                                  newSmsFrequency
                                )
                              }
                              renderVerifyComponent={({
                                phone,
                                onClose,
                                onVerifySuccess
                              }) => (
                                <>
                                  <UserPhoneVerifyModal
                                    phone={phone}
                                    onSuccess={onVerifySuccess}
                                    onClose={onClose}
                                  />
                                </>
                              )}
                            />
                          </FrequencyCellLayout>
                        </TableCell>
                      </tr>
                    ))}
                  </tbody>
                </AlertsTable>
              )
            }
          </Media>
          <EmailSection>
            <SubTitle>Emails</SubTitle>
            <EmailList>
              <EmailSubscription>
                <Checkbox
                  checked={isSubscribedToClientDigest}
                  onChange={async () => {
                    const { status, isSubscribed } =
                      await updateClientDigestSubscription(
                        !isSubscribedToClientDigest
                      );
                    let message = null;
                    if (status === "success") {
                      if (isSubscribed === true) {
                        message = (
                          <div>
                            <b>Success.</b> You have subscribed to the Streams
                            Weekly Update.
                          </div>
                        );
                      } else {
                        message = (
                          <div>
                            <b>Success.</b> You have unsubscribed from the
                            Streams Weekly Update.
                          </div>
                        );
                      }
                    } else {
                      message = (
                        <div>
                          <b>Error.</b> An Error Occured while changing the
                          Streams Weekly Update.
                        </div>
                      );
                    }
                    notify(status, message);
                  }}>
                  Streams Weekly Update
                </Checkbox>
              </EmailSubscription>
            </EmailList>
          </EmailSection>
        </Subscriptions>
        <ToastNotification />
      </RouteContainer>
    );
  }

  componentDidMount() {
    this.props.getStreams({ page: 1, limit: 1000 });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.streams.length !== this.props.streams.length) {
      this.setState({
        streams: this.props.streams
      });
    }
  }

  handleFrequencyChange = (updatedStream) => {
    this.setState({
      streams: this.state.streams.map((stream) => {
        return stream.streamId === updatedStream.streamId
          ? updatedStream
          : stream;
      }, [])
    });
  };
}

ManageSubscriptionsRoute.propTypes = {
  streams: PropTypes.array.isRequired,
  phone: PropTypes.string.isRequired,
  isSubscribedToClientDigest: PropTypes.bool.isRequired,
  shouldShowUnsubscribeFlash: PropTypes.bool.isRequired,
  getStreams: PropTypes.func.isRequired,
  updateStreamSubscriptionFrequency: PropTypes.func.isRequired,
  updateClientDigestSubscription: PropTypes.func.isRequired
};

export default ManageSubscriptionsRoute;
