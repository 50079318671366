import PropTypes from "prop-types";
import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { loadIntercom, setShowIntercom } from "../../utils/external";
import ScrollToTopOnRouteChange from "../common/ScrollToTopOnRouteChange";
import ActivityRoute from "../routes/activity/connected/ActivityRoute";
import AttractConnectRoute from "../routes/attract/connected/AttractConnectRoute";
import CASConnectRoute from "../routes/cas/connected/CASConnectRoute";
import ClientsRoute from "../routes/clients/ClientsRoute";
import ClientRoute from "../routes/clients/connected/ClientRoute";
import ConnectorRoute from "../routes/connector/connected/ConnectorRoute.js";
import LikedRoute from "../routes/liked/connected/LikedRoute";
import ListingRoute from "../routes/listings/connected/ListingRoute";
import LoginFromPasswordReset from "../routes/login/LoginFromPasswordReset";
import SettingsRoute from "../routes/settings/connected/SettingsRoute";
import SettingsRouteLeadGeneration from "../routes/settings/connected/SettingsRouteLeadGeneration";
import ErrorRoute from "../routes/static/Error";
import DevelopersRoute from "../routes/static/connected/Developers";
import StreamsRoute from "../routes/streams/StreamsRoute";
import StreamItemRoute from "../routes/streams/connected/StreamItemRoute";
import StreamRoute from "../routes/streams/connected/StreamRoute";
import StreamRouteNew from "../routes/streams/connected/StreamRouteNew";
import ManageSubscriptionsRoute from "../routes/subscriptions/connected/ManageSubscriptionsRoute";
import SubscriptionFrequencyChangeRoute from "../routes/subscriptions/connected/SubscriptionFrequencyChangeRoute";
import UnsubscribeRoute from "../routes/subscriptions/connected/UnsubscribeRoute";
import Salesforce from "./Salesforce";
import Header from "./connected/Header";
import { Main } from "./styled/app";

class AppAuthed extends Component {
  render() {
    if (!this.props.mlsCode || !this.props.mlsCredentialId) return null;

    if (!this.props.isMlsCredentialValid && !this.props.isSettingsRoute) {
      return <Redirect to="/settings/mls" />;
    }

    if (this.props.isLoginRoute) {
      return <Redirect to="/" />;
    }

    return (
      <ScrollToTopOnRouteChange pathname={this.props.location.pathname}>
        <Salesforce />
        <Header />
        <Main>
          <Switch>
            <Route
              component={StreamItemRoute}
              path="/stream_items/:streamItemId"
            />
            <Route component={StreamRouteNew} path="/streams/new" />
            <Route component={StreamRoute} path="/streams/:streamId" />
            <Route component={StreamsRoute} path="/streams" />
            <Route component={ListingRoute} path="/listings/:listingId" />
            <Route component={ClientRoute} path="/clients/:clientId" />
            <Route component={ClientsRoute} path="/clients" />
            <Route
              component={LoginFromPasswordReset}
              path="/password/edit/:resetToken"
            />
            <Route
              component={CASConnectRoute}
              path="/connect/cloud_agent_suite/verify"
            />
            <Route
              component={AttractConnectRoute}
              path="/connect/cloud_attract"
            />
            <Route
              component={UnsubscribeRoute}
              path="/unsubscribe/:subscriptionId"
            />
            <Route
              component={SubscriptionFrequencyChangeRoute}
              path="/subscriptions/:subscriptionId"
            />
            <Route component={ManageSubscriptionsRoute} path="/subscriptions" />
            {this.props.isClientWithLikedListings && (
              <Route component={LikedRoute} path="/liked" />
            )}
            {this.props.isAgent && (
              <Route
                component={SettingsRouteLeadGeneration}
                path="/settings/lead_generation"
              />
            )}
            <Route component={SettingsRoute} path="/settings" />
            <Route component={DevelopersRoute} path="/developers" />
            <Route component={ActivityRoute} path="/activity" />
            <Route component={ActivityRoute} exact path="/" />
            <Route component={ConnectorRoute} path="/product_connector" />
            <Route component={ErrorRoute} />
          </Switch>
        </Main>
      </ScrollToTopOnRouteChange>
    );
  }

  componentDidMount() {
    this.props.getCurrentUser();
    loadIntercom();

    if (this.props.mlsCode) {
      this.getConfigs();
    }
  }

  componentDidUpdate({ mlsCode, showIntercom }) {
    if ((!mlsCode && !!this.props.mlsCode) || mlsCode !== this.props.mlsCode) {
      this.getConfigs();
    }

    if (!showIntercom && this.props.showIntercom) {
      setShowIntercom(true);
    }

    if (!this.props.showIntercom) {
      setShowIntercom(false);
    }
  }

  getConfigs = () => {
    this.props.getBillingInfo();
    this.props.getSsoParams();
    this.props.getMLSDetails(this.props.mlsCode);
  };
}

AppAuthed.propTypes = {
  location: PropTypes.object.isRequired,
  mlsCode: PropTypes.string.isRequired,
  mlsCredentialId: PropTypes.string.isRequired,
  isAgent: PropTypes.bool.isRequired,
  isLoginRoute: PropTypes.bool.isRequired,
  showIntercom: PropTypes.bool.isRequired,
  isSettingsRoute: PropTypes.bool.isRequired,
  isMlsCredentialValid: PropTypes.bool.isRequired,
  isClientWithLikedListings: PropTypes.bool.isRequired,
  getBillingInfo: PropTypes.func.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  getSsoParams: PropTypes.func.isRequired,
  getMLSDetails: PropTypes.func.isRequired
};

export default AppAuthed;
