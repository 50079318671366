import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Link } from "@wrstudios/components";
import { emailFrequencies, getFrequencyName } from "../../stream/invite/utils";
import {
  RouteContainer,
  Title,
  Subscriptions as Subscription,
  LargeText,
  ButtonLayout
} from "./styled/subscriptions-route";

class SubscriptionFrequencyChangeRoute extends Component {
  render() {
    const { hasFetched, frequency, streamName, streamId } = this.props;
    const frequencyLabel =
      frequency === emailFrequencies.NEVER
        ? "No Updates"
        : `${getFrequencyName(frequency)} Updates`;
    return hasFetched ? (
      <RouteContainer>
        <Title>Subscriptions</Title>
        <Subscription>
          <LargeText>
            You've successfully changed your alerts to:
            <br />
            <strong>{frequencyLabel}</strong> on <strong>{streamName}</strong>
          </LargeText>
          <ButtonLayout>
            <Link to={`/streams/${streamId}`}>
              <Button large>View Stream</Button>
            </Link>
          </ButtonLayout>
        </Subscription>
      </RouteContainer>
    ) : null;
  }

  componentDidMount() {
    this.props.getStreamSubscription(this.props.subscriptionId);
  }
}
SubscriptionFrequencyChangeRoute.propTypes = {
  frequency: PropTypes.oneOf(Object.values(emailFrequencies)),
  streamName: PropTypes.string,
  streamId: PropTypes.string,
  subscriptionId: PropTypes.string.isRequired,
  getStreamSubscription: PropTypes.func.isRequired
};

export default SubscriptionFrequencyChangeRoute;
