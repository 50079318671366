import React from "react";
import PropTypes from "prop-types";
import IconHide from "../icons/IconHide";
import { Container } from "./styled/stream-item-hides";

function StreamItemHides({ hides, isHidden }) {
  if (isHidden) return null;

  return (
    <Container>
      <IconHide width="18" height="18" />
      <div>
        {hides.length === 1 && <strong>{hides[0]}</strong>}
        {hides.length === 2 && (
          <>
            <strong>{hides[0]}</strong> and <strong>{hides[1]}</strong>
          </>
        )}
        {hides.length >= 3 && (
          <span>
            {hides.slice(0, hides.length - 1).map((name, index) => (
              <span key={index}>
                <strong>{name}</strong>,{" "}
              </span>
            ))}
            and <strong>{hides.slice(hides.length - 1, hides.length)}</strong>
          </span>
        )}{" "}
        hid this property.
      </div>
    </Container>
  );
}

StreamItemHides.propTypes = {
  hides: PropTypes.array.isRequired,
  isHidden: PropTypes.bool.isRequired
};

export default StreamItemHides;
