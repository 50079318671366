import styled from "styled-components";
import { default as BaseSelect } from "../../select/Select";
import { Input, Selected, SelectedItemInput } from "../../select/styled/select";

export const Container = styled.form`
  display: flex;
  align-items: center;
  width: 100%;

  svg {
    margin-left: 1rem;
    fill: ${({ theme }) => theme.colors.streamsBlue};
  }
`;

export const Select = styled(BaseSelect)`
  border: 0;
  background-color: transparent;

  ${Selected} {
    padding: 0;
  }

  ${SelectedItemInput} {
    margin: 0;
  }

  ${Input} {
    ${({ theme }) => theme.text.h2};
    width: 24.5rem;
    line-height: normal;
    padding: 0;
    color: ${({ theme }) => theme.colors.greyDark};
    border: 0;
    border-bottom: 0.2rem dashed
      ${({ theme, hasName }) =>
        hasName ? "transparent" : theme.colors.greyLight};
    border-radius: 0;
    background-color: transparent;

    @media (max-width: ${({ theme }) => theme.breakpoints.page.twoColumn}px) {
      width: 100%;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
      text-align: center;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.blackLight};
    }

    &:hover {
      color: ${({ theme, hasName }) =>
        hasName ? theme.colors.grey : theme.colors.greyDark};
    }

    &:hover:focus {
      color: ${({ theme }) => theme.colors.greyDark};
    }

    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.colors.streamsBlue};

      &::placeholder {
        color: ${({ theme }) => theme.colors.blackLight};
      }
    }
  }
`;
