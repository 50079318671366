import React, { useState } from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import { findOptionByLabel } from "../../select/utils";
import PillSearch from "../../common/PillSearch";
import { Container } from "./styled/stream-filter-locations";

function StreamFilterLocations({
  filters,
  locations,
  locationLabels,
  customAreas,
  selectedLocations,
  replaceStreamFilters
}) {
  const [localSelectedLocations, setSelectedLocations] = useState(
    selectedLocations
  );

  const handleSelect = (location) => {
    setSelectedLocations((locations) => [
      ...locations.filter((location) => !location.includes("Custom Area")),
      location
    ]);
  };

  const handleRemove = (location) => {
    setSelectedLocations((locations) =>
      locations.filter((l) => l !== location)
    );
  };

  const handleClose = () => {
    if (isEqual(localSelectedLocations, selectedLocations)) return;

    let newFilters = localSelectedLocations.reduce((filters, value) => {
      const option =
        findOptionByLabel(value, locations) ||
        findOptionByLabel(value, customAreas);

      if (!option) return filters;

      const filter = filters[option.key] || {};
      const newOption =
        option.key === "location" // if the value is a custom area
          ? { ...filter, within: [...(filter.within || []), option.within] }
          : { ...filter, should: [...(filter.should || []), value] };

      return { ...filters, [option.key]: newOption };
    }, {});
    let { location, ...filtersWithoutLocation } = filters;

    if (newFilters.location) {
      newFilters = {
        ...newFilters,
        location: {
          ...newFilters.location,
          within: JSON.stringify(newFilters.location.within)
        }
      };
    }

    replaceStreamFilters({ ...filtersWithoutLocation, ...newFilters });
  };

  return (
    <Container>
      <PillSearch
        allItems={locationLabels}
        selectedItems={localSelectedLocations}
        onSelect={handleSelect}
        onRemove={handleRemove}
        onClose={handleClose}
      />
    </Container>
  );
}

StreamFilterLocations.propTypes = {
  filters: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  locationLabels: PropTypes.array.isRequired,
  customAreas: PropTypes.array.isRequired,
  replaceStreamFilters: PropTypes.func.isRequired
};

export default StreamFilterLocations;
