import React from "react";
import PropTypes from "prop-types";
import { AriaMessage } from "@wrstudios/components";
import {
  Container,
  Thumbnail,
  RemainingCount
} from "./styled/activity-listing";

function ActivityListing({
  url,
  title,
  photo,
  remainingCount,
  hasRemainingCount
}) {
  return (
    <Container to={url}>
      <Thumbnail style={{ backgroundImage: `url('${photo}')` }} title={title} />
      <AriaMessage>{title}</AriaMessage>
      {hasRemainingCount && <RemainingCount>+{remainingCount}</RemainingCount>}
    </Container>
  );
}

ActivityListing.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired,
  remainingCount: PropTypes.number.isRequired,
  hasRemainingCount: PropTypes.bool.isRequired
};

export default ActivityListing;
