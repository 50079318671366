import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Comments,
  Empty,
  Comment,
  ViewAll
} from "./styled/stream-item-comments";

function StreamItemComments({ hasComments, comments }) {
  const [showAll, setShowAll] = useState(false);
  const trimmedComments = showAll ? comments : comments.slice(0, 1);

  return (
    <>
      <Comments>
        {!hasComments && <Empty>No comments yet</Empty>}
        {trimmedComments.map((comment, index) => (
          <Comment key={index}>
            <strong>{comment.author}</strong> <span>{comment.content}</span>
          </Comment>
        ))}
      </Comments>
      {comments.length > 1 && (
        <ViewAll onClick={() => setShowAll(!showAll)}>
          {showAll && "Hide comments"}
          {!showAll && `Show all ${comments.length} comments`}
        </ViewAll>
      )}
    </>
  );
}

StreamItemComments.defaultProps = {
  onMount: function () {}
};

StreamItemComments.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired
    })
  ),
  hasComments: PropTypes.bool.isRequired
};

export default StreamItemComments;
