import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { AriaMessage } from "@wrstudios/components";
import IconClose from "../icons/IconClose";
import IconMiniCMA from "../icons/IconMiniCMA";
import IconMiniMLX from "../icons/IconMiniMLX";
import {
  Container,
  Action,
  Group,
  Icon,
  Content,
  Header,
  List,
  Item,
  Link
} from "./styled/listing-controls-menu";

class ListingControlsMenu extends Component {
  ref = createRef();

  componentDidMount() {
    if (this.props.isFocused) {
      this.ref.current.focus();
    }
  }

  render() {
    const {
      newCmaSubjectUrl,
      newCmaPropertyUrl,
      newCmaFlyerUrl,
      newMlxListingUrl
    } = this.props;

    return (
      <Container>
        <Action ref={this.ref} onClick={this.props.onClose}>
          <IconClose />
          <AriaMessage>Close more listing options</AriaMessage>
        </Action>
        <Group>
          <Icon>
            <IconMiniCMA />
          </Icon>
          <Content>
            <Header>CMA</Header>
            <List>
              <Item>
                <Link href={newCmaSubjectUrl} target="_blank">
                  Make CMA Report
                </Link>
              </Item>
              <Item>
                <Link href={newCmaPropertyUrl} target="_blank">
                  Make Property Report
                </Link>
              </Item>
              <Item>
                <Link href={newCmaFlyerUrl} target="_blank">
                  Make Flyer
                </Link>
              </Item>
            </List>
          </Content>
        </Group>
        {newMlxListingUrl && (
          <Group>
            <Icon>
              <IconMiniMLX />
            </Icon>
            <Content>
              <Header>MLX</Header>
              <List>
                <Item>
                  <Link href={newMlxListingUrl} target="_blank">
                    Open in Cloud MLX
                  </Link>
                </Item>
              </List>
            </Content>
          </Group>
        )}
      </Container>
    );
  }
}

ListingControlsMenu.propTypes = {
  isFocused: PropTypes.bool,
  newCmaSubjectUrl: PropTypes.string,
  newCmaPropertyUrl: PropTypes.string,
  newCmaFlyerUrl: PropTypes.string,
  newMlxListingUrl: PropTypes.string,
  onClose: PropTypes.func
};

export default ListingControlsMenu;
