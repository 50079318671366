import React from "react";
import PropTypes from "prop-types";
import { Timestamp } from "./styled/activity-content";

function ActivityContent({ updatedAtAgo, children }) {
  return (
    <>
      {children} {updatedAtAgo && <Timestamp>{updatedAtAgo}</Timestamp>}
    </>
  );
}

ActivityContent.propTypes = {
  updatedAtAgo: PropTypes.string.isRequired
};

export default ActivityContent;
