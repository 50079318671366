import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import Label from "../common/Label";
import IconDropdownArrowDown from "../icons/IconDropdownArrowDown";
import IconDropdownArrowUp from "../icons/IconDropdownArrowUp";
import {
  Container,
  TriggerButton,
  TriggerLabel,
  TriggerPointer,
  Menu,
  Options,
  Option,
  Checkbox
} from "./styled/selector";

class Selector extends Component {
  menuRef = createRef();
  optionsRef = createRef();

  state = {
    isOpen: false,
    highlighted: 0
  };

  render() {
    const {
      id,
      label,
      triggerLabel,
      options,
      selectedOptions,
      onChange
    } = this.props;
    const { isOpen } = this.state;

    return (
      <>
        {label && <Label htmlFor={id}>{label}</Label>}
        <Container isOpen={isOpen} onKeyDown={this.handleKeyDown}>
          <TriggerButton
            id={id}
            type="button"
            isOpen={isOpen}
            onClick={this.handleOpen}>
            <TriggerLabel>{triggerLabel}</TriggerLabel>
            <TriggerPointer>
              {!isOpen && <IconDropdownArrowDown />}
              {isOpen && <IconDropdownArrowUp />}
            </TriggerPointer>
          </TriggerButton>
          {isOpen && (
            <Menu ref={this.menuRef}>
              <Options isOpen={isOpen} ref={this.optionsRef}>
                {options.map((option, index) => {
                  const onMouseEnter = () => this.handleHover(index);
                  const isHighlighted = this.state.highlighted === index;
                  const isChecked = selectedOptions.includes(option.value);

                  return (
                    <Option {...{ onMouseEnter, isHighlighted, key: index }}>
                      <Checkbox
                        value={option.value}
                        checked={isChecked}
                        onChange={onChange}>
                        {option.label}
                      </Checkbox>
                    </Option>
                  );
                })}
              </Options>
            </Menu>
          )}
        </Container>
      </>
    );
  }

  componentWillUnmount() {
    this.removeEventListeners();
  }

  handleOpen = () => {
    if (this.state.isOpen) {
      return;
    }

    window.addEventListener("mouseup", this.handleWindowClick);
    this.setState({ isOpen: true, highlighted: 0 });
  };

  handleClose = () => {
    window.removeEventListener("mouseup", this.handleWindowClick);
    this.setState({ isOpen: false });
  };

  handleWindowClick = ({ target }) => {
    if (!this.state.isOpen || this.menuRef.current.contains(target)) {
      return;
    }

    this.handleClose();
  };

  handleKeyDown = (e) => {
    if (["Escape", "ArrowUp", "ArrowDown"].includes(e.key)) {
      e.preventDefault();
    }

    switch (e.key) {
      case "Escape":
        return this.handleClose();
      case "ArrowUp":
        return this.handleUpArrow();
      case "ArrowDown":
        return this.handleDownArrow();
      default:
        return;
    }
  };

  handleUpArrow = (e) => {
    if (this.state.highlighted === 0) {
      return;
    }

    this.setState({ highlighted: this.state.highlighted - 1 }, () => {
      const menuRect = this.menuRef.current.getBoundingClientRect();
      const option = this.optionsRef.current.children[this.state.highlighted];
      const optionRect = option.getBoundingClientRect();

      if (menuRect.top > optionRect.top) {
        this.optionsRef.current.scrollTop =
          this.optionsRef.current.scrollTop - (menuRect.top - optionRect.top);
      }
    });
  };

  handleDownArrow = () => {
    if (this.state.highlighted === this.props.options.length - 1) {
      return;
    }

    this.setState({ highlighted: this.state.highlighted + 1 }, () => {
      const menuRect = this.menuRef.current.getBoundingClientRect();
      const option = this.optionsRef.current.children[this.state.highlighted];
      const optionOffsetTop = option.offsetTop;
      const optionHeight = option.getBoundingClientRect().height;
      const optionDiff = optionOffsetTop + optionHeight - menuRect.height;

      this.optionsRef.current.scrollTop = optionDiff;
    });
  };

  handleHover = (index) => {
    this.setState({ highlighted: index });
  };

  removeEventListeners = () => {
    window.removeEventListener("mouseup", this.handleWindowClick);
  };
}

Selector.propTypes = {
  id: PropTypes.string,
  isSingular: PropTypes.bool,
  label: PropTypes.string.isRequired,
  dataLabel: PropTypes.string,
  triggerLabel: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func
};

Selector.defaultProps = {
  id: null,
  isSingular: false,
  label: "",
  dataLabel: "Select an Option",
  triggerLabel: "Select an Option",
  options: [],
  selectedOptions: [],
  onChange: function () {}
};

export default Selector;
