import { connect } from "react-redux";
import { setStreamItemLike } from "../../../actions/streamItem";
import StreamItemLike from "../StreamItemLike";

function select({ streamItems }, { streamItemId }) {
  const streamItem = streamItems.byId[streamItemId] || {};

  return {
    liked: !!streamItem.liked
  };
}

const actions = {
  setStreamItemLike
};

export default connect(select, actions)(StreamItemLike);
