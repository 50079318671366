import React, { Component } from "react";
import PropTypes from "prop-types";
import { timer } from "../../../utils/timer";
import { isMobilePhone } from "@wrstudios/utils";
import FormGroup from "../../common/FormGroup";
import Label from "../../common/Label";
import Input from "../../common/Input";
import Tip from "../../common/Tip";
import Flash from "../../common/Flash";
import Select from "../../select/Select";
import UserAvatar from "../../user/connected/UserAvatar";
import UserPhoneVerifyModal from "../../user/UserPhoneVerifyModal";
import { findOptionByLabel, findOptionByValue } from "../../select/utils";
import { Button } from "./styled/settings-route";
import {
  Container,
  Form,
  Avatar,
  Phone,
  PhoneVerify
} from "./styled/settings-route-profile";

class SettingsRouteProfile extends Component {
  state = {
    name: this.props.name || "",
    email: this.props.email || "",
    phone: this.props.phone || "",
    officePhone: this.props.officePhone || "",
    city: this.props.city || "",
    state: this.props.state || "",
    company: this.props.company || "",
    website: this.props.website || "",
    license: this.props.license || "",
    timezone: this.props.timezone || "",
    avatar: this.props.avatar || "",
    showVerifyModal: false,
    phoneVerified: false
  };

  render() {
    const {
      isAgent,
      states,
      statesLabels,
      timezones,
      timezonesLabels,
      isFetching,
      hasFetched,
      errors
    } = this.props;

    const {
      name,
      email,
      phone,
      officePhone,
      city,
      state,
      company,
      website,
      license,
      timezone,
      showVerifyModal,
      phoneVerified
    } = this.state;
    const stateLabel = (findOptionByValue(state, states) || {}).label;
    const isDisabled = this.getIsDisabled();
    const propsPhone = (this.props.phone || "").replace(/-/g, "");
    const statePhone = phone.replace(/-/g, "");
    const disableVerifyButton =
      propsPhone === statePhone || !isMobilePhone(this.state.phone);

    return (
      <>
        <Container>
          <Form onSubmit={this.handleOnSubmit}>
            <FormGroup>
              <Label htmlFor="name">Name</Label>
              <Input
                id="name"
                name="name"
                placeholder="Full name"
                value={name}
                onChange={this.handleOnChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder="name@domain.com"
                value={email}
                onChange={this.handleOnChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="phone">Mobile Phone</Label>
              <Phone>
                <Input
                  id="phone"
                  name="phone"
                  placeholder="000-000-0000"
                  value={phone}
                  onChange={this.handleOnChange}
                />
                <PhoneVerify
                  type="button"
                  disabled={disableVerifyButton}
                  onClick={this.handleOnPhoneVerifyClick}>
                  Verify
                </PhoneVerify>
              </Phone>
              {!disableVerifyButton && !phoneVerified && (
                <Tip>Verify your mobile phone number to save.</Tip>
              )}
              {phoneVerified && (
                <Tip>Your mobile phone number has been saved!</Tip>
              )}
            </FormGroup>
            {isAgent && (
              <FormGroup>
                <Label htmlFor="officePhone">Office Phone Number</Label>
                <Input
                  id="officePhone"
                  name="officePhone"
                  placeholder="000-000-0000"
                  value={officePhone}
                  onChange={this.handleOnChange}
                />
              </FormGroup>
            )}
            <FormGroup>
              <Label htmlFor="city">City</Label>
              <Input
                id="city"
                name="city"
                value={city}
                onChange={this.handleOnChange}
              />
            </FormGroup>
            <FormGroup>
              <Select
                key={states}
                label="State / Province"
                placeholder="Select a State / Province"
                value={stateLabel}
                items={statesLabels}
                onChange={this.handleOnStatesChange}
              />
            </FormGroup>
            {isAgent && (
              <>
                <FormGroup>
                  <Label htmlFor="company">Company Name</Label>
                  <Input
                    id="company"
                    name="company"
                    value={company}
                    onChange={this.handleOnChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="website">Website</Label>
                  <Input
                    id="website"
                    name="website"
                    value={website}
                    onChange={this.handleOnChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="license">License Number</Label>
                  <Input
                    id="license"
                    name="license"
                    value={license}
                    onChange={this.handleOnChange}
                  />
                </FormGroup>
              </>
            )}
            <FormGroup>
              <Select
                key={timezones}
                label="Time Zone"
                placeholder="Select a Time Zone"
                value={timezone}
                items={timezonesLabels}
                onChange={this.handleOnTimezoneChange}
              />
            </FormGroup>
            {errors.map(({ message }, index) => (
              <FormGroup key={index}>
                <Flash status="error">{message}</Flash>
              </FormGroup>
            ))}
            {hasFetched && (
              <FormGroup>
                <Flash status="success">User profile updated</Flash>
              </FormGroup>
            )}
            <Button disabled={isDisabled}>
              {!isFetching && "Save Profile"}
              {isFetching && "Saving Profile"}
            </Button>
          </Form>
          <Avatar>
            <UserAvatar
              onChange={this.handleOnAvatarChange}
              onRemove={this.handleOnAvatarRemove}
            />
          </Avatar>
        </Container>
        {showVerifyModal && (
          <UserPhoneVerifyModal
            phone={this.state.phone}
            showVerifyCodeStep={true}
            onSuccess={this.handleOnPhoneVerifySuccess}
            onClose={this.handleOnPhoneVerifyClose}
          />
        )}
      </>
    );
  }

  componentDidUpdate() {
    this.handleOnSuccess();
  }

  componentWillUnmount() {
    this.props.resetUserProfileNetwork();
  }

  handleOnChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleOnStatesChange = (state) => {
    const option = findOptionByLabel(state, this.props.states);

    this.setState({ state: option.value });
  };

  handleOnTimezoneChange = (timezone) => {
    const option = findOptionByLabel(timezone, this.props.timezones);

    this.setState({ timezone: option.value });
  };

  handleOnAvatarChange = (avatar) => {
    this.setState({ avatar });
  };

  handleOnAvatarRemove = () => {
    this.setState({ avatar: "" });
  };

  handleOnPhoneVerifyClick = () => {
    this.setState({ showVerifyModal: true });
  };

  handleOnPhoneVerifyClose = () => {
    this.setState({ showVerifyModal: false });
  };

  handleOnPhoneVerifySuccess = () => {
    this.setState({ phoneVerified: true }, async () => {
      await timer(2000);

      this.setState({ phoneVerified: false });
    });
  };

  handleOnSubmit = (e) => {
    e.preventDefault();

    const state = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      officePhone: this.state.officePhone,
      city: this.state.city,
      state: this.state.state,
      company: this.state.company,
      website: this.state.website,
      license: this.state.license,
      timezone: this.state.timezone,
      avatar: this.state.avatar
    };

    if (state.phone) {
      const { phone, avatar, ...modifiedState } = state;
      return this.props.updateUserProfile(modifiedState);
    }

    // Remove avatar as it's not needed when updating a profile.
    const { avatar, ...modifiedState } = state;

    this.props.updateUserProfile(modifiedState);
  };

  handleOnSuccess = async () => {
    if (this.props.hasFetched) {
      await timer(2000);

      this.props.resetUserProfileNetwork();
    }
  };

  getIsDisabled = () => {
    const hasNameChanged = this.state.name !== this.props.name;
    const hasEmailChanged = this.state.email !== this.props.email;
    const isPhoneEmpty =
      !this.state.phone && this.state.phone !== this.props.phone;
    const hasOfficePhoneChanged =
      this.state.officePhone !== this.props.officePhone;
    const hasCityChanged = this.state.city !== this.props.city;
    const hasStateChanged = this.state.state !== this.props.state;
    const hasCompanyChanged = this.state.company !== this.props.company;
    const hasWebsiteChanged = this.state.website !== this.props.website;
    const hasLicenseChanged = this.state.license !== this.props.license;
    const hasTimezoneChanged = this.state.timezone !== this.props.timezone;
    const hasAvatarChanged = this.state.avatar !== this.props.avatar;
    const hasFormChanged =
      hasNameChanged ||
      hasEmailChanged ||
      isPhoneEmpty ||
      hasOfficePhoneChanged ||
      hasCityChanged ||
      hasStateChanged ||
      hasCompanyChanged ||
      hasWebsiteChanged ||
      hasLicenseChanged ||
      hasTimezoneChanged ||
      hasAvatarChanged;

    return this.props.isFetching || !hasFormChanged;
  };
}

SettingsRouteProfile.propTypes = {
  isAgent: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  officePhone: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  website: PropTypes.string.isRequired,
  license: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  states: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string
    })
  ).isRequired,
  statesLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  timezones: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string
    })
  ).isRequired,
  timezonesLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  isFetching: PropTypes.bool.isRequired,
  hasFetched: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string
    })
  ).isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  resetUserProfileNetwork: PropTypes.func.isRequired
};

export default SettingsRouteProfile;
