import React, { Component } from "react";
import PropTypes from "prop-types";
import Media from "react-media";
import { breakpoints } from "@wrstudios/theme";
import { findOptionByLabel } from "../select/utils";
import IconSearch from "../icons/IconSearch";
import { Container, Select, Search } from "./styled/stream-locations";

class StreamLocations extends Component {
  state = {
    searchTerm: ""
  };

  render() {
    return (
      <Media query={`(max-width: ${breakpoints.sm}px)`}>
        {(matches) => (
          <Container>
            <Search>
              <IconSearch />
            </Search>
            <Select
              items={this.props.locationsLabels}
              onSelect={this.handleOnSelect}
              autoFocus
              placeholder={
                matches
                  ? "Enter City, Area, or Zip"
                  : "Begin by entering a City, Area or Zip code."
              }
              defaultHighlightedIndex={0}
              persistInputValue={false}
              rowHeight={32}
              arrows={false}
              searchable
            />
          </Container>
        )}
      </Media>
    );
  }

  handleOnSelect = (value) => {
    const { defaultPropType } = this.props;
    const option = findOptionByLabel(value, this.props.locations);

    this.setState({ searchTerm: value }, () => {
      this.props.replaceStreamFilters({
        [option.key]: { should: [value] },

        // When available, use Forge's default_prop_type mls flag as default filter for new streams
        ...(!!defaultPropType && {
          prop_type: { field: "prop_type", eq: [defaultPropType] }
        })
      });
    });
  };
}

StreamLocations.propTypes = {
  locations: PropTypes.array.isRequired,
  locationsLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultPropType: PropTypes.string,
  replaceStreamFilters: PropTypes.func.isRequired
};

export default StreamLocations;
