import styled from "styled-components";

export const Grid = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -0.3rem;
`;

export const Cell = styled.div`
  padding: 0 0.4rem;
`;

export const Spacer = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
`;
