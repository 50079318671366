import {
  deleteInvitation as deleteInvitationAPI,
  resendInvitation as resendInvitationAPI
} from "../modules/railsApi";
import { transformInvitation } from "../modules/invitation";

export function resendInvitation({ invitationId }) {
  return async (dispatch, getState) => {
    try {
      const { application } = getState();

      dispatch({
        type: "RESEND_INVITATION_INITIATED",
        payload: { invitationId }
      });

      const invitationData = await resendInvitationAPI({
        invitationId,
        railsApiToken: application.railsApiToken
      });
      const transformedInvitation = transformInvitation(invitationData);

      dispatch({
        type: "RESEND_INVITATION_SUCCEEDED",
        payload: { invitationId, invitation: transformedInvitation }
      });
    } catch (error) {
      dispatch({
        type: "RESEND_INVITATION_FAILED",
        payload: { error, invitationId }
      });
    }
  };
}

export function deleteInvitation({ invitationId }) {
  return async (dispatch, getState) => {
    try {
      const { application } = getState();

      dispatch({
        type: "DELETE_INVITATION_INITIATED",
        payload: { invitationId }
      });

      await deleteInvitationAPI({
        invitationId,
        railsApiToken: application.railsApiToken
      });
      const { invitations } = getState();
      const {
        [invitationId]: removedInvitation,
        ...newInvitations
      } = invitations.byId;

      dispatch({
        type: "DELETE_INVITATION_SUCCEEDED",
        payload: {
          invitationId,
          invitations: newInvitations,
          streamId: removedInvitation.streamId
        }
      });
    } catch (error) {
      // Rollback optimistic update
      dispatch({
        type: "DELETE_INVITATION_FAILED",
        payload: { error, invitationId }
      });
    }
  };
}
