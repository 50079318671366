import { connect } from "react-redux";
import { getClientRecommendedListings } from "../../../actions/client";
import ClientRecommendedListings from "../ClientRecommendedListings";

function select(
  { clients: { byId: clientsById }, streamItems, listings },
  { match }
) {
  const clientId = match.params.clientId;
  const client = clientsById[clientId];
  const { recommendedStreamItemIds = [] } = client;
  const hasStreamItems =
    recommendedStreamItemIds.length > 0 &&
    recommendedStreamItemIds.every((id) => streamItems.byId[id]);
  const isLoading = !hasStreamItems && listings.isLoading;

  return {
    client,
    clientId,
    isLoading,
    hasStreamItems,
    recommendedStreamItemIds
  };
}

const actions = { getClientRecommendedListings };

export default connect(select, actions)(ClientRecommendedListings);
