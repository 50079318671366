import { connect } from "react-redux";
import StreamItemCommentSubscribers from "../StreamItemCommentSubscribers";

function select({ user, streamItems }, { streamItemId }) {
  const streamItem = streamItems.byId[streamItemId] || {};

  // Filter out the agent and re-add the agent so their arent' duplicates
  const agent = user.isAgent ? user : user.primaryAgent || {};
  const modifiedAgent = { ...agent, avatarUrl: agent.avatar };

  const subscribers = [
    ...Object.values(streamItem.subscriptions || {})
      .map((subscription) => subscription.subscriber)
      .filter(Boolean)
      .filter((client) => client.id !== agent.id),
    modifiedAgent
  ].filter(Boolean);

  return {
    subscribers
  };
}

export default connect(select)(StreamItemCommentSubscribers);
