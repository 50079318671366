import { connect } from "react-redux";
import { setFullscreenMediaDetails } from "../../../actions/listing";
import ListingSlider from "../ListingSlider";

function select({ listings }, { listingId }) {
  const listing = listings.byId[listingId] || {};

  return {
    mapboxApiToken: process.env.REACT_APP_MAPBOX_API_TOKEN,
    latitude: listing.latitude,
    longitude: listing.longitude,
    photos: listing.photos,
    virtualTour: listing.virtualTour
  };
}

const actions = {
  setFullscreenMediaDetails
};

export default connect(select, actions)(ListingSlider);
