import React from "react";
import PropTypes from "prop-types";
import { LoadingSpinner } from "@wrstudios/components";
import { getSocialUsername } from "../../modules/user";
import { SOCIAL_ICONS } from "../../modules/client";
import LinkWithIcon from "../common/LinkWithIcon";
import IconArrowDown from "../icons/IconArrowDown";
import {
  CardSegment,
  ProfileLink,
  SocialIcon,
  Username,
  LinkWithIconLayout,
  SocialProfileError,
  Underlined,
  StatusLabel
} from "./styled/client-profile";

function ClientProfileSocial({
  id,
  socialProfiles,
  hasSocialProfiles,
  socialProfileStatusNotFetched,
  socialProfileStatusProcessing,
  socialProfileStatusFound,
  socialProflileStatusNotFound,
  isGettingSocialData,
  hasSocialDataError,
  getClientSocialData
}) {
  if (isGettingSocialData) {
    return (
      <CardSegment>
        <LinkWithIconLayout>
          <LinkWithIcon as="button" disabled>
            <LoadingSpinner />
            Fetching social profiles
          </LinkWithIcon>
        </LinkWithIconLayout>
      </CardSegment>
    );
  }

  if (hasSocialDataError) {
    return (
      <CardSegment>
        <LinkWithIconLayout>
          <SocialProfileError onClick={() => getClientSocialData(id)}>
            Error fetching profiles. <Underlined>Try again?</Underlined>
          </SocialProfileError>
        </LinkWithIconLayout>
      </CardSegment>
    );
  }

  return (
    <CardSegment>
      {socialProfileStatusNotFetched && (
        <LinkWithIconLayout>
          <LinkWithIcon as="button" onClick={() => getClientSocialData(id)}>
            <IconArrowDown />
            Fetch social media profiles
          </LinkWithIcon>
        </LinkWithIconLayout>
      )}
      {socialProfileStatusProcessing && (
        <StatusLabel>
          Looking for social profiles... <br /> Check back in a few minutes.
        </StatusLabel>
      )}
      {socialProfileStatusFound &&
        hasSocialProfiles &&
        socialProfiles.map((profile) => (
          <ProfileLink href={profile.url} target="_blank" key={profile.type}>
            <SocialIcon
              src={SOCIAL_ICONS[profile.type]}
              alt={`${profile.type} icon`}
            />
            <Username>{getSocialUsername(profile)}</Username>
          </ProfileLink>
        ))}
      {socialProflileStatusNotFound && (
        <StatusLabel>No social profiles found</StatusLabel>
      )}
    </CardSegment>
  );
}

ClientProfileSocial.propTypes = {
  id: PropTypes.string.isRequired,
  socialProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired
    })
  ),
  hasSocialProfiles: PropTypes.bool.isRequired,
  socialProfileStatusNotFetched: PropTypes.bool.isRequired,
  socialProfileStatusProcessing: PropTypes.bool.isRequired,
  socialProfileStatusFound: PropTypes.bool.isRequired,
  socialProflileStatusNotFound: PropTypes.bool.isRequired,
  isGettingSocialData: PropTypes.bool.isRequired,
  hasSocialDataError: PropTypes.bool.isRequired,
  getClientSocialData: PropTypes.func.isRequired
};

export default ClientProfileSocial;
