import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Link } from "@wrstudios/components";
import {
  RouteContainer,
  Title,
  Subscriptions as Subscription,
  LargeText,
  ButtonLayout
} from "./styled/subscriptions-route";

class UnsubscribeRoute extends Component {
  render() {
    const { hasFetched, streamName } = this.props;
    return hasFetched ? (
      <RouteContainer>
        <Title>Your Subscription</Title>
        <Subscription>
          <LargeText>
            You've successfully unsubscribed from:
            <br />
            <strong>{streamName}</strong>
          </LargeText>
          <ButtonLayout>
            <Link to="/activity">
              <Button large>Go to Activity</Button>
            </Link>
          </ButtonLayout>
        </Subscription>
      </RouteContainer>
    ) : null;
  }

  componentDidMount() {
    this.props.getStreamSubscription(this.props.subscriptionId);
  }
}

UnsubscribeRoute.propTypes = {
  hasFetched: PropTypes.bool.isRequired,
  streamName: PropTypes.string,
  streamId: PropTypes.string,
  subscriptionId: PropTypes.string.isRequired,
  getStreamSubscription: PropTypes.func.isRequired
};

export default UnsubscribeRoute;
