import React from "react";
import PropTypes from "prop-types";
import IconRecommend from "../icons/IconRecommend";
import { Container } from "./styled/stream-item-recommends";

function StreamItemRecommends({ name, isHidden }) {
  if (isHidden) return null;

  return (
    <Container>
      <IconRecommend />
      <div>
        <strong>{name}</strong> recommended this property.
      </div>
    </Container>
  );
}

StreamItemRecommends.propTypes = {
  name: PropTypes.string.isRequired,
  isHidden: PropTypes.bool.isRequired
};

export default StreamItemRecommends;
