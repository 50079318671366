import { connect } from "react-redux";
import { getFirstName } from "@wrstudios/utils";
import { AgentInfoDropdown } from "@wrstudios/components";

function select({
  application: { railsApiToken },
  user: {
    primaryAgent: {
      initials,
      initialsColor,
      avatar,
      name,
      email,
      company,
      address,
      city,
      state,
      zip,
      phone,
      officePhone
    }
  }
}) {
  return {
    isAuthed: !!railsApiToken,
    hasCompleteAddress: !!address && !!city && !!state && !!zip,
    hasPhoneNumber: !!phone || !!officePhone,
    firstName: name ? getFirstName(name) : "",
    initials,
    initialsColor,
    avatar,
    name,
    email,
    company,
    address,
    city,
    state,
    zip,
    phone,
    officePhone,
    zIndex: 21
  };
}

export default connect(select)(AgentInfoDropdown);
