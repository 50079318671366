import { connect } from "react-redux";
import ClientRoute from "../ClientRoute";
import { getClient } from "../../../../actions/client";

function select({ clients }, { location, match }) {
  const clientId = match.params.clientId;
  const client = clients.byId[clientId] || {};
  const hasClient = !!Object.values(client).length;
  const isFetching = clients.getClientNetwork.isFetching && !hasClient;
  const hasFetched = clients.getClientNetwork.hasFetched || hasClient;
  const hasErrors = !!clients.getClientNetwork.errors.length;
  const notFound = hasFetched && !hasClient;

  return {
    location,
    hasClient,
    clientId: clientId || "",
    clientName: client.name || "",
    isFetching,
    hasErrors,
    notFound
  };
}

const actions = {
  getClient
};

export default connect(select, actions)(ClientRoute);
