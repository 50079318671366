import { connect } from "react-redux";
import { replaceStreamFilters } from "../../../../actions/stream";
import StreamFilterBeds from "../StreamFilterBeds";

function select({ stream }) {
  const { beds = {}, ...filters } = stream.filters.byId;
  const min = beds.gte;
  const max = beds.lte;

  return {
    filters,
    min,
    max
  };
}

const actions = {
  replaceStreamFilters
};

export default connect(select, actions)(StreamFilterBeds);
