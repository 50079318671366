import React from "react";

function IconArrowRightLong() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        d="M13.47 13.47a.75.75 0 101.06 1.06l4-4 .53-.53-.53-.53-4-4a.75.75 0 10-1.06 1.06l2.72 2.72H2a.75.75 0 000 1.5h14.19l-2.72 2.72z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default IconArrowRightLong;
