import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";
import smoothscroll from "smoothscroll-polyfill";
import { getRootDiv } from "@wrstudios/utils";
import { loadExternalLibraries } from "./utils/external";
import App from "./components/root/connected/App";
import { /*registerServiceWorker,*/ unregister } from "./registerServiceWorker";
import AppProvider from "./components/root/AppProvider";
// import { reloadOnNewServiceWorkerActivated } from "./utils/seviceWorker";
smoothscroll.polyfill();

ReactDOM.render(
  <AppProvider>
    <App />
  </AppProvider>,
  getRootDiv()
);

unregister();
// registerServiceWorker({ dispatch: store.dispatch });
// reloadOnNewServiceWorkerActivated();
loadExternalLibraries();
