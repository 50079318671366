import React from "react";

function IconSuccess() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="9.6" fill="#29A329" />
      <path
        d="M14.1875 9.28992C14.5758 8.9029 15.2042 8.90343 15.5919 9.2911C15.98 9.67924 15.98 10.3085 15.5919 10.6967L11.2012 15.0874C10.811 15.4776 10.1784 15.4776 9.7882 15.0874C9.39754 14.6968 9.39808 14.0632 9.78939 13.6732L14.1875 9.28992Z"
        fill="white"
      />
      <path
        d="M8.28898 13.543C7.90273 13.1541 7.90377 12.5261 8.29131 12.1386C8.67976 11.7501 9.30956 11.7501 9.69802 12.1386L11.238 13.6785C11.6278 14.0683 11.6279 14.7004 11.238 15.0903C10.8472 15.4811 10.2133 15.4801 9.82378 15.088L8.28898 13.543Z"
        fill="white"
      />
    </svg>
  );
}

export default IconSuccess;
