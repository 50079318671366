import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import { Button } from "@wrstudios/components";
import FormGroup from "../../common/FormGroup";
import StreamFilterPropertyTypeOverlay from "./connected/StreamFilterPropertyTypeOverlay";
import StreamFilterPriceOverlay from "./connected/StreamFilterPriceOverlay";
import StreamFilterBedsOverlay from "./connected/StreamFilterBedsOverlay";
import StreamFilterBathsOverlay from "./connected/StreamFilterBathsOverlay";
import StreamFilterSqftOverlay from "./connected/StreamFilterSqftOverlay";
import StreamFilterAdvancedOverlay from "./connected/StreamFilterAdvancedOverlay";
import { Container } from "./styled/stream-filters-overlay";

class StreamFiltersOverlay extends Component {
  state = {
    filters: {}
  };

  render() {
    return (
      <Container
        isEditingCriteria={this.props.isEditingCriteria}
        onSubmit={this.handleOnSubmit}>
        <FormGroup>
          <StreamFilterPropertyTypeOverlay onChange={this.handleOnChange} />
        </FormGroup>
        <FormGroup>
          <StreamFilterPriceOverlay onChange={this.handleOnChange} />
        </FormGroup>
        <FormGroup>
          <StreamFilterBedsOverlay onChange={this.handleOnChange} />
        </FormGroup>
        <FormGroup>
          <StreamFilterBathsOverlay onChange={this.handleOnChange} />
        </FormGroup>
        <FormGroup>
          <StreamFilterSqftOverlay onChange={this.handleOnChange} />
        </FormGroup>
        <FormGroup>
          <StreamFilterAdvancedOverlay
            onChange={this.handleOnAdvancedOverlayChange}
          />
        </FormGroup>
        <FormGroup>
          <Button>Apply Filters</Button>
        </FormGroup>
      </Container>
    );
  }

  componentDidMount() {
    window.addEventListener("resize", this.hideOverlay);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.hideOverlay);
  }

  handleOnChange = (filter) => {
    this.setState(({ filters }) => ({ filters: { ...filters, ...filter } }));
  };

  handleOnAdvancedOverlayChange = (filter, removedFilterKey) => {
    this.setState(({ filters }) => {
      const newFilters = { ...filters, ...filter };
      const {
        [removedFilterKey]: removedFilter,
        ...newFiltersWithoutRemovedFilter
      } = newFilters;

      return {
        filters: newFiltersWithoutRemovedFilter
      };
    });
  };

  handleOnSubmit = (e) => {
    e.preventDefault();

    if (!isEqual(this.props.filters, this.state.filters)) {
      // Remove all empty and falsy items
      const filters = { ...this.props.locationFilters, ...this.state.filters };
      const modifiedFilters = Object.entries(filters)
        .filter(([_, filter]) => !!Object.keys(filter || {}).length)
        .reduce(
          (filters, [field, filter]) => ({ ...filters, [field]: filter }),
          {}
        );

      this.props.replaceStreamFilters(modifiedFilters);
    }

    this.props.setShowStreamFilters(false);
  };

  width = window.outerWidth;
  hideOverlay = (e) => {
    if (this.width === e.target.outerWidth) return;
    this.props.setShowStreamFilters(false);
  };
}

StreamFiltersOverlay.propTypes = {
  isEditingCriteria: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  locationFilters: PropTypes.object.isRequired,
  replaceStreamFilters: PropTypes.func.isRequired,
  setShowStreamFilters: PropTypes.func.isRequired
};

export default StreamFiltersOverlay;
