import React from "react";
import PropTypes from "prop-types";
import { Container } from "./styled/stream-card-poker-chips";

function StreamCardPokerChips({ clients }) {
  return <Container clients={clients} size="xxs" />;
}

StreamCardPokerChips.propTypes = {
  clients: PropTypes.array.isRequired
};

export default StreamCardPokerChips;
