export function network(state = {}, { type, payload }) {
  const matches = /(.*)_(REQUEST|RESOLVED|REJECTED)/.exec(type);

  // not a *_REQUEST / *_RESOLVED /  *_REJECTED action, so we ignore it
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    //      and false when receiving GET_TODOS_RESOLVED / GET_TODOS_REJECTED
    [requestName]: {
      isLoading: requestState === "REQUEST",
      hasLoaded: requestState === "RESOLVED",
      // If we have a payload that's an object, we check for an error property
      // if we don't have a property of error we use the payload.
      error:
        requestState === "REJECTED"
          ? payload
            ? payload.error
              ? payload.error
              : payload
            : null
          : null
    }
  };
}
