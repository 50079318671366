import { connect } from "react-redux";
import { searchStreams } from "../../../actions/stream";
import StreamCardsSearch from "../StreamCardsSearch";

function select({ streams: { searchTerm } }) {
  return {
    searchTerm
  };
}

const actions = {
  searchStreams
};

export default connect(select, actions)(StreamCardsSearch);
