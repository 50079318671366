import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import uuidv4 from "uuid/v4";
import { createObjectUrl, revokeObjectUrl } from "@wrstudios/utils";
import { CropperModal } from "@wrstudios/components";
import { ACCEPTED_FILE_TYPES } from "./utils";
import { Container, Avatar, Change, Input, Button } from "./styled/user-avatar";

class UserAvatar extends Component {
  fileRef = createRef();

  state = {
    showCropperModal: false,
    avatar: this.props.avatar || "",
    avatarPreview: "",
    key: uuidv4()
  };

  render() {
    return (
      <Container>
        <Avatar
          initials={this.props.initials}
          initialsColor={this.props.initialsColor}
          avatarUrl={this.state.avatar}
          size="xxl"
          loading={this.props.isFetching}
        />
        <Input
          ref={this.fileRef}
          key={this.state.key}
          type="file"
          tabIndex="-1"
          accept={ACCEPTED_FILE_TYPES}
          onChange={this.handleOnChange}
        />
        <Change variant="secondary" onClick={this.handleOnChangeClick}>
          Change Photo
        </Change>
        {this.state.showCropperModal && (
          <CropperModal
            circle
            aspectRatio={1}
            imageUrl={this.state.avatarPreview}
            onClose={this.handleOnImageCropClose}
            footer={({ toBlob }) => (
              <>
                <Button
                  variant="secondary"
                  onClick={this.handleOnImageCropClose}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={() => toBlob(this.handleOnImageCropSubmit)}>
                  Submit
                </Button>
              </>
            )}
          />
        )}
      </Container>
    );
  }

  componentDidUpdate(_, prevState) {
    if (prevState.avatar !== this.state.avatar) {
      this.props.onChange(this.state.avatar);
    }
  }

  handleOnChangeClick = () => {
    this.fileRef.current.click();
  };

  handleOnRemoveClick = () => {
    this.setState({ avatar: "" }, () => {
      this.props.onRemove();
    });
  };

  handleOnChange = (e) => {
    const { files } = e.target;

    this.setState(({ avatarPreview }) => {
      if (avatarPreview) revokeObjectUrl(avatarPreview);

      return {
        showCropperModal: true,
        avatarPreview: createObjectUrl(files[0])
      };
    });
  };

  handleOnImageCropSubmit = async (blob) => {
    const image = new File([blob], `avatar-${uuidv4()}.png`, {
      type: "image/png"
    });

    if (this.imageUrl) revokeObjectUrl(this.image);
    this.imageUrl = createObjectUrl(image);

    this.setState(
      { showCropperModal: false, key: uuidv4(), avatar: this.imageUrl },
      () => {
        this.props.uploadUserAvatar(image);
      }
    );
  };

  handleOnImageCropClose = () => {
    this.setState({ showCropperModal: false, key: uuidv4() });
  };
}

UserAvatar.propTypes = {
  initials: PropTypes.string,
  initialsColor: PropTypes.string,
  avatar: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onRemove: PropTypes.func
};

UserAvatar.defaultProps = {
  onChange: function () {},
  onRemove: function () {}
};

export default UserAvatar;
