import { connect } from "react-redux";
import { acceptInvitation, setAppScrollable } from "../../../actions/app";
import { updateUserPassword } from "../../../actions/user";
import { alertTemplates } from "../invite/utils";
import StreamSignupModal from "../StreamSignupModal";

function select({
  application: { railsApiToken },
  user: {
    phone,
    name,
    email,
    invitation,
    isAcceptingInvitation,
    acceptInvitationError
  },
  stream: { getStreamNetwork, primaryAlertType, currentStreamId }
}) {
  return {
    name,
    phone,
    email,
    invitation,
    railsApiToken,
    isAcceptingInvitation,
    acceptInvitationError,
    streamId: currentStreamId,
    streamNetwork: getStreamNetwork,
    alertsAreAutomatic: primaryAlertType === alertTemplates.AUTOMATIC
  };
}

const actions = { acceptInvitation, setAppScrollable, updateUserPassword };

export default connect(select, actions)(StreamSignupModal);
