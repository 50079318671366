import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -1.2rem;
`;

export const Column = styled.div`
  width: calc(100% / 3);
  padding: 1.2rem;

  @media (max-width: 768px) {
    width: 50%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    width: 100%;
  }
`;
