import React from "react";
import PropTypes from "prop-types";
import ActivityLink from "../activity-types/connected/ActivityLink";
import { ResourceLink } from "../styled/activities";
import { Quote } from "./styled/activity-comment";

function getPersonComponent({
  clientId,
  clientName,
  isAgent,
  isCommenter,
  isCurrentlyViewedClient
}) {
  if (isAgent && !isCurrentlyViewedClient) {
    return (
      <ResourceLink to={`/clients/${clientId}`}>{clientName}</ResourceLink>
    );
  } else if (isCommenter) {
    return <span>You</span>;
  } else {
    return <strong>{clientName}</strong>;
  }
}

function ActivityComment({
  activityId,
  clientId,
  clientName,
  streamItemId,
  address,
  comment,
  isAgent,
  isCommenter,
  isCurrentlyViewedClient
}) {
  return (
    <ActivityLink isAgent={isAgent} activityId={activityId}>
      {getPersonComponent({
        clientId,
        clientName,
        isAgent,
        isCommenter,
        isCurrentlyViewedClient
      })}{" "}
      commented on{" "}
      <ResourceLink to={`/stream_items/${streamItemId}`}>
        {address}:
      </ResourceLink>{" "}
      <Quote>{comment}</Quote>
    </ActivityLink>
  );
}

ActivityComment.propTypes = {
  activityId: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
  streamItemId: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  isAgent: PropTypes.bool.isRequired,
  isCommenter: PropTypes.bool.isRequired,
  isCurrentlyViewedClient: PropTypes.bool.isRequired
};

export default ActivityComment;
