import { connect } from "react-redux";
import StreamItemComments from "../StreamItemComments";

function select({ streamItems }, { streamItemId }) {
  const streamItem = streamItems.byId[streamItemId] || {};
  const streamItemComments = streamItem.comments || [];
  const { asComment: recommendationMsgAsComment } =
    streamItem.recommendation || {};
  const comments = [
    recommendationMsgAsComment,
    ...streamItemComments
  ].filter(({ content }) => Boolean(content));
  const hasComments = !!comments.length;

  return {
    comments,
    hasComments
  };
}

export default connect(select)(StreamItemComments);
