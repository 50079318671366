import { connect } from "react-redux";
import { createStreamItemComment } from "../../../actions/streamItem";
import StreamItemCommentForm from "../StreamItemCommentForm";

function select({ streamItems }, { streamItemId }) {
  // const streamItem = streamItems.byId[streamItemId] || {};
  const errors = streamItems.commentNetwork.errors;
  const hasErrors = !!errors.length;

  return {
    errors,
    hasErrors
  };
}

const actions = {
  createStreamItemComment
};

export default connect(select, actions, null, { forwardRef: true })(
  StreamItemCommentForm
);
