import { connect } from "react-redux";
import { login } from "../../../../actions/app";
import { emitAction } from "../../../../utils/redux";
import Login from "../LoginRoute";

function select({ application, login: { isLoggingIn, loginError } }) {
  return {
    isAuthed: !!application.railsApiToken,
    isLoggingIn,
    loginError
  };
}

const actions = {
  login,
  emitAction
};

export default connect(select, actions)(Login);
