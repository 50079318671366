import React, { Component } from "react";
import PropTypes from "prop-types";
import { formatNumber, pluralize } from "@wrstudios/utils";
import Label from "../../common/Label";
import Input from "../../common/Input";
import StreamFilter from "./StreamFilter";
import { buildMinMaxLabel } from "./utils";
import { Grid, Cell, Spacer } from "./styled/stream-filter-price";

class StreamFilterBaths extends Component {
  state = {
    min: null,
    max: null
  };

  static getDerivedStateFromProps(props, state) {
    return {
      min:
        state.min === null &&
        typeof props.min !== "undefined" &&
        props.min !== "0"
          ? props.min
          : state.min,
      max:
        state.max === null &&
        typeof props.max !== "undefined" &&
        props.max !== "0"
          ? props.max
          : state.max
    };
  }

  render() {
    const min = Number(formatNumber(this.props.min, "0[.]00"));
    const max = Number(formatNumber(this.props.max, "0[.]00"));
    const label = buildMinMaxLabel(
      Number(formatNumber(this.props.min, "0[.]00")).toString(),
      Number(formatNumber(this.props.max, "0[.]00")).toString(),
      max && !min ? pluralize("Baths", max) : "Baths"
    );

    return (
      <StreamFilter
        hasSelectedValues={!!label}
        label={label || "Baths"}
        onClear={this.handleOnClear}
        onClose={this.handleOnClose}>
        {({ apply }) => (
          <>
            <Label htmlFor="bath-min">Bath Range</Label>
            <Grid>
              <Cell>
                <Input
                  id="bath-min"
                  name="min"
                  value={this.state.min || ""}
                  placeholder="No min"
                  onChange={this.handleOnChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      apply();
                    }
                  }}
                />
              </Cell>
              <Cell>
                <Spacer>-</Spacer>
              </Cell>
              <Cell>
                <Input
                  name="max"
                  value={this.state.max || ""}
                  placeholder="No Max"
                  onChange={this.handleOnChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      apply();
                    }
                  }}
                />
              </Cell>
            </Grid>
          </>
        )}
      </StreamFilter>
    );
  }

  handleOnChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleOnClear = () => {
    this.setState({ min: "", max: "" });
  };

  handleOnClose = () => {
    const hasStateChanged = this.state.min !== null || this.state.max !== null;
    const stateEqualsProps =
      this.state.min === this.props.min && this.state.max === this.props.max;

    if (stateEqualsProps || !hasStateChanged) return;

    let baths = {};
    const min = Number(formatNumber(this.state.min, "0[.]00"));
    const max = Number(formatNumber(this.state.max, "0[.]00"));

    if (min) {
      baths = {
        ...baths,
        gte: (min < 0 ? min * -1 : min).toString(),
        field: "baths_search"
      };
    }
    if (max) {
      baths = {
        ...baths,
        lte: (max < 0 ? max * -1 : max).toString(),
        field: "baths_search"
      };
    }

    const newFilters = !!Object.values(baths).length
      ? { ...this.props.filters, baths_search: baths }
      : this.props.filters;

    this.props.replaceStreamFilters(newFilters);
  };
}

StreamFilterBaths.propTypes = {
  filters: PropTypes.object.isRequired,
  min: PropTypes.string,
  max: PropTypes.string,
  replaceStreamFilters: PropTypes.func.isRequired
};

export default StreamFilterBaths;
