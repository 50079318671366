import React from "react";

function IconMiniCMA(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="16"
      width="16"
      viewBox="0 0 16 16"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx="8" cy="8" fill="#20b575" r="8" />
        <path
          d="M7.403 12.869v-.926a3.771 3.771 0 0 1-1.146-.32 2.773 2.773 0 0 1-.805-.599 2.256 2.256 0 0 1-.524-.754 4.068 4.068 0 0 1-.228-.755l1.519-.377c.008.184.047.367.114.54.072.185.19.35.341.485.146.145.325.257.524.327.241.09.501.126.76.107.35.019.696-.077.979-.27a.769.769 0 0 0 .35-.648.69.69 0 0 0-.236-.541 1.834 1.834 0 0 0-.691-.32l-1.147-.221a2.844 2.844 0 0 1-1.571-.805 1.977 1.977 0 0 1-.57-1.46c-.006-.294.05-.587.167-.861.116-.271.295-.514.524-.712a3.73 3.73 0 0 1 .76-.541c.287-.139.598-.23.918-.271V3H8.68v1.004c.326.048.638.157.919.32.257.127.49.293.69.492.188.172.345.371.464.59.102.188.179.386.228.592l-1.519.434a.778.778 0 0 0-.114-.32 1.813 1.813 0 0 0-.288-.385 1.263 1.263 0 0 0-.464-.263 1.236 1.236 0 0 0-.63-.114 1.35 1.35 0 0 0-.926.27.76.76 0 0 0-.342.648.575.575 0 0 0 .228.491c.18.156.4.266.638.32l1.146.271a3.487 3.487 0 0 1 1.732.862c.357.4.558.902.569 1.424 0 .278-.057.554-.167.812a3.316 3.316 0 0 1-.463.712c-.226.21-.482.389-.76.534a2.842 2.842 0 0 1-1.032.327v.869z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default IconMiniCMA;
