import styled from "styled-components";

export const Container = styled.button`
  color: ${({ theme, active }) =>
    active ? theme.colors.streamsBlue : theme.colors.grey};
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.streamsBlue};
  }
`;
