import { camelCase, random } from "lodash";
import {
  formatCurrencyShort,
  formatNumber,
  getParsedQueryString,
  getTimeAgo,
  pluralize
} from "@wrstudios/utils";
import { transformStreamItems } from "./streamItem";
import { transformInvitations } from "./invitation";
import { transformClient } from "./client";

import bg1 from "../static/images/new-stream-route-backgrounds/1.jpg";
import bg2 from "../static/images/new-stream-route-backgrounds/2.jpg";
import bg3 from "../static/images/new-stream-route-backgrounds/3.jpg";
import bg4 from "../static/images/new-stream-route-backgrounds/4.jpg";
import bg5 from "../static/images/new-stream-route-backgrounds/5.jpg";
import bg6 from "../static/images/new-stream-route-backgrounds/6.jpg";
import bg7 from "../static/images/new-stream-route-backgrounds/7.jpg";
import bg8 from "../static/images/new-stream-route-backgrounds/8.jpg";
import bg9 from "../static/images/new-stream-route-backgrounds/9.jpg";
import bg10 from "../static/images/new-stream-route-backgrounds/10.jpg";

export function parseFiltersFromParams(params) {
  const parsed = getParsedQueryString(params);

  if (!parsed.criteria) {
    return {};
  }

  const res = parsed.criteria.reduce((state, filter) => {
    if (state[filter.field]) {
      return {
        ...state,
        [filter.field]: { ...state[filter.field], ...filter }
      };
    }
    return { ...state, [filter.field]: filter };
  }, {});
  return res;
}

export function getClients({ stream, user }) {
  const subscribers = stream.subscribers || [];
  const agent = user.isClient ? user.primaryAgent || {} : user;
  const agentSubscriber = subscribers.find(
    (subscriber) => subscriber.email === agent.email
  );
  const subscribersWithoutAgent = subscribers.filter(
    (subscriber) => subscriber.email !== agent.email
  );
  const subscribersWithAgentAtEndOfList = [
    ...subscribersWithoutAgent,
    agentSubscriber
  ].filter(Boolean);
  return subscribersWithAgentAtEndOfList;
}

export function transformStreams({ streams, mls }) {
  // streams.results comes from searching as it also includes a count.
  const transformedStreams = (streams.results || streams).reduce(
    (state, stream) => {
      const {
        stream: transformedStream,
        streamItems: { streamItems }
      } = transformStream({ stream, mls });

      return {
        subscriptions: {
          ...state.subscriptions,
          ...transformedStream.subscriptions
        },
        streams: {
          ...state.streams,
          [transformedStream.id]: transformedStream
        },
        streamItems: { ...state.streamItems, ...streamItems },
        streamIds: [...state.streamIds, transformedStream.id]
      };
    },
    { streams: {}, streamItems: {}, streamIds: [], subscriptions: {} }
  );

  return transformedStreams;
}

export function transformStream({ stream, mls }) {
  const { streamItemIds, ...streamItems } = transformStreamItems({
    streamItems: stream.filtered_stream_items || stream.stream_items || [],
    mls
  });
  const { invitations, invitationIds } = transformInvitations(
    stream.invitations || []
  );
  const { subscriptions, subscribers, subscriptionIds, subscriptionClientIds } =
    transformStreamSubscriptions(stream.stream_subscriptions || []);
  const filters = (JSON.parse(stream.filter_criteria || "{}").filter || []).map(
    (filter) => {
      if (filter.field === "polygons") filter.field = "location";
      return filter;
    }
  );
  const filtersHash = filters.reduce(
    (filters, filter) => ({
      ...filters,
      [filter.field]: { ...filters[filter.field], ...filter }
    }),
    {}
  );
  const criteria = transformFilterCriteria(stream.filter_criteria);

  return {
    stream: {
      ...criteria,
      id: stream.id || "",
      name: stream.name || "",
      streamItemCount: stream.stream_item_count || 0,
      likesCount: stream.likes_count || 0,
      likedCount: stream.liked_count || 0,
      recommendedCount: stream.recommendation_count || 0,
      commentsCount: stream.comments_count || 0,
      commentedCount: stream.commented_count || 0,
      hiddenCount: stream.hidden_count || 0,
      updatedAt: stream.updated_at || "",
      smsEnabled: stream.sms_enabled || false,
      status: stream.status || "No Status",
      updatedAtAgo: getTimeAgo(stream.updated_at),
      isSelected: stream.is_selected || false,
      firstThreeListingIds: stream.first_three_listing_ids || [],
      primaryAlertType: stream.primary_alert_type,
      mlsCode: stream.mls_code || "",
      streamItemIds,
      invitationIds,
      subscribers,
      subscriptions,
      subscriptionIds,
      clientIds: subscriptionClientIds,
      filters,
      filtersHash
    },
    streamItems,
    invitations,
    subscriptions
  };
}

export function transformStreamSubscriptions(subscriptions) {
  return subscriptions.reduce(
    (state, subscripton) => {
      const transformedStreamSubscription =
        transformStreamSubscription(subscripton);

      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [transformedStreamSubscription.id]: transformedStreamSubscription
        },
        subscribers: [
          ...state.subscribers,
          transformedStreamSubscription.subscriber
        ].filter(Boolean),
        subscriptionIds: [
          ...state.subscriptionIds,
          transformedStreamSubscription.id
        ],
        subscriptionClientIds: [
          ...state.subscriptionClientIds,
          transformedStreamSubscription.userId
        ]
      };
    },
    {
      subscriptions: {},
      subscribers: [],
      subscriptionIds: [],
      subscriptionClientIds: []
    }
  );
}

export function transformStreamSubscription(subscription) {
  return {
    id: subscription.id || "",
    userId: String(subscription.user_id || ""),
    streamId: String(subscription.stream_id || ""),
    emailFrequency: subscription.email_frequency || "",
    smsFrequency: subscription.sms_alert_type || "",
    ...(subscription.user && { subscriber: transformClient(subscription.user) })
  };
}

function transformFilterCriteria(filterCriteria) {
  const { filter = [] } = JSON.parse(filterCriteria || "{}");
  // Normalizing the json blob
  // * Note: Fields are dynamic from the server
  const criteria = filter.reduce((criteria, { field, ...rest }) => {
    const [value] = Object.values(rest);
    let camelCaseField = camelCase(transformFilterCriteriaField(field));

    // Remap polygons to location
    if (camelCaseField === "polygons") {
      camelCaseField = "location";
    }

    if (Array.isArray(value)) {
      criteria[camelCaseField] = value;
    } else {
      if (!Array.isArray(criteria[camelCaseField]))
        criteria[camelCaseField] = [];

      Object.keys(rest).forEach((key) => {
        if (key === "gte") criteria[camelCaseField][0] = rest[key];
        if (key === "lte") criteria[camelCaseField][1] = rest[key];
        if (key === "within") criteria[camelCaseField] = rest[key];
      });
    }

    return criteria;
  }, {});

  // * Note: We default to array if nothing was there so we don't throw errors when formatted with `.join()`
  let {
    city = [],
    state = [],
    zip = [],
    area = [],
    price = [],
    beds = [],
    baths = [],
    sqft = [],
    propType = [],
    location,
    polygons,
    ...rest
  } = criteria;

  city = city.filter(Boolean);
  state = state.filter(Boolean);
  zip = zip.filter(Boolean);
  area = area.filter(Boolean);
  beds = beds.filter(Boolean);
  baths = baths.filter(Boolean);
  sqft = sqft.filter(Boolean);
  propType = propType.filter(Boolean);

  const parsedLocations = JSON.parse(polygons || location || "[]");
  const customAreas = parsedLocations
    .filter(Boolean)
    .map((_, index) => `Custom Area ${index + 1}`);
  const hasCustomAreasLabels = !!customAreas.length;
  const locations = hasCustomAreasLabels
    ? customAreas
    : [...city, ...zip, ...area];
  const bedsFormatted = !!beds.length
    ? pluralize(
        `${beds.join("-")} Beds`,
        beds.reduce((beds, bed) => (beds += bed), 0)
      )
    : "";
  const bathsFormatted = !!baths.length
    ? pluralize(
        `${baths.join("-")} Baths`,
        baths.reduce((baths, bath) => (baths += bath), 0)
      )
    : "";
  const sqftFormatted = !!sqft.length
    ? `${sqft.map((sqft) => formatNumber(sqft, "0[,]0a")).join("-")} sqft`
    : "";

  const priceMinFormatted = !!price[0] ? formatCurrencyShort(price[0]) : "";
  const priceMaxFormatted = !!price[1] ? formatCurrencyShort(price[1]) : "";
  const priceMinMaxFormatted = [priceMinFormatted, priceMaxFormatted].filter(
    Boolean
  );
  let priceFormatted =
    priceMinMaxFormatted.length === 2 ? priceMinMaxFormatted.join("-") : "";

  if (priceMinFormatted && !priceMaxFormatted) {
    priceFormatted = `over ${priceMinFormatted}`;
  } else if (!priceMinFormatted && priceMaxFormatted) {
    priceFormatted = `under ${priceMaxFormatted}`;
  }

  const propTypeFormatted = !!propType.length ? propType.join(", ") : "";
  const combinedCriteria = [
    bedsFormatted,
    bathsFormatted,
    sqftFormatted,
    priceFormatted
  ].filter(Boolean);
  const combinedCriteriaFormatted = !!combinedCriteria.length
    ? combinedCriteria.join(", ")
    : propTypeFormatted;

  return {
    city,
    cityFormatted: city.join(", "),
    state,
    stateFormatted: state.join(", "),
    zip,
    zipFormatted: zip.join(", "),
    price,
    locations,
    locationsFormatted: locations.join(", "),
    beds,
    bedsFormatted,
    baths,
    bathsFormatted,
    sqft,
    sqftFormatted,
    propType,
    propTypeFormatted,
    criteria: combinedCriteria,
    criteriaFormatted: combinedCriteriaFormatted,
    ...rest
  };
}

function transformFilterCriteriaField(field) {
  switch (field) {
    case "baths_search":
      return "baths";
    case "price_list":
      return "price";
    default:
      return field;
  }
}

export function remapFiltersToIncludeField(filters, initialFilters = {}) {
  return Object.entries(filters).reduce((filters, [key, operations]) => {
    return { ...filters, [key]: { field: key, ...operations } };
  }, initialFilters);
}

export function getRandomStreamBgImg() {
  const BG_IMAGES = [bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8, bg9, bg10];
  const randomIndex = random(0, BG_IMAGES.length - 1);
  return BG_IMAGES[randomIndex];
}

export function countFilters(filters) {
  // Remove status from filters
  const { status, ...allFilters } = filters;

  return Object.values(allFilters).reduce((total, filter) => {
    if (filter.eq) return total + filter.eq.length;
    if (filter.should) return total + filter.should.length;

    if (filter.within || filter.polygons) {
      const items = JSON.parse(filter.within || filter.polygons || "[]");
      return total + items.length;
    }

    return total + 1;
  }, 0);
}
