import { STREAM_ITEM_FILTER_TYPES } from "../modules/streamItem";

export const initial = {
  // To be used for stream detail ui state
  currentStreamId: null,
  filters: { byId: {} },
  filteredStreamItemIds: [],
  filteredListingIds: [],
  itemsCount: 0,
  listingsCount: 0,
  newStreamId: null,
  isMapShowing: false,
  isShowingFilters: false,
  isShowingStreamModal: false,
  isShowingNestedModal: false,
  streamModalOpenedViaSave: false,
  recommendedStreamItemId: null,
  recommendationModalOpen: false,
  recommendationToastOpen: false,
  isCreating: false,
  isSaving: false,
  hasSaved: false,
  isEditingCriteria: false,
  listingUnderPointer: null, // id of listing
  clientSearchTerm: "",
  clientIdsToInvite: [],
  autoFocusNewClient: false,
  page: 1,
  limit: 18,
  filter: STREAM_ITEM_FILTER_TYPES.ALL,
  primaryAlertType: null,
  getStreamNetwork: { isFetching: false, hasFetched: false, errors: [] },
  updateNetwork: { isFetching: false, errors: [] },
  inviteClientNetwork: { isFetching: false, errors: [] },
  createSubscriptionNetwork: { isFetching: false, errors: [] }
};

export function stream(state = initial, { type, payload }) {
  switch (type) {
    case "GET_FORGE_LISTINGS_SUCCEEDED":
      return {
        ...state,
        filteredListingIds: payload.listingIds,
        filters: { byId: payload.filtersHash },
        listingsCount: payload.listingsCount,
        page: payload.page,
        limit: payload.limit
      };
    case "GET_STREAM_INITIATED":
      return {
        ...state,
        getStreamNetwork: { isFetching: true, hasFetched: false, errors: [] }
      };
    case "GET_STREAM_FAILED":
      return {
        ...state,
        getStreamNetwork: {
          isFetching: false,
          hasFetched: false,
          errors: payload.errorsArray
        }
      };
    case "GET_STREAM_SUCCEEDED":
      return {
        ...state,
        currentStreamId: payload.stream.id,
        filteredStreamItemIds: payload.stream.streamItemIds,
        filters: { byId: payload.stream.filtersHash },
        primaryAlertType: payload.stream.primaryAlertType,
        itemsCount: payload.stream.streamItemCount,
        getStreamNetwork: { isFetching: false, hasFetched: true, errors: [] }
      };
    case "GET_STREAM_ITEMS_SUCCEEDED":
      return {
        ...state,
        filteredStreamItemIds: payload.streamItems.streamItemIds,
        page: payload.page,
        limit: payload.limit,
        filter: payload.filter,
        itemsCount: payload.itemsCount,
        isEditingCriteria: false
      };
    case "CREATE_STREAM_INITIATED":
      return { ...state, isSaving: true, hasSaved: false };
    case "RENAME_STREAM_INITIATED":
      return { ...state, isSaving: true, hasSaved: false };
    case "CREATE_STREAM_SUCCEEDED":
      return {
        ...state,
        isShowingStreamModal: true,
        streamModalOpenedViaSave: true,
        currentStreamId: payload.stream.id,
        isSaving: false,
        hasSaved: true,
        newStreamId: payload.stream.id,
        isEditingCriteria: false,
        filteredStreamItemIds: payload.stream.streamItemIds,
        filters: { byId: payload.stream.filtersHash }
      };
    case "RENAME_STREAM_SUCCEEDED":
      return {
        ...state,
        currentStreamId: payload.stream.id,
        isSaving: false,
        hasSaved: true,
        newStreamId: payload.stream.id,
        isEditingCriteria: false,
        filteredStreamItemIds: payload.stream.streamItemIds,
        filters: { byId: payload.stream.filtersHash }
      };
    case "CREATE_STREAM_FAILED":
    case "RENAME_STREAM_FAILED":
      return { ...state, isSaving: false, hasSaved: false };
    case "SET_PRIMARY_ALERT_TYPE_RESOLVED":
      return {
        ...state,
        primaryAlertType: payload.stream.primaryAlertType,
        streamModalOpenedViaSave: false
      };
    case "UPDATE_STREAM_INITIATED":
      return { ...state, updateNetwork: { isFetching: true, errors: [] } };
    case "UPDATE_STREAM_SUCCEEDED":
      return {
        ...state,
        isEditingCriteria: false,
        updateNetwork: { isFetching: false, errors: [] },
        filteredStreamItemIds: payload.stream.streamItemIds,
        filters: { byId: payload.stream.filtersHash }
      };
    case "UPDATE_STREAM_FAILED":
      return {
        ...state,
        updateNetwork: { isFetching: false, errors: payload.errorsArray }
      };
    case "OPEN_STREAM_MODAL_VIA_ALERTS":
      return {
        ...state,
        isShowingStreamModal: true,
        streamModalOpenedViaSave: false
      };
    case "CLOSE_STREAM_MODAL":
      return {
        ...state,
        isShowingStreamModal: false,
        isShowingNestedModal: false,
        streamModalOpenedViaSave: false
      };
    case "OPEN_USER_VERIFY_MODAL":
      return {
        ...state,
        isShowingNestedModal: true
      };
    case "CLOSE_USER_VERIFY_MODAL":
      return {
        ...state,
        isShowingNestedModal: false
      };
    case "ALERTS_TEMPLATE_SELECTED":
      return { ...state, alertsTemplate: payload };
    case "ADD_CLIENT":
      return {
        ...state,
        autoFocusNewClient: true,
        clientIdsToInvite: [...state.clientIdsToInvite, payload.id]
      };
    case "UPDATE_CLIENT_NAME":
      return { ...state, autoFocusNewClient: false };
    case "SET_STREAM_MAP_SHOWING":
      return { ...state, isMapShowing: payload.isMapShowing };
    case "SET_STREAM_FILTERS":
      return {
        ...state,
        filters: { byId: payload },
        isEditingCriteria: true,
        page: 1
      };
    case "REMOVE_STREAM_FILTERS":
      return { ...state, filters: { byId: payload }, isEditingCriteria: true };
    case "SET_LISTING_UNDER_POINTER":
      return { ...state, listingUnderPointer: payload };
    case "SET_STREAM_IS_CREATING":
      return { ...state, newStreamId: null, isCreating: payload.isCreating };
    case "SET_IS_EDITING_CRITERIA":
      return { ...state, isEditingCriteria: payload.isEditingCriteria };
    case "SEND_STREAM_INVITE_INITIATED":
      return {
        ...state,
        inviteClientNetwork: { isFetching: true, errors: [] }
      };
    case "SEND_STREAM_INVITE_SUCCEEDED":
      return {
        ...state,
        clientSearchTerm: "",
        clientIdsToInvite: [],
        inviteClientNetwork: { isFetching: false, errors: [] }
      };
    case "SEND_STREAM_INVITE_FAILED":
      return {
        ...state,
        inviteClientNetwork: { isFetching: false, errors: payload.errorsArray }
      };
    case "SET_STREAM_CLIENT_SEARCH_TERM":
      return { ...state, clientSearchTerm: payload };
    case "TOGGLE_STREAM_CLIENT_INVITE":
      return { ...state, clientIdsToInvite: payload };
    case "RESET_STREAM_CLIENT_INVITE":
      return { ...state, clientSearchTerm: "", clientIdsToInvite: [] };
    case "RESET_STREAM":
      return {
        ...initial,
        isCreating: true,
        page: state.page,
        limit: state.limit,
        isShowingStreamModal: state.isShowingStreamModal,
        streamModalOpenedViaSave: state.isShowingStreamModal
      };
    case "INIT_STREAMS_INDEX":
    case "CLEAR_API_TOKEN":
      return initial;
    case "SET_STREAM_FILTERS_SHOWING":
      return { ...state, isShowingFilters: payload };
    case "UPDATE_USER_MLS_SUCCEEDED":
      return {
        ...initial,
        limit: state.limit,
        isMapShowing: state.isMapShowing
      };

    // Recommendations
    case "SET_RECOMMENDATION_MODAL_OPEN":
      return { ...state, ...payload };
    case "CLOSE_RECOMMENDATION_MODAL":
      return { ...state, recommendationModalOpen: false };
    case "RECOMMENDATION_SENT":
      return {
        ...state,
        recommendationModalOpen: false,
        recommendationToastOpen: true
      };

    default:
      return state;
  }
}
