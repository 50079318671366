import { connect } from "react-redux";
import ActivityModal from "../ActivityModal";

function select({ activities }, { activityId }) {
  const activity = activities.byId[activityId] || { stream: {} };
  const stream = activity.stream || {};
  const streamItemIds = stream.streamItemIds || [];
  const client = activity.client || {};
  const listingIds = (activity.stream || {}).listingIds || [];
  const hasTimestamp = !!activity.updatedAtDate && !!activity.updatedAtTime;

  return {
    type: activity.type || "",
    clientName: client.name || "",
    clientEmail: client.email || "",
    listingIds,
    streamItemIds,
    hasListingIds: !!listingIds.length,
    hasStreamItemIds: !!streamItemIds.length,
    timestamp: hasTimestamp
      ? `${activity.updatedAtDate} at ${activity.updatedAtTime}`
      : ""
  };
}

export default connect(select)(ActivityModal);
