import { connect } from "react-redux";
import { sortStreams } from "../../../actions/stream";
import StreamCardsSort from "../StreamCardsSort";

function select({ streams: { sortTerm } }) {
  return {
    sortTerm
  };
}

const actions = {
  sortStreams
};

export default connect(select, actions)(StreamCardsSort);
