import React from "react";
import PropTypes from "prop-types";
import { colors } from "@wrstudios/theme";
import { Container, Avatar } from "./styled/poker-chip-more";

function PokerChipMore({ isAgent, amount, size, onClick }) {
  const isButton = isAgent && !!onClick;

  return (
    <Container
      isButton={isButton}
      as={isButton ? "button" : "div"}
      onClick={isButton ? onClick : undefined}>
      <Avatar
        initials={`+${amount}`}
        initialsColor={colors.gray[400]}
        size={size}
      />
    </Container>
  );
}

PokerChipMore.propTypes = {
  isAgent: PropTypes.bool.isRequired,
  amount: PropTypes.number.isRequired,
  size: PropTypes.string,
  onClick: PropTypes.func
};

export default PokerChipMore;
