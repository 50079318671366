import { FORGE_CONFIGS_FOR_STREAMS } from "@wrstudios/utils";
import { GraphQLClient } from "graphql-request";

const forgeUrl = process.env.REACT_APP_FORGE_API_URL;

const initMLSQuery = /* GraphQL */ `
  query InitMLSQuery($mlsKey: String!) {
    mlses {
      key
      name
      state
    }
    mls(key: $mlsKey) {
      config
      status_mapping {
        active
        backup
        pending
        closed
      }
      fields {
        key
        title
        category_name
        display_name
        field_type
        input_type
        col_type
      }
      city: field(name: "city") {
        key
        title
        category_name
        display_name
        field_type
        input_type
        col_type
        search_values
      }
      area: field(name: "area") {
        key
        title
        category_name
        display_name
        field_type
        input_type
        col_type
        search_values
      }
      zip: field(name: "zip") {
        key
        title
        category_name
        display_name
        field_type
        input_type
        col_type
        search_values
      }
      prop_type: field(name: "prop_type") {
        key
        title
        category_name
        display_name
        field_type
        input_type
        col_type
        search_values
      }
      prop_sub_type: field(name: "prop_sub_type") {
        key
        title
        category_name
        display_name
        field_type
        input_type
        col_type
        search_values
      }
    }
  }
`;

export const listingFields = `
  id
  acres
  address
  area
  assoc_fee
  baths
  baths_full
  baths_half
  baths_quarter
  baths_three_quarter
  beds
  changes
  city
  county
  date_expired
  date_list
  date_offmarket
  date_pending
  date_sold
  directions
  dom
  garages
  lat
  lon
  lotdim
  lotsize
  mapped_status
  mlsnum
  photos
  photo_count
  photos_updated_at
  price
  prop_sub_type
  prop_type
  remarks
  remarks_private
  school_district
  school_elementary
  school_high
  school_middle
  showing_inst
  sqft
  sqft_source
  state
  status
  subdivision
  taxes
  updated_at
  year_built
  zipcode
  features
  agent_list {
    id
    name
    phone
    email
    agent_id
  }
  agent_sell {
    id
    name
    phone
    email
  }
  office_list {
    id
    name
    phone
  }
  office_sell {
    name
    phone
  }
`;

const listingsQuery = `query ListingsQuery($key: String!, $page: Int, $limit: Int, $filter: [FilterQueryType]) {
  mls(key: $key) {
    key
    listings(page: $page, limit: $limit, filter: $filter) {
      count
      explain
      results {
        ${listingFields}
      }
    }
  }
}`;

const loginMutation = `mutation LoginMutation($key: String!, $secret: String!) {
  login(key: $key, secret: $secret) {
    token
  }
}`;

const fieldQuery = `query FieldQuery($key: String!, $name: String!) {
  mls(key: $key) {
		field(name: $name) {
      key
      title
      category_name
      display_name
      field_type
      input_type
      col_type
      search_values
    }
  }
}`;

const pinsQuery = `query PinsQuery($key: String!, $limit: Int, $precision: Int, $filter: [FilterQueryType]) {
  mls(key: $key) {
    key
    listings(limit: $limit, filter: $filter) {
      count
      explain
      pins(precision: $precision) {
        geohash
        lat
        lon
        records {
          id
          status
        }
      }
    }
  }
}`;

function buildForgeClient(forgeToken = "") {
  return new GraphQLClient(forgeUrl, {
    headers: { Authorization: `Bearer ${forgeToken}` }
  });
}

export async function getForgeToken(env) {
  const forgeClient = buildForgeClient();
  const loginVariables = {
    key: FORGE_CONFIGS_FOR_STREAMS[env].key,
    secret: FORGE_CONFIGS_FOR_STREAMS[env].secret
  };

  const {
    login: { token }
  } = await forgeClient.request(loginMutation, loginVariables);
  return token;
}

export async function initMLS({ mlsKey, forgeToken }) {
  const forgeClient = buildForgeClient(forgeToken);
  const variables = { mlsKey };
  return await forgeClient.request(initMLSQuery, variables);
}

export async function getForgeListings({
  filter,
  page,
  limit,
  mlsCode,
  forgeToken
}) {
  const forgeClient = buildForgeClient(forgeToken);
  const listingVariables = { key: mlsCode, page, limit, filter };
  return await forgeClient.request(listingsQuery, listingVariables);
}

export async function getForgeListingsByIds({ ids, mlsCode, forgeToken }) {
  const forgeClient = buildForgeClient(forgeToken);
  const variables = {
    key: mlsCode,
    limit: ids.length,
    filter: [{ field: "id", eq: ids }]
  };
  return await forgeClient.request(listingsQuery, variables);
}

export async function getForgePinsByMapBounds({
  mapBounds,
  precision,
  mlsCode,
  forgeToken
}) {
  const forgeClient = buildForgeClient(forgeToken);
  const filter = [
    { field: "location", within: JSON.stringify([mapBounds]) },
    { field: "status", not: ["Canceled", "Withdrawn"] },
    { field: "date_offmarket", gte: "60 days ago" }
  ];

  return await forgeClient.request(pinsQuery, {
    key: mlsCode,
    precision,
    filter
  });
}

export async function getMLSField({ field, mlsCode, forgeToken }) {
  const forgeClient = buildForgeClient(forgeToken);
  const variables = { key: mlsCode, name: field };
  return await forgeClient.request(fieldQuery, variables);
}
