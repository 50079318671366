import { connect } from "react-redux";
import {
  getActivitiesForClient,
  resetActivitiesForClient
} from "../../../actions/client";
import ClientActivities from "../ClientActivities";

function select(
  {
    clients: { isLoadingClientActivities, byId: clientsById },
    activities: { byId: activitiesById, paginationByClientId },
    mls: { photoProxyStrategy }
  },
  { match }
) {
  const clientId = match.params.clientId;
  const activityIds = paginationByClientId[clientId]
    ? paginationByClientId[clientId].order
    : [];
  const isComplete = paginationByClientId[clientId]
    ? paginationByClientId[clientId].isComplete
    : false;
  const client = clientsById[clientId] || {};

  return {
    client,
    clientId,
    isLoading: isLoadingClientActivities,
    isComplete,
    activityIds,
    mlsConfigsLoaded: !!photoProxyStrategy
  };
}

const actions = {
  getActivitiesForClient,
  resetActivitiesForClient
};

export default connect(select, actions)(ClientActivities);
