export function transformPins({ pins, mls }) {
  return pins.reduce((state, pin, index) => {
    return {
      ...state,
      [pin.geohash]: {
        id: pin.geohash,
        lat: pin.lat,
        lon: pin.lon,
        records: pin.records.map(({ id, status }) => ({
          id,
          statusValue: getMappedStatus({ status, mls })
        }))
      }
    };
  }, {});
}

function getMappedStatus({ status, mls }) {
  if (mls.status.byId.active.includes(status)) {
    return "active";
  }
  if (mls.status.byId.backup.includes(status)) {
    return "backup";
  }
  if (mls.status.byId.pending.includes(status)) {
    return "pending";
  }
  if (mls.status.byId.closed.includes(status)) {
    return "closed";
  }
  return "";
}

export function filterPins({ mapPins, mapListings }) {
  const listingIds = mapListings.map(({ id }) => id);

  return mapPins.reduce((state, pin) => {
    const isSingle = pin.records.length === 1;
    const pinListingId = (pin.records[0] || {}).id;

    if (isSingle && listingIds.includes(pinListingId)) {
      return state;
    }

    return [...state, pin];
  }, []);
}

/**
 * Returns the areas contained within the filters.
 */
export function getAreasFromFilters(filters) {
  if (!filters.byId.location) {
    return [];
  }

  const coordinateSets = filters.byId.location.within || "[]";

  return JSON.parse(coordinateSets).map((coordinates) => {
    return { coordinates };
  });
}

/**
 * Returns the listings associated with listing ids.
 */
export function getListingsFromListingIds({ filteredListingIds, listings }) {
  return filteredListingIds
    .map((id) => {
      const listing = listings.byId[id];

      if (!listing) return null;

      return {
        ...listing,
        streamItem: {},
        hasStreamItemId: false
      };
    })
    .filter(Boolean);
}

/**
 * Returns the listings associated with stream item - item ids.
 */
export function getListingsFromStreamItemIds({
  filteredStreamItemIds,
  streamItems,
  listings
}) {
  return filteredStreamItemIds
    .map((id) => {
      const streamItem = streamItems.byId[id] || {};
      const listing = listings.byId[streamItem.itemId];

      if (!listing) return null;

      return {
        ...listing,
        streamItem,
        hasStreamItemId: !!streamItem.id
      };
    })
    .filter(Boolean);
}
