import React from "react";
import { ListingCardLoading } from "@wrstudios/components";
import FadeTransition from "../../common/FadeTransition";
import { CardLayout, LoadingContainer } from "./styled/liked-route";

function LikedRouteLoading({ on }) {
  return (
    <FadeTransition mountsOn={on}>
      <LoadingContainer>
        <CardLayout>
          {Array(18)
            .fill()
            .map((_, index) => (
              <ListingCardLoading key={index} showFooter />
            ))}
        </CardLayout>
      </LoadingContainer>
    </FadeTransition>
  );
}

export default LikedRouteLoading;
