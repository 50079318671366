import { connect } from "react-redux";
import { requestPasswordReset } from "../../../../actions/app";
import { emitAction } from "../../../../utils/redux";
import RequestPasswordResetRoute from "../RequestPasswordResetRoute";

function select({
  login: {
    isRequestingPasswordReset,
    requestPasswordResetSuccess,
    requestPasswordResetError
  }
}) {
  return {
    isRequestingPasswordReset,
    requestPasswordResetSuccess,
    requestPasswordResetError
  };
}

const actions = { requestPasswordReset, emitAction };

export default connect(select, actions)(RequestPasswordResetRoute);
