import React from "react";
import PropTypes from "prop-types";
import uuidv1 from "uuid/v1";
import FocusTrap from "focus-trap-react";
import { Portal } from "@wrstudios/components";
import { Container, Dialog, Title, Body } from "./styled/alert-dialog";

function AlertDialog({ title, onClose, children, ...rest }) {
  const id = uuidv1();

  return (
    <Portal>
      <Container>
        <FocusTrap
          focusTrapOptions={{
            onDeactivate: onClose,
            clickOutsideDeactivates: true,
            initialFocus: "#dialog"
          }}>
          <Dialog
            id="dialog"
            tabIndex="-1"
            role="alertdialog"
            aria-modal="true"
            aria-labelledby={`dialog-label-${id}`}
            aria-describedby={`dialog-description-${id}`}
            {...rest}>
            {title && <Title id={`dialog-label-${id}`}>{title}</Title>}
            <Body id={`dialog-description-${id}`}>{children}</Body>
          </Dialog>
        </FocusTrap>
      </Container>
    </Portal>
  );
}

AlertDialog.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func
};

AlertDialog.defaultProps = {
  onClose: function () {}
};

export default AlertDialog;
