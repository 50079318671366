import React from "react";
import PropTypes from "prop-types";
import ActivityModal from "../connected/ActivityModal";
import ActivityAvatar from "../connected/ActivityAvatar";
import ActivityContent from "../connected/ActivityContent";
import ActivityListing from "../connected/ActivityListing";
import {
  Container,
  Body,
  Image,
  Content,
  Action,
  Button
} from "./styled/activity";

function ActivityAlert({ activityId, hasListingIds, isAgent, children }) {
  return (
    <Container>
      <Image>
        <ActivityAvatar activityId={activityId} />
      </Image>
      <Body>
        <Content>
          <ActivityContent activityId={activityId}>{children}</ActivityContent>
        </Content>
        {isAgent && (
          <Action>
            <ActivityModal
              activityId={activityId}
              render={({ openModal }) => (
                <Button variant="tertiary" onClick={openModal}>
                  View Alert
                </Button>
              )}
            />
          </Action>
        )}
        {!isAgent && hasListingIds && (
          <Action>
            <ActivityListing activityId={activityId} />
          </Action>
        )}
      </Body>
    </Container>
  );
}

ActivityAlert.propTypes = {
  activityId: PropTypes.string.isRequired,
  hasListingIds: PropTypes.bool.isRequired,
  isAgent: PropTypes.bool.isRequired
};

export default ActivityAlert;
