import React from "react";
import PropTypes from "prop-types";
import { Transition } from "./styled/fade-transition";

function FadeTransition({ mountsOn, children }) {
  return (
    <Transition in={mountsOn} timeout={200} unmountOnExit>
      {children}
    </Transition>
  );
}

FadeTransition.propTypes = {
  mountsOn: PropTypes.bool.isRequired
};

export default FadeTransition;
