import React from "react";
import PropTypes from "prop-types";
import VisuallyHidden from "@reach/visually-hidden";
import IconClose from "../../icons/IconClose";
import {
  Container,
  TooltipArrow,
  IconCloseLayout,
  CalloutLabel,
  CalloutHeading,
  CalloutDescription
} from "./styled/stream-filter-onboarding";

function StreamFilterOnboarding({ completeCriteriaOnboarding }) {
  return (
    <Container>
      <TooltipArrow />
      <IconCloseLayout onClick={completeCriteriaOnboarding}>
        <IconClose />
        <VisuallyHidden>Close</VisuallyHidden>
      </IconCloseLayout>
      <CalloutLabel>New</CalloutLabel>
      <CalloutHeading>Preview your Stream as you build it.</CalloutHeading>
      <CalloutDescription>
        Refine your search with filters like price, beds, and baths. When it’s
        just right, <strong>Save your Stream</strong> to set up alerts.
      </CalloutDescription>
    </Container>
  );
}

StreamFilterOnboarding.propTypes = {
  completeCriteriaOnboarding: PropTypes.func.isRequired
};

export default StreamFilterOnboarding;
