import React, { useState } from "react";
import ClientAgentsModal from "../client/connected/ClientAgentsModal";
import { CardCreate } from "./styled/stream-card-create";

function StreamCardCreate() {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <CardCreate text="Create a Stream" onClick={() => setShowModal(true)} />
      {showModal && <ClientAgentsModal onClose={() => setShowModal(false)} />}
    </>
  );
}

export default StreamCardCreate;
