import styled from "styled-components";
import { Link } from "@wrstudios/components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  top: 0;
  min-width: 24rem;
  width: 24rem;
  margin-right: 2.4rem;

  @media (max-width: 767px) {
    margin: 0 auto;
    width: 100%;
    max-width: 36rem;
  }
`;

export const Card = styled.div`
  width: 100%;
  border-radius: 0.3rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.greyLighter};
  background-color: ${({ theme }) => theme.colors.white};
`;

export const AvatarLayout = styled.div`
  display: flex;
  justify-content: center;
  z-index: 1;
  margin: 0 auto 2.4rem;
`;

export const ContactLink = styled.a`
  display: block;
  text-align: center;
  ${({ theme }) => theme.noWrap}
  max-width: 21rem;
`;

export const CardSegment = styled.div`
  padding: 2rem 1.6rem;
  border-top: 0.1rem solid ${({ theme }) => theme.colors.greyLighter};
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.greyDark};
  letter-spacing: 0;
  line-height: 1.8rem;

  &:first-of-type {
    border-top: none;
  }

  a,
  a:hover {
    color: ${({ theme }) => theme.colors.greyDark};
  }
`;

export const ClientName = styled.h4`
  ${({ theme }) => theme.text.h4};
  text-align: center;
  margin-bottom: 0.6rem;
  word-break: break-word;
`;

export const SharesWithLabel = styled.h6`
  margin-bottom: 1.6rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: #233040;
  line-height: 1.8rem;
`;

export const SharedClientLayout = styled(Link)`
  display: flex;
  align-items: center;
  margin-bottom: 1.4rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const SharedClientName = styled.span`
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 2.1rem;
  padding-left: 1.5rem;
`;

export const ProfileLink = styled.a`
  display: flex;
  align-items: center;
  padding: 0.4rem;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
`;

export const LinkWithIconLayout = styled.div`
  display: flex;
  justify-content: center;
`;

export const SocialProfileError = styled.button`
  font-size: 1.3rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.colors.red};
  cursor: pointer;
`;

export const Underlined = styled.span`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.streamsBlue};
`;

export const SocialIcon = styled.img`
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1.1rem;
`;

export const Username = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin: 0;
`;

export const StatusLabel = styled.span`
  display: block;
  text-align: center;
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.greyMedium};
`;
