import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, LoadingSpinner } from "@wrstudios/components";
import AlertDialog from "../../../alert/AlertDialog";
import { Actions } from "./styled/settings-route-billing";
import { Group, SubTitle, Text, Button } from "../styled/settings-route";

class SettingsRouteBillingIndex extends Component {
  state = {
    showCancelAlert: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching) {
      this.props.logout();
    }
  }

  componentDidMount() {
    if (this.props.hasLwaId) {
      window.location = `${process.env.REACT_APP_LW_AGENT_URL}/billing?jwt=${this.props.lwaJwt}`;
    }
  }

  render() {
    const {
      hasLwaId,
      isCASUser,
      name,
      amountFormatted,
      nextRenewalFormatted,
      cardNumber,
      cardExpiration,
      billingId,
      state,
      isFetching
    } = this.props;
    const { showCancelAlert } = this.state;

    if (hasLwaId) {
      return (
        <div className="helix-d-flex helix-justify--center helix-align--middle">
          <LoadingSpinner />
        </div>
      );
    }

    return (
      <>
        {!isCASUser && (
          <Group>
            <SubTitle>Billing Details</SubTitle>
            <Text>
              Your Cloud Streams account is currently <strong>{state}</strong>{" "}
              and you are on the <strong>{name}</strong> plan.
            </Text>
            <Text>
              Your next renewal date is <strong>{nextRenewalFormatted}</strong>{" "}
              at which time you will be charged{" "}
              <strong>{amountFormatted}</strong> on the credit card below.
            </Text>
            <Text>
              <strong>Credit card number: {cardNumber}</strong>
            </Text>
            <Text>
              <strong>Expiration date: {cardExpiration}</strong>
            </Text>
            <Text>
              <strong>Billing ID: {billingId}</strong>
            </Text>
            <Text>
              Any charges on your credit card statement will be listed from{" "}
              <strong>"Cloud Streams MLS Access."</strong>
            </Text>
            <Link to="/settings/billing/edit">
              <Button>Change Plan or Edit Credit Card</Button>
            </Link>
          </Group>
        )}
        <Group>
          <SubTitle>Cancel Account</SubTitle>
          <Text>
            By cancelling your account you will immediately lose access to Cloud
            Streams and your data will be deleted. This action cannot be undone.
          </Text>
          <Button
            disabled={isFetching}
            variant="danger"
            onClick={this.handleOnCancelClick}>
            {!isFetching && "Cancel Account"}
            {isFetching && "Cancelling Account"}
          </Button>
        </Group>
        {showCancelAlert && (
          <AlertDialog title="Cancel Account" onClose={this.handleOnAlertClose}>
            <Text>
              Are you sure you want to cancel your account? Remember - this is
              irreversible! You will receive an e-mail confirming your account
              has been successfully cancelled.
            </Text>
            <Text>
              Please contact{" "}
              <a href="mailto:support@cloudstreams.net">
                support@cloudstreams.net
              </a>{" "}
              if you do not receive confirmation or if you have any questions.
            </Text>
            <Actions>
              <Button
                variant="tertiary"
                autoFocus
                onClick={this.handleOnAlertClose}>
                Cancel
              </Button>
              <Button variant="danger" onClick={this.handleOnAlertConfirm}>
                Delete Forever
              </Button>
            </Actions>
          </AlertDialog>
        )}
      </>
    );
  }

  handleOnCancelClick = () => {
    this.setState({ showCancelAlert: true });
  };

  handleOnAlertClose = () => {
    this.setState({ showCancelAlert: false });
  };

  handleOnAlertConfirm = () => {
    this.setState({ showCancelAlert: false }, this.props.cancelUserAccount);
  };
}

SettingsRouteBillingIndex.propTypes = {
  lwaJwt: PropTypes.string,
  hasLwaId: PropTypes.bool.isRequired,
  isCASUser: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  amountFormatted: PropTypes.string.isRequired,
  nextRenewalFormatted: PropTypes.string.isRequired,
  cardNumber: PropTypes.string.isRequired,
  cardExpiration: PropTypes.string.isRequired,
  billingId: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  cancelUserAccount: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
};

export default SettingsRouteBillingIndex;
