import React from "react";

function IconInfo() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="12" cy="12" rx="9.6" ry="9.6" fill="#2952A3" />
      <path
        d="M10.8119 10.7905C10.8132 10.1326 11.3468 9.6 12.0047 9.6C12.6635 9.6 13.1975 10.134 13.1975 10.7928L13.1976 16.8012C13.1976 17.4633 12.6609 18 11.9988 18C11.3358 18 10.7987 17.4618 10.8 16.7988L10.8119 10.7905Z"
        fill="white"
      />
      <path
        d="M13.2 7.2C13.2 7.86274 12.6627 8.4 12 8.4C11.3372 8.4 10.8 7.86274 10.8 7.2C10.8 6.53726 11.3372 6 12 6C12.6627 6 13.2 6.53726 13.2 7.2Z"
        fill="white"
      />
    </svg>
  );
}

export default IconInfo;
