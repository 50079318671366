import {
  getBillingInfo as getBillingInfoAPI,
  updateBillingInfo as updateBillingInfoAPI
} from "../modules/railsApi";
import { transformBillingInfo } from "../modules/billing";

export function getBillingInfo() {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken },
      user: { isAgent }
    } = getState();

    if (!isAgent) return;

    dispatch({ type: "GET_BILLING_INFO_INITIATED" });

    try {
      const { billing_info } = await getBillingInfoAPI({ railsApiToken });

      dispatch({
        type: "GET_BILLING_INFO_SUCCEEDED",
        payload: transformBillingInfo(billing_info)
      });
    } catch (error) {
      dispatch({ type: "GET_BILLING_INFO_FAILED", payload: { error } });
    }
  };
}

export function updateBillingInfo(newBillingInfo) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken },
      billing: { planId }
    } = getState();

    dispatch({ type: "UPDATE_BILLING_INFO_INITIATED" });

    try {
      const { updateBilling } = await updateBillingInfoAPI({
        planId,
        newBillingInfo,
        railsApiToken
      });

      dispatch({
        type: "UPDATE_BILLING_INFO_SUCCEEDED",
        payload: transformBillingInfo(updateBilling)
      });
    } catch (error) {
      dispatch({
        type: "UPDATE_BILLING_INFO_FAILED",
        payload: { error, errorsArray: (error.response || {}).errors || [] }
      });
    }
  };
}
