import React from "react";
import PropTypes from "prop-types";
import Select from "../select/Select";
import { findOptionByLabel, findOptionByValue } from "../select/utils";
import IconKeyboardArrowDown from "../icons/IconKeyboardArrowDown";
import IconKeyboardArrowUp from "../icons/IconKeyboardArrowUp";
import { Container } from "./styled/client-cards-sort";

const items = [
  { value: "updated_at", label: "Recent Activity", dir: "desc" },
  { value: "name", label: "Name", dir: "asc" }
];
const labels = items.map((item) => item.label);

function ClientCardsSort({ sortTerm, sortClients }) {
  const selectedItem = findOptionByValue(sortTerm, items);

  const handleChange = (sortValue) => {
    const option = findOptionByLabel(sortValue, items);
    sortClients({ sortTerm: option.value, sortDir: option.dir });
  };

  return (
    <Container>
      <Select
        value={selectedItem.label}
        items={labels}
        closeArrow={<IconKeyboardArrowDown />}
        openArrow={<IconKeyboardArrowUp />}
        onChange={handleChange}
      />
    </Container>
  );
}

ClientCardsSort.propTypes = {
  sortTerm: PropTypes.string,
  sortClients: PropTypes.func.isRequired
};

export default ClientCardsSort;
