import React, { useState, forwardRef } from "react";
import PropTypes from "prop-types";
import FormGroup from "../common/FormGroup";
import Flash from "../common/Flash";
import {
  Container,
  Inner,
  Input,
  Submit,
  Errors
} from "./styled/stream-item-comment-form";

const StreamItemCommentForm = forwardRef(
  ({ streamItemId, errors, hasErrors, createStreamItemComment }, ref) => {
    const [comment, setComment] = useState("");

    const handleSubmit = (e) => {
      e.preventDefault();
      setComment("");
      createStreamItemComment({ streamItemId, comment });
    };

    return (
      <Container onSubmit={handleSubmit}>
        <Inner>
          <Input
            ref={ref}
            placeholder="Type your comment..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          {!!comment && (
            <Submit type="submit" variant="tertiary">
              Post
            </Submit>
          )}
          {hasErrors && (
            <Errors>
              {errors.map(({ message }, index) => (
                <FormGroup key={index}>
                  <Flash>{message}</Flash>
                </FormGroup>
              ))}
            </Errors>
          )}
        </Inner>
      </Container>
    );
  }
);

StreamItemCommentForm.propTypes = {
  errors: PropTypes.array,
  hasErrors: PropTypes.bool.isRequired,
  createStreamItemComment: PropTypes.func.isRequired
};

export default StreamItemCommentForm;
