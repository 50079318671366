import { connect } from "react-redux";
import { getClientLikedListings } from "../../../actions/client";
import ClientLikedListings from "../ClientLikedListings";

function select(
  { clients: { byId: clientsById }, streamItems, listings },
  { match }
) {
  const clientId = match.params.clientId;
  const client = clientsById[clientId];
  const { likedItemIds } = client;
  const hasStreamItems =
    likedItemIds.length > 0 && likedItemIds.every((id) => streamItems.byId[id]);
  const isLoading = !hasStreamItems && listings.isLoading;

  return {
    client,
    clientId,
    isLoading,
    likedItemIds,
    hasStreamItems
  };
}

const actions = { getClientLikedListings };

export default connect(select, actions)(ClientLikedListings);
