import { buildRailsApiClient } from "./";

export const listingFields = `
  id
  acres
  address
  area
  assoc_fee
  baths
  baths_full
  baths_half
  baths_quarter
  baths_three_quarter
  beds
  changes
  city
  county
  date_expired
  date_list
  date_offmarket
  date_pending
  date_sold
  directions
  dom
  garages
  lat
  lon
  lotdim
  lotsize
  mapped_status
  mlsnum
  photos
  photo_count
  photos_updated_at
  price
  prop_sub_type
  prop_type
  remarks
  remarks_private
  school_district
  school_elementary
  school_high
  school_middle
  showing_inst
  sqft
  sqft_source
  state
  status
  subdivision
  taxes
  updated_at
  year_built
  zipcode
  features
  agent_list {
    id
    name
    phone
    email
    agent_id
  }
  agent_sell {
    id
    name
    phone
    email
  }
  office_list {
    id
    name
    phone
  }
  office_sell {
    name
    phone
  }
`;

export async function requestShowing({
  clientId,
  agentId,
  listingId,
  streamItemId,
  phone,
  message,
  railsApiToken
}) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const query = /* GraphQL */ `
    mutation RequestShowing(
      $clientId: ID!
      $agentId: ID!
      $listingId: ID!
      $streamItemId: ID!
      $phone: String!
      $message: String
    ) {
      requestShowing(
        client_id: $clientId
        agent_id: $agentId
        listing_id: $listingId
        stream_item_id: $streamItemId
        phone: $phone
        message: $message
      )
    }
  `;
  const variables = {
    clientId,
    agentId,
    listingId,
    streamItemId,
    phone,
    message
  };
  const { requestShowing } = await railsApiClient.request(query, variables);
  return requestShowing;
}
