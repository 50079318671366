import qs from "qs";
import { connect } from "react-redux";
import {
  getStreams,
  updateStreamSubscriptionFrequency
} from "../../../../actions/stream";
import { updateClientDigestSubscription } from "../../../../actions/user";
import ManageSubscriptionsRoute from "../ManageSubscriptionsRoute";

function select(
  {
    streams: { byId, filteredIds },
    user: { id, phone, isSubscribedToClientDigest }
  },
  { location: { search } }
) {
  const { client_digest } = qs.parse(search, { ignoreQueryPrefix: true });
  const shouldShowUnsubscribeFlash =
    client_digest === "unsubscribe" && !isSubscribedToClientDigest;
  const streams = filteredIds.map((streamId) => {
    const stream = byId[streamId] || {};
    const { name, locationsFormatted, criteria } = stream;
    const { id: subscriptionId, emailFrequency, smsFrequency } =
      Object.values(stream.subscriptions).find(
        (subscription) => subscription.userId === id
      ) || {};

    return {
      name,
      streamId,
      locationsFormatted,
      criteria: criteria.join(" • "),
      subscriptionId,
      emailFrequency,
      smsFrequency
    };
  });

  return {
    streams,
    isSubscribedToClientDigest,
    shouldShowUnsubscribeFlash,
    phone
  };
}

const actions = {
  getStreams,
  updateStreamSubscriptionFrequency,
  updateClientDigestSubscription
};

export default connect(select, actions)(ManageSubscriptionsRoute);
