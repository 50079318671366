import React from "react";
import PropTypes from "prop-types";
import { AnimatePresence, motion } from "framer-motion";
import StreamInviteSubscribedClient from "./connected/StreamInviteSubscribedClient";
import StreamInvitedClient from "./connected/StreamInvitedClient";
import { Container } from "./styled/stream-invite-clients";

function StreamInvitees({ streamId, subscriptionIds, invitationIds }) {
  return (
    <Container>
      <AnimatePresence>
        {subscriptionIds.map((subscriptionId) => (
          <motion.div
            {...transitionConfig}
            key={`stream-invite-subscribed-client-${subscriptionId}`}>
            <StreamInviteSubscribedClient
              subscriptionId={subscriptionId}
              streamId={streamId}
            />
          </motion.div>
        ))}
        {invitationIds.map((invitationId) => (
          <motion.div
            {...transitionConfig}
            key={`stream-invited-client-${invitationId}`}>
            <StreamInvitedClient invitationId={invitationId} />
          </motion.div>
        ))}
      </AnimatePresence>
    </Container>
  );
}

StreamInvitees.propTypes = {
  streamId: PropTypes.string.isRequired,
  subscriptionIds: PropTypes.array.isRequired,
  invitationIds: PropTypes.array.isRequired
};

export default StreamInvitees;

const transitionConfig = {
  initial: { opacity: 1 },
  animate: { opacity: 1 },
  exit: { opacity: 0, height: 0 },
  style: { overflow: "hidden" }
};
