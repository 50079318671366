import { connect } from "react-redux";
import ConnectorRoute from "../ConnectorRoute.js";
import { logout } from "../../../../actions/app";

function select({ user }) {
  const { name, email } = user;
  return {
    name,
    email
  };
}

const actions = {
  logout
};

export default connect(select, actions)(ConnectorRoute);
