import React from "react";
import PropTypes from "prop-types";
import PokerChip from "./connected/PokerChip";
import PokerChipMore from "./connected/PokerChipMore";
import { Container } from "./styled/poker-chips";

function PokerChips({ clients, limit, size, className, onClick, onMoreClick }) {
  const limitedClients =
    clients.length > limit ? clients.slice(0, limit) : clients;

  return (
    <Container className={className}>
      {limitedClients.map((client, index) => (
        <PokerChip
          key={client.id}
          client={client}
          size={size}
          onClick={onClick}
          style={{ zIndex: limitedClients.length - index }}
        />
      ))}
      {clients.length > limit && (
        <PokerChipMore
          amount={clients.length - limit}
          size={size}
          onClick={onMoreClick}
        />
      )}
    </Container>
  );
}

PokerChips.propTypes = {
  clients: PropTypes.array.isRequired,
  limit: PropTypes.number,
  size: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onMoreClick: PropTypes.func
};

PokerChips.defaultProps = {
  limit: 4,
  size: "xxs"
};

export default PokerChips;
