import { connect } from "react-redux";
import { ListingDetails } from "@wrstudios/components";

function select({ listings: { byId } }, { listingId }) {
  const { details } = byId[listingId] || {};

  return {
    details
  };
}

export default connect(select)(ListingDetails);
