import React from "react";

function IconComment(props) {
  return (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.915 13.745L17 17l-3.255-1.085a7 7 0 1 1 2.17-2.17z"
        stroke="currentColor"
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconComment;
