import { connect } from "react-redux";
import SettingsRouteLeadGeneration from "../SettingsRouteLeadGeneration";

function select({ user }) {
  return {
    url: `${process.env.REACT_APP_API_URL}/lg/${user.guid}`,
    apiKey: user.guid,
    avatarUrl: user.avatar
  };
}

export default connect(select)(SettingsRouteLeadGeneration);
