import { connect } from "react-redux";
import PokerChip from "../PokerChip";

function select({ user }) {
  return {
    isAgent: user.isAgent || false,
    isInvitee: user.isInvitee || false
  };
}

export default connect(select)(PokerChip);
