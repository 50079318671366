import React from "react";
import { Card } from "@wrstudios/components";
import {
  BodyLayout,
  DummyAvatar,
  InfoLayout,
  DummyName,
  DummyInfo,
  DummyIcon
} from "./styled/client-card-loading";

function ClientCardLoading() {
  return (
    <Card>
      <Card.Body>
        <BodyLayout>
          <DummyAvatar />
          <InfoLayout>
            <DummyName />
            <DummyInfo />
          </InfoLayout>
        </BodyLayout>
      </Card.Body>
      <Card.Footer>
        <DummyIcon />
      </Card.Footer>
    </Card>
  );
}

export default ClientCardLoading;
