import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import StreamFilters from "../../stream/filters/connected/StreamFilters";
import StreamItems from "../../stream/connected/StreamItems";
import StreamMap from "../../stream/connected/StreamMap";
import StreamFiltersOverlay from "../../stream/filters/connected/StreamFiltersOverlay";
import { Container, ContentContainer, Filters } from "./styled/stream-route";
import { resetStream } from "../../../actions/stream";

function StreamRouteNewEditStep() {
  const {
    hasOnboarded,
    isShowingFilters,
    isMapShowing,
    isEditingCriteria
  } = useSelector(({ stream, user }) => ({
    hasOnboarded: !user.criteriaOnboarding,
    isShowingFilters: stream.isShowingFilters || false,
    isMapShowing: stream.isMapShowing || false,
    isEditingCriteria: stream.isEditingCriteria || false
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(resetStream());
  }, [dispatch]);

  return (
    <>
      <Filters>
        <StreamFilters showOnboarding />
      </Filters>
      <Container
        hasOnboarded={hasOnboarded}
        isShowingFilters={isShowingFilters}>
        <ContentContainer
          isShowingFilters={isShowingFilters}
          isMapShowing={isMapShowing}
          isEditingCriteria={isEditingCriteria}>
          <StreamItems columns={isMapShowing ? 2 : 3} />
          {isShowingFilters && <StreamFiltersOverlay />}
        </ContentContainer>
        <StreamMap />
      </Container>
    </>
  );
}

export default StreamRouteNewEditStep;
