import { connect } from "react-redux";
import {
  getStreams,
  initStreamsIndex,
  deleteStream,
  resetStreams
} from "../../../actions/stream";
import { getListings } from "../../../actions/listing";
import StreamCards from "../StreamCards";

function select({ streams, user }) {
  const streamIds = streams.filteredIds;
  const streamsArray = Object.values(streams.byId).filter(({ id }) =>
    streams.filteredIds.includes(id)
  );
  const listingIdsByMls = Object.entries(
    streamsArray.reduce(
      (byMls, stream) => ({
        ...byMls,
        [stream.mlsCode]: [
          ...(byMls[stream.mlsCode] || []),
          ...stream.firstThreeListingIds
        ]
      }),
      {}
    )
  );

  return {
    user,
    streams,
    streamIds,
    page: streams.page,
    limit: streams.limit,
    totalCount: streams.count,
    hasLoaded: streams.hasFetched,
    isAgent: user.isAgent,
    listingIdsByMls
  };
}

const actions = {
  getStreams,
  getListings,
  initStreamsIndex,
  deleteStream,
  resetStreams
};

export default connect(select, actions)(StreamCards);
