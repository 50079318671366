import { connect } from "react-redux";
import { isShowingTimeAvailable } from "showing-time-client";
import {
  setStreamItemView,
  getStreamItem,
  getPublicStreamItem
} from "../../../../actions/streamItem";
import { getShowingTimeUrl } from "../../../../actions/listing";
import StreamItemRoute from "../StreamItemRoute";

function select({ application, user, mls, streamItems, listings }, { match }) {
  const streamItemId = match.params.streamItemId;
  const streamItem = streamItems.byId[streamItemId] || {};
  const hasStreamItem = !!Object.values(streamItem).length;
  const hasFetchedShowingTimeUrl = listings.getShowingTimeUrlNetwork.hasFetched;
  const isFetching = streamItems.getItemNetwork.isFetching && !hasStreamItem;
  const hasFetched = streamItems.getItemNetwork.hasFetched || hasStreamItem;
  const hasErrors = !!streamItems.getItemNetwork.errors.length;
  const notFound = hasFetched && !hasStreamItem;

  return {
    streamItemId,
    itemId: streamItem.itemId || "",
    type: streamItem.type || "",
    isShowingTimeAvailable: isShowingTimeAvailable(user.mlsCode),
    mlsConfigsLoaded: !!mls.photoProxyStrategy,
    isAuthed: !!application.railsApiToken,
    isAgent: user.isAgent || false,
    hasFetchedShowingTimeUrl,
    hasFetched,
    isFetching,
    notFound,
    hasErrors
  };
}

const actions = {
  getShowingTimeUrl,
  setStreamItemView,
  getStreamItem,
  getPublicStreamItem
};

export default connect(select, actions)(StreamItemRoute);
