export const initial = {
  isLoading: false,
  isAppScrollable: true,
  isAppHeaderSticky: true,
  railsApiToken: null,
  forgeToken: null,
  ssoParams: null,
  states: {
    byId: {}
  },
  timezones: {
    byId: {}
  }
};

export function application(state = initial, { type, payload }) {
  switch (type) {
    case "LOGIN_SUCCEEDED":
    case "UPDATE_USER_PASSWORD_SUCCEEDED":
    case "ACCEPT_INVITATION_SUCCEEDED":
      return { ...state, railsApiToken: payload.railsApiToken };
    case "GET_FORGE_TOKEN_SUCCEEDED":
      return { ...state, forgeToken: payload };
    case "GET_SSO_PARAMS_SUCCEEDED":
      return { ...state, ssoParams: payload };
    case "HANDLE_APP_MOUNT_INITIATED":
      return { ...state, isLoading: true };
    case "HANDLE_APP_MOUNT_SUCCEEDED":
    case "HANDLE_APP_MOUNT_FAILED":
      return { ...state, isLoading: false };
    case "GET_APP_HELPERS_SUCCEEDED":
      return {
        ...state,
        states: { ...state.states, byId: payload.states },
        timezones: { ...state.timezones, byId: payload.timezones }
      };
    case "SET_APP_SCROLLABLE":
      return { ...state, isAppScrollable: payload.isAppScrollable };
    case "SET_APP_HEADER_STICKY":
      return { ...state, isAppHeaderSticky: payload.isAppHeaderSticky };
    case "LOGIN_AFTER_PASSWORD_RESET":
      return { ...state, railsApiToken: payload.token };
    case "SET_STREAM_FILTERS_SHOWING":
      return { ...state, isAppScrollable: !state.isAppScrollable };
    case "CLEAR_API_TOKEN":
      return { ...state, railsApiToken: null };
    default:
      return state;
  }
}
