const newCmaSubjectUrl = `${process.env.REACT_APP_CLOUD_CMA_URL}/cmas/new`;
const newCmaPropertyUrl = `${process.env.REACT_APP_CLOUD_CMA_URL}/properties/new`;
const newCmaFlyerUrl = `${process.env.REACT_APP_CLOUD_CMA_URL}/flyers/new`;
const newMlxListingUrl = `${process.env.REACT_APP_CLOUD_MLX_URL}/listings`;

export function getNewCmaSubjectUrl({ listing, ssoParams }) {
  const { address, city, state, zip, beds, baths, sqft } = listing || {};
  const fullAddress = `${address}, ${city}, ${state} ${zip}, United States`;
  return `${newCmaSubjectUrl}?address=${fullAddress}&beds=${beds}&baths=${baths}&sqft=${(
    sqft || ""
  ).replace(",", "")}&${ssoParams}&mlsnums=`;
}

export function getNewCmaPropertyUrl({ mlsnums, ssoParams }) {
  return `${newCmaPropertyUrl}?mlsnums=${mlsnums}&${ssoParams}`;
}

export function getNewCmaFlyerUrl({ mlsnums, ssoParams }) {
  return `${newCmaFlyerUrl}?mlsnums=${mlsnums}&${ssoParams}`;
}

export function getNewMlxListingUrl({ listingId, jwt }) {
  return `${newMlxListingUrl}/${listingId}?jwt=${jwt}`;
}

export function getAttractConnectUrl({ redirect, continue_to, email, token }) {
  return `${redirect}?continue_to=${continue_to}&email=${email}&token=${token}`;
}
