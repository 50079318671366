import styled from "styled-components";
import { Button as BaseButton, Modal } from "@wrstudios/components";

export const Form = styled.form`
  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    width: 100%;
    height: 100%;
  }
`;

export const Text = styled.p`
  font-size: 1.4rem;
  line-height: 2.4rem;
  margin: 0;
`;

export const Button = styled(BaseButton)`
  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    width: 100%;
    justify-content: center;
  }
`;

export const Divider = styled.div`
  height: 0.1rem;
  margin: 2.5rem 0;
  background-color: ${({ theme }) => theme.colors.whiteDark};
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -1rem;
`;

export const Cell = styled.div`
  padding: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    width: 100%;
  }
`;

export const Error = styled.p`
  font-size: 1.4rem;
  line-height: 2.4rem;
  margin: 1rem 0 -1rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.red};
`;

export const ModalContent = styled(Modal.Content)`
  max-width: 47.1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    max-width: 100%;
  }
`;

export const ModalBody = styled(Modal.Body)`
  padding: 0 2rem 2rem;

  ${Cell} {
    width: 50%;

    @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
      width: 100%;
    }
  }
`;

export const ModalFooter = styled(Modal.Footer)`
  margin: 0 -2rem -2rem;
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.whiteDark};

  ${Cell} {
    &:first-child {
      flex-grow: 1;
    }

    &:last-child {
      flex-shrink: 0;
    }
  }
`;
