import { batch } from "react-redux";
import {
  updateUserProfile as updateUserProfileAPI,
  uploadUserAvatar as uploadUserAvatarAPI,
  updateUserPassword as updateUserPasswordAPI,
  updateUserMLSCredentials as updateUserMLSCredentialsAPI,
  cancelUserAccount as cancelUserAccountAPI,
  sendUserPhoneVerificationCode as sendUserPhoneVerificationCodeAPI,
  verifyUserPhoneVerificationCode as verifyUserPhoneVerificationCodeAPI,
  updateClientDigestSubscriptionAPI
} from "../modules/railsApi";
import { completeCriteriaOnboarding as completeCriteriaOnboardingAPI } from "../api/user";
import {
  transformUser,
  transformUserAvatar,
  transformUserMLS
} from "../modules/user";
import { initMLS } from "./mls";

export function uploadUserAvatar(file) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken }
    } = getState();

    dispatch({ type: "UPLOAD_USER_AVATAR_INITIATED" });

    try {
      const formData = new FormData();
      formData.append("user[avatar]", file);

      const avatarData = await uploadUserAvatarAPI({ formData, railsApiToken });
      const transformedAvatarData = transformUserAvatar(avatarData);

      dispatch({
        type: "UPLOAD_USER_AVATAR_SUCCEEDED",
        payload: transformedAvatarData
      });

      return transformedAvatarData;
    } catch (error) {
      dispatch({ type: "UPLOAD_USER_AVATAR_FAILED", payload: { error } });
    }
  };
}

export function updateUserProfile(user) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken },
      user: { id }
    } = getState();

    dispatch({ type: "UPDATE_USER_PROFILE_INITIATED" });

    try {
      const { updateUser } = await updateUserProfileAPI({
        id,
        ...user,
        railsApiToken
      });

      dispatch({
        type: "UPDATE_USER_PROFILE_SUCCEEDED",
        payload: { user: transformUser(updateUser) }
      });
    } catch (error) {
      dispatch({
        type: "UPDATE_USER_PROFILE_FAILED",
        payload: { error, errorsArray: (error.response || {}).errors || [] }
      });
    }
  };
}

export function updateClientDigestSubscription(bool) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken },
      user: { id }
    } = getState();

    dispatch({
      type: "UPDATE_CLIENT_DIGEST_SUBSCRIPTION_INITIATED",
      payload: bool
    });

    try {
      const isSubscribedToClientDigest =
        await updateClientDigestSubscriptionAPI({
          id,
          value: bool,
          railsApiToken
        });

      dispatch({
        type: "UPDATE_CLIENT_DIGEST_SUBSCRIPTION_SUCCEEDED",
        payload: isSubscribedToClientDigest
      });
      return {
        status: "success",
        isSubscribed: isSubscribedToClientDigest
      };
    } catch (error) {
      dispatch({
        type: "UPDATE_CLIENT_DIGEST_SUBSCRIPTION_FAILED",
        payload: { error, errorsArray: (error.response || {}).errors || [] }
      });

      return {
        status: "error"
      };
    }
  };
}

export function resetUserProfileNetwork() {
  return { type: "RESET_USER_PROFILE_NETWORK" };
}

export function updateUserPassword({ password, passwordConfirm }) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken },
      user: { id }
    } = getState();

    dispatch({ type: "UPDATE_USER_PASSWORD_INITIATED" });

    try {
      const {
        resetPassword: { token, user }
      } = await updateUserPasswordAPI({
        id,
        password,
        passwordConfirm,
        railsApiToken
      });

      dispatch({
        type: "UPDATE_USER_PASSWORD_SUCCEEDED",
        payload: { railsApiToken: token, user: transformUser(user) }
      });
    } catch (error) {
      dispatch({
        type: "UPDATE_USER_PASSWORD_FAILED",
        payload: { error, errorsArray: (error.response || {}).errors || [] }
      });
    }
  };
}

export function resetUserPasswordNetwork() {
  return { type: "RESET_USER_PASSWORD_NETWORK" };
}

export function updateUserMLSCredentials({
  mlsCode,
  mlsUserId,
  mlsUserPassword
}) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken, forgeToken },
      user: { mlsCredentialId }
    } = getState();

    batch(() => {
      dispatch({ type: "UPDATE_USER_MLS_INITIATED" });
      dispatch({ type: "INIT_MLS_REQUESTED" });
    });

    try {
      const {
        updateMlsCredential: {
          agent: { mls_credential }
        }
      } = await updateUserMLSCredentialsAPI({
        mlsCredentialId,
        mlsCode,
        mlsUserId,
        mlsUserPassword,
        railsApiToken
      });
      const transformedUserMLS = transformUserMLS(mls_credential);
      const { mlses, configs, locations, locationsLabels, fields, status } =
        await initMLS({ mlsKey: transformedUserMLS.code, forgeToken });

      batch(() => {
        dispatch({
          type: "UPDATE_USER_MLS_SUCCEEDED",
          payload: { mls: transformedUserMLS }
        });
        dispatch({ type: "GET_ALL_MLSES_SUCCEEDED", payload: mlses });
        dispatch({ type: "GET_MLS_CONFIGS_SUCCEEDED", payload: configs });
        dispatch({ type: "GET_MLS_FIELDS_SUCCEEDED", payload: fields });
        dispatch({
          type: "GET_MLS_LOCATIONS_SUCCEEDED",
          payload: { locations: locations, locationsLabels }
        });
        dispatch({ type: "GET_MLS_STATUS_SUCCEEDED", payload: status });
        dispatch({ type: "INIT_MLS_SUCCEEDED" });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: "UPDATE_USER_MLS_FAILED",
          payload: { error, errorsArray: (error.response || {}).errors || [] }
        });
        dispatch({ type: "INIT_MLS_FAILED", payload: { error } });
      });
    }
  };
}

export function resetUserMLSNetwork() {
  return { type: "RESET_USER_MLS_NETWORK" };
}

export function cancelUserAccount() {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken }
    } = getState();

    dispatch({ type: "CANCEL_USER_INITIATED" });

    try {
      await cancelUserAccountAPI({ railsApiToken });

      dispatch({ type: "CANCEL_USER_SUCCEEDED" });
    } catch (error) {
      dispatch({ type: "CANCEL_USER_FAILED", payload: { error } });
    }
  };
}

export function setInvitationAcceptanceCredentials({
  clientId,
  agentId,
  emailFrequency,
  invitationGuid,
  streamSubscriptionId
}) {
  return {
    type: "SET_INVITATION_ACCEPTANCE_CREDENTIALS",
    payload: {
      clientId,
      agentId,
      emailFrequency,
      invitationGuid,
      streamSubscriptionId
    }
  };
}

export function sendUserPhoneVerificationCode({ phoneNumber }) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken }
    } = getState();

    dispatch({ type: "SEND_USER_PHONE_VERIFICATION_CODE_INITIATED" });

    try {
      const code = await sendUserPhoneVerificationCodeAPI({
        phoneNumber,
        railsApiToken
      });

      dispatch({
        type: "SEND_USER_PHONE_VERIFICATION_CODE_SUCCEEDED",
        payload: code
      });
    } catch (error) {
      dispatch({
        type: "SEND_USER_PHONE_VERIFICATION_CODE_FAILED",
        payload: error
      });
    }
  };
}

export function verifyUserPhoneVerificationCode({ phoneNumber, code, secret }) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken }
    } = getState();

    dispatch({ type: "VERIFY_USER_PHONE_VERIFICATION_CODE_INITIATED" });

    try {
      await verifyUserPhoneVerificationCodeAPI({
        phoneNumber,
        code,
        secret,
        railsApiToken
      });

      dispatch({
        type: "VERIFY_USER_PHONE_VERIFICATION_CODE_SUCCEEDED",
        payload: phoneNumber
      });
    } catch (error) {
      dispatch({
        type: "VERIFY_USER_PHONE_VERIFICATION_CODE_FAILED",
        payload: error
      });
    }
  };
}

export function resetUserPhoneVerification() {
  return { type: "RESET_USER_PHONE_VERIFICATION" };
}

export function completeCriteriaOnboarding() {
  return async (dispatch, getState) => {
    const {
      user: { id: userId },
      application: { railsApiToken }
    } = getState();

    dispatch({ type: "COMPLETE_CRITERIA_ONBOARDING_REQUEST" }); // optimistic update

    try {
      await completeCriteriaOnboardingAPI({ railsApiToken, userId });
      dispatch({ type: "COMPLETE_CRITERIA_ONBOARDING_RESOLVED" });
    } catch (error) {
      dispatch({ type: "COMPLETE_CRITERIA_ONBOARDING_REJECTED" });
    }
  };
}

export function updateActiveAgent({ agentId, mlsCode }) {
  return { type: "UPDATE_ACTIVE_AGENT", payload: { agentId, mlsCode } };
}

export function updateMlsCode(mlsCode) {
  return { type: "UPDATE_MLS_CODE", payload: mlsCode };
}
