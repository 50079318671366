import React from "react";

function IconArrowLeft() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 -4 25 30">
      <g filter="url(#icon-arrow-left)">
        <path
          fill="none"
          stroke="#4D5A6A"
          strokeLinecap="round"
          strokeWidth="1.5"
          d="M16 5l-6 6 6 6"
        />
      </g>
      <defs>
        <filter
          id="icon-arrow-left"
          width="23.81"
          height="29.5"
          x=".94"
          y=".25"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default IconArrowLeft;
