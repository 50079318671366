import styled from "styled-components";
import { RouteContainer } from "@wrstudios/components";

export const Container = styled(RouteContainer)`
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }
`;

export const Grid = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 -1.15rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    flex-direction: column;
    margin: 0 -1.6rem;
  }
`;

export const Content = styled.div`
  flex-shrink: 0;
  width: calc(100% / 3 * 2);
  margin-top: 3rem;
  padding: 0 1.15rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.twoColumn}px) {
    width: 50%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    width: 100%;
    padding: 0 1.6rem;
  }
`;

export const Sidebar = styled.aside`
  flex-grow: 1;
  padding: 0 1.15rem;
  position: sticky;
  top: 8.4rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.twoColumn}px) {
    width: 50%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    width: 100%;
    order: -1;
    margin-top: 0;
    padding: 0 1.6rem;
    position: static;
  }
`;

export const Group = styled.div`
  margin-bottom: 3.2rem;

  &:last-child {
    margin-bottom: 0.8rem;
  }
`;

export const Disclaimer = styled.div`
  margin-top: 1.6rem;
  padding: 0 1.6rem;
`;
