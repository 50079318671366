import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CopyToClipboard from "react-copy-to-clipboard";
import { Modal } from "@wrstudios/components";
import leadGenerationPreviewImage from "../../../static/images/lead-generation-preview.png";
import InputCopy from "../../common/InputCopy";
import Label from "../../common/Label";
import Input from "../../common/Input";
import IconEmbed from "../../icons/IconEmbed";
import IconFacebook from "../../icons/IconFacebook";
import IconTwitter from "../../icons/IconTwitter";
import IconPinterest from "../../icons/IconPinterest";
import { RouteContainer, Header, Title, Body } from "./styled/settings-route";
import { SubTitle, Text } from "./styled/settings-route";
import {
  Copy,
  Preview,
  Image,
  Link,
  ButtonLink,
  Section,
  Grid,
  Cell,
  ShareButton,
  ShareButtonIconEmbed,
  ShareButtonIcon,
  ShareButtonText,
  ModalDescription,
  ModalContent,
  ModalFooter
} from "./styled/settings-route-lead-generation";

function useCopy() {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const handler = setTimeout(() => {
      setCopied(false);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [copied]);

  return [copied, setCopied];
}

// Note: This is custom from the settings route, that's why we are reusing code from settings route
function SettingsRouteLeadGeneration({ url, apiKey, avatarUrl }) {
  return (
    <RouteContainer small={true}>
      <Header>
        <Title>Lead Generation</Title>
      </Header>
      <Body>
        <SubTitle>Landing Page</SubTitle>
        <Text>
          When prospective clients visit your Cloud Streams lead generation
          landing page they can setup their own streams. Just share your
          personalized URL below or link to it from your website.
        </Text>
        <Text>
          Once your client submits their email address, they will automatically
          be sent an email invitation to setup their stream.
        </Text>
        <Preview>
          <Link href={url} target="_blank">
            <Image
              src={leadGenerationPreviewImage}
              alt="Lead Generation Preview"
            />
          </Link>
          <ButtonLink href={url} target="_blank">
            View Landing Page
          </ButtonLink>
        </Preview>
        <Copy>
          <Label htmlFor="landing-page-url">Copy Page Link</Label>
          <InputCopy id="landing-page-url" value={url} />
        </Copy>
        <Copy>
          <Label htmlFor="api-key">API Key</Label>
          <InputCopy id="api-key" value={apiKey} />
        </Copy>
        <SettingsLeadGenerationShare url={url} avatarUrl={avatarUrl} />
      </Body>
    </RouteContainer>
  );
}

SettingsRouteLeadGeneration.propTypes = {
  url: PropTypes.string.isRequired,
  apiKey: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string
};

function SettingsLeadGenerationShare({ url, avatarUrl }) {
  const [copied, setCopied] = useCopy();
  const [showEmbedCode, setShowEmbedCode] = useState(false);
  const socialTitle = encodeURIComponent("Looking for the Perfect Home?");
  const socialMessage = encodeURIComponent(
    "With faster listing alerts Zillow, be the first to know when your dream home hits the market."
  );
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    url
  )}&t=${socialTitle}`;
  const twitterUrl = `https://twitter.com/intent/tweet?text=${socialMessage}&url=${encodeURIComponent(
    url
  )}&via=cloud_streams`;
  const pinterestUrl = `http://pinterest.com/pin/create/button/?url=${encodeURIComponent(
    url
  )}&media=${encodeURIComponent(avatarUrl)}&media=${encodeURIComponent(
    window.location.origin + leadGenerationPreviewImage
  )}&description=${socialTitle} ${socialMessage}`;
  const embedCode = `<script id="cloud_streams_api_widget_script" src="${url}?format=js"></script>`;

  return (
    <Section>
      <Header>
        <SubTitle noMargin>Share</SubTitle>
      </Header>
      <Grid>
        <Cell>
          <ShareButton as="button" onClick={() => setShowEmbedCode(true)}>
            <span>
              <ShareButtonIconEmbed>
                <IconEmbed />
              </ShareButtonIconEmbed>
              <ShareButtonText>Embed</ShareButtonText>
            </span>
          </ShareButton>
          {showEmbedCode && (
            <Modal onClose={() => setShowEmbedCode(false)}>
              <ModalContent>
                <Modal.Header>
                  Embed
                  <Modal.Close />
                </Modal.Header>
                <Modal.Body>
                  <ModalDescription>
                    Copy and paste this code into your web page HTML to embed a
                    button that links to your landing page:
                  </ModalDescription>
                  <Input as="textarea" rows="5" defaultValue={embedCode} />
                </Modal.Body>
                <ModalFooter>
                  <CopyToClipboard
                    text={embedCode}
                    onCopy={() => setCopied(true)}>
                    <ButtonLink>
                      {copied ? "Code Copied!" : "Copy Code"}
                    </ButtonLink>
                  </CopyToClipboard>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}
        </Cell>
        <Cell>
          <ShareButton
            href={facebookUrl}
            target="_blank"
            rel="noopener noreferrer">
            <span>
              <ShareButtonIcon>
                <IconFacebook />
              </ShareButtonIcon>
              <ShareButtonText>Facebook</ShareButtonText>
            </span>
          </ShareButton>
        </Cell>
        <Cell>
          <ShareButton
            href={twitterUrl}
            target="_blank"
            rel="noopener noreferrer">
            <span>
              <ShareButtonIcon>
                <IconTwitter />
              </ShareButtonIcon>
              <ShareButtonText>Twitter</ShareButtonText>
            </span>
          </ShareButton>
        </Cell>
        <Cell>
          <ShareButton
            href={pinterestUrl}
            target="_blank"
            rel="noopener noreferrer">
            <span>
              <ShareButtonIcon>
                <IconPinterest />
              </ShareButtonIcon>
              <ShareButtonText>Pinterest</ShareButtonText>
            </span>
          </ShareButton>
        </Cell>
      </Grid>
    </Section>
  );
}

export default SettingsRouteLeadGeneration;
