import React from "react";
import PropTypes from "prop-types";
import ActivityAlert from "../activity-types/connected/ActivityAlert";
import { ResourceLink } from "../styled/activities";

function getPersonComponent({
  clientId,
  clientName,
  isAgent,
  isCommenter,
  isCurrentlyViewedClient
}) {
  if (isAgent && !isCurrentlyViewedClient) {
    return (
      <ResourceLink to={`/clients/${clientId}`}>{clientName}</ResourceLink>
    );
  } else if (isCommenter) {
    return <span>You</span>;
  } else {
    return <strong>{clientName}</strong>;
  }
}

function ActivitySMS({
  activityId,
  listingId,
  listingAddress,
  streamItemId,
  hasStreamItemId,
  clientId,
  clientName,
  isAgent,
  isCommenter,
  isCurrentlyViewedClient
}) {
  const user = getPersonComponent({
    clientId,
    clientName,
    isAgent,
    isCommenter,
    isCurrentlyViewedClient
  });

  return (
    <ActivityAlert activityId={activityId}>
      {user} received a Text alert about{" "}
      <ResourceLink
        to={
          hasStreamItemId
            ? `/stream_items/${streamItemId}`
            : `/listings/${listingId}`
        }>
        {listingAddress}
      </ResourceLink>
    </ActivityAlert>
  );
}

ActivitySMS.propTypes = {
  activityId: PropTypes.string.isRequired,
  listingId: PropTypes.string.isRequired,
  listingAddress: PropTypes.string.isRequired,
  streamItemId: PropTypes.string.isRequired,
  hasStreamItemId: PropTypes.bool.isRequired,
  clientId: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
  isAgent: PropTypes.bool.isRequired,
  isCommenter: PropTypes.bool.isRequired,
  isCurrentlyViewedClient: PropTypes.bool.isRequired
};

export default ActivitySMS;
