import React from "react";

function IconAvatarLike(props) {
  return (
    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle fill="#EC5252" cx="8" cy="8" r="8" />
        <circle fillOpacity=".1" fill="#000" cx="8" cy="8" r="8" />
        <path
          d="M8 13l-.725-.645C4.7 10.062 3 8.549 3 6.698 3 5.184 4.207 4 5.75 4c.87 0 1.705.397 2.25 1.023A3.025 3.025 0 0 1 10.25 4C11.793 4 13 5.184 13 6.698c0 1.851-1.7 3.364-4.275 5.657L8 13z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
}

export default IconAvatarLike;
