import { connect } from "react-redux";
import { getStreamSubscription } from "../../../../actions/subscription";
import SubscriptionFrequencyChangeRoute from "../SubscriptionFrequencyChangeRoute";

function select(
  {
    streams,
    subscriptions: {
      byId,
      getStreamSubscriptionNetwork: { hasFetched }
    }
  },
  { match }
) {
  const subscriptionId = match.params.subscriptionId;
  const subscription = byId[subscriptionId] || {};
  const stream = streams.byId[subscription.streamId] || {};

  return {
    hasFetched,
    subscriptionId,
    frequency: subscription.emailFrequency,
    streamName: stream.name,
    streamId: stream.id
  };
}

const actions = { getStreamSubscription };

export default connect(select, actions)(SubscriptionFrequencyChangeRoute);
