import { connect } from "react-redux";
import SettingsRouteBillingEdit from "../SettingsRouteBillingEdit";
import { updateBillingInfo } from "../../../../../actions/billing";

function select({ billing: { billingNetwork } }) {
  return {
    isFetching: billingNetwork.isFetching,
    hasFetched: billingNetwork.hasFetched,
    errors: billingNetwork.errors
  };
}

const actions = {
  updateBillingInfo
};

export default connect(select, actions)(SettingsRouteBillingEdit);
