import styled from "styled-components";
import { Button as BaseButton } from "@wrstudios/components";

export const Container = styled.div`
  position: relative;
`;

export const Button = styled(BaseButton)`
  font-weight: ${({ hasSelectedValues }) =>
    hasSelectedValues ? "600" : "400"};
  color: ${({ theme, hasSelectedValues }) =>
    hasSelectedValues ? theme.colors.streamsBlue : theme.colors.grey};
  border-width: 0.1rem;
  border-color: ${({ theme }) => theme.colors.greyLight};
  background-color: ${({ theme }) => theme.colors.white};
  white-space: nowrap;
`;
