export const initial = {
  byId: {}
};

export function invitations(state = initial, { type, payload }) {
  switch (type) {
    case "GET_STREAM_SUCCEEDED":
    case "INVITE_CLIENTS_RESOLVED":
    case "SEND_STREAM_INVITE_SUCCEEDED":
      return { ...state, byId: { ...state.byId, ...payload.invitations } };
    case "RESEND_INVITATION_INITIATED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.invitationId]: {
            ...state.byId[payload.invitationId],
            isResending: true,
            hasResent: false
          }
        }
      };
    case "RESEND_INVITATION_SUCCEEDED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.invitationId]: {
            ...state.byId[payload.invitationId],
            isResending: false,
            hasResent: true
          }
        }
      };
    case "RESEND_INVITATION_FAILED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.invitationId]: {
            ...state.byId[payload.invitationId],
            isResending: false,
            hasResent: false
          }
        }
      };
    case "DELETE_INVITATION_INITIATED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.invitationId]: {
            ...state.byId[payload.invitationId],
            isDeleting: true
          }
        }
      };
    case "DELETE_INVITATION_SUCCEEDED":
      return { ...state, byId: payload.invitations };
    case "DELETE_INVITATION_FAILED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.invitationId]: {
            ...state.byId[payload.invitationId],
            isDeleting: false
          }
        }
      };
    case "CLEAR_API_TOKEN":
      return initial;
    default:
      return state;
  }
}
