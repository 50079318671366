import React, { Component, Children, cloneElement } from "react";
import PropTypes from "prop-types";
import uuidv4 from "uuid/v4";
import TabList from "./TabList";
import TabPanel from "./TabPanel";
import { Container } from "./styled/tabs";

class Tabs extends Component {
  state = {
    activePanelIndex: 0
  };

  render() {
    return <Container>{this.renderChildren()}</Container>;
  }

  renderChildren = () => {
    const { activePanelIndex } = this.state;
    let index = 0;

    const tabPanels = Children.map(this.props.children, (child) => {
      const isPanelActive =
        index === activePanelIndex && child.type === TabPanel;

      if (child.type === TabPanel) {
        index += 1;
      }

      const randomId = uuidv4();
      return cloneElement(child, {
        tabId: `tab-${randomId}`,
        tabPanelId: `tab-panel-${randomId}`,
        isPanelActive,
        activePanelIndex,
        updateActivePanelIndex: this.handleUpdateActivePanelIndex
      });
    });

    return tabPanels;
  };

  handleUpdateActivePanelIndex = (activePanelIndex) => {
    this.setState({ activePanelIndex });
  };
}

// prettier-ignore
Tabs.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOfType([
          PropTypes.oneOf([TabList]), 
          PropTypes.shape({ target: PropTypes.oneOf([TabList]) }),
          PropTypes.oneOf([TabPanel]),
          PropTypes.shape({ target: PropTypes.oneOf([TabPanel])})
        ])
      })
    ),
    PropTypes.oneOf([TabList]),
    PropTypes.shape({ target: PropTypes.oneOf([TabList]) }),
    PropTypes.oneOf([TabPanel]),
    PropTypes.shape({ target: PropTypes.oneOf([TabPanel]) })
  ])
};

export default Tabs;
