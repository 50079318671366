import React, { Component } from "react";
import PropTypes from "prop-types";
import Media from "../../../utils/media";
import Rect from "@reach/rect";
import { Container, Button } from "./styled/stream-filter";
import StreamFilterModal from "./StreamFilterModal";

class StreamFilter extends Component {
  state = {
    isModalOpen: false
  };

  componentDidUpdate(_, prevState) {
    if (
      prevState.isModalOpen !== this.state.isModalOpen &&
      !this.state.isModalOpen
    ) {
      this.props.onClose();
    }
  }

  render() {
    const {
      label,
      hasSelectedValues,
      disableClear,
      disableApply,
      children
    } = this.props;

    return (
      <Rect>
        {({ ref, rect }) => {
          return (
            <Container ref={ref}>
              <Button
                hasSelectedValues={hasSelectedValues}
                variant={hasSelectedValues ? "primary" : "secondary"}
                onClick={this.handleOnOpen}>
                {label}
              </Button>
              {this.state.isModalOpen && (
                <StreamFilterModal
                  buttonRect={rect}
                  disableClear={disableClear}
                  disableApply={disableApply}
                  onClear={this.handleOnClear}
                  onApply={this.handleOnApply}
                  onClose={this.handleOnClose}>
                  {children({ apply: this.handleOnApply })}
                </StreamFilterModal>
              )}
            </Container>
          );
        }}
      </Rect>
    );
  }

  componentDidMount() {
    this.media = new Media("(max-width: 1090px)");
    this.media.addListener(this.hideOverlay);
  }

  componentWillUnmount() {
    this.media.removeListener(this.hideOverlay);
  }

  handleOnOpen = () => {
    this.setState({ isModalOpen: true });
  };

  handleOnClose = () => {
    this.setState({ isModalOpen: false });
  };

  handleOnClear = () => {
    this.props.onClear();
  };

  handleOnApply = () => {
    this.setState({ isModalOpen: false }, () => {
      this.props.onApply();
    });
  };

  hideOverlay = (e) => {
    if (e.matches) {
      this.setState({ isModalOpen: false });
    }
  };
}

StreamFilter.defaultProps = {
  hasSelectedValues: PropTypes.bool,
  label: PropTypes.string,
  disableClear: PropTypes.bool,
  disableApply: PropTypes.bool,
  onClear: PropTypes.func,
  onApply: PropTypes.func,
  onClose: PropTypes.func
};

StreamFilter.defaultProps = {
  hasSelectedValues: false,
  label: "Filters",
  onClear: function () {},
  onApply: function () {},
  onClose: function () {}
};

export default StreamFilter;
