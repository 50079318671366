import React from "react";
import PropTypes from "prop-types";
import { Menu } from "@wrstudios/components";
import { Avatar } from "./styled/user-menu";

function UserMenu({
  isAgent,
  isClient,
  isImpersonating,
  initials,
  initialsColor,
  avatar,
  name,
  logout,
  adminUrl
}) {
  return (
    <Menu>
      <Menu.Button aria-label={`${name} Account Menu`}>
        <Avatar
          initials={initials}
          initialsColor={initialsColor}
          avatarUrl={avatar}
        />
      </Menu.Button>
      <Menu.List>
        <Menu.Header>{name}</Menu.Header>
        <Menu.Link to="/settings">Account Settings</Menu.Link>
        <Menu.Link to="/subscriptions">Manage Notifications</Menu.Link>
        <Menu.Divider />
        {isAgent && (
          <Menu.Link to="/settings/lead_generation">Lead Generation</Menu.Link>
        )}
        {isAgent && <Menu.Divider />}
        <Menu.LinkExternal href="https://learn.cloudagentsuite.com/videos-streams">
          Learning Resources
        </Menu.LinkExternal>
        {isAgent && (
          <Menu.LinkExternal href="https://lonewolf.my.site.com/s/topic/0TO2L0000008JRFWA2/cloud-streams">
            Support Center
          </Menu.LinkExternal>
        )}
        {isClient && (
          <Menu.LinkExternal href="https://lonewolf.my.site.com/s/topic/0TO2L0000008JRFWA2/cloud-streams">
            Support Center
          </Menu.LinkExternal>
        )}
        {isAgent && (
          <Menu.LinkExternal href="https://cloudagentsuite.com/launchpad">
            Launchpad
          </Menu.LinkExternal>
        )}
        <Menu.Divider />
        <Menu.Item
          onClick={() => {
            logout();

            if (isImpersonating) {
              window.location.href = adminUrl;
            }
          }}>
          {isImpersonating ? "Back to your account" : "Logout"}
        </Menu.Item>
      </Menu.List>
    </Menu>
  );
}

UserMenu.propTypes = {
  isAgent: PropTypes.bool.isRequired,
  isClient: PropTypes.bool.isRequired,
  isImpersonating: PropTypes.bool.isRequired,
  initials: PropTypes.string.isRequired,
  initialsColor: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired
};

export default UserMenu;
