import styled from "styled-components";

export const RecommendedContainer = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;

  svg {
    stroke: white;
    paint-order: stroke;
    fill: ${({ theme }) => theme.colors.yellow};
  }
`;

export const LikedContainer = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;

  svg {
    stroke: white;
    stroke-width: 2;
    paint-order: stroke;
    fill: ${({ theme }) => theme.colors.red};
  }
`;

// filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.2)) drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
