export const initial = {
  byId: {},
  isLoading: false,
  hasLoaded: false,
  getListingNetwork: {
    isFetching: false,
    hasFetched: false,
    errors: []
  },
  getShowingTimeUrlNetwork: {
    isFetching: false,
    hasFetched: false,
    errors: []
  },
  requestShowingNetwork: {
    isFetching: false,
    hasFetched: false,
    errors: null
  },
  zoomLevel: null,
  isFullscreenMediaOpen: false,
  fullscreenMediaTab: "map",
  fullscreenMediaPhotoIndex: 0
};

export function listings(state = initial, { type, payload }) {
  switch (type) {
    case "GET_STREAM_INITIATED":
    case "GET_STREAMS_INITIATED":
    case "GET_STREAM_ITEMS_INITIATED":
    case "GET_LISTINGS_INITIATED":
    case "GET_FORGE_LISTINGS_INITIATED":
    case "GET_CLIENT_LIKED_LISTINGS_INITIATED":
    case "GET_CLIENT_RECOMMENDED_LISTINGS_INITIATED":
      return { ...state, isLoading: true, hasLoaded: false };
    case "GET_LISTINGS_SUCCEEDED":
    case "GET_FORGE_LISTINGS_SUCCEEDED":
    case "GET_LISTINGS_FOR_PIN_SUCCEEDED":
      return {
        ...state,
        isLoading: false,
        hasLoaded: true,
        byId: { ...state.byId, ...payload.listings }
      };
    case "GET_STREAMS_SUCCEEDED":
    case "GET_STREAM_SUCCEEDED":
    case "GET_STREAM_ITEMS_SUCCEEDED":
    case "CREATE_STREAM_SUCCEEDED":
    case "UPDATE_STREAM_SUCCEEDED":
    case "RENAME_STREAM_SUCCEEDED":
    case "GET_CLIENT_LIKED_LISTINGS_SUCCEEDED":
    case "GET_CLIENT_RECOMMENDED_LISTINGS_SUCCEEDED":
      return {
        ...state,
        isLoading: false,
        hasLoaded: true,
        byId: { ...state.byId, ...payload.streamItems.listings }
      };
    case "GET_STREAM_ITEM_SUCCEEDED":
    case "GET_PUBLIC_STREAM_ITEM_SUCCEEDED":
      return {
        ...state,
        isLoading: false,
        hasLoaded: true,
        byId: {
          ...state.byId,
          [payload.listing.id]: {
            ...state.byId[payload.listing.id],
            ...payload.listing
          }
        }
      };
    case "GET_LISTING_INITIATED":
      return {
        ...state,
        getListingNetwork: { isFetching: true, hasFetched: false, errors: [] }
      };
    case "GET_LISTING_SUCCEEDED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: { ...state.byId[payload.id], ...payload }
        },
        getListingNetwork: { isFetching: false, hasFetched: true, errors: [] }
      };
    case "GET_LISTING_REJECTED":
      return {
        ...state,
        getListingNetwork: {
          isFetching: false,
          hasFetched: false,
          errors: payload.errorsArray
        }
      };
    case "GET_LISTING_SCHOOLS_SUCCEEDED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: { ...state.byId[payload.id], schools: payload.schools }
        }
      };
    case "GET_SHOWING_TIME_URL_INITIATED":
      return {
        ...state,
        getShowingTimeUrlNetwork: {
          isFetching: true,
          hasFetched: false,
          errors: []
        }
      };
    case "GET_SHOWING_TIME_URL_SUCCEEDED":
      return {
        ...state,
        getShowingTimeUrlNetwork: {
          isFetching: false,
          hasFetched: true,
          errors: []
        },
        byId: {
          ...state.byId,
          [payload.id]: {
            ...state.byId[payload.id],
            showingTimeUrl: payload.showingTimeUrl
          }
        }
      };
    case "REQUEST_SHOWING_INITIATED":
      return {
        ...state,
        requestShowingNetwork: {
          isFetching: true,
          hasFetched: false,
          error: null
        }
      };
    case "REQUEST_SHOWING_SUCCEEDED":
      return {
        ...state,
        requestShowingNetwork: {
          isFetching: false,
          hasFetched: true,
          error: null
        }
      };
    case "REQUEST_SHOWING_FAILED":
      return {
        ...state,
        requestShowingNetwork: {
          isFetching: false,
          hasFetched: false,
          error: payload
        }
      };
    case "RESET_REQUEST_SHOWING":
      return { ...state, requestShowingNetwork: initial.requestShowingNetwork };
    case "UPDATE_USER_MLS_SUCCEEDED":
    case "CLEAR_API_TOKEN":
      return initial;
    case "SET_LISTING_FULLSCREEN_MEDIA_DETAILS":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: {
            ...state.byId[payload.id],
            isFullscreenMediaOpen: payload.open,
            fullscreenMediaTab: payload.tab,
            fullscreenMediaPhotoIndex: payload.photoIndex
          }
        }
      };
    default:
      return state;
  }
}
