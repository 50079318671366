import { pluralize } from "@wrstudios/utils";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import {
  Listing,
  ListingAddress,
  ListingCell,
  ListingDetails,
  ListingDom,
  ListingGrid,
  ListingImage,
  ListingPrice,
  ListingStatus
} from "./styled/listing-mini-card";

function ListingMiniCard({ listing }) {
  const [firstPhoto] = listing.photos || [];
  const details = [
    listing.bedsFormatted,
    listing.bathsFormatted,
    listing.sqftFormatted,
    listing.built
  ].filter(Boolean);
  const hasDetails = !!details.length;
  const detailsList = details.map((detail, index) => (
    <span key={index}>
      {index > 0 ? <> &bull; </> : null}
      {detail}
    </span>
  ));

  return (
    <Listing>
      <ListingGrid>
        <ListingCell>
          <ListingImage style={{ backgroundImage: `url('${firstPhoto}')` }} />
        </ListingCell>
        <ListingCell>
          <ListingAddress>{listing.address}</ListingAddress>
          {hasDetails && (
            <ListingDetails title={details.join(", ")}>
              {detailsList}
            </ListingDetails>
          )}
          {!hasDetails && (
            <ListingDetails title={listing.acresFormatted}>
              {listing.acresFormatted}
            </ListingDetails>
          )}
          <ListingDetails>
            {[
              listing.priceFormatted ? (
                <ListingPrice title={listing.priceFormatted}>
                  {listing.priceFormatted}
                </ListingPrice>
              ) : null,
              listing.statusLabel ? (
                <ListingStatus
                  status={listing.statusValue}
                  title={listing.statusLabel}>
                  {listing.statusLabel}
                </ListingStatus>
              ) : null,
              listing.dom ? (
                <ListingDom title={pluralize("Day", listing.dom, true)}>
                  {pluralize("Day", listing.dom, true)}
                </ListingDom>
              ) : null
            ]
              .filter(Boolean)
              .map((item, index) => (
                <Fragment key={index}>
                  {!!index && <span> &bull; </span>}
                  {item}
                </Fragment>
              ))}
          </ListingDetails>
        </ListingCell>
      </ListingGrid>
    </Listing>
  );
}

ListingMiniCard.propTypes = {
  listing: PropTypes.shape({
    id: PropTypes.string.isRequired,
    photos: PropTypes.arrayOf(PropTypes.string),
    bedsFormatted: PropTypes.string,
    bathsFormatted: PropTypes.string,
    sqftFormatted: PropTypes.string,
    built: PropTypes.string,
    acresFormatted: PropTypes.string,
    priceFormatted: PropTypes.string,
    statusLabel: PropTypes.string,
    statusValue: PropTypes.string,
    dom: PropTypes.string
  }).isRequired
};

export default ListingMiniCard;
