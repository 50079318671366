import React from "react";
import { Container } from "./styled/link-with-icon";

/**
 * * USAGE EXAMPLES:
 * Used in `Add a Client` modal for importing contacts
 * Used in `Client Profile Card` to fetch social profiles
 */

function LinkWithIcon({ children, ...rest }) {
  return <Container {...rest}>{children}</Container>;
}

export default LinkWithIcon;
