export const initial = {
  all: {
    byId: {}
  },
  standardFields: {
    byId: {}
  },
  fineTuningFields: {
    byId: {}
  },
  status: {
    byId: {}
  },
  locations: [],
  locationsLabels: [],
  photoProxyStrategy: null,
  defaultPropType: null,
  disclaimer: ""
};

export function mls(state = initial, { type, payload }) {
  switch (type) {
    case "GET_ALL_MLSES_SUCCEEDED":
      return { ...state, all: { byId: payload } };
    case "GET_MLS_FIELDS_SUCCEEDED":
      return {
        ...state,
        standardFields: {
          byId: { ...state.standardFields.byId, ...payload.standardFields }
        },
        fineTuningFields: {
          byId: { ...state.fineTuningFields.byId, ...payload.fineTuningFields }
        }
      };
    case "GET_MLS_FINE_TUNING_FIELD_SUCCEEDED":
      return {
        ...state,
        fineTuningFields: {
          ...state.fineTuningFields,
          byId: { ...state.fineTuningFields.byId, [payload.key]: payload }
        }
      };
    case "GET_MLS_LOCATIONS_SUCCEEDED":
      return {
        ...state,
        locations: payload.locations,
        locationsLabels: payload.locationsLabels
      };
    case "GET_MLS_STATUS_SUCCEEDED":
      return { ...state, status: { byId: payload } };
    case "GET_MLS_CONFIGS_SUCCEEDED":
      return {
        ...state,
        photoProxyStrategy: payload.photoProxyStrategy || "none",
        defaultPropType: payload.defaultPropType,
        disclaimer: payload.disclaimer
      };
    case "UPDATE_USER_MLS_SUCCEEDED":
      return initial;
    case "CLEAR_API_TOKEN":
      return initial;
    default:
      return state;
  }
}
