import React, { Component, createRef } from "react";
import { Modal } from "@wrstudios/components";
import { getRandomStreamBgImg } from "../../../modules/stream";
import StreamLocations from "../../stream/connected/StreamLocations";
import VideoWistia from "../../video/VideoWistia";
import IconPlayCircle from "../../icons/IconPlayCircle";
import {
  Container,
  Card,
  Title,
  SubTitle,
  Autocomplete,
  ModalBody,
  ModalContent,
  WatchVideoWrapper,
  WatchVideo
} from "./styled/stream-route-new";

class StreamRouteNewLocationStep extends Component {
  ref = createRef();
  backgroundImage = getRandomStreamBgImg();

  state = {
    isModalOpen: false
  };

  render() {
    return (
      <>
        <Container ref={this.ref} background={this.backgroundImage}>
          <Card>
            <Title>Create a Property Stream</Title>
            <SubTitle>
              As soon as homes hit the market, your clients will receive
              real-time alerts, branded to you.{" "}
              <strong>Where is your client looking for a home?</strong>
            </SubTitle>
            <Autocomplete>
              <StreamLocations />
            </Autocomplete>
            {this.state.isModalOpen && (
              <Modal onClose={this.handleOnModalClose}>
                <ModalContent>
                  <ModalBody>
                    <VideoWistia id="u8fvdjvapp" autoplay={true} />
                    <button style={{ display: "none" }} />
                  </ModalBody>
                </ModalContent>
              </Modal>
            )}
            <WatchVideoWrapper>
              <WatchVideo onClick={this.handleOnModalOpen}>
                Watch how to create a Stream
                <IconPlayCircle />
              </WatchVideo>
            </WatchVideoWrapper>
          </Card>
        </Container>
      </>
    );
  }

  handleOnModalOpen = () => {
    this.setState({ isModalOpen: true });
  };

  handleOnModalClose = () => {
    this.setState({ isModalOpen: false });
  };
}

export default StreamRouteNewLocationStep;
