import styled, { css } from "styled-components";
import { UserAvatar as BaseAvatar } from "@wrstudios/components";
import { default as BaseIconAlert } from "../../icons/IconAlert";

export const Container = styled.div``;

const avatar = css`
  width: 4.8rem;
  height: 4.8rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    width: 3.8rem;
    height: 3.8rem;
  }
`;

export const Avatar = styled(BaseAvatar)`
  ${avatar};
`;

export const IconAlert = styled(BaseIconAlert)`
  ${avatar};
`;

export const Icon = styled.span`
  display: inline-block;
`;
