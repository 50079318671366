import React, {
  createContext,
  useContext,
  useReducer,
  useRef,
  useEffect
} from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { motion, AnimatePresence } from "framer-motion";
import FocusTrap from "focus-trap-react";
import { Portal } from "@wrstudios/components";
import IconClose from "../icons/IconClose";
import StreamInviteMyAlerts from "./invite/connected/StreamInviteMyAlerts";
import StreamInviteAlertsTemplates from "./invite/StreamInviteAlertsTemplates";
import StreamInvitees from "./invite/connected/StreamInvitees";
import { getDefaultFrequency, alertTemplates } from "./invite/utils";
import StreamNameInput from "./connected/StreamNameInput";
import StreamInviteUser from "./invite/StreamInviteUser";
import {
  Container,
  ModalCard,
  CloseButton,
  ModalSection,
  ModalTitle,
  NameSection,
  StreamNameLabel,
  ModalSubtitle,
  InvitesLayout,
  InviteInfo,
  WhiteBackdrop,
  MessageLayout,
  UpdateSettingsLayout,
  InvitationMessage,
  InviteButton,
  UpdateSettingsButton
} from "./styled/stream-modal";

function StreamModal({
  streamId,
  agentId,
  isShowingStreamModal,
  isShowingNestedModal,
  streamModalOpenedViaSave,
  hasInvitations,
  hasSubscription,
  isAgent,
  isClient,
  primaryAlertType,
  setPrimaryAlertType,
  inviteClients,
  inviteClientsResolved,
  addClientsToCuratedStreamResolved,
  addClientsToCuratedStream,
  emitAction
}) {
  // Create Modal State
  const [state, dispatch] = useReducer(reducer, {
    peopleToInvite:
      hasSubscription || hasInvitations ? [] : [newStreamInvitee()],
    invitationMessageHasOpened: false,
    invitationMessage: ""
  });

  // Computed State
  const { peopleToInvite, invitationMessage, invitationMessageHasOpened } =
    state;
  const shouldShowAlertSettings = isClient || !!primaryAlertType;
  const shouldShowAlertTemplates = isAgent && !primaryAlertType;
  const emptyInvitees = peopleToInvite.filter(isBlankInvitee);
  const inviteesAreValid = schema.isValidSync(
    peopleToInvite.filter(isNotBlankInvitee)
  );
  const hasInviteeFieldset = peopleToInvite.length > 0;
  const canAddAnotherInvitee = inviteesAreValid && emptyInvitees.length !== 1;
  const isCuratedStream = primaryAlertType === alertTemplates.CURATED;

  function handleAlertTemplateSelection(alertsTemplate) {
    setPrimaryAlertType({ streamId, alertsTemplate });
    dispatch({ type: "SET_STREAMS_ALERT_TEMPLATE", payload: alertsTemplate });
  }

  function handleSendInvite() {
    // convert `peopleToInvite` to `InvitationParamsType`
    const invitations = peopleToInvite
      .filter(isNotBlankInvitee)
      .map((person) => ({
        email: person.email,
        name: person.name,
        frequency: person.frequency,
        alert_type: primaryAlertType,
        message: invitationMessage
      }));

    inviteClients({
      agentId,
      streamId,
      invitations
    });

    emitAction({ type: "CLOSE_STREAM_MODAL" });
  }

  function handleUpdateSettings() {
    const clients = peopleToInvite.filter(isNotBlankInvitee).map((person) => ({
      name: person.name,
      email: person.email
    }));

    addClientsToCuratedStream({ streamId, clients });
    emitAction({ type: "CLOSE_STREAM_MODAL" });
  }

  useEffect(() => {
    if (inviteClientsResolved || addClientsToCuratedStreamResolved) {
      dispatch({ type: "CLEAR_INVITEES" });
    }
  }, [inviteClientsResolved, addClientsToCuratedStreamResolved]);

  const inputRef = useRef({
    // we have to initialize a height for the invitation message animation
    getBoundingClientRect() {
      return {
        height: 72 // default height of invitation message component
      };
    }
  });
  const inputRect = useRect(inputRef);

  return (
    <StreamModalContext.Provider
      value={{
        state: { ...state, canAddAnotherInvitee },
        dispatch,
        actions: { handleSendInvite, handleAlertTemplateSelection }
      }}>
      <AnimatePresence>
        {isShowingStreamModal && (
          <Portal>
            <FocusTrap
              focusTrapOptions={{
                initialFocus: streamModalOpenedViaSave
                  ? "#stream-name-input"
                  : hasInvitations || hasSubscription
                  ? "#stream-modal-close"
                  : "[aria-labelledby=invitee-0-name]"
              }}>
              <Container>
                <motion.div key="modal-backdrop" {...backdropAnimationConfig}>
                  <WhiteBackdrop
                    onClick={() => emitAction({ type: "CLOSE_STREAM_MODAL" })}
                  />
                </motion.div>
                <motion.div key="modal" {...modalAnimationConfig}>
                  <ModalCard
                    fitContent={shouldShowAlertSettings}
                    onKeyDown={(e) =>
                      e.key === "Escape" &&
                      emitAction({ type: "CLOSE_STREAM_MODAL" })
                    }>
                    {!isShowingNestedModal && (
                      <>
                        <CloseButton
                          onClick={() =>
                            emitAction({ type: "CLOSE_STREAM_MODAL" })
                          }
                          id="stream-modal-close">
                          <IconClose />
                        </CloseButton>
                        <ModalSection>
                          <ModalTitle>
                            {streamModalOpenedViaSave
                              ? "Stream Saved"
                              : !!primaryAlertType
                              ? getAlertLabel(primaryAlertType)
                              : "Alert Settings"}
                          </ModalTitle>
                        </ModalSection>
                        {streamModalOpenedViaSave && (
                          <ModalSection>
                            <NameSection>
                              <StreamNameLabel htmlFor="stream-name-input">
                                Name of this Stream
                                <StreamNameInput
                                  inputId="stream-name-input"
                                  streamId={streamId}
                                />
                              </StreamNameLabel>
                            </NameSection>
                          </ModalSection>
                        )}
                        {shouldShowAlertTemplates && (
                          <ModalSection color="greyLightest">
                            <StreamInviteAlertsTemplates />
                          </ModalSection>
                        )}
                      </>
                    )}
                    {shouldShowAlertSettings && (
                      <>
                        <ModalSection>
                          <StreamInviteMyAlerts streamId={streamId} />
                        </ModalSection>
                        {!isShowingNestedModal && (
                          <>
                            <ModalSection color="greyLightest">
                              <InvitesLayout>
                                <ModalSubtitle>
                                  {isAgent
                                    ? hasInvitations
                                      ? "Client Alerts"
                                      : isCuratedStream
                                      ? "Add Clients"
                                      : "Invite Clients"
                                    : null}
                                  {isClient
                                    ? hasSubscription
                                      ? "Others' Alerts"
                                      : "Invite family and friends"
                                    : null}
                                </ModalSubtitle>
                                <StreamInvitees streamId={streamId} />
                                <StreamInviteUser streamId={streamId} />
                                <InviteInfo>
                                  {isAgent ? "Clients" : "People"} on the same
                                  Stream will see each others’ likes and
                                  comments.
                                </InviteInfo>
                              </InvitesLayout>
                            </ModalSection>
                            <AnimatePresence>
                              {hasInviteeFieldset && (
                                <motion.div
                                  transition={spring}
                                  initial={
                                    invitationMessageHasOpened ||
                                    (!hasSubscription && !hasInvitations)
                                      ? false
                                      : { height: 0 }
                                  }
                                  animate={{ height: inputRect.height }}
                                  exit={{ height: 0 }}
                                  style={{
                                    overflow: "hidden",
                                    position: "sticky",
                                    bottom: "0",
                                    zIndex: "10"
                                  }}
                                  onAnimationComplete={() =>
                                    dispatch({
                                      type: "SET_MESSAGE_OPEN"
                                    })
                                  }>
                                  <ModalSection
                                    color="whiteDark"
                                    ref={inputRef}>
                                    {isCuratedStream ? (
                                      <UpdateSettingsLayout>
                                        <UpdateSettingsButton
                                          disabled={!inviteesAreValid}
                                          onClick={handleUpdateSettings}>
                                          Update Alerts
                                        </UpdateSettingsButton>
                                      </UpdateSettingsLayout>
                                    ) : (
                                      <MessageLayout>
                                        <InvitationMessage
                                          maxRows={6}
                                          placeholder="Add message (optional)"
                                          value={invitationMessage}
                                          onChange={(e) =>
                                            dispatch({
                                              type: "SET_INVITATION_MESSAGE",
                                              payload: e.target.value
                                            })
                                          }
                                        />
                                        <InviteButton
                                          disabled={!inviteesAreValid}
                                          onClick={handleSendInvite}>
                                          Send Invite
                                        </InviteButton>
                                      </MessageLayout>
                                    )}
                                  </ModalSection>
                                </motion.div>
                              )}
                            </AnimatePresence>
                          </>
                        )}
                      </>
                    )}
                  </ModalCard>
                </motion.div>
              </Container>
            </FocusTrap>
          </Portal>
        )}
      </AnimatePresence>
    </StreamModalContext.Provider>
  );
}

StreamModal.propTypes = {
  streamId: PropTypes.string.isRequired,
  agentId: PropTypes.string.isRequired,
  isAgent: PropTypes.bool.isRequired,
  isClient: PropTypes.bool.isRequired,
  hasInvitations: PropTypes.bool.isRequired,
  hasSubscription: PropTypes.bool.isRequired,
  inviteClientsResolved: PropTypes.bool.isRequired,
  streamModalOpenedViaSave: PropTypes.bool.isRequired,
  isShowingStreamModal: PropTypes.bool.isRequired,
  isShowingNestedModal: PropTypes.bool.isRequired,
  addClientsToCuratedStreamResolved: PropTypes.bool.isRequired,
  primaryAlertType: PropTypes.oneOf(Object.values(alertTemplates)),
  addClientsToCuratedStream: PropTypes.func.isRequired,
  setPrimaryAlertType: PropTypes.func.isRequired,
  inviteClients: PropTypes.func.isRequired,
  emitAction: PropTypes.func.isRequired
};

const StreamModalContext = createContext();
export const useStreamModal = () => useContext(StreamModalContext);
export default StreamModal;

function reducer(state, { type, payload }) {
  const newInvitees = state.peopleToInvite.slice(0);
  switch (type) {
    case "SET_STREAMS_ALERT_TEMPLATE":
      return {
        ...state,
        peopleToInvite: state.peopleToInvite.map((person) => ({
          ...person,
          frequency: getDefaultFrequency(payload)
        }))
      };
    case "ADD_INVITEE_FIELDSET":
      return {
        ...state,
        peopleToInvite: [...state.peopleToInvite, newStreamInvitee(payload)]
      };
    case "SET_MESSAGE_OPEN":
      return {
        ...state,
        invitationMessageHasOpened: true
      };
    case "INVITEE_INPUT_CHANGE":
      newInvitees[payload.inviteeIndex] = {
        ...newInvitees[payload.inviteeIndex],
        [payload.keyToChange]: payload.value
      };
      return {
        ...state,
        peopleToInvite: newInvitees
      };
    case "VALIDATE_NAME":
      newInvitees[payload.inviteeIndex] = {
        ...newInvitees[payload.inviteeIndex],
        nameTouched: newInvitees[payload.inviteeIndex].name.length > 0,
        nameValid: yup
          .string()
          .min(1)
          .isValidSync(newInvitees[payload.inviteeIndex].name)
      };
      return {
        ...state,
        peopleToInvite: newInvitees
      };
    case "VALIDATE_EMAIL":
      newInvitees[payload.inviteeIndex] = {
        ...newInvitees[payload.inviteeIndex],
        emailTouched: newInvitees[payload.inviteeIndex].email.length > 0,
        emailValid: yup
          .string()
          .min(1)
          .email()
          .isValidSync(newInvitees[payload.inviteeIndex].email)
      };
      return {
        ...state,
        peopleToInvite: newInvitees
      };
    case "SELECT_INVITEE":
      newInvitees[payload.inviteeIndex] = {
        ...newInvitees[payload.inviteeIndex],
        ...payload.selectedClient,
        nameTouched: true,
        nameValid: true,
        emailValid: true,
        emailTouched: true
      };
      return {
        ...state,
        peopleToInvite: newInvitees
      };
    case "SELECT_INVITEE_FREQUENCY":
      newInvitees[payload.inviteeIndex] = {
        ...newInvitees[payload.inviteeIndex],
        frequency: payload.selectedFrequency
      };
      return {
        ...state,
        peopleToInvite: newInvitees
      };
    case "SET_INVITATION_MESSAGE":
      return {
        ...state,
        invitationMessage: payload
      };
    case "CLEAR_INVITEES":
      return {
        ...state,
        peopleToInvite: [],
        invitationMessageHasOpened: false,
        invitationMessage: ""
      };
    default:
      return state;
  }
}

function isNotBlankInvitee(invitee) {
  return invitee.name !== "" && invitee.email !== "";
}

function isBlankInvitee(invitee) {
  return invitee.name === "" && invitee.email === "";
}

const newStreamInvitee = (alertsTemplate = "automatic") => ({
  name: "",
  email: "",
  frequency: getDefaultFrequency(alertsTemplate),
  nameTouched: false,
  emailTouched: false,
  nameValid: false,
  emailValid: false
});

// Object Validation Schema for Stream Invitees
const schema = yup.array().of(
  yup.object().shape({
    email: yup.string().email("Invalid email").required("Required"),
    name: yup.string().min(1).required("Required")
  })
);

function getAlertLabel(alertType) {
  switch (alertType) {
    case "automatic":
      return "Automatic Alerts";
    case "curated":
      return "Curated Alerts";
    default:
      return "Alert Settings";
  }
}

// Animation Configs
const backdropAnimationConfig = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
};

const modalAnimationConfig = {
  initial: {
    zIndex: 100,
    opacity: 0,
    scale: 0.9
  },
  animate: {
    opacity: 1,
    scale: 1
  },
  exit: {
    opacity: 0,
    scale: 0.9
  }
};

const spring = {
  type: "spring",
  damping: 100,
  stiffness: 200
};

function useRect(ref) {
  if (!ref || !ref.current) {
    return {
      width: null,
      height: 72,
      x: null,
      y: null,
      top: null,
      right: null,
      bottom: null,
      left: null
    };
  }

  const rect = ref.current.getBoundingClientRect();
  return rect;
}
