import React, { Component } from "react";
import PropTypes from "prop-types";
import Media from "react-media";
import { Route, Switch } from "react-router-dom";
import { navigateTo } from "@wrstudios/utils";
import { RouteContainer } from "@wrstudios/components";
import SubNav from "../../common/SubNav";
import NavLink from "../../common/NavLink";
import ResourceLoader from "../../common/ResourceLoader";
import ClientActivities from "../../client/connected/ClientActivities";
import ClientStreams from "../../client/connected/ClientStreams";
import ClientLikedListings from "../../client/connected/ClientLikedListings";
import ClientRecommendedListings from "../../client/connected/ClientRecommendedListings";
import ClientProfile from "../../client/ClientProfile";
import {
  RouteHeading,
  NavLayout,
  ContentLayout,
  Content
} from "./styled/client-route";

class ClientRoute extends Component {
  render() {
    if (!this.props.hasClient) return null;
    const {
      clientId,
      clientName,
      isFetching,
      notFound,
      hasErrors
    } = this.props;

    return (
      <ResourceLoader
        isLoading={isFetching}
        notFound={notFound}
        hasError={hasErrors}>
        <Media
          query="(max-width: 767px)"
          onChange={(isSmallScreen) => this.handleWindowResize(isSmallScreen)}>
          {(isSmallScreen) => (
            <RouteContainer>
              {isSmallScreen && <RouteHeading>{clientName}</RouteHeading>}
              <NavLayout>
                <SubNav>
                  <NavLink to={`/clients/${clientId}/activities`}>
                    Activity
                  </NavLink>
                  <NavLink to={`/clients/${clientId}/streams`}>Streams</NavLink>
                  <NavLink to={`/clients/${clientId}/liked`}>Liked</NavLink>
                  <NavLink to={`/clients/${clientId}/recommended`}>
                    Recommended
                  </NavLink>
                  {isSmallScreen && (
                    <NavLink to={`/clients/${clientId}/profile`}>
                      Profile
                    </NavLink>
                  )}
                </SubNav>
              </NavLayout>
              <ContentLayout>
                {!isSmallScreen && <ClientProfile clientId={clientId} />}
                <Content>
                  <Switch>
                    <Route
                      component={ClientStreams}
                      path="/clients/:clientId/streams"
                    />
                    <Route
                      component={ClientLikedListings}
                      path="/clients/:clientId/liked"
                    />
                    <Route
                      component={ClientRecommendedListings}
                      path="/clients/:clientId/recommended"
                    />
                    <Route
                      component={ClientProfile}
                      path="/clients/:clientId/profile"
                    />
                    <Route
                      component={ClientActivities}
                      path="/clients/:clientId"
                    />
                  </Switch>
                </Content>
              </ContentLayout>
            </RouteContainer>
          )}
        </Media>
      </ResourceLoader>
    );
  }

  componentDidMount() {
    this.handleNewLocation();
  }

  componentDidUpdate(prevProps) {
    if (this.props.clientId !== prevProps.clientId) {
      this.props.getClient(this.props.clientId);
    }
  }

  handleNewLocation = () => {
    this.props.getClient(this.props.clientId);
  };

  handleWindowResize = (isSmallScreen) => {
    if (!isSmallScreen && this.props.location.pathname.includes("profile")) {
      navigateTo(`/clients/${this.props.clientId}`, { replace: true });
    }
  };
}

ClientRoute.propTypes = {
  location: PropTypes.object.isRequired,
  hasClient: PropTypes.bool.isRequired,
  clientId: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  notFound: PropTypes.bool.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  getClient: PropTypes.func.isRequired
};

export default ClientRoute;
