import styled, { css } from "styled-components";
import { Button as BaseButton } from "@wrstudios/components";

export const Container = styled.div`
  ${({ theme }) => theme.layers.overlay};
  min-width: 21.5rem;
  max-width: 41.5rem;
  margin-top: 1rem;
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.white};
  outline: none;
  position: fixed;
  z-index: 10;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
`;

const button = css`
  font-weight: 700;
  padding: 0;
  border: 0;
`;

export const Clear = styled(BaseButton)`
  ${button};
  color: ${({ theme }) => theme.colors.grey};
`;

export const Apply = styled(BaseButton)`
  ${button};
  margin-left: auto;
  color: ${({ theme }) => theme.colors.streamsBlue};
`;
