import React from "react";
import PropTypes from "prop-types";
import ThreeColumnLayout from "../common/ThreeColumnLayout";
// import CardCreate from "../common/CardCreate";
import ClientCardLoading from "./ClientCardLoading";
import { Container } from "./styled/client-card-loading";

function ClientCardsLoading({ limit }) {
  return (
    <Container data-testid="loading">
      <ThreeColumnLayout>
        {/* Hidden until phase 2. */}
        {/* <CardCreate text="Add a Client" /> */}
        {new Array(limit).fill().map((_, index) => (
          <ClientCardLoading key={index} />
        ))}
      </ThreeColumnLayout>
    </Container>
  );
}

ClientCardsLoading.propTypes = {
  limit: PropTypes.number.isRequired
};

export default ClientCardsLoading;
