import { connect } from "react-redux";
import { hasLocationHash, getParsedQueryString } from "@wrstudios/utils";
import { unrecommendStreamItem } from "../../../actions/streamItem";
import { emitAction } from "../../../utils/redux";
import {
  getNewCmaSubjectUrl,
  getNewCmaPropertyUrl,
  getNewCmaFlyerUrl,
  getNewMlxListingUrl
} from "../../../modules/interProduct";
import ListingsControls from "../ListingControls";

function select(
  {
    application: { railsApiToken, ssoParams },
    streamItems,
    listings: { byId, hasLoaded, isLoading },
    user
  },
  { streamItemId, listingId }
) {
  const { recommend = "" } = getParsedQueryString();
  const streamItem = streamItems.byId[streamItemId] || {};
  const listing = byId[listingId] || {};
  const hasCommentsHash = hasLocationHash("comments");

  return {
    streamId: streamItem.streamId,
    statusValue: listing.statusValue,
    statusLabel: listing.statusLabel,
    priceFormatted: listing.priceFormatted,
    pricePerSqft: listing.pricePerSqft,
    domFormatted: listing.domFormatted,
    showingTimeUrl: listing.showingTimeUrl,
    newCmaSubjectUrl: getNewCmaSubjectUrl({ listing, ssoParams }),
    newCmaPropertyUrl: getNewCmaPropertyUrl({
      mlsnums: listing.mlsnum,
      ssoParams
    }),
    newCmaFlyerUrl: getNewCmaFlyerUrl({ mlsnums: listing.mlsnum, ssoParams }),
    newMlxListingUrl: user.isCASUser
      ? getNewMlxListingUrl({
          listingId: listing.id,
          jwt: user.casJwt
        })
      : "",
    currentPath: streamItemId
      ? `/stream_items/${streamItemId}`
      : `/listings/${listingId}`,
    shouldOpenRecommendModal: Boolean(recommend === "true" && hasLoaded),
    isAgent: user.isAgent,
    isClient: user.isClient,
    hasStreamItemId: !!streamItemId,
    hasCommentsHash,
    isAuthed: !!railsApiToken
  };
}

const actions = {
  emitAction,
  unrecommendStreamItem
};

export default connect(select, actions)(ListingsControls);
