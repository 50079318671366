import React from "react";
import PropTypes from "prop-types";
import { Link } from "@wrstudios/components";
import StreamItemRecommend from "./StreamItemRecommend";
import StreamItemLike from "./connected/StreamItemLike";
import StreamItemHide from "./connected/StreamItemHide";
import StreamItemComment from "./connected/StreamItemComment";
import StreamItemShare from "./connected/StreamItemShare";
import { Container } from "./styled/stream-item-controls";

function StreamItemControls({ streamItemId, isHidden, isClient, isAgent }) {
  return (
    <Container isHidden={isHidden}>
      {isAgent && <StreamItemRecommend streamItemId={streamItemId} />}
      {isClient && <StreamItemLike streamItemId={streamItemId} />}
      <StreamItemHide streamItemId={streamItemId} />
      <Link to={`/stream_items/${streamItemId}#comments`}>
        <StreamItemComment streamItemId={streamItemId} isLink={true} />
      </Link>
      <StreamItemShare streamItemId={streamItemId} />
    </Container>
  );
}

StreamItemControls.propTypes = {
  streamItemId: PropTypes.string.isRequired,
  isHidden: PropTypes.bool,
  isClient: PropTypes.bool,
  isAgent: PropTypes.bool
};

export default StreamItemControls;
