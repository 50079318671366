import PropTypes from "prop-types";

function LoggedOutApp({ hasUrlToken, children }) {
  return hasUrlToken ? null : children;
}

LoggedOutApp.propTypes = {
  hasUrlToken: PropTypes.bool.isRequired
};

export default LoggedOutApp;
