import { connect } from "react-redux";
import { replaceStreamFilters } from "../../../actions/stream";
import StreamLocations from "../StreamLocations";

function select({ mls: { locations, locationsLabels, defaultPropType } }) {
  return {
    locations,
    locationsLabels,
    defaultPropType
  };
}

const actions = {
  replaceStreamFilters
};

export default connect(select, actions)(StreamLocations);
