import React, { Component } from "react";
import PropTypes from "prop-types";
import { pluralize } from "@wrstudios/utils";
import IconAlertCircle from "../icons/IconAlertCircle";
import IconViewCircle from "../icons/IconViewCircle";
import IconFavoriteCircle from "../icons/IconFavoriteCircle";
import IconCommentCircle from "../icons/IconCommentCircle";
import {
  Container,
  Grid,
  Cell,
  Stat,
  Icon,
  Title,
  Subtitle,
  Notice,
  ShowAll
} from "./styled/activity-stats";

class ActivityStats extends Component {
  render() {
    const {
      alertsCount,
      viewsCount,
      likesCount,
      commentsCount,
      isAlertsActive,
      isViewsActive,
      isLikesActive,
      isCommentsActive,
      hasActiveFilter,
      isLoading,
      setActivitiesFilter
    } = this.props;

    return (
      <Container>
        <Grid>
          <Cell>
            <Stat
              disabled={isLoading}
              isActive={isAlertsActive}
              onClick={() =>
                !isAlertsActive && setActivitiesFilter("StreamAlert")
              }>
              <Icon>
                <IconAlertCircle />
              </Icon>
              <Title>{alertsCount}</Title>
              <Subtitle>{pluralize("Alerts", alertsCount)}</Subtitle>
            </Stat>
          </Cell>
          <Cell>
            <Stat
              disabled={isLoading}
              isActive={isViewsActive}
              onClick={() =>
                !isViewsActive && setActivitiesFilter("StreamItemView")
              }>
              <Icon>
                <IconViewCircle />
              </Icon>
              <Title>{viewsCount}</Title>
              <Subtitle>{pluralize("Views", viewsCount)}</Subtitle>
            </Stat>
          </Cell>
          <Cell>
            <Stat
              disabled={isLoading}
              isActive={isLikesActive}
              onClick={() =>
                !isLikesActive && setActivitiesFilter("ActsAsVotable::Vote")
              }>
              <Icon>
                <IconFavoriteCircle />
              </Icon>
              <Title>{likesCount}</Title>
              <Subtitle>{pluralize("Likes", likesCount)}</Subtitle>
            </Stat>
          </Cell>
          <Cell>
            <Stat
              disabled={isLoading}
              isActive={isCommentsActive}
              onClick={() =>
                !isCommentsActive && setActivitiesFilter("Comment")
              }>
              <Icon>
                <IconCommentCircle />
              </Icon>
              <Title>{commentsCount}</Title>
              <Subtitle>{pluralize("Comments", commentsCount)}</Subtitle>
            </Stat>
          </Cell>
        </Grid>
        {hasActiveFilter && (
          <Notice>
            Showing {isAlertsActive && "alerts"} {isViewsActive && "views"}
            {isLikesActive && "likes"} {isCommentsActive && "comments"} only.{" "}
            <ShowAll
              disabled={isLoading}
              onClick={() => setActivitiesFilter("")}>
              Show all activity
            </ShowAll>
            .
          </Notice>
        )}
      </Container>
    );
  }

  componentDidMount() {
    this.props.getActivityStats();
  }
}

ActivityStats.propTypes = {
  alertsCount: PropTypes.number.isRequired,
  viewsCount: PropTypes.number.isRequired,
  likesCount: PropTypes.number.isRequired,
  commentsCount: PropTypes.number.isRequired,
  isAlertsActive: PropTypes.bool.isRequired,
  isViewsActive: PropTypes.bool.isRequired,
  isLikesActive: PropTypes.bool.isRequired,
  isCommentsActive: PropTypes.bool.isRequired,
  hasActiveFilter: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getActivityStats: PropTypes.func.isRequired,
  setActivitiesFilter: PropTypes.func.isRequired
};

export default ActivityStats;
