import { connect } from "react-redux";
import { getBaseUrl } from "@wrstudios/utils";
import { logout } from "../../../actions/app";
import UserMenu from "../UserMenu";

function select({
  application: { isNavOpen, railsApiToken },
  user: {
    id,
    isAgent,
    isClient,
    name,
    initials,
    initialsColor,
    avatar,
    isImpersonating
  }
}) {
  return {
    isNavOpen,
    isAuthed: !!railsApiToken,
    isAgent,
    isClient,
    isImpersonating,
    name,
    initials,
    initialsColor,
    avatar,
    adminUrl: `${getBaseUrl()}/admin/users/${id}`
  };
}

const actions = {
  logout
};

export default connect(select, actions)(UserMenu);
