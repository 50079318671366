import React from "react";
import IllustrationAutomaticAlerts from "../../illustrations/IllustrationAutomaticAlerts";
import IllustrationCuratedAlerts from "../../illustrations/IllustrationCuratedAlerts";
import { useStreamModal } from "../StreamModal";
import { alertTemplates } from "./utils";
import {
  Container,
  Heading,
  AlertButtonGroup,
  AlertButtonLayout,
  AlertButton,
  AutomaticIllustrationLayout,
  CuratedIllustrationLayout,
  AlertTypeLabel,
  AlertTypeDescription
} from "./styled/stream-invite-alerts-templates";
const { AUTOMATIC, CURATED } = alertTemplates;

function StreamInviteAlertsTemplates() {
  const {
    actions: { handleAlertTemplateSelection }
  } = useStreamModal();

  return (
    <Container>
      <Heading>Choose the type of alerts</Heading>
      <AlertButtonGroup>
        <AlertButtonLayout>
          <AlertButton onClick={() => handleAlertTemplateSelection(AUTOMATIC)}>
            <AutomaticIllustrationLayout>
              <IllustrationAutomaticAlerts />
            </AutomaticIllustrationLayout>
            <AlertTypeLabel>Automatic</AlertTypeLabel>
            <AlertTypeDescription>
              Your client receives all the properties that meet these filters
              automatically.
            </AlertTypeDescription>
          </AlertButton>
        </AlertButtonLayout>
        <AlertButtonLayout>
          <AlertButton onClick={() => handleAlertTemplateSelection(CURATED)}>
            <CuratedIllustrationLayout>
              <IllustrationCuratedAlerts />
            </CuratedIllustrationLayout>
            <AlertTypeLabel>Curated</AlertTypeLabel>
            <AlertTypeDescription>
              You review all properties first and choose which to send to your
              clients.
            </AlertTypeDescription>
          </AlertButton>
        </AlertButtonLayout>
      </AlertButtonGroup>
    </Container>
  );
}

export default StreamInviteAlertsTemplates;
