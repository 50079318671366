import React from "react";
import PropTypes from "prop-types";
import { Container } from "./styled/nav-link";

function NavLink({ children, ...rest }) {
  return <Container {...rest}>{children}</Container>;
}

NavLink.propTypes = {
  to: PropTypes.any,
  exact: PropTypes.bool,
  replace: PropTypes.bool,
  activeStyle: PropTypes.object,
  activeClassName: PropTypes.string,
  children: PropTypes.any
};

export default NavLink;
