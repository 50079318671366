import { getTimeAgo } from "@wrstudios/utils";
import { transformListing } from "./listing";
import { transformUser } from "./user";
import { transformStreamSubscriptions } from "./stream";

export const STREAM_ITEM_FILTER_TYPES = Object.freeze({
  ALL: "all",
  LIKED: "liked",
  COMMENTED: "commented",
  HIDDEN: "hidden",
  RECOMMENDED: "recommended"
});

export function transformStreamItems({ streamItems, mls }) {
  const transformedStreamItems = (streamItems || []).reduce(
    (streamItems, streamItem) => ({
      ...streamItems,
      [streamItem.id]: transformStreamItem({ streamItem, mls })
    }),
    {}
  );
  const transformedStreamItemsValues = Object.values(transformedStreamItems);

  // Remove stream items and only have the core stream item details. e.g. type, item id, etc.
  const newStreamItems = transformedStreamItemsValues.reduce(
    (streamItems, { streamItem }) => ({
      ...streamItems,
      [streamItem.id]: streamItem
    }),
    {}
  );
  const newStreamItemIds = (streamItems || []).map(({ id }) => id);

  // These are our stream items by types
  const listings = transformedStreamItemsValues.reduce(
    (listings, { listing }) => ({ ...listings, [listing.id]: listing }),
    {}
  );

  return {
    byId: newStreamItems,
    streamItemIds: newStreamItemIds,
    listings
  };
}

export function transformStreamItem({ streamItem, mls }) {
  const recommendation = transformStreamItemRecommendation(
    streamItem.recommendation || {}
  );
  const comments = transformStreamItemComments(streamItem.comments || []);
  const type = streamItem.item_type || "Listing";
  const item = {
    type,
    id: streamItem.id || "",
    itemId: streamItem.item_id || "",
    streamId: String(streamItem.stream_id || ""),
    recommendation,
    comments,
    commentsCount: comments.length,
    hidden: streamItem.hidden || false,
    liked: streamItem.liked || false,
    likesCount: streamItem.likes_count || 0,
    likers: (streamItem.likers || []).map(transformUser),
    hiders: (streamItem.hiders || []).map(transformUser),
    subscriptions: transformStreamSubscriptions(
      (streamItem.stream || {}).stream_subscriptions || []
    ).subscriptions
  };

  switch (type) {
    case "Listing":
      return {
        streamItem: item,
        listing: transformListing({ listing: streamItem.listing || {}, mls })
      };
    default:
      console.warn(
        `Could not find a stream item type for type: ${item.streamItemType} with id: ${streamItem.streamItemId}`
      );
      return item;
  }
}

export function transformStreamItemRecommendation(streamItemRecommendation) {
  const agent = streamItemRecommendation.agent || {};

  return {
    message: streamItemRecommendation.message || null,
    id: streamItemRecommendation.id || null,
    name: agent.name || "",
    agentId: agent.id || "",
    asComment: {
      commentableId: null,
      author: agent.name || "",
      streamItemId: streamItemRecommendation.stream_item_id || null,
      id: streamItemRecommendation.id || null,
      content: streamItemRecommendation.message || null,
      updatedAt: streamItemRecommendation.updated_at || null,
      updatedAtFormatted: `${getTimeAgo(streamItemRecommendation.updated_at)}`
    }
  };
}

function transformStreamItemComments(streamItemComments) {
  return streamItemComments.map((comment) =>
    transformStreamItemComment(comment)
  );
}

export function transformStreamItemComment(streamItemComment) {
  return {
    streamItemId: (streamItemComment.stream_item || {}).id || "",
    commentableId: streamItemComment.commentable_id || "",
    id: streamItemComment.id || "",
    author: streamItemComment.user_name || "",
    content: streamItemComment.content || "",
    updatedAt: streamItemComment.updated_at || "",
    updatedAtFormatted: `${getTimeAgo(streamItemComment.updated_at)}`
  };
}
