import { connect } from "react-redux";
import { getActivities, resetActivities } from "../../../actions/activity";
import Activities from "../Activities";

function select({ activities, mls }) {
  return {
    activityIds: activities.order,
    hasActivityIds: !!activities.order.length,
    filter: activities.filter || "",
    isLoading: activities.isLoading,
    isComplete: activities.isComplete,
    mlsConfigsLoaded: !!mls.photoProxyStrategy
  };
}

const actions = {
  getActivities,
  resetActivities
};

export default connect(select, actions)(Activities);
