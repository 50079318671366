import { buildRailsApiClient } from "./";
import { streamItemFields } from "../modules/railsApi";
import { streamSubscriptionFields } from "../api/subscription";

const clientRecommendedListingsQuery = /* GraphQL */ `
  query ClientRecommendedListingsQuery($id: ID!) {
    client(id: $id) {
      recommendations {
        stream_item_id
        stream_item {
          ${streamItemFields}
        }
      }
    }
  }
`;

export async function getClientRecommendedListingsAPI({ id, railsApiToken }) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const variables = { id };
  const {
    client: { recommendations = [] }
  } = await railsApiClient.request(clientRecommendedListingsQuery, variables);

  return recommendations;
}

export async function addClientsForCuration({
  streamId,
  clients,
  railsApiToken
}) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const variables = { stream_id: streamId, clients };
  const mutation = /* GraphQL */ `
    mutation addClientsForCuration($stream_id: ID!, $clients: [InvitationParamsType]!) {
      addClientsForCuration(stream_id: $stream_id, clients: $clients) {
        id
        name
        email
        avatar
        created_at
        updated_at
        primary_photo
        social_profiles {
          username
          url
          service
        }
        social_data_status
        pending
        stream_subscriptions {
          ${streamSubscriptionFields}
        }
      }
    }
  `;

  const { addClientsForCuration } = await railsApiClient.request(
    mutation,
    variables
  );

  return addClientsForCuration;
}
