import styled from "styled-components";
import { breakpoints, colors } from "@wrstudios/theme";

export const Container = styled.div`
  overflow: hidden;
`;

export const Grid = styled.div`
  display: flex;
  margin: -0.8rem;
`;

export const Cell = styled.div`
  padding: 0.8rem;
`;

const Text = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 1.5rem;
  }
`;

export const Name = styled(Text)`
  font-size: 700;
  color: ${colors.gray[900]};
`;

export const Company = styled(Text)`
  color: ${colors.gray[800]};
`;

export const Link = styled(Text)`
  color: ${colors.streams[500]};
`;
