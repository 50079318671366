import React from "react";
import PropTypes from "prop-types";
import { AriaMessage } from "@wrstudios/components";
import { Container, Pill } from "./styled/switch";

function Switch({ isOn, name, ariaMessage, onChange, ...rest }) {
  return (
    <Container isOn={isOn} onClick={() => onChange(!isOn)} {...rest}>
      {name && <input type="hidden" name={name} value={isOn ? "on" : "off"} />}
      <Pill isOn={isOn} />
      <AriaMessage live="polite">
        {ariaMessage && <>{ariaMessage}</>}
        {!ariaMessage && <>The switch is {isOn ? "On" : "Off"}</>}
      </AriaMessage>
    </Container>
  );
}

Switch.propTypes = {
  isOn: PropTypes.bool,
  name: PropTypes.string,
  ariaMessage: PropTypes.string,
  onChange: PropTypes.func
};

Switch.defaultProps = {
  isOn: false,
  ariaMessage: "",
  onChange: () => {}
};

export default Switch;
