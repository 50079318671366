export const initial = {
  name: "",
  initials: "",
  email: "",
  phone: "",
  phoneVerificationCode: "",
  city: "",
  state: "",
  company: "",
  website: "",
  license: "",
  timezone: "",
  avatar: "",
  mlsCode: "",
  mlsCredentialId: "",
  isMlsCredentialValid: false,
  mlsName: "",
  type: "",
  agents: [],
  activeAgentId: null,
  casJwt: null,
  isCASUser: false,
  isSSOUser: false,
  isAgent: false,
  isClient: false,
  isNewUser: false,
  primaryAgent: null,
  criteriaOnboarding: true, // true indicates that the criteria onboarding tooltip should be displayed
  isSubscribedToClientDigest: false,
  recommendedStreamItemIds: [], // isAgent only
  likedStreamItemIds: [], // isClient only
  userNetwork: { isFetching: false, hasFetched: false, errors: [] },
  profileNetwork: { isFetching: false, hasFetched: false, errors: [] },
  passwordNetwork: { isFetching: false, hasFetched: false, errors: [] },
  mlsNetwork: { isFetching: false, hasFetched: false, errors: [] },
  avatarNetwork: { isFetching: false },
  cancelNetwork: { isFetching: false },
  phoneVerifyNetwork: { isFetching: false, hasFetched: false, errors: [] },
  phoneCodeVerifyNetwork: { isFetching: false, hasFetched: false, errors: [] },
  invitation: null,
  isAcceptingInvitation: false,
  isImpersonating: false,
  acceptInvitationError: null
};

export function user(state = initial, { type, payload }) {
  switch (type) {
    case "LOGIN_SUCCEEDED":
      return {
        ...state,
        isImpersonating: payload.isImpersonating,
        isSSOUser: payload.isSSOUser
      };
    case "SET_INVITATION_ACCEPTANCE_CREDENTIALS":
      return { ...state, isNewUser: true, invitation: payload };
    case "ACCEPT_INVITATION_INITIATED":
      return { ...state, isAcceptingInvitation: true };
    case "ACCEPT_INVITATION_SUCCEEDED":
      return {
        ...state,
        name: payload.name,
        email: payload.email,
        initials: payload.initials,
        isNewUser: false,
        isAcceptingInvitation: false,
        acceptInvitationError: null
      };
    case "ACCEPT_INVITATION_FAILED":
      return {
        ...state,
        isAcceptingInvitation: false,
        acceptInvitationError: payload.message
      };
    case "ACCEPT_INVITATION_INVALID":
      return {
        ...state,
        isAcceptingInvitation: false,
        acceptInvitationError: payload
      };
    case "GET_CURRENT_USER_INITIATED":
      return {
        ...state,
        userNetwork: { isFetching: true, hasFetched: false, errors: [] }
      };
    case "GET_CURRENT_USER_SUCCEEDED":
      return {
        ...state,
        ...payload.user,
        mlsCode: payload.mls.code,
        mlsName: payload.mls.name,
        mlsCredentialId: payload.mls.credentialId,
        isMlsCredentialValid: payload.mls.isValid,
        userNetwork: { isFetching: false, hasFetched: true, errors: [] }
      };
    case "GET_CURRENT_USER_FAILED":
      return {
        ...state,
        userNetwork: {
          isFetching: false,
          hasFetched: false,
          errors: payload.errorsArray
        }
      };
    case "ADD_STREAM_ITEM_LIKE":
    case "REMOVE_STREAM_ITEM_LIKE":
      return { ...state, likedStreamItemIds: payload };
    case "UPDATE_USER_PROFILE_INITIATED":
      return {
        ...state,
        profileNetwork: { isFetching: true, hasFetched: false, errors: [] }
      };
    case "UPDATE_USER_PROFILE_FAILED":
      return {
        ...state,
        profileNetwork: {
          isFetching: false,
          hasFetched: false,
          errors: payload.errorsArray
        }
      };
    case "UPDATE_USER_PROFILE_SUCCEEDED":
      return {
        ...state,
        ...payload.user,
        profileNetwork: { isFetching: false, hasFetched: true, errors: [] }
      };
    case "RESET_USER_PROFILE_NETWORK":
      return {
        ...state,
        profileNetwork: { isFetching: false, hasFetched: false, errors: [] }
      };
    case "UPDATE_USER_PASSWORD_INITIATED":
      return {
        ...state,
        passwordNetwork: { isFetching: true, hasFetched: false, errors: [] }
      };
    case "UPDATE_USER_PASSWORD_FAILED":
      return {
        ...state,
        passwordNetwork: {
          isFetching: false,
          hasFetched: false,
          errors: payload.errorsArray
        }
      };
    case "UPDATE_USER_PASSWORD_SUCCEEDED":
      return {
        ...state,
        ...payload.user,
        passwordNetwork: { isFetching: false, hasFetched: true, errors: [] },
        isNewUser: false
      };
    case "RESET_USER_PASSWORD_NETWORK":
      return {
        ...state,
        passwordNetwork: { isFetching: false, hasFetched: false, errors: [] }
      };
    case "UPDATE_USER_MLS_INITIATED":
      return {
        ...state,
        mlsNetwork: { isFetching: true, hasFetched: false, errors: [] }
      };
    case "UPDATE_USER_MLS_FAILED":
      return {
        ...state,
        mlsNetwork: {
          isFetching: false,
          hasFetched: false,
          errors: payload.errorsArray
        }
      };
    case "UPDATE_USER_MLS_SUCCEEDED":
      return {
        ...state,
        mlsCode: payload.mls.code,
        mlsName: payload.mls.name,
        mlsCredentialId: payload.mls.credentialId,
        isMlsCredentialValid: payload.mls.isValid,
        mlsNetwork: { isFetching: false, hasFetched: true, errors: [] },
        likedStreamItemIds: initial.likedStreamItemIds
      };
    case "RESET_USER_MLS_NETWORK":
      return {
        ...state,
        mlsNetwork: { isFetching: false, hasFetched: false, errors: [] }
      };
    case "UPLOAD_USER_AVATAR_INITIATED":
      return { ...state, avatarNetwork: { isFetching: true } };
    case "UPLOAD_USER_AVATAR_FAILED":
      return { ...state, avatarNetwork: { isFetching: false } };
    case "UPLOAD_USER_AVATAR_SUCCEEDED":
      return {
        ...state,
        avatarNetwork: { isFetching: false },
        avatar: payload.avatar
      };
    case "UPDATE_CLIENT_DIGEST_SUBSCRIPTION_INITIATED":
    case "UPDATE_CLIENT_DIGEST_SUBSCRIPTION_SUCCEEDED":
      return { ...state, isSubscribedToClientDigest: payload };
    case "CANCEL_USER_INITIATED":
      return { ...state, cancelNetwork: { isFetching: true } };
    case "CANCEL_USER_FAILED":
    case "CANCEL_USER_SUCCEEDED":
      return { ...state, cancelNetwork: { isFetching: false } };
    case "SEND_USER_PHONE_VERIFICATION_CODE_INITIATED":
      return {
        ...state,
        phoneVerifyNetwork: { isFetching: true, hasFetched: false, errors: [] }
      };
    case "SEND_USER_PHONE_VERIFICATION_CODE_SUCCEEDED":
      return {
        ...state,
        phoneVerificationCode: payload,
        phoneVerifyNetwork: { isFetching: false, hasFetched: true, errors: [] }
      };
    case "SEND_USER_PHONE_VERIFICATION_CODE_FAILED":
      return {
        ...state,
        phoneVerifyNetwork: {
          isFetching: false,
          hasFetched: false,
          errors: payload.response.errors
        }
      };
    case "VERIFY_USER_PHONE_VERIFICATION_CODE_INITIATED":
      return {
        ...state,
        phoneCodeVerifyNetwork: {
          isFetching: true,
          hasFetched: false,
          errors: []
        }
      };
    case "VERIFY_USER_PHONE_VERIFICATION_CODE_SUCCEEDED":
      return {
        ...state,
        phoneVerificationCode: "",
        phone: payload,
        phoneCodeVerifyNetwork: {
          isFetching: false,
          hasFetched: true,
          errors: []
        }
      };
    case "VERIFY_USER_PHONE_VERIFICATION_CODE_FAILED":
      return {
        ...state,
        phoneCodeVerifyNetwork: {
          isFetching: false,
          hasFetched: false,
          errors: payload.response.errors
        }
      };
    case "RESET_USER_PHONE_VERIFICATION":
      return {
        ...state,
        phoneVerificationCode: "",
        phoneVerifyNetwork: {
          isFetching: false,
          hasFetched: false,
          errors: []
        },
        phoneCodeVerifyNetwork: {
          isFetching: false,
          hasFetched: false,
          errors: []
        }
      };
    case "GET_MINIMAL_STREAM_ITEM_SUCCEEDED":
      return { ...state, mlsCode: payload.streamItem.mlsCode };
    case "COMPLETE_CRITERIA_ONBOARDING_REQUEST":
      return { ...state, criteriaOnboarding: false }; // false indicates the user has completed this onboarding step
    case "UPDATE_ACTIVE_AGENT":
      return {
        ...state,
        activeAgentId: payload.agentId,
        mlsCode: payload.mlsCode
      };
    case "UPDATE_MLS_CODE":
      return { ...state, mlsCode: payload };
    case "CLEAR_API_TOKEN":
      return initial;
    default:
      return state;
  }
}
