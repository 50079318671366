/**
 * Maps a type to a more friendly type.
 *
 * @param {String} type
 */
export function getFriendlyType(type) {
  switch (type) {
    case "email":
      return "email";
    case "sms":
      return "text";
    case "update_daily":
      return "update";
    default:
      console.warn(`No friendly type for [${type}]`);
      return "";
  }
}
