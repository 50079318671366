export const streamSubscriptionFields = /* GraphQL */ `
  id
  stream_id
  user_id
  email_frequency
  sms_alert_type
  user {
    id
    type
    name
    email
    avatar
    primary_photo
    social_profiles {
      username
      url
      service
    }
    social_data_status
  }
`;
