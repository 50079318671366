import { connect } from "react-redux";
import { getShowingTimeUrl } from "../../../../actions/listing";
import { isShowingTimeAvailable } from "showing-time-client";
import ListingRoute from "../ListingRoute";

function select({ mls, listings, user }, { match }) {
  const listingId = match.params.listingId;
  const listing = listings.byId[listingId] || {};
  const hasListing = !!Object.values(listing).length;
  const hasFetched = listings.getListingNetwork.hasFetched || hasListing;

  return {
    listingId,
    hasListing,
    isFetching: listings.getListingNetwork.isFetching && !hasListing,
    hasErrors: !!listings.getListingNetwork.errors.length,
    notFound: hasFetched && !hasListing,
    mlsConfigsLoaded: !!mls.photoProxyStrategy,
    isListingActive: listing.statusValue === "active",
    isShowingTimeAvailable: isShowingTimeAvailable(user.mlsCode)
  };
}

const actions = {
  getShowingTimeUrl
};

export default connect(select, actions)(ListingRoute);
