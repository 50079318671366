import { connect } from "react-redux";
import {
  setStreamIsCreating,
  setStreamFilters
} from "../../../../actions/stream";
import { parseFiltersFromParams } from "../../../../modules/stream";
import StreamRouteNew from "../StreamRouteNew";

function select({ stream, user }, { location }) {
  const hasFilters = !!Object.values(stream.filters.byId).length;
  const filtersFromParams = parseFiltersFromParams(location.search);
  const hasFiltersFromParams = !!Object.keys(filtersFromParams).length;

  return {
    newStreamId: stream.newStreamId || "",
    hasNewStreamId: !!stream.newStreamId,
    filters: stream.filters.byId,
    hasFilters,
    isCreating: stream.isCreating,
    hasSaved: stream.hasSaved,
    isNewUser: user.isNewUser || false,
    filtersFromParams,
    hasFiltersFromParams
  };
}

const actions = {
  setStreamFilters,
  setStreamIsCreating
};

export default connect(select, actions)(StreamRouteNew);
