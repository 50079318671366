import { connect } from "react-redux";
import { logout } from "../../../../../actions/app";
import { cancelUserAccount } from "../../../../../actions/user";
import SettingsRouteBillingIndex from "../SettingsRouteBillingIndex";

function select({
  user: {
    lwaId,
    lwaJwt,
    isCASUser,
    cancelNetwork: { isFetching }
  },
  billing: {
    name,
    amountFormatted,
    nextRenewalFormatted,
    cardNumber,
    cardExpiration,
    billingId,
    state
  }
}) {
  return {
    hasLwaId: !!lwaId,
    lwaJwt,
    isCASUser,
    name,
    amountFormatted,
    nextRenewalFormatted: nextRenewalFormatted || "N/A",
    cardNumber: cardNumber || "N/A",
    cardExpiration: cardExpiration || "N/A",
    billingId: billingId || "N/A",
    state,
    isFetching
  };
}

const actions = {
  cancelUserAccount,
  logout
};

export default connect(select, actions)(SettingsRouteBillingIndex);
