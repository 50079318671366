/**
 * Gets the filtered items to show based on if we
 * can search or if it's a multi select dropdown.
 *
 * @param {Array} items
 * @param {Object} selectedItems
 * @param {String} inputValue
 * @param {Boolean} isSearchable
 * @param {Boolean} isMulti
 * @param {Boolean} isTypeahead
 */
export function getFilteredItems(
  items,
  selectedItemValues,
  inputValue,
  isSearchable,
  isMulti,
  isTypeahead
) {
  return items.filter((item) => {
    if ((!isSearchable && !isMulti) || isTypeahead) return true;

    const includesMultipleSearchItems = selectedItemValues.includes(item);
    const includesSearchItem = item
      .toLowerCase()
      .includes(inputValue.toLowerCase());

    if (isSearchable && !isMulti) return !inputValue || includesSearchItem;
    if (!isSearchable && isMulti) return !includesMultipleSearchItems;

    return includesSearchItem && !includesMultipleSearchItems;
  });
}

/**
 * Finds an option by label.
 *
 * @param {String} label
 * @param {Array} options
 * @param {String} labelKey
 */
export function findOptionByLabel(label, options = [], labelKey = "label") {
  return options.find((option) => option[labelKey] === label);
}

/**
 * Finds an option by value.
 *
 * @param {String} value
 * @param {Array} options
 * @param {String} labelKey
 */
export function findOptionByValue(value, options = [], valueKey = "value") {
  return options.find((option) => option[valueKey] === value);
}
