import { Modal } from "@wrstudios/components";
import PropTypes from "prop-types";
import React, { useState } from "react";
import UserAgent from "../user/UserAgent";
import ListingMiniCard from "./ListingMiniCard";
import {
  Error,
  FooterAction,
  Form,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalContent,
  ModalHeader,
  Request,
  SendRequest,
  BackToListing,
  Text,
  Textarea
} from "./styled/listing-request-showing";

function ListingRequestShowing({
  streamItemId,
  listing,
  user,
  network,
  requestShowing,
  resetRequestShowing
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [phone, setPhone] = useState(user.phone || "");
  const [message, setMessage] = useState("");
  const [agent] = user.agents || [];

  const handleSubmit = (e) => {
    e.preventDefault();
    requestShowing({ streamItemId, listingId: listing.id, phone, message });
  };

  const handleClose = () => {
    setIsOpen(false);
    resetRequestShowing();
  };

  return (
    <>
      <Request onClick={() => setIsOpen(true)}>Request a Showing</Request>
      {isOpen && (
        <Modal onClose={handleClose}>
          {!network.hasFetched && (
            <ModalContent>
              <Form onSubmit={handleSubmit}>
                <ModalHeader>
                  Request a Showing
                  <Modal.Close />
                </ModalHeader>
                <ModalBody>
                  <FormGroup>
                    <ListingMiniCard listing={listing} />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="phone">Your Mobile Phone</Label>
                    <Input
                      id="phone"
                      placeholder="555-555-5555"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="message">Message</Label>
                    <Textarea
                      id="message"
                      rows={5}
                      placeholder="When would be your preferred times to see this property? Any other thoughts or comments?"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </FormGroup>
                </ModalBody>
                <Modal.Footer>
                  <FooterAction>
                    <SendRequest disabled={network.isFetching}>
                      {!network.isFetching ? "Send Request" : "Sending Request"}
                    </SendRequest>
                  </FooterAction>
                  {network.error && <Error>{network.error}</Error>}
                </Modal.Footer>
              </Form>
            </ModalContent>
          )}
          {network.hasFetched && (
            <ModalContent>
              <ModalHeader>
                Request Sent
                <Modal.Close />
              </ModalHeader>
              <ModalBody>
                <ListingMiniCard listing={listing} />
                <Text>
                  Thank you for for requesting a showing. Expect to hear from me
                  soon. If you need to get in touch sooner, feel free to contact
                  me:
                </Text>
                {agent && <UserAgent agent={agent} />}
              </ModalBody>
              <Modal.Footer>
                <BackToListing variant="secondary" onClick={handleClose}>
                  Back to Listing
                </BackToListing>
              </Modal.Footer>
            </ModalContent>
          )}
        </Modal>
      )}
    </>
  );
}

ListingRequestShowing.propTypes = {
  streamItemId: PropTypes.string.isRequired,
  listing: PropTypes.object.isRequired,
  user: PropTypes.shape({ phone: PropTypes.string.isRequired }),
  network: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    hasFetched: PropTypes.bool.isRequired,
    error: PropTypes.string
  }).isRequired,
  requestShowing: PropTypes.func.isRequired,
  resetRequestShowing: PropTypes.func.isRequired
};

export default ListingRequestShowing;
