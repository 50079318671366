import React from "react";
import { RouteContainer } from "@wrstudios/components";
import StreamCardsSearch from "../../stream/connected/StreamCardsSearch";
import StreamCardsSort from "../../stream/connected/StreamCardsSort";
import StreamCards from "../../stream/connected/StreamCards";
import {
  Header,
  Controls,
  Search,
  Sort,
  Title,
  Body
} from "./styled/streams-route";

function StreamsRoute() {
  return (
    <RouteContainer>
      <Header>
        <Title>Streams</Title>
        <Controls>
          <Search>
            <StreamCardsSearch />
          </Search>
          <Sort>
            <StreamCardsSort />
          </Sort>
        </Controls>
      </Header>
      <Body>
        <StreamCards />
      </Body>
    </RouteContainer>
  );
}

export default StreamsRoute;
