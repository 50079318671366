import React, { Component } from "react";
import PropTypes from "prop-types";
import { LoadingSpinner } from "@wrstudios/components";
import { Container, Select } from "./styled/stream-name";

class StreamName extends Component {
  state = {
    value: ""
  };

  render() {
    const {
      name,
      hasName,
      autocomplete,
      isMapShowing,
      isLoading,
      isSaving
    } = this.props;

    return (
      <Container isMapShowing={isMapShowing} onSubmit={this.handleOnSubmit}>
        <Select
          key={name || autocomplete}
          value={name}
          items={autocomplete}
          disabled={isLoading || isSaving}
          hasName={hasName}
          placeholder="Name this Stream"
          persistInputValue={true}
          arrows={false}
          typeahead
          onChange={this.handleOnChange}
          onSelect={this.handleOnSelect}
          onBlur={this.handleNameChange}
        />
        {isSaving && <LoadingSpinner />}
      </Container>
    );
  }

  handleOnSubmit = (e) => {
    e.preventDefault();

    this.handleNameChange();
  };

  handleOnSelect = (value) => {
    this.setState({ value }, () => {
      this.props.createStream(this.state.value);
    });
  };

  handleOnChange = (value) => {
    this.setState({ value });
  };

  handleOnSelect = (value) => {
    this.setState({ value }, () => {
      this.handleNameChange();
    });
  };

  handleNameChange = () => {
    const {
      streamId,
      name,
      filters,
      hasFilters,
      hasStream,
      renameStream,
      createStream
    } = this.props;
    const { value } = this.state;

    if (!value || !hasFilters || value === name) return;

    hasStream
      ? renameStream({ id: streamId, name: value })
      : createStream({ name: value, filter: filters });
  };
}

StreamName.propTypes = {
  streamId: PropTypes.string,
  hasStream: PropTypes.bool.isRequired,
  filters: PropTypes.array.isRequired,
  hasFilters: PropTypes.bool.isRequired,
  autocomplete: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  hasName: PropTypes.bool.isRequired,
  isMapShowing: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  createStream: PropTypes.func.isRequired,
  renameStream: PropTypes.func.isRequired
};

export default StreamName;
