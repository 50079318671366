import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
  left: 0;
`;

export const Dialog = styled.div`
  ${({ theme }) => theme.layers.modal};
  max-width: 50rem;
  padding: 2rem;
  outline: none;
`;

export const Title = styled.h3`
  ${({ theme }) => theme.text.h3};
  margin-bottom: 2rem;
`;

export const Body = styled.div``;
