import { connect } from "react-redux";
import { buildStreamItemShareUrl } from "../../../modules/share";
import StreamItemShare from "../StreamItemShare";

function select({ streamItems, listings }, { streamItemId }) {
  const streamItem = streamItems.byId[streamItemId] || {};
  const listing = listings.byId[streamItem.itemId] || {};

  return {
    listingAddress: listing.addressFormatted || "",
    streamItemUrl: buildStreamItemShareUrl(streamItem.id)
  };
}

export default connect(select)(StreamItemShare);
