import styled from "styled-components";
import { default as BaseFlash } from "../../../../common/Flash";
import { Button } from "../../styled/settings-route";

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;

  ${Button} {
    margin: 0 0 0 1rem;
  }
`;

export const Flash = styled(BaseFlash)`
  margin: 1.5rem 0 0;
`;
