import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ isScrollable }) =>
    !isScrollable &&
    css`
      height: 100vh;
      overflow: hidden;
    `}
`;

export const Main = styled.main`
  flex-grow: 1;
`;
