import { connect } from "react-redux";
import { getListingsFromForge } from "../../../actions/listing";
import { countFilters } from "../../../modules/stream";
import StreamItems from "../StreamItems";

function select({
  stream,
  mls: { photoProxyStrategy },
  listings: { isLoading }
}) {
  const filtersArray = Object.values(stream.filters.byId);
  const hasFilters = !!filtersArray.length;
  const mlsConfigsLoaded = !!photoProxyStrategy;
  const count = stream.isEditingCriteria
    ? stream.listingsCount
    : stream.itemsCount;
  const hasMultiplePages = count > stream.limit;
  const showListings = countFilters(stream.filters.byId) > 0;
  const filteredStreamItemIds = showListings
    ? stream.filteredStreamItemIds
    : [];
  const filteredListingIds = showListings ? stream.filteredListingIds : [];

  return {
    isLoading,
    filters: filtersArray,
    filteredStreamItemIds,
    filteredListingIds,
    page: stream.page,
    limit: stream.limit,
    filter: stream.filter,
    hasFilters,
    hasMultiplePages,
    isEditingCriteria: stream.isEditingCriteria,
    isMapShowing: stream.isMapShowing,
    mlsConfigsLoaded
  };
}

const actions = {
  getListingsFromForge
};

export default connect(select, actions)(StreamItems);
