import { connect } from "react-redux";
import StreamItemsLoading from "../StreamItemsLoading";

function select({ stream: { isCreating, isEditingCriteria, isMapShowing } }) {
  return {
    isCreating,
    isEditingCriteria,
    isMapShowing
  };
}

export default connect(select)(StreamItemsLoading);
