import { connect } from "react-redux";
import StreamItemHides from "../StreamItemHides";

function select({ streamItems, user }, { streamItemId }) {
  const streamItem = streamItems.byId[streamItemId] || {};
  const hiders = streamItem.hiders || [];
  const hides = hiders
    .filter((hider) => hider.id !== user.id)
    .map((hider) => hider.name);

  return {
    hides,
    isHidden: !hides.length
  };
}

export default connect(select)(StreamItemHides);
