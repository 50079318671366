import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "@wrstudios/components";
import Input from "../../common/Input";
import Label from "../../common/Label";
import IconError from "../../icons/IconError";
import IconCheckCircleOutline from "../../icons/IconCheckCircleOutline";
import IconArrowLeft from "../../icons/IconArrowLeft";
import IconCloudStreamsVertical from "../../icons/IconCloudStreamsVertical";
import IconArrowRightLong from "../../icons/IconArrowRightLong";
import IconCASHorizontal from "../../icons/IconCASHorizontal";
import {
  Container,
  Header,
  CASLink,
  Arrow,
  CASLogo,
  LearnMore,
  Logo,
  Content,
  Title,
  Text,
  Form,
  FormGroup,
  Error,
  Success,
  SubmitContainer,
  Submit,
  Reset,
  Nav,
  NavItems,
  NavItem
} from "./styled/login-route";

function RequestPasswordResetRoute({
  isRequestingPasswordReset,
  requestPasswordResetSuccess,
  requestPasswordResetError,
  requestPasswordReset,
  emitAction
}) {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    requestPasswordReset(email);
  };

  useEffect(() => {
    emitAction({ type: "RESET_REQUEST_PASSWORD_TO_DEFAULT" });
  }, [emitAction]);

  return (
    <Container>
      <Header>
        <CASLink
          href="https://cloudagentsuite.com/"
          target="_blank"
          rel="noopener noreferrer">
          <Arrow>
            <IconArrowLeft />
          </Arrow>
          <CASLogo>
            <IconCASHorizontal />
          </CASLogo>
        </CASLink>
        <LearnMore
          href="https://cloudagentsuite.com/tools/streams"
          target="_blank"
          rel="noopener noreferrer">
          Learn more about Cloud Streams
          <IconArrowRightLong />
        </LearnMore>
      </Header>
      <Content style={{ maxWidth: "40rem" }}>
        <Logo>
          <IconCloudStreamsVertical />
        </Logo>
        <Form onSubmit={handleSubmit}>
          <Title>Need your password reset?</Title>
          <Text>
            Please enter your email and we’ll send you instructions on how to
            reset your password
          </Text>
          <FormGroup>
            <Label htmlFor="email">Email</Label>
            <Input
              type="email"
              id="email"
              placeholder="john@acmerealty.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
          {requestPasswordResetSuccess && (
            <FormGroup>
              <Success role="alert">
                <IconCheckCircleOutline />
                Sent! Check your email for instructions.
              </Success>
            </FormGroup>
          )}
          {requestPasswordResetError && (
            <FormGroup>
              <Error role="alert">
                <IconError />
                {requestPasswordResetError}
              </Error>
            </FormGroup>
          )}
          <SubmitContainer>
            <Submit disabled={isRequestingPasswordReset}>
              {isRequestingPasswordReset
                ? "Emailing Instructions..."
                : "Email Instructions"}
            </Submit>
          </SubmitContainer>
        </Form>
        <Reset>
          <Link to="/login">Return to Login</Link>
        </Reset>
      </Content>
      <Nav>
        <NavItems>
          <NavItem>
            <a
              href="https://cloudagentsuite.com/tos"
              target="_blank"
              rel="noopener noreferrer">
              Terms of Service
            </a>
          </NavItem>
          <NavItem>
            <a
              href="https://www.wrstudios.com/privacy_policy"
              target="_blank"
              rel="noopener noreferrer">
              Privacy
            </a>
          </NavItem>
          <NavItem>
            <a
              href="https://www.wrstudios.com/dmca_policy"
              target="_blank"
              rel="noopener noreferrer">
              DMCA
            </a>
          </NavItem>
        </NavItems>
      </Nav>
    </Container>
  );
}

RequestPasswordResetRoute.propTypes = {
  emitAction: PropTypes.func.isRequired,
  requestPasswordReset: PropTypes.func.isRequired,
  isRequestingPasswordReset: PropTypes.bool.isRequired,
  requestPasswordResetSuccess: PropTypes.bool.isRequired,
  requestPasswordResetError: PropTypes.string
};

export default RequestPasswordResetRoute;
