import React from "react";
import PropTypes from "prop-types";
import { resetScrollPosition } from "@wrstudios/utils";
import Pagination from "../common/Pagination";

function StreamItemsPagination({
  streamId,
  count,
  hasItems,
  filters,
  page,
  limit,
  filter,
  isEditingCriteria,
  isLoading,
  getStreamItems,
  getListingsFromForge
}) {
  return (
    <Pagination
      isVisible={hasItems && !isLoading}
      pageSize={limit}
      totalCount={count}
      currentPage={page}
      handleSelectSize={(size) => {
        isEditingCriteria
          ? getListingsFromForge({ filter: filters, page, limit: Number(size) })
          : getStreamItems({ streamId, page, limit: Number(size), filter });
        resetScrollPosition();
      }}
      handleClickPage={(page) => {
        isEditingCriteria
          ? getListingsFromForge({ filter: filters, page, limit })
          : getStreamItems({ streamId, page, limit, filter });
        resetScrollPosition();
      }}
    />
  );
}

StreamItemsPagination.propTypes = {
  streamId: PropTypes.string,
  count: PropTypes.number.isRequired,
  hasItems: PropTypes.bool.isRequired,
  filters: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  filter: PropTypes.oneOf([
    "all",
    "recommended",
    "liked",
    "commented",
    "hidden"
  ]),
  isEditingCriteria: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getStreamItems: PropTypes.func.isRequired,
  getListingsFromForge: PropTypes.func.isRequired
};

export default StreamItemsPagination;
