import { connect } from "react-redux";
import StreamInvitees from "../StreamInvitees";

function select({ subscriptions, invitations, user }, { streamId }) {
  const subscriptionIds = Object.values(subscriptions.byId)
    .filter((s) => s.userId !== user.id && s.streamId === streamId)
    .map((s) => s.id);

  const invitationIds = Object.values(invitations.byId)
    .filter((invitation) => invitation.streamId === streamId)
    .map(({ id }) => id);

  return {
    subscriptionIds,
    invitationIds
  };
}

export default connect(select)(StreamInvitees);
