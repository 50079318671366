import styled from "styled-components";
import { Link } from "@wrstudios/components";

export const Container = styled(Link)`
  display: block;
  position: relative;
`;

export const Thumbnail = styled.div`
  width: 6.8rem;
  height: 4.8rem;
  background: ${({ theme }) => theme.colors.greyLighter} center center / cover;
`;

export const RemainingCount = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.2rem;
  padding: 0.2rem 0.3rem;
  background: rgba(0, 0, 0, 0.5);
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  bottom: 0;
  right: 0;
`;
