export function loadExternalLibraries() {
  loadGoogleAnalytics();
  loadWistia();
}

export function setShowIntercom(boolean) {
  if (!window.Intercom) {
    return;
  }
  window.setImmediate(() =>
    window.Intercom("update", { hide_default_launcher: !boolean })
  );
}

export function loadIntercom() {
  if (process.env.REACT_APP_API_ENV !== "production") {
    return;
  }

  const appId = "sl5gz67c";
  const intercom = window.Intercom;

  window.intercomSettings = {
    app_id: appId,
    hide_default_launcher: true
  };

  if (typeof intercom === "function") {
    intercom("reattach_activator");
    intercom("update", window.intercomSettings);
  } else {
    let newIntercom = () => {
      newIntercom.c(arguments);
    };
    newIntercom.q = [];
    newIntercom.c = (args) => {
      newIntercom.q.push(args);
    };
    window.Intercom = newIntercom;

    const loadScript = () => {
      const script = window.document.createElement("script");
      script.setAttribute("src", `https://widget.intercom.io/widget/${appId}`);
      window.document.head.appendChild(script);
    };

    if (window.document.readyState === "complete") {
      loadScript();
    } else if (window.attachEvent) {
      window.attachEvent("onload", loadScript);
    } else {
      window.addEventListener("load", loadScript, false);
    }
  }
}

function loadGoogleAnalytics() {
  if (process.env.NODE_ENV !== "production") {
    return;
  }

  const script1 = window.document.createElement("script");
  script1.innerHTML = `
    window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
    ga('create', 'UA-48321784-1', 'auto');
    ga('send', 'pageview');
  `;

  const script2 = window.document.createElement("script");
  script2.setAttribute("src", "https://www.google-analytics.com/analytics.js");
  script2.async = true;

  window.document.head.appendChild(script1);
  window.document.head.appendChild(script2);
}

function loadWistia() {
  const script = window.document.createElement("script");
  script.setAttribute("src", "https://fast.wistia.com/assets/external/E-v1.js");
  window.document.head.appendChild(script);
}
