import { breakpoints } from "@wrstudios/theme";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import Media from "react-media";
import ResourceLoader from "../../common/ResourceLoader";
import StreamItems from "../../stream/connected/StreamItems";
import StreamMap from "../../stream/connected/StreamMap";
import StreamMapToggle from "../../stream/connected/StreamMapToggle";
import StreamModal from "../../stream/connected/StreamModal";
import StreamName from "../../stream/connected/StreamName";
import StreamSignupModal from "../../stream/connected/StreamSignupModal";
import StreamTabs from "../../stream/connected/StreamTabs";
import StreamFilters from "../../stream/filters/connected/StreamFilters";
import StreamFiltersOverlay from "../../stream/filters/connected/StreamFiltersOverlay";
import StreamInvite from "../../stream/invite/connected/StreamInvite";
import {
  Container,
  ContentContainer,
  Controls,
  Filters,
  Header,
  MobileHeader,
  NameCell,
  NameGrid,
  StreamInviteLayout
} from "./styled/stream-route";

function StreamRoute({
  streamId,
  streamMlsCode,
  notFound,
  hasErrors,
  isFetching,
  hasFetched,
  isMapShowing,
  isNewUser,
  isShowingFilters,
  isEditingCriteria,
  setAppHeaderSticky,
  mlsConfigsLoaded,
  getMinimalStream,
  resetStream,
  getStream,
  updateMlsCode
}) {
  useEffect(() => {
    setAppHeaderSticky(true);
    return () => {
      setAppHeaderSticky(false);
      resetStream();
    };
  }, [resetStream, setAppHeaderSticky]);

  useEffect(() => {
    if (mlsConfigsLoaded) {
      if (isNewUser) {
        getMinimalStream(streamId);
      } else {
        getStream(streamId);
      }
    }
  }, [getMinimalStream, getStream, isNewUser, mlsConfigsLoaded, streamId]);

  useEffect(() => {
    if (streamMlsCode) {
      updateMlsCode(streamMlsCode);
    }
  }, [streamMlsCode, updateMlsCode]);

  const filtersRef = useRef(null);
  return (
    <ResourceLoader
      isLoading={!hasFetched || !mlsConfigsLoaded}
      notFound={notFound}
      hasError={hasErrors}>
      {isNewUser && <StreamSignupModal />}
      <StreamModal streamId={streamId} />
      <Filters ref={filtersRef}>
        <StreamFilters streamId={streamId} />
      </Filters>
      <Container isShowingFilters={isShowingFilters}>
        <ContentContainer
          isShowingFilters={isShowingFilters}
          isMapShowing={isMapShowing}
          isEditingCriteria={isEditingCriteria}>
          <Media query={`(max-width: ${breakpoints.sm}px)`}>
            {(isMobile) =>
              isMobile ? (
                <MobileHeader>
                  <StreamName streamId={streamId} />
                  <StreamInviteLayout>
                    <StreamInvite streamId={streamId} />
                  </StreamInviteLayout>
                  <StreamTabs streamId={streamId} />
                </MobileHeader>
              ) : (
                <Header>
                  <NameGrid>
                    <NameCell>
                      <StreamName streamId={streamId} />
                    </NameCell>
                    <NameCell>
                      <StreamMapToggle />
                    </NameCell>
                  </NameGrid>
                  <Controls>
                    <StreamTabs streamId={streamId} />
                    <StreamInvite streamId={streamId} />
                  </Controls>
                </Header>
              )
            }
          </Media>
          <StreamItems streamId={streamId} columns={isMapShowing ? 2 : 3} />
          {isShowingFilters && <StreamFiltersOverlay />}
        </ContentContainer>
        <StreamMap />
      </Container>
    </ResourceLoader>
  );
}

StreamRoute.propTypes = {
  streamId: PropTypes.string.isRequired,
  streamMlsCode: PropTypes.string.isRequired,
  setAppHeaderSticky: PropTypes.func.isRequired,
  isShowingFilters: PropTypes.bool.isRequired,
  isEditingCriteria: PropTypes.bool.isRequired,
  isNewUser: PropTypes.bool.isRequired,
  isMapShowing: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  notFound: PropTypes.bool.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  shouldGetStreamData: PropTypes.bool.isRequired,
  mlsConfigsLoaded: PropTypes.bool.isRequired,
  resetStream: PropTypes.func.isRequired,
  getStream: PropTypes.func.isRequired,
  getMinimalStream: PropTypes.func.isRequired,
  updateMlsCode: PropTypes.func.isRequired
};

export default StreamRoute;
