import styled from "styled-components";

export const Container = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.page.twoColumn}px) {
  }
`;

export const Title = styled.h2`
  ${({ theme }) => theme.text.h2};
  margin-bottom: 2rem;
`;

export const SubTitle = styled.h4`
  ${({ theme }) => theme.text.h4};
  margin-bottom: 1.5rem;
`;

export const Underlined = styled.div`
  text-decoration: underline;
  font-style: italic;
  margin-bottom: 1rem;
`;

export const Paragraph = styled.div`
  margin-bottom: ${({ isLast }) => (isLast ? 4 : 1.5)}rem;
`;
