import { connect } from "react-redux";
import { setAppHeaderSticky } from "../../../../actions/app";
import {
  resetStream,
  getStream,
  getMinimalStream
} from "../../../../actions/stream";
import { updateMlsCode } from "../../../../actions/user";
import StreamRoute from "../StreamRoute";

function select({ streams, stream, user, mls }, { match }) {
  const streamId = match.params.streamId;
  const foundStream = streams.byId[match.params.streamId] || {};
  const hasStream = !!Object.values(foundStream).length;
  const isFetching = stream.getStreamNetwork.isFetching && !hasStream;
  const hasFetched = stream.getStreamNetwork.hasFetched || hasStream;
  const hasErrors = !!stream.getStreamNetwork.errors.length;
  const notFound = hasFetched && !hasStream;
  const shouldGetStreamData = stream.currentStreamId !== streamId;
  const mlsConfigsLoaded = !!mls.photoProxyStrategy;
  const hasSubscription = (foundStream.subscriptionIds || []).length > 0;

  return {
    streamId,
    isFetching,
    notFound,
    hasErrors,
    shouldGetStreamData,
    mlsConfigsLoaded,
    hasSubscription,
    hasFetched,
    streamMlsCode: foundStream.mlsCode || "",
    isNewUser: user.isNewUser || false,
    isMapShowing: stream.isMapShowing || false,
    isShowingFilters: stream.isShowingFilters || false,
    isEditingCriteria: stream.isEditingCriteria || false
  };
}

const actions = {
  resetStream,
  getStream,
  getMinimalStream,
  setAppHeaderSticky,
  updateMlsCode
};

export default connect(select, actions)(StreamRoute);
