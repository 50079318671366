import React from "react";
import PropTypes from "prop-types";
import IconCloud from "../icons/IconCloud";
import { Container, Text, Icon } from "./styled/cas-sso";

function CasSSO({ product, children, ...props }) {
  return (
    <Container
      {...props}
      href={`${process.env.REACT_APP_CAS_URL}/app/external_login/${product}`}>
      <Icon>
        <IconCloud />
      </Icon>
      <Text>Sign in with Cloud Agent Suite</Text>
    </Container>
  );
}

CasSSO.defaultProps = {
  product: "cloud_streams"
};

CasSSO.propTypes = {
  product: PropTypes.oneOf([
    "cloud_mlx",
    "cloud_cma",
    "cloud_streams",
    "cloud_attract"
  ])
};

export default CasSSO;
