import React from "react";

function IconClose(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5303 0.46967C0.823193 0.176777 1.29807 0.176777 1.59096 0.46967L7.99997 6.87868L14.409 0.46967C14.7019 0.176777 15.1767 0.176777 15.4696 0.46967C15.7625 0.762563 15.7625 1.23744 15.4696 1.53033L9.06063 7.93934L15.5303 14.409C15.8232 14.7019 15.8232 15.1768 15.5303 15.4697C15.2374 15.7626 14.7625 15.7626 14.4696 15.4697L7.99997 9L1.5303 15.4697C1.23741 15.7626 0.762533 15.7626 0.469639 15.4697C0.176746 15.1768 0.176746 14.7019 0.469639 14.409L6.93931 7.93934L0.5303 1.53033C0.237407 1.23744 0.237407 0.762563 0.5303 0.46967Z"
        fill="#0A5C39"
      />
    </svg>
  );
}

export default IconClose;
