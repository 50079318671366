import React from "react";
import { RouteContainer } from "@wrstudios/components";
import ClientCardsSearch from "../../client/connected/ClientCardsSearch";
import ClientCardsSort from "../../client/connected/ClientCardsSort";
import ClientCards from "../../client/connected/ClientCards";
import {
  Header,
  Controls,
  Search,
  Sort,
  Title,
  Body
} from "./styled/clients-route";

function ClientsRoute() {
  return (
    <RouteContainer>
      <Header>
        <Title>Clients</Title>
        <Controls>
          <Search>
            <ClientCardsSearch />
          </Search>
          <Sort>
            <ClientCardsSort />
          </Sort>
        </Controls>
      </Header>
      <Body>
        <ClientCards />
      </Body>
    </RouteContainer>
  );
}

export default ClientsRoute;
