import React, { Component } from "react";
import PropTypes from "prop-types";
import ResourceLoader from "../../common/ResourceLoader";
import Listing from "../../listing/connected/Listing";

class PublicStreamItemRoute extends Component {
  render() {
    return (
      <ResourceLoader
        isLoading={this.props.isFetching}
        notFound={this.props.notFound}
        hasError={this.props.hasErrors}>
        {/* Only one type for now. */}
        {this.props.type === "Listing" && (
          <Listing
            streamItemId={this.props.streamItemId}
            listingId={this.props.itemId}
          />
        )}
      </ResourceLoader>
    );
  }

  componentDidMount() {
    this.props.getPublicStreamItem({ streamItemId: this.props.streamItemId });
  }
}

PublicStreamItemRoute.propTypes = {
  streamItemId: PropTypes.string.isRequired,
  itemId: PropTypes.string,
  type: PropTypes.string,
  hasMlsCode: PropTypes.bool.isRequired,
  mlsConfigsLoaded: PropTypes.bool.isRequired,
  isAuthedForge: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  notFound: PropTypes.bool.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  getPublicStreamItem: PropTypes.func.isRequired
};

export default PublicStreamItemRoute;
