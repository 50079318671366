import React from "react";

function IconFavoriteCircle(props) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <circle fillOpacity=".1" fill="#EC5252" cx="24" cy="24" r="24" />
        <path d="M10 10h28v28H10z" />
        <path
          d="M23.9 35l-1.581-1.433C16.706 28.47 13 25.109 13 20.995 13 17.632 15.632 15 18.995 15c1.896 0 3.716.883 4.904 2.272C25.087 15.882 26.907 15 28.804 15c3.362 0 5.994 2.632 5.994 5.995 0 4.114-3.705 7.476-9.318 12.572L23.9 35z"
          stroke="#EC5252"
          fill="#EC5252"
        />
      </g>
    </svg>
  );
}

export default IconFavoriteCircle;
