import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import SettingsRouteMLS from "./connected/SettingsRouteMLS";
import SettingsRouteProfile from "./connected/SettingsRouteProfile";
import SettingsRoutePassword from "./connected/SettingsRoutePassword";
import SettingsRouteBillingIndex from "./billing/connected/SettingsRouteBillingIndex";
import SettingsRouteBillingEdit from "./billing/connected/SettingsRouteBillingEdit";
import {
  RouteContainer,
  Header,
  Title,
  Invalid,
  Body,
  Navigation,
  Link,
  ExternalLink
} from "./styled/settings-route";

function SettingsRoute({
  lwaJwt,
  hasLwaId,
  isAgent,
  isProfileRoute,
  isMlsCredentialValid,
  isSSOUser
}) {
  return (
    <RouteContainer small={!isProfileRoute}>
      <Header>
        <Title>Account Settings</Title>
        {!isMlsCredentialValid && (
          <Invalid>
            <div>Your MLS credentials are invalid,</div>
            <div>please update them below.</div>
          </Invalid>
        )}
        <Navigation>
          <Link to="/settings" exact>
            Profile
          </Link>
          <Link to="/settings/password">Password</Link>
          {isAgent && !isSSOUser && <Link to="/settings/mls">MLS</Link>}
          {isAgent &&
            (hasLwaId && lwaJwt ? (
              <ExternalLink
                href={`${process.env.REACT_APP_LW_AGENT_URL}/billing?jwt=${lwaJwt}`}>
                Billing
              </ExternalLink>
            ) : (
              <Link to="/settings/billing">Billing</Link>
            ))}
        </Navigation>
      </Header>
      <Body>
        <Route component={SettingsRouteProfile} exact path="/settings" />
        <Route
          component={SettingsRoutePassword}
          exact
          path="/settings/password"
        />
        <Route component={SettingsRouteMLS} exact path="/settings/mls" />
        <Route
          component={SettingsRouteBillingIndex}
          exact
          path="/settings/billing"
        />
        <Route
          component={SettingsRouteBillingEdit}
          exact
          path="/settings/billing/edit"
        />
      </Body>
    </RouteContainer>
  );
}

SettingsRoute.propTypes = {
  lwaJwt: PropTypes.string,
  userLwaId: PropTypes.string,
  isAgent: PropTypes.bool.isRequired,
  isProfileRoute: PropTypes.bool.isRequired,
  isMlsCredentialValid: PropTypes.bool.isRequired
};

export default SettingsRoute;
