import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  width: 100%;
  min-height: 12.9rem;
  z-index: 10;
  top: 1.2rem;
`;

export const BodyLayout = styled.div`
  display: flex;
  align-items: center;
`;

export const DummyAvatar = styled.div`
  min-width: 6rem;
  min-height: 6rem;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.greyLighter};
`;

export const InfoLayout = styled.div`
  width: 100%;
  margin-left: 1rem;
`;

export const DummyName = styled.div`
  max-width: 50%;
  min-height: 2rem;
  margin-bottom: 0.4rem;
  background-color: ${({ theme }) => theme.colors.greyLighter};
`;

export const DummyInfo = styled.div`
  max-width: 40%;
  min-height: 1.7rem;
  background-color: ${({ theme }) => theme.colors.greyLighter};
`;

export const DummyIcon = styled.div`
  margin-left: auto;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.greyLighter};
`;
