import { batch } from "react-redux";
import { get } from "lodash";
import { emailIsValid, getInitials, navigateTo } from "@wrstudios/utils";
import { removeFromStorage } from "../utils/persist";
import { getForgeToken } from "../modules/forge";
import {
  getApiToken,
  getCurrentUserAPI,
  requestPasswordResetAPI,
  resetPasswordFromEmailAPI,
  resetPasswordAPI,
  acceptInvitation as acceptInvitationAPI,
  getAppHelpers as getAppHelpersAPI,
  getSsoParams as getSsoParamsAPI
} from "../modules/railsApi";
import { transformUser, transformUserMLS } from "../modules/user";
import { transformHelpers } from "../modules/app";
import { initMLS } from "./mls";

export function handleAppMount() {
  return async (dispatch) => {
    dispatch({ type: "HANDLE_APP_MOUNT_INITIATED" });

    try {
      const forgeToken = await getForgeToken(process.env.REACT_APP_API_ENV);
      const { helpers } = await getAppHelpersAPI();

      dispatch({ type: "GET_FORGE_TOKEN_SUCCEEDED", payload: forgeToken });
      dispatch({
        type: "GET_APP_HELPERS_SUCCEEDED",
        payload: transformHelpers(helpers)
      });
      dispatch({ type: "HANDLE_APP_MOUNT_SUCCEEDED" });
    } catch (error) {
      dispatch({ type: "HANDLE_APP_MOUNT_FAILED", payload: { error } });
    }
  };
}

// * Notes: You can login either from a token or from an email and password combination.
export function login({
  email,
  password,
  token: urlToken,
  isImpersonating,
  isSSOUser
}) {
  return async (dispatch, getState) => {
    const {
      login: { friendlyRedirect }
    } = getState();
    dispatch({ type: "LOGIN_INITIATED" });

    try {
      let apiToken = urlToken;

      // If we don't have a token from the url, use email and password to login.
      if (!urlToken) {
        const { token } = await getApiToken({ email, password });
        apiToken = token;
      }

      localStorage.setItem("token", apiToken);
      dispatch({
        type: "LOGIN_SUCCEEDED",
        payload: { railsApiToken: apiToken, isImpersonating, isSSOUser }
      });

      if (urlToken) {
        navigateTo(`${window.location.pathname}${window.location.search}`, {
          replace: true
        });
      }

      if (friendlyRedirect) {
        navigateTo(friendlyRedirect, { replace: true });
      }
    } catch (error) {
      const message = get(
        error,
        "response.errors[0].message",
        "Invalid email or password"
      );
      dispatch({
        type: "LOGIN_FAILED",
        payload: { error, message }
      });
    }
  };
}

export function getCurrentUser() {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken }
    } = getState();

    batch(() => {
      dispatch({ type: "GET_CURRENT_USER_INITIATED" });
      dispatch({ type: "INIT_MLS_REQUESTED" });
    });

    try {
      const { currentUser, agent } = await getCurrentUserAPI(railsApiToken);
      const transformedUser = transformUser(currentUser);
      const transformedUserMLS = transformUserMLS(agent.mls_credential || {});

      dispatch({
        type: "GET_CURRENT_USER_SUCCEEDED",
        payload: { user: transformedUser, mls: transformedUserMLS }
      });
    } catch (error) {
      dispatch({ type: "GET_CURRENT_USER_FAILED", payload: { error } });
    }
  };
}

export function getMLSDetails(mlsCode) {
  return async (dispatch, getState) => {
    const { application } = getState();
    const {
      mlses,
      configs,
      locations,
      locationsLabels,
      fields,
      status
    } = await initMLS({ mlsKey: mlsCode, forgeToken: application.forgeToken });

    try {
      batch(() => {
        dispatch({ type: "GET_ALL_MLSES_SUCCEEDED", payload: mlses });
        dispatch({ type: "GET_MLS_CONFIGS_SUCCEEDED", payload: configs });
        dispatch({ type: "GET_MLS_FIELDS_SUCCEEDED", payload: fields });
        dispatch({
          type: "GET_MLS_LOCATIONS_SUCCEEDED",
          payload: { locations: locations, locationsLabels }
        });
        dispatch({ type: "GET_MLS_STATUS_SUCCEEDED", payload: status });
        dispatch({ type: "INIT_MLS_RESOLVED" });
      });
    } catch (error) {
      dispatch({ type: "INIT_MLS_FAILED", payload: { error } });
    }
  };
}

export function requestPasswordReset(email) {
  return async (dispatch) => {
    if (!emailIsValid(email)) {
      return dispatch({
        type: "REQUEST_PASSWORD_RESET_INVALID",
        payload: "Please enter a valid email"
      });
    }

    dispatch({ type: "REQUEST_PASSWORD_RESET_INITIATED" });

    try {
      const response = await requestPasswordResetAPI(email);
      if (response.requestPasswordReset.email === email) {
        dispatch({ type: "REQUEST_PASSWORD_RESET_SUCCEEDED" });
      }
    } catch (error) {
      dispatch({
        type: "REQUEST_PASSWORD_RESET_FAILED",
        payload: { error, message: "There was an error, please try again" }
      });
    }
  };
}

export function resetPasswordFromEmail(
  password,
  passwordConfirmation,
  resetToken
) {
  return async (dispatch) => {
    if (password !== passwordConfirmation) {
      return dispatch({
        type: "RESET_PASSWORD_INVALID",
        payload: "Passwords do not match"
      });
    }

    dispatch({ type: "RESET_PASSWORD_INITIATED" });

    try {
      const {
        resetPasswordFromEmail: { token }
      } = await resetPasswordFromEmailAPI(
        password,
        passwordConfirmation,
        resetToken
      );
      dispatch({ type: "RESET_PASSWORD_SUCCEEDED", payload: { token } });
    } catch (error) {
      dispatch({
        type: "RESET_PASSWORD_FAILED",
        payload: { error, message: "There was an error, please try again" }
      });
    }
  };
}

export function resetPassword(password, passwordConfirmation, railsApiToken) {
  return async (dispatch) => {
    if (password !== passwordConfirmation) {
      return dispatch({
        type: "RESET_PASSWORD_INVALID",
        payload: "Passwords do not match"
      });
    }

    dispatch({ type: "RESET_PASSWORD_INITIATED" });

    try {
      const {
        resetPassword: { token }
      } = await resetPasswordAPI(password, passwordConfirmation, railsApiToken);

      dispatch({ type: "LOGIN_AFTER_PASSWORD_RESET", payload: { token } });
      dispatch({ type: "SET_IS_NEW_USER", payload: false });
    } catch (error) {
      dispatch({
        type: "RESET_PASSWORD_FAILED",
        payload: { error, message: "There was an error, please try again" }
      });
    }
  };
}

export function acceptInvitation({
  name,
  email,
  password,
  passwordConfirmation,
  frequency,
  invitationGuid,
  agentId,
  clientId,
  token
}) {
  return async (dispatch) => {
    if (password !== passwordConfirmation) {
      return dispatch({
        type: "ACCEPT_INVITATION_INVALID",
        payload: "Passwords do not match"
      });
    }

    dispatch({ type: "ACCEPT_INVITATION_INITIATED" });

    try {
      const data = await acceptInvitationAPI({
        name,
        email,
        password,
        passwordConfirmation,
        invitationGuid,
        frequency,
        agentId,
        clientId,
        token
      });

      const newRailsApiToken = data.token;
      const initials = getInitials(name);

      dispatch({
        type: "ACCEPT_INVITATION_SUCCEEDED",
        payload: { railsApiToken: newRailsApiToken, name, email, initials }
      });
    } catch (error) {
      dispatch({
        type: "ACCEPT_INVITATION_FAILED",
        payload: {
          error,
          message: "Invitation expired, please contact your agent."
        }
      }); // * This is not likely to happen, but this is here as a catch-all
    }
  };
}

export function getSsoParams() {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken }
    } = getState();

    dispatch({ type: "GET_SSO_PARAMS_INITIATED" });

    try {
      const { ssoParams } = await getSsoParamsAPI(railsApiToken);
      dispatch({ type: "GET_SSO_PARAMS_SUCCEEDED", payload: ssoParams });
    } catch (error) {
      dispatch({ type: "GET_SSO_PARAMS_FAILED", payload: { error } });
    }
  };
}

export function logout() {
  return (dispatch, getState) => {
    const { user } = getState();

    removeFromStorage();
    removeFromStorage(true);

    if (user.isCASUser) {
      window.location.href = `${process.env.REACT_APP_CAS_URL}/app/launchpad`;
      return;
    }

    dispatch({ type: "CLEAR_API_TOKEN" });
  };
}

export function setAppHeaderSticky(isAppHeaderSticky) {
  return { type: "SET_APP_HEADER_STICKY", payload: { isAppHeaderSticky } };
}

export function setAppScrollable(isAppScrollable) {
  return { type: "SET_APP_SCROLLABLE", payload: { isAppScrollable } };
}
