import { connect } from "react-redux";
import StreamFilterBathsOverlay from "../StreamFilterBathsOverlay";

function select({ stream: { filters } }) {
  const baths = filters.byId.baths_search || {};
  const min = baths.gte;
  const max = baths.lte;

  return {
    min,
    max
  };
}

export default connect(select)(StreamFilterBathsOverlay);
