import { connect } from "react-redux";
import {
  replaceStreamFilters,
  setShowStreamFilters
} from "../../../../actions/stream";
import StreamFiltersOverlay from "../StreamFiltersOverlay";

function select({ stream }) {
  const { city, zip, area, location, status, ...filters } = stream.filters.byId;
  const locationFilters = { city, zip, area, location };

  return {
    isEditingCriteria: stream.isEditingCriteria,
    filters,
    locationFilters
  };
}

const actions = {
  replaceStreamFilters,
  setShowStreamFilters
};

export default connect(select, actions)(StreamFiltersOverlay);
