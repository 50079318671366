import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { unrecommendStreamItem } from "../../actions/streamItem";
import IconRecommend from "../icons/IconRecommend";
import StreamItemRecommendModal from "../stream/StreamItemRecommendModal";
import { Container } from "./styled/stream-item-recommend";

function StreamItemRecommend({ streamItemId }) {
  const dispatch = useDispatch();
  const { recommendation, streamId } = useSelector(
    ({ streamItems }) => streamItems.byId[streamItemId] || {}
  );
  const recommended = !!(recommendation || {}).id;

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleRecommend = recommended // prettier-ignore
    ? () =>
        dispatch(
          unrecommendStreamItem({
            streamId,
            streamItemId,
            recommendationId: recommendation.id
          })
        )
    : () => setModalIsOpen(true);

  return (
    <>
      <Container active={recommended} onClick={handleRecommend}>
        <IconRecommend />
      </Container>
      <StreamItemRecommendModal
        streamItemId={streamItemId}
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        onSent={() => setModalIsOpen(false)}
      />
    </>
  );
}

StreamItemRecommend.propTypes = {
  streamItemId: PropTypes.string.isRequired
};

StreamItemRecommend.defaultProps = {
  openModalOnMount: false
};

export default StreamItemRecommend;
