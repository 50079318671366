import React from "react";

function NotificationIconError() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3386 3.08821C11.2562 2.17063 12.7438 2.17063 13.6614 3.08821L20.9118 10.3386C21.8294 11.2562 21.8294 12.7439 20.9118 13.6614L13.6614 20.9118C12.7438 21.8294 11.2561 21.8294 10.3386 20.9118L3.08818 13.6614C2.1706 12.7439 2.1706 11.2562 3.08818 10.3386L10.3386 3.08821Z"
        fill="#B31A1A"
      />
      <path
        d="M12.8762 13.4693C12.8465 13.9266 12.4669 14.2824 12.0086 14.2824C11.5504 14.2824 11.1708 13.9266 11.1411 13.4693L10.7392 7.27773C10.6943 6.58586 11.2433 6 11.9367 6H12.0806C12.774 6 13.323 6.58586 13.2781 7.27773L12.8762 13.4693ZM10.6898 16.7184C10.6898 16.3488 10.8186 16.0352 11.0762 15.7776C11.3338 15.5088 11.6419 15.3744 12.0003 15.3744C12.3699 15.3744 12.6834 15.5088 12.941 15.7776C13.2098 16.0352 13.3442 16.3488 13.3442 16.7184C13.3442 17.088 13.2098 17.4016 12.941 17.6592C12.6834 17.9168 12.3699 18.0456 12.0003 18.0456C11.6419 18.0456 11.3338 17.9168 11.0762 17.6592C10.8186 17.4016 10.6898 17.088 10.6898 16.7184Z"
        fill="white"
      />
    </svg>
  );
}

export default NotificationIconError;
