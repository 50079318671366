import React from "react";
import PropTypes from "prop-types";
import { LoadingSpinner } from "@wrstudios/components";
import ResourceNotFound from "./ResourceNotFound";
import ResourceError from "./ResourceError";
import { Container, Loading } from "./styled/resource-loader";

function ResourceLoader({ isLoading, notFound, hasError, children }) {
  if (isLoading) {
    return (
      <Container>
        <Loading>
          <LoadingSpinner /> Loading...
        </Loading>
      </Container>
    );
  }

  if (hasError) {
    return (
      <Container>
        <ResourceError />
      </Container>
    );
  }

  if (notFound) {
    return (
      <Container>
        <ResourceNotFound />
      </Container>
    );
  }

  return children;
}

ResourceLoader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  notFound: PropTypes.bool.isRequired
};

ResourceLoader.defaultProps = {
  isLoading: false,
  hasError: false,
  notFound: false
};

export default ResourceLoader;
