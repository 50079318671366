import { connect } from "react-redux";
import StreamItemLikes from "../StreamItemLikes";

function select({ streamItems, user }, { streamItemId }) {
  const streamItem = streamItems.byId[streamItemId] || {};
  const likers = streamItem.likers || [];
  const likes = likers
    .filter((liker) => liker.id !== user.id)
    .map((liker) => liker.name);

  return {
    likes,
    isHidden: !likes.length
  };
}

export default connect(select)(StreamItemLikes);
