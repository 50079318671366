import { connect } from "react-redux";
import { ListingHistory } from "@wrstudios/components";

function select({ listings: { byId } }, { listingId }) {
  const { history, updatedAtDateShort, updatedAtTime } = byId[listingId] || {};

  return {
    history,
    updatedAtDateShort,
    updatedAtTime
  };
}

export default connect(select)(ListingHistory);
