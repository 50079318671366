import styled from "styled-components";
import { UserAvatar } from "@wrstudios/components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  margin-left: -0.4rem;
  border-radius: 50%;
  box-shadow: ${({ theme }) => `0 0 0 0.2rem ${theme.colors.white}`};
  cursor: ${({ isButton }) => (isButton ? "pointer" : "default")};
`;

export const Avatar = styled(UserAvatar)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grey};
  background-color: ${({ theme }) => theme.colors.greyLight};
`;
