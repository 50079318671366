export function transformMLSes(mlses) {
  return mlses.reduce((state, mls) => {
    const transformedMLS = transformMLS(mls);
    return { ...state, [transformedMLS.key]: transformedMLS };
  }, {});
}

export function transformMLS(mls) {
  return {
    key: mls.key,
    label: mls.name,
    name: mls.name,
    state: mls.state.reduce(
      (states, state) => ({ ...states, [state]: state }),
      {}
    )
  };
}

export function transformFields(fields) {
  const {
    standard: standardFields,
    fine_tuning: fineTuningFields
  } = fields.reduce((fields, field) => {
    const transformedField = transformField(field);

    return {
      ...fields,
      [transformedField.field]: {
        ...fields[transformedField.field],
        [transformedField.key]: transformedField
      }
    };
  }, {});

  return {
    standardFields,
    fineTuningFields
  };
}

export function transformField(field) {
  return {
    key: field.key || "",
    value: field.key || "",
    title: field.title || "",
    category: field.category_name || "",
    name: field.display_name || "",
    label: field.display_name || "",
    type: field.input_type || "",
    colType: field.col_type || "",
    field: field.field_type || "",
    values: field.search_values || [],
    isRange: field.input_type === "range",
    isMulti: field.input_type === "multi",
    isSingle: field.input_type === "single",
    isArray: field.col_type === "array",
    isBoolean: field.col_type === "boolean",
    isDate: field.col_type === "date",
    isGeoPoint: field.col_type === "geo_point",
    isGeoShape: field.col_type === "geo_shape",
    isKeyword: field.col_type === "keyword",
    isInteger: field.col_type === "integer",
    isFloat: field.col_type === "float",
    isLong: field.col_type === "long",
    isText: field.col_type === "text"
  };
}

export function transformLocations(locations) {
  return locations.reduce((locations, location) => {
    return [...locations, ...transformLocation(location)];
  }, []);
}

export function transformLocation(location) {
  return location.search_values.map((value) => {
    return {
      key: location.key,
      label: value,
      value
    };
  });
}

export function transformStatus(status) {
  return {
    active: (status || {}).active || ["Active"],
    backup: (status || {}).backup || [],
    pending: (status || {}).pending || [],
    closed: (status || {}).closed || []
  };
}

export function transformMLSConfigs(config) {
  const parsed = JSON.parse(config);

  return {
    defaultPropType: parsed.default_prop_type || null,
    photoProxyStrategy: parsed.photo_proxy || "none",
    disclaimer: parsed.disclaimer || ""
  };
}

export function getLocationsLabels(locations) {
  return locations.map(({ label }) => label);
}
