import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IconClose from "../icons/IconClose";
import { ToastWrapper } from "./styled/toast-wrapper";
const CloseButton = () => (
  <div
    style={{
      width: "16px",
      height: "16px",
      position: "absolute",
      top: "16px",
      right: "16px"
    }}>
    <IconClose />
  </div>
);
function ToastNotification() {
  return (
    <ToastWrapper>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={CloseButton}
      />
    </ToastWrapper>
  );
}
export default ToastNotification;
