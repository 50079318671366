import styled, { css } from "styled-components";
import {
  Button as BaseButton,
  UserAvatar as BaseAvatar
} from "@wrstudios/components";
import BaseCheckbox from "../../../common/Checkbox";
import { getFontWeightByFrequency, getFontColorByFrequency } from "../utils";

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 1.2rem 0;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.greyLighter};

  &:first-of-type {
    padding-top: 0.2rem;
  }

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 2rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Client = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled(BaseAvatar)`
  width: 4rem;
  height: 4rem;
  font-size: 1.3rem;
  line-height: 1.8rem;
  margin-right: 1.2rem;
`;

const Truncate = styled.span`
  flex-grow: 1;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Details = styled(Truncate)`
  display: block;
  overflow: auto;
`;

const name = css`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.greyDark};
`;

export const Name = styled(Truncate)`
  ${name};
  display: block;
`;

export const Status = styled.span`
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: ${({ theme }) => theme.colors.grey};
`;

export const ResendButton = styled(BaseButton)`
  font-weight: 600;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0.8rem;
`;

export const RemoveButton = styled.button`
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.1rem;
  height: 2.1rem;
  padding: 0;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.colors.greyLighter};
  }
`;

export const Checkbox = styled(BaseCheckbox)`
  margin-left: 1rem;
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  > * + * {
    margin-left: 3rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    width: 100%;
    margin: 1.2rem 0 0;

    > button {
      margin-left: auto;
    }
  }
`;

export const FrequencyLabel = styled.span`
  display: inline-flex;
  align-items: center;
  font-size: 1.4rem;
  line-height: 1.4rem;
  font-weight: ${({ frequency }) => getFontWeightByFrequency(frequency)};
  color: ${({ theme, frequency }) =>
    getFontColorByFrequency({ theme, frequency })};

  svg {
    margin-right: 0.8rem;
    fill: ${({ theme, frequency }) =>
      getFontColorByFrequency({ theme, frequency })};
  }
`;
