import { buildRailsApiClient } from "./";

export async function createRecommendation({
  streamItemId,
  listingId,
  agentId,
  mls,
  streamId,
  message,
  notifications,
  railsApiToken
}) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const mutation = /* GraphQL */ `
    mutation createRecommendation(
      $stream_item_id: Int!
      $listing_id: String!
      $agent_id: Int!
      $mls: String!
      $stream_id: Int!
      $message: String
      $notifications: [RecommendationsNotificationType]
    ) {
      createRecommendation(
        stream_item_id: $stream_item_id
        listing_id: $listing_id
        agent_id: $agent_id
        mls: $mls
        stream_id: $stream_id
        message: $message
        notifications: $notifications
      ) {
        stream_item {
          id
          recommendation {
            id
            message
            stream_item_id
            updated_at
            agent {
              id
              name
            }
          }
        }
        stream {
          id
          recommendation_count
        }
      }
    }
  `;
  const variables = {
    stream_item_id: parseInt(streamItemId, 10),
    stream_id: parseInt(streamId, 10),
    agent_id: parseInt(agentId, 10),
    listing_id: listingId,
    notifications,
    message,
    mls
  };
  const { createRecommendation } = await railsApiClient.request(
    mutation,
    variables
  );

  return createRecommendation;
}

export async function deleteRecommendation({
  recommendationId,
  railsApiToken
}) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const mutation = /* GraphQL */ `
    mutation deleteRecommendation($id: ID!) {
      deleteRecommendation(id: $id)
    }
  `;
  const variables = { id: recommendationId };
  const { deleteRecommendation } = await railsApiClient.request(
    mutation,
    variables
  );

  return deleteRecommendation;
}
