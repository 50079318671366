import { connect } from "react-redux";
import { ListingShowings } from "@wrstudios/components";
import { getInitialsColor } from "@wrstudios/theme";

function select({ listings: { byId } }, { listingId }) {
  const {
    showings,
    agentName,
    agentEmail,
    agentPhone,
    agentInitials,
    officeName
  } = byId[listingId] || {};

  return {
    showings,
    agentName,
    agentEmail,
    agentPhone,
    agentInitials,
    agentInitialsColor: getInitialsColor(agentName),
    officeName
  };
}

export default connect(select)(ListingShowings);
