import styled from "styled-components";
import { Menu } from "@wrstudios/components";

export const MenuButton = styled(Menu.Button)`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: ${({ theme, isHighlighted }) =>
    isHighlighted ? theme.colors.streamsBlue : theme.colors.grey};

  svg {
    width: 2.1rem;
    height: 2.1rem;
    fill: ${({ theme }) => theme.colors.grey};
  }
`;

export const MenuButtonIcon = styled.span`
  margin-right: 0.6rem;
`;
