import React from "react";
import PropTypes from "prop-types";
import ActivityAlert from "../activity-types/connected/ActivityAlert";
import { ResourceLink } from "../styled/activities";

function getPersonComponent({
  clientId,
  clientName,
  isAgent,
  isCommenter,
  isCurrentlyViewedClient
}) {
  if (isAgent && !isCurrentlyViewedClient) {
    return (
      <ResourceLink to={`/clients/${clientId}`}>{clientName}</ResourceLink>
    );
  } else if (isCommenter) {
    return <span>You</span>;
  } else {
    return <strong>{clientName}</strong>;
  }
}

function ActivityEmail({
  activityId,
  streamId,
  streamTitle,
  streamItemId,
  listingId,
  listingAddress,
  streamItemCount,
  hasStreamItemId,
  hasMultipleStreamItems,
  clientId,
  clientName,
  isAgent,
  isCommenter,
  isCurrentlyViewedClient
}) {
  const user = getPersonComponent({
    clientId,
    clientName,
    isAgent,
    isCommenter,
    isCurrentlyViewedClient
  });

  return (
    <ActivityAlert activityId={activityId}>
      {!hasMultipleStreamItems && (
        <>
          {user} received an email alert about{" "}
          <ResourceLink
            to={
              hasStreamItemId
                ? `/stream_items/${streamItemId}`
                : `/listings/${listingId}`
            }>
            {listingAddress}
          </ResourceLink>
          .
        </>
      )}
      {hasMultipleStreamItems && (
        <>
          {user} received an email alert with {streamItemCount} properties that
          match{" "}
          <ResourceLink to={`/streams/${streamId}`}>{streamTitle}</ResourceLink>
          .
        </>
      )}
    </ActivityAlert>
  );
}

ActivityEmail.propTypes = {
  activityId: PropTypes.string.isRequired,
  streamId: PropTypes.string.isRequired,
  streamTitle: PropTypes.string.isRequired,
  streamItemId: PropTypes.string.isRequired,
  listingId: PropTypes.string.isRequired,
  listingAddress: PropTypes.string.isRequired,
  streamItemCount: PropTypes.number.isRequired,
  hasStreamItemId: PropTypes.bool.isRequired,
  hasMultipleStreamItems: PropTypes.bool.isRequired,
  clientId: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
  isAgent: PropTypes.bool.isRequired,
  isCommenter: PropTypes.bool.isRequired,
  isCurrentlyViewedClient: PropTypes.bool.isRequired
};

export default ActivityEmail;
