import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import ListingCard from "../listing/connected/ListingCard";
import FadeTransition from "../common/FadeTransition";
import StreamItemsEmptyState from "./StreamItemsEmptyState";
import StreamItemsLoading from "./connected/StreamItemsLoading";
import StreamItem from "./connected/StreamItem";
import StreamItemsPagination from "./connected/StreamItemsPagination";
import { Container, Cell } from "./styled/stream-items";
import { STREAM_ITEM_FILTER_TYPES } from "../../modules/streamItem";

class StreamItems extends Component {
  render() {
    const {
      streamId,
      filteredStreamItemIds,
      filteredListingIds,
      hasMultiplePages,
      isLoading,
      isMapShowing,
      isEditingCriteria,
      columns,
      filter
    } = this.props;
    const count = isEditingCriteria
      ? filteredListingIds.length
      : filteredStreamItemIds.length;

    if (count === 0 && !isLoading) {
      return <StreamItemsEmptyState filter={filter} />;
    }

    return (
      <>
        <Container>
          <FadeTransition mountsOn={isLoading}>
            <StreamItemsLoading columns={columns} />
          </FadeTransition>
          {isEditingCriteria
            ? filteredListingIds.map((listingId, index) => (
                <Cell key={index} isMapShowing={isMapShowing} columns={columns}>
                  <ListingCard
                    listingId={listingId}
                    url={`/listings/${listingId}`}
                  />
                </Cell>
              ))
            : filteredStreamItemIds.map((streamItemId, index) => (
                <Cell key={index} isMapShowing={isMapShowing} columns={columns}>
                  <StreamItem streamItemId={streamItemId} />
                </Cell>
              ))}
        </Container>
        {hasMultiplePages && <StreamItemsPagination streamId={streamId} />}
      </>
    );
  }

  componentDidMount() {
    if (!this.props.mlsConfigsLoaded) {
      return;
    }

    if (this.props.hasFilters) {
      this.props.getListingsFromForge({
        filter: this.props.filters,
        page: this.props.page,
        limit: this.props.limit
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.mlsConfigsLoaded) {
      return;
    }

    const {
      filters,
      hasFilters,
      mlsConfigsLoaded,
      isEditingCriteria,
      getListingsFromForge,
      page,
      limit
    } = this.props;
    const shouldFetchListingsFromForge =
      !isEqual(prevProps.filters, filters) && hasFilters;

    if (!prevProps.mlsConfigsLoaded && mlsConfigsLoaded) {
      if (isEditingCriteria) {
        getListingsFromForge({ filter: filters, page, limit });
      }
    } else if (isEditingCriteria && shouldFetchListingsFromForge) {
      getListingsFromForge({ filter: filters, page, limit });
    }
  }
}

StreamItems.propTypes = {
  streamId: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  columns: PropTypes.number,
  filters: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  filter: PropTypes.oneOf(Object.values(STREAM_ITEM_FILTER_TYPES)).isRequired,
  hasFilters: PropTypes.bool.isRequired,
  hasMultiplePages: PropTypes.bool.isRequired,
  isMapShowing: PropTypes.bool.isRequired,
  isEditingCriteria: PropTypes.bool.isRequired,
  mlsConfigsLoaded: PropTypes.bool.isRequired,
  getListingsFromForge: PropTypes.func.isRequired
};

StreamItems.defaultProps = {
  columns: 2
};

export default StreamItems;
