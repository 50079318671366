import { connect } from "react-redux";
import { resetPasswordFromEmail } from "../../../../actions/app";
import { emitAction } from "../../../../utils/redux";
import ResetPasswordRoute from "../ResetPasswordRoute";

function select(
  { login: { isResettingPassword, resetPasswordError, newRailsApiToken } },
  { match }
) {
  return {
    resetToken: match.params.resetToken,
    isResettingPassword,
    newRailsApiToken,
    resetPasswordError
  };
}

const actions = { resetPasswordFromEmail, emitAction };

export default connect(select, actions)(ResetPasswordRoute);
