import React from "react";
import PropTypes from "prop-types";
import Switch from "../common/Switch";
import { Label, LabelText } from "./styled/stream-map-toggle";

function StreamMapToggle({ isMapShowing, setStreamMapShowing }) {
  return (
    <Label htmlFor="streamMapToggle">
      <LabelText>Show Map</LabelText>
      <Switch
        id="streamMapToggle"
        isOn={isMapShowing}
        onChange={setStreamMapShowing}
      />
    </Label>
  );
}

StreamMapToggle.propTypes = {
  isMapShowing: PropTypes.bool.isRequired,
  setStreamMapShowing: PropTypes.func.isRequired
};

export default StreamMapToggle;
