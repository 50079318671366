import React, { Component } from "react";
import PropTypes from "prop-types";
import { Menu } from "@wrstudios/components";
import IconDropdownArrowDown from "../icons/IconDropdownArrowDown";
// import UserPhoneVerifyModal from "../user/UserPhoneVerifyModal";
import IconTextMessage from "../icons/IconTextMessage";
import { MenuButton, MenuButtonIcon } from "./styled/stream-frequency";
import {
  smsFrequencies,
  getFrequencyLabel,
  getFrequencyName
} from "./invite/utils";

class StreamSmsFrequency extends Component {
  state = {
    shouldShowVerification: false,
    frequency: this.props.initialValue,
    desiredFrequency: undefined
  };

  render() {
    const { showLabel } = this.props;
    const { shouldShowVerification, frequency } = this.state;
    return (
      <>
        <Menu>
          <MenuButton isHighlighted={frequency !== smsFrequencies.NEVER}>
            <MenuButtonIcon>
              <IconTextMessage />
            </MenuButtonIcon>
            {showLabel
              ? getFrequencyLabel(frequency)
              : getFrequencyName(frequency)}
            <IconDropdownArrowDown />
          </MenuButton>
          <Menu.List offset={6} showPoint={false}>
            <Menu.Item
              onClick={() => this.handleSelect(smsFrequencies.REAL_TIME)}>
              Real-time Text
            </Menu.Item>
            <Menu.Item onClick={() => this.handleSelect(smsFrequencies.NEVER)}>
              Never Text
            </Menu.Item>
          </Menu.List>
        </Menu>
        {shouldShowVerification &&
          this.props.renderVerifyComponent({
            phone: this.props.phone,
            onVerifySuccess: this.handleVerifySuccess,
            onClose: this.handleVerifyClose
          })}
      </>
    );
  }

  handleSelect = (frequency) => {
    if (this.props.phone) {
      this.setState({
        frequency,
        shouldShowVerification: false
      });
      this.props.onSelect(frequency);
    } else {
      this.setState({
        shouldShowVerification: true,
        desiredFrequency: frequency
      });
    }
    this.props.onClose();
  };

  handleVerifySuccess = () => {
    this.setState({
      frequency: this.state.desiredFrequency,
      desiredFrequency: undefined
    });
  };

  handleVerifyClose = () => {
    this.setState({ shouldShowVerification: false });
  };
}

StreamSmsFrequency.propTypes = {
  initialValue: PropTypes.oneOf(Object.values(smsFrequencies)),
  phone: PropTypes.string.isRequired,
  showLabel: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  renderVerifyComponent: PropTypes.func,
  onShowVerification: PropTypes.func
};

StreamSmsFrequency.defaultProps = {
  renderVerifyComponent: () => {},
  initialValue: smsFrequencies.NEVER,
  showLabel: true
};

export default StreamSmsFrequency;
