import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
`;

export const Modal = styled.div`
  ${({ theme }) => theme.layers.modal};
  max-width: 37.5rem;
  outline: none;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.4rem 1.4rem 0.7rem;
`;

export const Close = styled.button`
  margin-left: auto;
  transform: translate(0.5rem, -0.5rem);
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.colors.blackLight};
  }
`;

export const Detail = styled.div`
  font-size: 1.2rem;
  line-height: 2.2rem;
  color: ${({ theme }) => theme.colors.blackLight};
`;

export const DetailType = styled.span`
  color: ${({ theme }) => theme.colors.greyDark};
`;

export const DetailLink = styled.a`
  color: ${({ theme }) => theme.colors.streamsBlue};
`;

export const Body = styled.div`
  padding: ${({ hasStreamItemIds }) =>
    hasStreamItemIds ? "1.6rem 2.4rem 6.6rem" : "1.6rem 2.4rem"};
  background-color: ${({ theme }) => theme.colors.greyLightest};
`;

export const Title = styled.div`
  ${({ theme }) => theme.text.medium};
  margin-bottom: 1.6rem;
`;

export const Text = styled.div`
  ${({ theme }) => theme.text.medium};
  text-align: center;
`;

export const Listings = styled.div`
  max-height: 50vh;
  margin: 0 -2.4rem 1.6rem;
  padding: 0 2.4rem 0.4rem;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Listing = styled.div`
  margin-bottom: 1.6rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Disclaimer = styled.div`
  ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.colors.blackLight};
`;
