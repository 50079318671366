import React from "react";
import PropTypes from "prop-types";
import IconHide from "../icons/IconHide";
import { Container } from "./styled/stream-item-hide";

function StreamItemHide({ streamItemId, hidden, type, setStreamItemHide }) {
  return (
    <Container
      active={hidden}
      onClick={() =>
        setStreamItemHide({ streamItemId, hidden: !hidden, type })
      }>
      <IconHide />
    </Container>
  );
}

StreamItemHide.propTypes = {
  streamItemId: PropTypes.string.isRequired,
  hidden: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  setStreamItemHide: PropTypes.func.isRequired
};

export default StreamItemHide;
