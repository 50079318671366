import { GraphQLClient } from "graphql-request";

const railsUrl = process.env.REACT_APP_API_URL;
const railsApiUrl = `${railsUrl}/graphql`;

export function buildRailsApiClient(railsApiToken) {
  const token = railsApiToken || localStorage.getItem("token");

  if (!token) {
    return new GraphQLClient(railsApiUrl);
  }

  return new GraphQLClient(railsApiUrl, {
    headers: { Authorization: `Bearer ${token}` }
  });
}
