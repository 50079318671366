import React from "react";
import PropTypes from "prop-types";
import { Link } from "@wrstudios/components";
import IconAvatarAlert from "../icons/IconAvatarAlert";
import IconAvatarView from "../icons/IconAvatarView";
import IconAvatarLike from "../icons/IconAvatarLike";
import IconAvatarComment from "../icons/IconAvatarComment";
import IconAvatarRecommend from "../icons/IconAvatarRecommend";
import { Container, Avatar, IconAlert, Icon } from "./styled/activity-avatar";

function ActivityAvatar({
  type,
  clientId,
  clientInitials,
  clientInitialsColor,
  clientAvatar,
  isAgent
}) {
  const content = (
    <Container>
      {type !== "sms" && type !== "email" && (
        <Avatar
          initials={clientInitials}
          initialsColor={clientInitialsColor}
          avatarUrl={clientAvatar}
        />
      )}
      {(type === "sms" || type === "email") && (
        <>
          {isAgent ? (
            <>
              <Avatar
                initials={clientInitials}
                initialsColor={clientInitialsColor}
                avatarUrl={clientAvatar}
              />
              <Icon>
                <IconAvatarAlert />
              </Icon>
            </>
          ) : (
            <IconAlert />
          )}
        </>
      )}
      {type === "view" && (
        <Icon>
          <IconAvatarView />
        </Icon>
      )}
      {type === "like" && (
        <Icon>
          <IconAvatarLike />
        </Icon>
      )}
      {type === "comment" && (
        <Icon>
          <IconAvatarComment />
        </Icon>
      )}
      {type === "recommend" && (
        <Icon>
          <IconAvatarRecommend />
        </Icon>
      )}
    </Container>
  );

  if (!isAgent) return content;

  return <Link to={`/clients/${clientId}`}>{content}</Link>;
}

ActivityAvatar.propTypes = {
  type: PropTypes.string.isRequired,
  clientId: PropTypes.string,
  clientInitials: PropTypes.string.isRequired,
  clientInitialsColor: PropTypes.string.isRequired,
  clientAvatar: PropTypes.string.isRequired,
  isAgent: PropTypes.bool.isRequired
};

export default ActivityAvatar;
