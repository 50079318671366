import React from "react";
import PropTypes from "prop-types";
import { getFrequencyName, getFrequencyLabel } from "./utils";
import IconEmail from "../../icons/IconEmail";
import IconTextMessage from "../../icons/IconTextMessage";
import IconDelete from "../../icons/IconDelete";
import StreamInviteClient from "./connected/StreamInviteClient";
import { FrequencyLabel, RemoveButton } from "./styled/stream-invite-client";

function StreamInviteSubscribedClient({
  streamId,
  subscriptionId,
  clientId,
  hasClient,
  emailFrequency,
  smsFrequency,
  isAgent,
  deleteStreamSubscription
}) {
  if (!hasClient) return null;

  return (
    <StreamInviteClient clientId={clientId}>
      <FrequencyLabel
        title={getFrequencyLabel(emailFrequency)}
        frequency={emailFrequency}>
        <IconEmail />
        {getFrequencyName(emailFrequency)}
      </FrequencyLabel>
      <FrequencyLabel
        title={getFrequencyLabel(smsFrequency)}
        frequency={smsFrequency}>
        <IconTextMessage />
        {getFrequencyName(smsFrequency)}
      </FrequencyLabel>
      {isAgent && (
        <RemoveButton
          onClick={() =>
            deleteStreamSubscription({ streamId, clientId, subscriptionId })
          }
          title="Remove"
          aria-label="Remove">
          <IconDelete />
        </RemoveButton>
      )}
    </StreamInviteClient>
  );
}

StreamInviteSubscribedClient.propTypes = {
  streamId: PropTypes.string.isRequired,
  subscriptionId: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  hasClient: PropTypes.bool.isRequired,
  emailFrequency: PropTypes.string.isRequired,
  smsFrequency: PropTypes.string.isRequired,
  isAgent: PropTypes.bool.isRequired,
  deleteStreamSubscription: PropTypes.func.isRequired
};

export default StreamInviteSubscribedClient;
