import { connect } from "react-redux";
import ActivityListing from "../ActivityListing";

function select({ activities, listings }, { activityId }) {
  const activity = activities.byId[activityId] || {};
  const stream = activity.stream || {};
  const streamId = stream.id || "";
  const streamTitle = stream.title || "";
  const streamItemIds = stream.streamItemIds || [];
  const streamItem = activity.streamItem || {};
  const streamItemId = streamItem.id || streamItemIds[0] || "";
  const hasMultipleStreamItemIds = streamItemIds.length > 1;
  const listingIds = stream.listingIds || [];
  const firstListingId = listingIds[0] || "";
  const firstListing = listings.byId[firstListingId] || {};
  const listingAddress = firstListing.addressFormatted || "";
  const hasStreamItemId = !!streamItemId;

  const limit = 1;
  const remainingCount =
    streamItemIds.length >= limit ? streamItemIds.length - limit : 0;
  const hasRemainingCount = !!remainingCount;

  const url =
    hasStreamItemId && !hasMultipleStreamItemIds
      ? `/stream_items/${streamItemId}`
      : `/streams/${streamId}`;
  const title =
    hasStreamItemId && !hasMultipleStreamItemIds
      ? `Link to ${listingAddress} listing page`
      : `Link to ${streamTitle} stream page`;

  return {
    url,
    title,
    photo: firstListing.firstPhoto || "",
    remainingCount,
    hasRemainingCount
  };
}

export default connect(select)(ActivityListing);
