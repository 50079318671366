import React from "react";
import PropTypes from "prop-types";
import { UserAvatar } from "@wrstudios/components";
import {
  AvatarLayout,
  ContactLink,
  CardSegment,
  ClientName
} from "./styled/client-profile";

function ClientProfileContactInfo({
  name,
  email,
  phoneNumber,
  avatarUrl,
  initials,
  initialsColor
}) {
  return (
    <CardSegment>
      <AvatarLayout>
        <UserAvatar
          size="xl"
          avatarUrl={avatarUrl}
          initials={initials}
          initialsColor={initialsColor}
        />
      </AvatarLayout>
      <ClientName>{name}</ClientName>
      <ContactLink href={`mailto:${email}`}>{email}</ContactLink>
      <ContactLink href={`tel:${phoneNumber}`}>{phoneNumber}</ContactLink>
    </CardSegment>
  );
}

ClientProfileContactInfo.propTypes = {
  clientId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  avatarUrl: PropTypes.string,
  status: PropTypes.string,
  sharedClients: PropTypes.array,
  socialProfiles: PropTypes.array
};

export default ClientProfileContactInfo;
