import styled from "styled-components";
import {
  RouteContainer as BaseRouteContainer,
  Link as BaseLink
} from "@wrstudios/components";
import BaseFlash from "../../../common/Flash";

export const RouteContainer = styled(BaseRouteContainer)`
  max-width: 68.2rem;
  margin: 3.2rem auto;
`;

export const Title = styled.h2`
  ${({ theme }) => theme.text.h2};
  margin-bottom: 3.2rem;
  text-align: center;
`;

export const Flash = styled(BaseFlash)`
  margin-bottom: 3.2rem;
`;

export const Subscriptions = styled.div`
  ${({ theme }) => theme.layers.flat};
  width: 100%;
  padding: 2.4rem 2.4rem 3rem;
`;

export const SubTitle = styled.h3`
  ${({ theme }) => theme.text.h3};
  margin-bottom: 1.6rem;
`;

export const LargeText = styled.p`
  ${({ theme }) => theme.text.h3}
  line-height: 3.2rem;
  font-weight: normal;
  margin: 0;

  strong {
    font-weight: 600;
  }
`;

export const SmallText = styled.span`
  ${({ theme }) => theme.text.medium}
  display: block;
  line-height: 2.4rem;
  margin: 1.2rem 0 0;
`;

export const LinkButton = styled.button`
  ${({ theme }) => theme.text.medium}
  color: ${({ theme }) => theme.colors.streamsBlue};
  text-decoration: underline;
`;

export const ButtonLayout = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3.2rem;
`;

export const SubscriptionRow = styled.div`
  padding: 2.4rem 0;
  border-top: 0.1rem solid ${({ theme }) => theme.colors.whiteDark};

  &:last-of-type {
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.whiteDark};
  }
`;

export const FrequenciesLayout = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.6rem;

  > * + * {
    margin-left: 1.8rem;
  }
`;

export const AlertsTable = styled.table`
  width: 100%;
  border-collapse: none;
  border-style: none;
`;

export const TableHead = styled.th`
  text-align: ${({ align = "left" }) => align};
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  padding: 0;
  padding-bottom: 1.2rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.whiteDark};
`;

export const TableCell = styled.td`
  padding: 0;
  height: 8.5rem;
  min-width: 12rem;
  vertical-align: middle;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.whiteDark};
`;

export const StreamLink = styled(BaseLink)`
  color: ${({ theme }) => theme.colors.streamsBlue};
  display: block;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
  margin-bottom: 0.3rem;
`;

export const StreamLocations = styled.span`
  ${({ theme }) => theme.text.small}
  display: block;
  margin-bottom: 0.2rem;
`;

export const StreamCriteria = styled.span`
  ${({ theme }) => theme.text.small}
  display: block;
`;

export const FrequencyCellLayout = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const EmailSection = styled.section`
  padding-top: 1.4rem;
`;

export const EmailList = styled.ul`
  margin: 0;
  padding: 0.8rem 2.4rem 0rem;
  list-style: none;
`;

export const EmailSubscription = styled.li`
  display: flex;
  align-items: center;
`;
