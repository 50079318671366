import { UserAvatar } from "@wrstudios/components";
import { getInitials } from "@wrstudios/utils";
import PropTypes from "prop-types";
import React from "react";
import {
  Cell,
  Company,
  Container,
  Grid,
  Link,
  Name
} from "./styled/user-agent";

function UserAgent({ agent }) {
  return (
    <Container>
      <Grid>
        <Cell>
          <UserAvatar
            initials={getInitials(agent.name)}
            avatarUrl={agent.avatar}
            size="md"
          />
        </Cell>
        <Cell>
          <Name>{agent.name}</Name>
          <Company>{agent.company}</Company>
          <div>
            <Link as="a" href={`tel:${agent.phone}`}>
              {agent.phone}
            </Link>
          </div>
          <div>
            <Link as="a" href={`mailto:${agent.email}`}>
              {agent.email}
            </Link>
          </div>
        </Cell>
      </Grid>
    </Container>
  );
}

UserAgent.propTypes = {
  agent: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
    company: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string
  }).isRequired
};

export default UserAgent;
