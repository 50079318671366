import { connect } from "react-redux";
import {
  updateUserPassword,
  resetUserPasswordNetwork
} from "../../../../actions/user";
import SettingsRoutePassword from "../SettingsRoutePassword";

function select({
  user: {
    lwaId,
    casJwt,
    passwordNetwork: { isFetching, hasFetched, errors }
  }
}) {
  return {
    hasLwaId: !!lwaId,
    casJwt,
    isFetching,
    hasFetched,
    errors
  };
}

const actions = {
  updateUserPassword,
  resetUserPasswordNetwork
};

export default connect(select, actions)(SettingsRoutePassword);
