import {
  getStreams as getStreamsAPI,
  searchStreams as searchStreamsAPI
} from "../modules/railsApi";
import {
  getStreamById,
  getMinimalStreamById,
  getStreamItems as getStreamItemsAPI,
  createStream as createStreamAPI,
  updateStream as updateStreamAPI,
  deleteStream as deleteStreamAPI
} from "../modules/railsApi";
import {
  updateStreamSubscriptionFrequency as updateStreamSubscriptionFrequencyAPI,
  createStreamSubscription as createStreamSubscriptionAPI,
  deleteStreamSubscription as deleteStreamSubscriptionAPI
} from "../modules/railsApi";
import { sendStreamInvite as sendStreamInviteAPI } from "../modules/railsApi";
import {
  transformStreams,
  transformStream,
  transformStreamSubscriptions,
  transformStreamSubscription,
  remapFiltersToIncludeField
} from "../modules/stream";
import {
  transformStreamItems,
  STREAM_ITEM_FILTER_TYPES
} from "../modules/streamItem";
import { transformInvitations } from "../modules/invitation";
import { setPrimaryAlertType as setPrimaryAlertTypeAPI } from "../api/stream";

export function getStreams({ page, limit }) {
  return async (dispatch, getState) => {
    const { application, streams, mls } = getState();

    // If we get clients while searching (most likely from pagination), call search clients with sorting
    if (streams.searchTerm) {
      return dispatch(
        searchStreams({
          page,
          limit,
          searchTerm: streams.searchTerm,
          sortTerm: streams.sortTerm,
          sortDir: streams.sortDir
        })
      );
    }

    dispatch({ type: "GET_STREAMS_INITIATED", payload: { page, limit } });

    try {
      const { currentUser, streams: streamsData } = await getStreamsAPI({
        page,
        limit,
        sortTerm: streams.sortTerm,
        sortDir: streams.sortDir,
        railsApiToken: application.railsApiToken
      });
      const transformedStreams = transformStreams({
        streams: streamsData,
        mls
      });

      dispatch({
        type: "GET_STREAMS_SUCCEEDED",
        payload: { ...transformedStreams, count: currentUser.streams_count }
      });
    } catch (error) {
      dispatch({ type: "GET_STREAMS_FAILED", payload: { error } });
    }
  };
}

export function sortStreams({ sortTerm, sortDir }) {
  return async (dispatch, getState) => {
    const { application, streams, mls } = getState();

    // If we sort while searching, call search clients with sorting
    if (streams.searchTerm) {
      return dispatch(
        searchStreams({ searchTerm: streams.searchTerm, sortTerm, sortDir })
      );
    }

    dispatch({
      type: "SORT_STREAMS_INITIATED",
      payload: { page: 1, limit: streams.limit, sortTerm, sortDir }
    });

    try {
      const { currentUser, streams: streamsData } = await getStreamsAPI({
        page: 1,
        limit: streams.limit,
        sortTerm,
        sortDir,
        railsApiToken: application.railsApiToken
      });
      const transformedStreams = transformStreams({
        streams: streamsData,
        mls
      });

      dispatch({
        type: "SORT_STREAMS_SUCCEEDED",
        payload: { ...transformedStreams, count: currentUser.streams_count }
      });
    } catch (error) {
      dispatch({ type: "SORT_STREAMS_FAILED", payload: { error } });
    }
  };
}

export function searchStreams({ page, limit, searchTerm, sortTerm, sortDir }) {
  return async (dispatch, getState) => {
    const { application, streams, mls } = getState();

    dispatch({
      type: "SEARCH_STREAMS_INITIATED",
      payload: {
        page: page || 1,
        limit: limit || streams.limit,
        searchTerm,
        sortTerm: sortTerm || streams.sortTerm,
        sortDir: sortDir || streams.sortDir
      }
    });

    try {
      const streamsData = await searchStreamsAPI({
        page: page || 1,
        limit: limit || streams.limit,
        searchTerm,
        sortTerm: sortTerm || streams.sortTerm,
        sortDir: sortDir || streams.sortDir,
        railsApiToken: application.railsApiToken
      });
      const transformedStreams = transformStreams({
        streams: streamsData.results,
        mls
      });

      dispatch({
        type: "SEARCH_STREAMS_SUCCEEDED",
        payload: { ...transformedStreams, count: streamsData.count }
      });
    } catch (error) {
      dispatch({ type: "SEARCH_STREAMS_FAILED", payload: { error } });
    }
  };
}

export function resetStreams() {
  return { type: "RESET_STREAMS" };
}

export function selectStreamAlertsTemplate(type = "automatic") {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken },
      stream: { currentStreamId },
      user: { id }
    } = getState();

    dispatch({ type: "ALERTS_TEMPLATE_SELECTED", payload: type });

    if (type === "curated") {
      // user must be an agent, so we should create their realtime subscription for this stream
      dispatch({ type: "CREATE_STREAM_SUBSCRIPTION_INITIATED" });

      try {
        const agentSubscription = await createStreamSubscriptionAPI({
          streamId: currentStreamId,
          userId: id,
          emailFrequency: "realtime",
          smsFrequency: "none",
          railsApiToken
        });
        const transformedAgentSubscription =
          transformStreamSubscription(agentSubscription);

        dispatch({
          type: "CREATE_STREAM_SUBSCRIPTION_SUCCESS",
          payload: {
            stream: { id: currentStreamId },
            subscription: transformedAgentSubscription
          }
        });
      } catch (error) {
        dispatch({ type: "CREATE_STREAM_SUBSCRIPTION_FAILED", payload: error });
      }
    }
  };
}

export function getStream(id) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken },
      stream,
      mls
    } = getState();

    dispatch({ type: "GET_STREAM_INITIATED" });

    try {
      const streamData = await getStreamById({
        id,
        railsApiToken,
        limit: stream.limit,
        page: stream.page,
        filter: stream.filter
      });
      const transformedStream = transformStream({
        stream: streamData || {},
        mls
      });

      dispatch({ type: "GET_STREAM_SUCCEEDED", payload: transformedStream });
    } catch (error) {
      dispatch({
        type: "GET_STREAM_FAILED",
        payload: {
          error,
          errorsArray: (error.response || {}).errors || []
        }
      });
    }
  };
}

export function getMinimalStream(id) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken },
      stream,
      mls
    } = getState();

    dispatch({ type: "GET_STREAM_INITIATED" });

    try {
      const streamData = await getMinimalStreamById({
        id,
        railsApiToken,
        limit: stream.limit,
        page: stream.page
      });
      const transformedStream = transformStream({ stream: streamData, mls });

      dispatch({ type: "GET_STREAM_SUCCEEDED", payload: transformedStream });
    } catch (error) {
      dispatch({ type: "GET_STREAM_FAILED", payload: { error } });
    }
  };
}

export function deleteStream({ streamId }) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken },
      streams: { limit, searchTerm, sortTerm, sortDir, count },
      mls
    } = getState();

    dispatch({ type: "DELETE_STREAM_INITIATED", payload: { id: streamId } });

    try {
      await deleteStreamAPI({ streamId, railsApiToken });

      const newCount = count - 1 <= 0 ? 0 : count - 1;
      const newPage = newCount > 0 ? Math.ceil(newCount / limit) : 1;

      const { streams: streamsData } = await getStreamsAPI({
        page: newPage,
        limit,
        searchTerm,
        sortTerm,
        sortDir,
        railsApiToken
      });
      const transformedStreams = transformStreams({
        streams: streamsData,
        mls
      });

      dispatch({
        type: "DELETE_STREAM_SUCCEEDED",
        payload: {
          ...transformedStreams,
          count: newCount,
          page: newPage
        }
      });
    } catch (error) {
      dispatch({
        type: "DELETE_STREAM_FAILED",
        payload: { error, id: streamId }
      });
    }
  };
}

export function getStreamItems({
  streamId,
  page,
  limit,
  filter = STREAM_ITEM_FILTER_TYPES.ALL
}) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken },
      mls
    } = getState();

    dispatch({ type: "GET_STREAM_ITEMS_INITIATED" });

    try {
      const streamData = await getStreamItemsAPI({
        streamId,
        page,
        limit,
        filter,
        railsApiToken
      });

      const { ALL, RECOMMENDED, LIKED, COMMENTED, HIDDEN } =
        STREAM_ITEM_FILTER_TYPES;
      const queryHasFilters = filter !== ALL;

      const countKey = {
        [ALL]: "stream_item_count",
        [RECOMMENDED]: "recommendations_count",
        [LIKED]: "likes_count",
        [COMMENTED]: "comments_count",
        [HIDDEN]: "hidden_count"
      }[filter];
      const itemsCount = streamData[countKey];

      const responseKey = queryHasFilters
        ? "filtered_stream_items"
        : "stream_items";
      const transformedStreamItems = transformStreamItems({
        streamItems: streamData[responseKey],
        mls
      });

      dispatch({
        type: "GET_STREAM_ITEMS_SUCCEEDED",
        payload: {
          streamItems: transformedStreamItems,
          itemsCount,
          page,
          limit,
          filter
        }
      });
    } catch (error) {
      dispatch({ type: "GET_STREAM_ITEMS_FAILED", payload: { error } });
    }
  };
}

export function resetStream() {
  return { type: "RESET_STREAM" };
}

export function initStreamsIndex() {
  return { type: "INIT_STREAMS_INDEX" };
}

export function createStream({ name, filter }) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken },
      streams: { count },
      mls,
      user
    } = getState();

    const agentId = user.isAgent
      ? user.id
      : user.activeAgentId || user.primaryAgent.id;

    dispatch({ type: "CREATE_STREAM_INITIATED" });

    try {
      const streamData = await createStreamAPI({
        agentId,
        name,
        filter,
        railsApiToken
      });
      const transformedStream = transformStream({ stream: streamData, mls });

      dispatch({
        type: "CREATE_STREAM_SUCCEEDED",
        payload: { ...transformedStream, count: count + 1 }
      });
    } catch (error) {
      dispatch({ type: "CREATE_STREAM_FAILED", payload: { error } });
    }
  };
}

export function updateStream({ id, name, filter }) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken },
      mls
    } = getState();

    dispatch({ type: "UPDATE_STREAM_INITIATED" });

    try {
      const streamData = await updateStreamAPI({
        id,
        name,
        filter,
        railsApiToken
      });
      const transformedStream = transformStream({ stream: streamData, mls });

      dispatch({ type: "UPDATE_STREAM_SUCCEEDED", payload: transformedStream });
    } catch (error) {
      dispatch({
        type: "UPDATE_STREAM_FAILED",
        payload: { error, errorsArray: (error.response || {}).errors || [] }
      });
    }
  };
}

export function renameStream({ id, name }) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken },
      mls
    } = getState();

    dispatch({ type: "RENAME_STREAM_INITIATED" });

    try {
      const streamData = await updateStreamAPI({
        id,
        name,
        railsApiToken
      });
      const transformedStream = transformStream({ stream: streamData, mls });

      dispatch({ type: "RENAME_STREAM_SUCCEEDED", payload: transformedStream });
    } catch (error) {
      dispatch({ type: "RENAME_STREAM_FAILED", payload: { error } });
    }
  };
}

export function setStreamFilters(filters) {
  return (dispatch, getState) => {
    const {
      stream: {
        filters: { byId }
      }
    } = getState();

    // * Notes: This does a merge, not a replace like the below method.
    dispatch({
      type: "SET_STREAM_FILTERS",
      payload: remapFiltersToIncludeField(filters, byId)
    });
  };
}

export function replaceStreamFilters(filters) {
  return {
    type: "SET_STREAM_FILTERS",
    payload: remapFiltersToIncludeField(filters)
  };
}

export function buildLocationFilters(features = []) {
  return (dispatch, getState) => {
    const { city, area, zip, ...filters } = getState().stream.filters.byId;

    // * we remove the 64-sided circle-like polygons bc we use the corresponding LineString radius for coordinates instead
    const locationFilters = features
      .filter(function isNotCircularPolygon(feature) {
        return feature.properties.meta !== "radius";
      })
      .reduce(
        (filter, feature) => {
          filter.within
            ? filter.within.push(feature.geometry.coordinates[0])
            : (filter.within = feature.geometry.coordinates);
          return filter;
        },
        { field: "location" }
      );

    if (locationFilters.within) {
      locationFilters.within = JSON.stringify(locationFilters.within);
    }

    const modifiedFilters = { ...filters, location: locationFilters };

    dispatch({ type: "SET_STREAM_FILTERS", payload: modifiedFilters });
  };
}

export function removeStreamFilter(filterFieldName) {
  return (dispatch, getState) => {
    const {
      stream: { filters }
    } = getState();

    const { [filterFieldName]: removedFilter, ...remainingFilters } =
      filters.byId;

    dispatch({ type: "SET_STREAM_FILTERS", payload: remainingFilters });
  };
}

export function setStreamIsCreating(isCreating) {
  return { type: "SET_STREAM_IS_CREATING", payload: { isCreating } };
}

export function setStreamMapShowing(isMapShowing) {
  return { type: "SET_STREAM_MAP_SHOWING", payload: { isMapShowing } };
}

export function setListingUnderPointer(id) {
  return { type: "SET_LISTING_UNDER_POINTER", payload: id };
}

export function sendStreamInvite({ id, clients }) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken },
      clients: { byId, filteredIds, limit }
    } = getState();

    // Filter out the stubbed clients.
    const clientsWithoutIds = clients.map(({ id, ...client }) => client);

    dispatch({ type: "SEND_STREAM_INVITE_INITIATED" });

    try {
      const clientsData = await sendStreamInviteAPI({
        id,
        clients: clientsWithoutIds,
        railsApiToken
      });
      const clientIds = clientsData.map((client) => client.id);
      const clientsDataWithInvitationProps = clientsData.map((client) => ({
        ...client,
        id: client.last_invitation.id,
        stream_id: id
      }));
      const { invitations, invitationIds } = transformInvitations(
        clientsDataWithInvitationProps
      );

      dispatch({
        type: "SEND_STREAM_INVITE_SUCCEEDED",
        payload: {
          invitations,
          invitationIds,
          stream: { id },
          clients: byId,
          clientIds: [...filteredIds, ...clientIds].slice(0, limit)
        }
      });
    } catch (error) {
      dispatch({
        type: "SEND_STREAM_INVITE_FAILED",
        payload: { error, errorsArray: (error.response || {}).errors || [] }
      });
    }
  };
}

export function toggleStreamClientInvite({ id, isSelected }) {
  return (dispatch, getState) => {
    const {
      stream: { clientIdsToInvite }
    } = getState();
    const newClientIdsToInvite = isSelected
      ? [...clientIdsToInvite, id]
      : clientIdsToInvite.filter((clientId) => clientId !== id);

    dispatch({
      type: "TOGGLE_STREAM_CLIENT_INVITE",
      payload: newClientIdsToInvite
    });
  };
}

export function setStreamClientSearchTerm(searchTerm) {
  return { type: "SET_STREAM_CLIENT_SEARCH_TERM", payload: searchTerm };
}

export function resetStreamClientInvite() {
  return { type: "RESET_STREAM_CLIENT_INVITE" };
}

export function setPrimaryAlertType({ streamId, alertsTemplate }) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken }
    } = getState();

    dispatch({ type: "SET_PRIMARY_ALERT_TYPE_REQUEST" });

    try {
      const stream = await setPrimaryAlertTypeAPI({
        streamId,
        alertType: alertsTemplate,
        railsApiToken
      });
      const { id, primary_alert_type, stream_subscriptions } = stream;
      const { subscriptions } =
        transformStreamSubscriptions(stream_subscriptions);

      dispatch({
        type: "SET_PRIMARY_ALERT_TYPE_RESOLVED",
        payload: {
          stream: { id, primaryAlertType: primary_alert_type },
          subscriptions
        }
      });
    } catch (error) {
      dispatch({ type: "SET_PRIMARY_ALERT_TYPE_REJECTED", payload: error });
    }
  };
}

export function updateStreamSubscriptionFrequency({
  streamId,
  subscriptionId,
  emailFrequency,
  smsFrequency
}) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken },
      subscriptions: { byId },
      user: { id, isAgent }
    } = getState();

    const subscription = byId[subscriptionId] || {};
    const hasSubscription = !!Object.values(subscription).length;

    // This is for an optimistic response.
    if (hasSubscription) {
      dispatch({
        type: "UPDATE_STREAM_SUBSCRIPTION_FREQUENCY_INITIATED",
        payload: { id: subscriptionId, emailFrequency, smsFrequency }
      });
    }

    try {
      // If we are an agent and we don't yet have a subscription for the current stream, let's create one.
      if (isAgent && !hasSubscription) {
        const agentSubscription = await createStreamSubscriptionAPI({
          streamId,
          userId: id,
          emailFrequency,
          smsFrequency: "none"
        });
        const transformedAgentSubscription =
          transformStreamSubscription(agentSubscription);

        subscriptionId = transformedAgentSubscription.id;

        // Send an optimistic request once we have the new stream subscription id back.
        dispatch({
          type: "CREATE_STREAM_SUBSCRIPTION_SUCCESS",
          payload: {
            stream: { id: streamId },
            subscription: transformedAgentSubscription
          }
        });
        dispatch({
          type: "UPDATE_STREAM_SUBSCRIPTION_FREQUENCY_INITIATED",
          payload: { id: subscriptionId, emailFrequency, smsFrequency }
        });
      }

      const streamSubscriptionData = await updateStreamSubscriptionFrequencyAPI(
        { subscriptionId, emailFrequency, smsFrequency, railsApiToken }
      );
      const transformedSubscription = transformStreamSubscription(
        streamSubscriptionData
      );

      dispatch({
        type: "UPDATE_STREAM_SUBSCRIPTION_FREQUENCY_SUCCEEDED",
        payload: transformedSubscription
      });

      return {
        status: "success",
        subscription: transformedSubscription
      };
    } catch (error) {
      // If we fail, we roll back our optimistic request. We roll back to subscriptions as we want to roll back all optimistic updates.
      dispatch({
        type: "UPDATE_STREAM_SUBSCRIPTION_FREQUENCY_FAILED",
        payload: { error, subscriptions: byId }
      });

      return {
        status: "error"
      };
    }
  };
}
export function deleteStreamSubscription({
  streamId,
  clientId,
  subscriptionId
}) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken },
      subscriptions,
      streams
    } = getState();

    const stream = streams.byId[streamId] || {};
    const oldClientIds = stream.clientIds;
    const oldSubscribers = stream.subscribers;
    const oldSubscriptionIds = stream.subscriptionIds;
    const oldSubscriptions = subscriptions.byId;
    const newSubscribers = stream.subscribers.filter(
      (subscriber) => subscriber.id !== clientId
    );
    const newClientIds = stream.clientIds.filter((id) => id !== clientId);
    const newSubscriptionIds = stream.subscriptionIds.filter(
      (id) => id !== subscriptionId
    );
    const { [subscriptionId]: removedSubscription, ...newSubscriptions } =
      subscriptions.byId;

    // This is for an optimistic response.
    dispatch({
      type: "DELETE_STREAM_SUBSCRIPTION_INITIATED",
      payload: {
        stream: {
          id: streamId,
          subscribers: newSubscribers,
          subscriptionIds: newSubscriptionIds,
          clientIds: newClientIds
        },
        subscriptions: newSubscriptions
      }
    });

    try {
      await deleteStreamSubscriptionAPI({ subscriptionId, railsApiToken });

      dispatch({ type: "DELETE_STREAM_SUBSCRIPTION_SUCCEEDED" });
    } catch (error) {
      // Roll back our optimistic request if it fails
      dispatch({
        type: "DELETE_STREAM_SUBSCRIPTION_FAILED",
        payload: {
          error,
          stream: {
            id: streamId,
            subscribers: oldSubscribers,
            subscriptionIds: oldSubscriptionIds,
            clientIds: oldClientIds
          },
          subscriptions: oldSubscriptions
        }
      });
    }
  };
}

export function setShowStreamFilters(isShowingFilters) {
  return { type: "SET_STREAM_FILTERS_SHOWING", payload: isShowingFilters };
}
