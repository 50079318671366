import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  align-items: flex-end;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Controls = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    flex-direction: column;
    align-items: center;
    margin: 2rem 0 0;
  }
`;

export const Search = styled.div`
  margin: 0 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    margin: 0 0 2rem;
  }
`;

export const Sort = styled.div`
  margin-left: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    margin: 0;
  }
`;

export const Title = styled.h1`
  ${({ theme }) => theme.text.h1};

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    width: 100%;
    text-align: center;
  }
`;

export const Body = styled.div`
  position: relative;
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }
`;
