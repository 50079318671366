import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: -1.2rem;
  z-index: 0;
`;

export const Cell = styled.div`
  padding: 1.2rem;
  width: ${({ columns }) => `calc(100% / ${columns})`};

  @media (max-width: 1024px) {
    width: ${({ isMapShowing }) => (isMapShowing ? "100%" : "calc(100% / 3)")};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.page.twoColumn}px) {
    width: ${({ isMapShowing }) => (isMapShowing ? "100%" : "50%")};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    width: 100%;
  }
`;
