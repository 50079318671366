import { connect } from "react-redux";
import { isCurrentlyViewedClient } from "../../../../modules/activity";
import ActivitySMS from "../ActivitySMS";

function select({ user, activities, listings }, { activityId }) {
  const activity = activities.byId[activityId] || {};
  const stream = activity.stream || {};
  const streamItemIds = stream.streamItemIds || [];
  const streamItem = stream.streamItem || {};
  const streamItemId = streamItem.id || streamItemIds[0] || "";
  const listingIds = stream.listingIds || [];
  const firstListing = listings.byId[listingIds[0] || ""] || {};
  const client = activity.client || {};

  return {
    activityId: activity.id || "",
    listingId: firstListing.id || "",
    listingAddress: firstListing.addressFormatted || "",
    streamItemId,
    hasStreamItemId: !!streamItemId,
    clientId: client.id || "",
    clientName: client.name || "",
    isAgent: user.isAgent || false,
    isCommenter: user.isClient && user.id === activity.client.id,
    isCurrentlyViewedClient: isCurrentlyViewedClient(activity)
  };
}

export default connect(select)(ActivitySMS);
