import { HelixIcon } from "@helix/helix-icon";
import {
  arrow_down as ArrowDownIcon,
  arrow_right as ArrowRightIcon,
  wolfie as WolfieIcon
} from "@helix/helix-icon/outlined";
import PropTypes from "prop-types";
import React from "react";
import { useLocation } from "react-router-dom";
import IconCloud from "../../icons/IconCloud";

async function connectAccount(url, options) {
  const response = await fetch(url, options);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const contentType = response.headers.get("content-type");
  if (!contentType || !contentType.includes("application/json")) {
    throw new TypeError("Response is not JSON");
  }
  return response.json();
}

async function connectProduct(tokenFromParams, service) {
  const url = `${process.env.REACT_APP_CAS_URL}/graphql/authenticated`;
  const query = `mutation connectProduct($service: ProductKey) {
    connectProduct(service: $service) {
      acknowledged
      redirectTo: redirect_to
    }
  }`;
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${tokenFromParams}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      query,
      variables: { service }
    })
  };
  try {
    const responseJson = await connectAccount(url, options);
    const productPayload = responseJson.data.connectProduct;
    if (productPayload) {
      window.location.href = `${process.env.REACT_APP_LW_AGENT_URL}/provisioning`;
    }
  } catch (error) {
    console.error(error);
  }
}
function ConnectorRoute({ name, email, logout }) {
  const { search } = useLocation();

  // ! Streams will CLEAR_API_TOKEN if "token" is a url param. Using casToken instead.
  const tokenFromParams = new URLSearchParams(search).get("casToken");
  const nameFromParams = new URLSearchParams(search).get("name") || "N/A";
  const emailFromParams = new URLSearchParams(search).get("email") || "N/A";

  return (
    <div className="helix-container--fluid helix-80vh">
      <div className="helix-d-flex helix-w-100-percent helix-justify--center helix-px-4">
        <div className="helix-row helix-100vh d-flex helix-align--middle helix-justify--center">
          <div className="helix-card helix-p-4">
            <div className="helix-card__body">
              <div className="helix-w-100-percent helix-d-flex helix-justify--center helix-mb-4">
                <HelixIcon
                  icon={WolfieIcon}
                  className="helix-mb-n1 helix-h-100 helix-w-100 helix-svg-fill--gray-900"
                  height="56"
                />
              </div>
              <h1 className="helix-text-gray-900 helix-display-small helix-mb-6 helix-text--center">
                Connect this account
              </h1>

              <div className="helix-d-flex helix-d-sm-block helix-justify--center helix-mb-6">
                <div className="helix-d-sm-flex helix-d-block">
                  <div className="helix-card helix-w-300">
                    <div className="helix-card__body helix-text--center">
                      <IconCloud
                        height="65"
                        fill="#0AA1DC"
                        className="helix-mb-4 helix-h-100 helix-w-100 helix-mt-2 helix-pb-2"
                      />
                      <div className="helix-body--strong helix-mb-1">
                        {name}
                      </div>
                      <div className="helix-text-gray-500 helix-small">
                        {email}
                      </div>
                    </div>
                  </div>
                  <div className="helix-d-flex helix-text-gray-900 helix-justify--center helix-align--middle helix-p-4">
                    <HelixIcon
                      icon={ArrowRightIcon}
                      className="helix-d-none helix-d-sm-block"
                    />
                    <HelixIcon
                      icon={ArrowDownIcon}
                      className="helix-d-block helix-d-sm-none"
                    />
                  </div>
                  <div className="helix-card helix-w-300">
                    <div className="helix-card__body helix-text--center helix-p-6">
                      <HelixIcon
                        icon={WolfieIcon}
                        height="70"
                        className="helix-mb-4 helix-h-100 helix-w-100 helix-svg-fill--red-900"
                      />

                      <div className="helix-body--strong helix-mb-1">
                        {nameFromParams}
                      </div>

                      <div className="helix-text-gray-500 helix-small">
                        {emailFromParams}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="helix-d-flex helix-mb-2 helix-align--middle helix-justify--center">
                <div className="helix-w-400">
                  <button
                    onClick={() =>
                      connectProduct(tokenFromParams, "cloud_streams")
                    }
                    className="helix-btn helix-btn--primary helix-w-100-percent helix-d-block helix-text--center hover:no-underline helix-mb-3">
                    Connect to Lone Wolf account
                  </button>
                  <button
                    onClick={() => connectProduct(tokenFromParams, null)}
                    className="helix-btn helix-btn--tertiary helix-w-100-percent helix-d-block helix-text--center hover:no-underline helix-mb-3">
                    Nevermind, don't connect
                  </button>
                  <button
                    className="helix-btn helix-text-blue-600 helix-btn--ghost helix-w-100-percent helix-d-block helix-text--center hover:no-underline"
                    onClick={() => logout()}>
                    Not you?
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ConnectorRoute.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string
};

export default ConnectorRoute;
