import React from "react";

function IconCloud(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 91.08 72">
      <path d="M61.1 70.85h-6.91a1.38 1.38 0 01-1.38-1.38v-6.16A1.15 1.15 0 0154 62.15h7.23a19.68 19.68 0 0019.7-19.73 19.32 19.32 0 00-16.17-18.78h-.26a3.84 3.84 0 01-2.81-2.1l-.12-.24A20.17 20.17 0 0043.32 9.85a20.41 20.41 0 00-20.21 18.52v.32A3.84 3.84 0 0120.59 32a15.8 15.8 0 00-10.42 13.86 15.52 15.52 0 0028.72 8.92l5.87-9.69a1.14 1.14 0 011.56-.38L51.81 48a1.14 1.14 0 01.38 1.56l-5.89 9.76A24.22 24.22 0 1114.79 25a29 29 0 0153.56-9.49 28 28 0 0121.27 27c.16 15.57-12.91 28.34-28.52 28.34z" />
    </svg>
  );
}

export default IconCloud;
