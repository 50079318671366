import { connect } from "react-redux";
import { replaceStreamFilters } from "../../../../actions/stream";
import StreamFilterSqft from "../StreamFilterSqft";

function select({ stream }) {
  const { sqft = {}, ...filters } = stream.filters.byId;
  const min = sqft.gte;
  const max = sqft.lte;

  return {
    filters,
    min,
    max
  };
}

const actions = {
  replaceStreamFilters
};

export default connect(select, actions)(StreamFilterSqft);
