import { connect } from "react-redux";
import {
  resendInvitation,
  deleteInvitation
} from "../../../../actions/invitation";
import StreamInvitedClient from "../StreamInvitedClient";

function select({ invitations, user, clients }, { invitationId }) {
  const { name, initials, initialsColor, email, isResending, hasResent } =
    invitations.byId[invitationId] || {};
  // TODO: once we're creating clients on the creation of invitations, we should be able to get avatarUrl back from invitation.client.avatar_url
  const { avatarUrl } =
    Object.values(clients.byId).find((client) => client.email === email) || {};

  return {
    name,
    initials,
    initialsColor,
    email,
    avatarUrl,
    isFetching: isResending,
    hasFetched: hasResent,
    isAgent: user.isAgent,
    isClient: user.isClient
  };
}

const actions = {
  resendInvitation,
  deleteInvitation
};

export default connect(select, actions)(StreamInvitedClient);
