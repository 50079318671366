import { getTimeAgo, getInitials } from "@wrstudios/utils";
import { getInitialsColor } from "@wrstudios/theme";

export function transformClients(clients) {
  // clients.results comes from searching as it also includes a count.
  const transformedClients = (clients.results || clients)
    .filter(Boolean)
    .reduce(
      (state, client) => {
        const transformedClient = transformClient(client);

        return {
          ...state,
          clients: {
            ...state.clients,
            [transformedClient.id]: transformedClient
          },
          clientIds: [...state.clientIds, transformedClient.id]
        };
      },
      { clients: {}, clientIds: [] }
    );

  return transformedClients;
}

export function transformClient(client) {
  const socialData = transformSocialData(client);

  return {
    id: client.id || "",
    name: (client.name || "").split("@")[0],
    initials: getInitials(client.name || client.email),
    initialsColor: getInitialsColor(client.name || client.email),
    email: client.email || "",
    phoneNumber: client.phone_number || "",
    avatarUrl: client.avatar || socialData.avatar || "",
    commentIds: (client.comments || []).map((comment) => comment.id),
    streamIds: (client.streams_by_mls || []).map((stream) => stream.id),
    likedItemIds: (client.likes || []).map((like) => like.id),
    isPending: client.pending || false,
    createdAt: client.created_at || "",
    updatedAt: client.updated_at || "",
    updatedAtAgo: client.created_at
      ? client.updated_at
        ? `Last activity: ${getTimeAgo(client.updated_at)}`
        : "No activity yet"
      : "Has not signed up",
    socialProfiles: socialData.socialProfiles,
    socialProfileStatus: socialData.socialProfileStatus,
    sharedClients: (client.shared_clients || [])
      .filter(Boolean)
      .map((sharedClient) => transformClient(sharedClient)),
    invitation: transformLastInvitation(client.last_invitation || {})
  };
}

function transformLastInvitation(invitation) {
  return {
    id: invitation.id || "",
    guid: invitation.guid || "",
    email: invitation.email || ""
  };
}

export function transformSocialData(client) {
  return {
    avatar: client.primary_photo || "",
    socialProfiles: transformClientSocialProfiles(client.social_profiles || []),
    socialProfileStatus:
      Number(client.social_data_status) || SOCIAL_PROFILE_STATUS.NOT_FETCHED
  };
}

function transformClientSocialProfiles(profiles) {
  return profiles.map((profile) => {
    return {
      username: profile.username || "",
      url: profile.url || "",
      type: (profile.service || "").toLowerCase()
    };
  });
}

// These are status codes provided to us by the FullContact API we use for this social data
export const SOCIAL_PROFILE_STATUS = Object.freeze({
  NOT_FETCHED: 0,
  FOUND: 200,
  PROCESSING: 202,
  NOT_FOUND: 404
});

export const SOCIAL_ICONS = {
  facebook: require("../static/images/icon-facebook.svg"),
  instagram: require("../static/images/icon-instagram.svg"),
  twitter: require("../static/images/icon-twitter.svg"),
  google: require("../static/images/icon-google-plus.svg"),
  linkedin: require("../static/images/icon-linkedin.png"),
  aboutme: require("../static/images/icon-aboutme.png")
};
