import React, { Component } from "react";
import PropTypes from "prop-types";
import { formatNumber } from "@wrstudios/utils";
import Label from "../../common/Label";
import Input from "../../common/Input";
import { Grid, Cell, Spacer } from "./styled/stream-filter-baths-overlay";

class StreamFilterBathsOverlay extends Component {
  state = {
    min: null,
    max: null
  };

  static getDerivedStateFromProps(props, state) {
    return {
      min:
        state.min === null &&
        typeof props.min !== "undefined" &&
        props.min !== "0"
          ? props.min
          : state.min,
      max:
        state.max === null &&
        typeof props.max !== "undefined" &&
        props.max !== "0"
          ? props.max
          : state.max
    };
  }

  render() {
    return (
      <>
        <Label htmlFor="baths-min">Bath Range</Label>
        <Grid>
          <Cell>
            <Input
              id="baths-min"
              name="min"
              value={this.state.min || ""}
              placeholder="No min"
              onChange={this.handleOnChange}
            />
          </Cell>
          <Cell>
            <Spacer>-</Spacer>
          </Cell>
          <Cell>
            <Input
              name="max"
              value={this.state.max || ""}
              placeholder="No Max"
              onChange={this.handleOnChange}
            />
          </Cell>
        </Grid>
      </>
    );
  }

  componentDidMount() {
    if (this.state.min !== null || this.state.max !== null) {
      this.sendFilter();
    }
  }

  componentDidUpdate(_, prevState) {
    if (
      (prevState.min === null && this.state.min !== null) ||
      (prevState.max === null && this.state.max !== null)
    ) {
      this.sendFilter();
    }
  }

  handleOnChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, this.sendFilter);
  };

  sendFilter = () => {
    let baths = {};
    const min = Number(formatNumber(this.state.min, "0[.]00"));
    const max = Number(formatNumber(this.state.max, "0[.]00"));

    if (min) baths = { ...baths, gte: min.toString(), field: "baths_search" };
    if (max) baths = { ...baths, lte: max.toString(), field: "baths_search" };

    this.props.onChange({ baths_search: baths });
  };
}

StreamFilterBathsOverlay.propTypes = {
  min: PropTypes.string,
  max: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default StreamFilterBathsOverlay;
