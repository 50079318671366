import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "@wrstudios/components";
import {
  selectCardMonths,
  selectCardTypes,
  selectCardYears
} from "../../../../modules/settings";
import FormGroup from "../../../common/FormGroup";
import Label from "../../../common/Label";
import Input from "../../../common/Input";
import Select from "../../../select/Select";
import Flash from "../../../common/Flash";
import { findOptionByLabel } from "../../../select/utils";
import { Group, SubTitle, Button } from "../styled/settings-route";
import { Container, Grid, Cell } from "./styled/settings-route-billing-edit";

class SettingsRouteBillingEdit extends Component {
  state = {
    firstName: "",
    lastName: "",
    cardType: "",
    cardNumber: "",
    cardMonth: "",
    cardYear: "",
    cardCVV: "",
    postal: ""
  };

  selectCardTypesLabels = selectCardTypes.map(({ label }) => label);

  selectCardMonthsLabels = selectCardMonths.map(({ label }) => label);

  selectCardYearsLabels = selectCardYears.map(({ label }) => label);

  render() {
    const {
      firstName,
      lastName,
      cardNumber,
      cardType,
      cardMonth,
      cardYear,
      cardCVV,
      postal
    } = this.state;
    const { isFetching, hasFetched, errors } = this.props;

    return (
      <Container>
        <form onSubmit={this.handleOnSubmit}>
          <Group>
            <SubTitle>Change Credit Card</SubTitle>
            <FormGroup>
              <Label htmlFor="first-name">First Name</Label>
              <Input
                id="first-name"
                name="firstName"
                value={firstName}
                onChange={this.handleOnChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="last-name">Last Name</Label>
              <Input
                id="last-name"
                name="lastName"
                value={lastName}
                onChange={this.handleOnChange}
              />
            </FormGroup>
            <FormGroup>
              <Select
                label="Card Type"
                placeholder="Select a Card Type"
                value={cardType}
                items={this.selectCardTypesLabels}
                onChange={this.handleOnCardTypeChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="card-number">Card Number</Label>
              <Input
                id="card-number"
                name="cardNumber"
                value={cardNumber}
                onChange={this.handleOnChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="card-expiration">Card Expiration</Label>
              <Grid>
                <Cell>
                  <Select
                    placeholder="Month"
                    value={cardMonth}
                    items={this.selectCardMonthsLabels}
                    onChange={this.handleOnCardMonthChange}
                  />
                </Cell>
                <Cell>
                  <Select
                    placeholder="Year"
                    value={cardYear}
                    items={this.selectCardYearsLabels}
                    onChange={this.handleOnCardYearChange}
                  />
                </Cell>
              </Grid>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="card-cvv">Card CVV</Label>
              <Input
                id="card-cvv"
                name="cardCVV"
                value={cardCVV}
                onChange={this.handleOnChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="card-cvv">Postal Code</Label>
              <Input
                id="postal"
                name="postal"
                value={postal}
                onChange={this.handleOnChange}
              />
            </FormGroup>
            {errors.map(({ message }, index) => (
              <FormGroup key={index}>
                <Flash status="error">{message}</Flash>
              </FormGroup>
            ))}
            {hasFetched && (
              <FormGroup>
                <Flash status="success">Account updated</Flash>
              </FormGroup>
            )}
          </Group>
          <Button disabled={this.getIsDisabled()}>
            {isFetching ? "Updating Account" : "Update Account"}
          </Button>
          <Link to="/settings/billing">
            <Button variant="tertiary">Cancel</Button>
          </Link>
        </form>
      </Container>
    );
  }

  handleOnChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleOnCardTypeChange = (cardType) => {
    const option = findOptionByLabel(cardType, selectCardTypes);
    this.setState({ cardType: option.value });
  };

  handleOnCardMonthChange = (cardMonth) => {
    const option = findOptionByLabel(cardMonth, selectCardMonths);
    this.setState({ cardMonth: option.value });
  };

  handleOnCardYearChange = (cardYear) => {
    const option = findOptionByLabel(cardYear, selectCardYears);
    this.setState({ cardYear: option.value });
  };

  handleOnSubmit = (e) => {
    e.preventDefault();
    this.props.updateBillingInfo(this.state);
  };

  getIsDisabled = () => {
    return [
      "firstName",
      "lastName",
      "cardType",
      "cardNumber",
      "cardMonth",
      "cardYear",
      "cardCVV",
      "postal"
    ].some((key) => this.state[key] === "");
  };
}

SettingsRouteBillingEdit.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  hasFetched: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  updateBillingInfo: PropTypes.func.isRequired
};

export default SettingsRouteBillingEdit;
