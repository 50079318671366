import React from "react";

function IconSearch(props) {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet" {...props}>
      <path d="M99.4179875,87.3015875 C99.8050667,87.7471458 100,88.2483988 100,88.8053467 C100,89.3622946 99.8050667,89.8635479 99.4179875,90.3091062 L90.3091042,99.4152046 C89.8607625,99.8050683 89.3622958,100 88.8053458,100 C88.2484,100 87.7471458,99.8050683 87.3015875,99.4152046 L62.2389292,74.3525479 C55.61125,78.6967417 48.3430792,80.8688387 40.4344208,80.8688387 C29.2926762,80.8688387 19.7688667,76.9145083 11.8657754,69.0058479 C3.95433042,61.0971875 0,51.5733779 0,40.4344196 C0,29.2954608 3.95433042,19.7716513 11.8657754,11.8629908 C19.7688667,3.95433042 29.2926762,0 40.4344208,0 C51.5733792,0 61.0971875,3.95433042 69.0086333,11.8629908 C76.9145083,19.7716513 80.871625,29.2954608 80.871625,40.4344196 C80.871625,48.34308 78.699525,55.6112504 74.3553333,62.2389308 C74.3553333,62.2389308 82.7095514,70.5931497 99.4179875,87.3015875 Z M12.781955,40.4344196 C12.781955,48.0089112 15.4831525,54.4973546 20.8855471,59.8997492 C26.2879421,65.3021442 32.8042329,68.0033417 40.4344208,68.0033417 C48.0646042,68.0033417 54.566975,65.3188525 59.9415208,59.9415204 C65.3160667,54.5669729 68.0033417,48.0646058 68.0033417,40.4344196 C68.0033417,32.8042329 65.3160667,26.3018658 59.9415208,20.9273183 C54.566975,15.5527708 48.0646042,12.8654971 40.4344208,12.8654971 C32.8042329,12.8654971 26.2879421,15.5666946 20.8855471,20.9690896 C15.4831525,26.3714842 12.781955,32.8599275 12.781955,40.4344196 Z" />
    </svg>
  );
}

export default IconSearch;
