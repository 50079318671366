import React, { Component } from "react";
import PropTypes from "prop-types";
import { navigateTo, navigateBack } from "@wrstudios/utils";
import FormGroup from "../../common/FormGroup";
import Flash from "../../common/Flash";
import {
  Container,
  Content,
  Box,
  Header,
  Text,
  Logout,
  Button,
  Errors
} from "./styled/cas-connect-route";

class CASConnectRoute extends Component {
  render() {
    const { email, isFetching, hasFetched, hasErrors, errors } = this.props;
    const isDisabled = isFetching || hasFetched;

    return (
      <Container>
        <Content>
          <Header>Request For Permission</Header>
          <Box>
            <Text>
              <strong>Cloud Agent Suite</strong> is requesting permission to
              access your Cloud Streams account.
            </Text>
            <Text>
              Logged in as <strong>{email}</strong> (
              <Logout onClick={this.handleOnLogout}>Not you?</Logout>)
            </Text>
            {!hasFetched && (
              <>
                <Button disabled={isDisabled} onClick={this.handleOnAllow}>
                  Allow
                </Button>
                <Button
                  variant="secondary"
                  disabled={isDisabled}
                  onClick={navigateBack}>
                  Cancel
                </Button>
              </>
            )}
            {hasErrors && (
              <Errors>
                {errors.map(({ message }, index) => (
                  <FormGroup key={index}>
                    <Flash>{message}</Flash>
                  </FormGroup>
                ))}
              </Errors>
            )}
            {hasFetched && (
              <FormGroup>
                <Flash status="success">
                  Success! Redirecting you back to Cloud Agent Suite.
                </Flash>
              </FormGroup>
            )}
          </Box>
        </Content>
      </Container>
    );
  }

  componentDidUpdate() {
    if (this.props.redirectUrl) {
      navigateTo(this.props.redirectUrl, { imperative: true });
    }
  }

  handleOnAllow = () => {
    this.props.connectCASAccount({
      jwt: this.props.jwt,
      redirectUrl: this.props.redirect
    });
  };

  handleOnLogout = () => {
    this.props.logout();
  };
}

CASConnectRoute.propTypes = {
  jwt: PropTypes.string.isRequired,
  redirect: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  hasFetched: PropTypes.bool.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired
    })
  ).isRequired,
  logout: PropTypes.func.isRequired,
  connectCASAccount: PropTypes.func.isRequired
};

export default CASConnectRoute;
