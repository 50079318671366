import styled from "styled-components";

export const Container = styled.div`
  padding: 2.4rem 0 3.2rem;
`;

export const Heading = styled.h4`
  ${({ theme }) => theme.text.h4}
  margin-bottom: 2.4rem;
`;

export const AlertButtonGroup = styled.div`
  display: flex;
  margin: 0 -1.2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    flex-direction: column;

    > * + * {
      margin-top: 1.6rem;
    }
  }
`;

export const AlertButtonLayout = styled.div`
  flex: 1 1 auto;
  padding: 0 1.2rem;
`;

export const AlertButton = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: ${({ theme }) => theme.shadows.raised},
    0 0 0 0.1rem ${({ theme }) => theme.colors.greyLighter};
  background-color: ${({ theme }) => theme.colors.white};
  outline: none;
  border-radius: 0.3rem;
  padding: 0 2.4rem 2.4rem;
  transition: box-shadow 100ms linear;
  cursor: pointer;

  &:hover {
    box-shadow: ${({ theme }) => theme.shadows.raised},
      0 0 0 0.1rem ${({ theme }) => theme.colors.blackLight};
  }

  &:focus {
    box-shadow: ${({ theme }) => theme.shadows.raised},
      0 0 0 0.2rem ${({ theme }) => theme.colors.streamsBlue};
  }
`;

export const AutomaticIllustrationLayout = styled.div`
  align-self: center;
  margin: 2.7rem auto 1.6rem;
`;

export const CuratedIllustrationLayout = styled.div`
  align-self: center;
  margin: 1.6rem auto 1.5rem;
`;

export const AlertTypeLabel = styled.h5`
  ${({ theme }) => theme.text.h5}
  margin-bottom: 0.4rem;
`;

export const AlertTypeDescription = styled.span`
  ${({ theme }) => theme.text.small}
  color: ${({ theme }) => theme.colors.greyDark};
  margin: 0;
  text-align: left;
`;
