import React from "react";
import PropTypes from "prop-types";
import Clipboard from "react-copy-to-clipboard";
import { Menu } from "@wrstudios/components";
import IconShare from "../icons/IconShare";
import { Container } from "./styled/stream-item-share";

function StreamItemShare({ listingAddress, streamItemUrl }) {
  return (
    <Container>
      <Menu>
        <Menu.Button aria-label="Share Property">
          <IconShare />
        </Menu.Button>
        <Menu.List position="top">
          <Menu.Item>
            <Clipboard text={streamItemUrl}>
              <span>Copy Link to Property</span>
            </Clipboard>
          </Menu.Item>
          <Menu.LinkExternal
            href={`mailto:?subject=${listingAddress}&body=Have a look at this property:%0d%0a${streamItemUrl}`}>
            Email Property
          </Menu.LinkExternal>
        </Menu.List>
      </Menu>
    </Container>
  );
}

StreamItemShare.propTypes = {
  listingAddress: PropTypes.string.isRequired,
  streamItemUrl: PropTypes.string.isRequired
};

export default StreamItemShare;
