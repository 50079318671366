import React from "react";
import { ListingCardLoading } from "@wrstudios/components";
import {
  LoadingLayout,
  CardLayout
} from "../routes/clients/styled/client-route";

function ClientLikedListingsLoading() {
  return (
    <LoadingLayout>
      <CardLayout>
        {Array(18)
          .fill()
          .map((_, index) => (
            <ListingCardLoading key={index} showFooter />
          ))}
      </CardLayout>
    </LoadingLayout>
  );
}

export default ClientLikedListingsLoading;
