import React, { useState, useContext, createContext } from "react";
import PropTypes from "prop-types";
import { Container, Message, MessageInner } from "./styled/tooltip";

const TooltipContext = createContext();

function Tooltip({ color, children }) {
  const [isShowingTip, showTip] = useState(false);

  return (
    <TooltipContext.Provider value={{ isShowingTip, color }}>
      <Container
        onMouseEnter={() => showTip(true)}
        onMouseLeave={() => showTip(false)}>
        {children}
      </Container>
    </TooltipContext.Provider>
  );
}

Tooltip.propTypes = {
  color: PropTypes.string
};

function TooltipMessage({ children }) {
  const { isShowingTip, color } = useTooltip();

  if (!isShowingTip) return null;

  return (
    <Message color={color}>
      <MessageInner color={color}>{children}</MessageInner>
    </Message>
  );
}

function useTooltip() {
  const context = useContext(TooltipContext);
  if (!context)
    throw new Error(
      "You must use [useTooltip] inside of a <Tooltip /> component"
    );
  return context;
}

export { Tooltip, TooltipMessage };
