import { connect } from "react-redux";
import { isCurrentlyViewedClient } from "../../../../modules/activity";
import ActivityLike from "../ActivityLike";

function select({ user, listings, activities }, { activityId }) {
  const activity = activities.byId[activityId] || {};
  const listingIds = (activity.stream || {}).listingIds || [];
  const firstListing = listings.byId[listingIds[0] || ""] || {};
  const streamItem = activity.streamItem || {};
  const client = activity.client || {};

  return {
    activityId: activity.id || "",
    clientId: client.id || "",
    clientName: client.name || "",
    streamItemId: streamItem.id || "",
    address: firstListing.address || "",
    isAgent: user.isAgent || false,
    isLiker: user.isClient && user.id === activity.client.id,
    isCurrentlyViewedClient: isCurrentlyViewedClient(activity)
  };
}

export default connect(select)(ActivityLike);
