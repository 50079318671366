import { connect } from "react-redux";
import {
  getCurrentUser,
  getSsoParams,
  getMLSDetails
} from "../../../actions/app";
import { getBillingInfo } from "../../../actions/billing";
import AppAuthed from "../AppAuthed";

function select({ user }, { location }) {
  return {
    location,
    isAgent: user.isAgent,
    mlsCode: user.mlsCode,
    mlsCredentialId: user.mlsCredentialId,
    pathname: location.pathname,
    isLoginRoute: location.pathname === "/login",
    showIntercom: false,
    isClientWithLikedListings:
      user.isClient && user.likedStreamItemIds.length > 0,
    isSettingsRoute: location.pathname.includes("/settings"),
    isMlsCredentialValid: user.isMlsCredentialValid
  };
}

const actions = {
  getCurrentUser,
  getBillingInfo,
  getSsoParams,
  getMLSDetails
};

export default connect(select, actions)(AppAuthed);
