import React, { Component } from "react";
import PropTypes from "prop-types";
import ResourceLoader from "../../common/ResourceLoader";
import Listing from "../../listing/connected/Listing";

class StreamItemRoute extends Component {
  render() {
    return (
      <ResourceLoader
        isLoading={this.props.isFetching}
        notFound={this.props.notFound}
        hasError={this.props.hasErrors}>
        {/* Only one type for now. */}
        {this.props.type === "Listing" && (
          <Listing
            streamItemId={this.props.streamItemId}
            listingId={this.props.itemId}
            isAuthed={this.props.isAuthed}
            isAgent={this.props.isAgent}
          />
        )}
      </ResourceLoader>
    );
  }

  componentDidMount() {
    if (this.props.isAuthed) {
      this.props.setStreamItemView({ streamItemId: this.props.streamItemId });
    }

    if (this.props.mlsConfigsLoaded) {
      this.getStreamItem();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.mlsConfigsLoaded && this.props.mlsConfigsLoaded) {
      this.getStreamItem();
    }

    if (
      this.props.hasFetched &&
      this.props.isShowingTimeAvailable &&
      !this.props.hasFetchedShowingTimeUrl
    ) {
      this.props.getShowingTimeUrl(this.props.itemId);
    }
  }

  getStreamItem() {
    if (this.props.isAuthed) {
      this.props.getStreamItem({ streamItemId: this.props.streamItemId });
    } else {
      this.props.getPublicStreamItem({ streamItemId: this.props.streamItemId });
    }
  }
}

StreamItemRoute.propTypes = {
  streamItemId: PropTypes.string.isRequired,
  itemId: PropTypes.string,
  type: PropTypes.string,
  mlsConfigsLoaded: PropTypes.bool.isRequired,
  isAuthed: PropTypes.bool.isRequired,
  isAgent: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  hasFetchedShowingTimeUrl: PropTypes.bool.isRequired,
  hasFetched: PropTypes.bool.isRequired,
  notFound: PropTypes.bool.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  setStreamItemView: PropTypes.func.isRequired,
  getStreamItem: PropTypes.func.isRequired,
  getPublicStreamItem: PropTypes.func.isRequired,
  getShowingTimeUrl: PropTypes.func.isRequired
};

export default StreamItemRoute;
