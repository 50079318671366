import { connect } from "react-redux";
import {
  getActivityStats,
  setActivitiesFilter
} from "../../../actions/activity";
import ActivityStats from "../ActivityStats";

function select({ activities }) {
  const { filter } = activities;
  const isAlertsActive = filter === "StreamAlert";
  const isViewsActive = filter === "StreamItemView";
  const isLikesActive = filter === "ActsAsVotable::Vote";
  const isCommentsActive = filter === "Comment";

  return {
    alertsCount: activities.stats.alerts || 0,
    viewsCount: activities.stats.views || 0,
    likesCount: activities.stats.likes || 0,
    commentsCount: activities.stats.comments || 0,
    isAlertsActive,
    isViewsActive,
    isLikesActive,
    isCommentsActive,
    hasActiveFilter:
      isAlertsActive || isViewsActive || isLikesActive || isCommentsActive,
    isLoading: activities.isLoading || false
  };
}

const actions = {
  getActivityStats,
  setActivitiesFilter
};

export default connect(select, actions)(ActivityStats);
