import React from "react";
import PropTypes from "prop-types";
import { ceil, range, compact } from "lodash";
import { IconDropdown } from "@wrstudios/icons";
import { sizeOptions } from "../../modules/listing";
import IconChevronLeft from "../icons/IconChevronLeft";
import IconChevronRight from "../icons/IconChevronRight";
import {
  Container,
  DirectionButton,
  PageButton,
  Ellipsis,
  PageControls,
  ViewingInfo,
  SelectContainer,
  Select
} from "./styled/pagination";

function Pagination({
  isVisible,
  pageSize,
  totalCount,
  currentPage,
  maxPageButtons,
  maxAdjacent,
  showInfo,
  showLimitSelection,
  handleSelectSize,
  handleClickPage
}) {
  const pageCount = ceil(totalCount / pageSize);
  const buttonData = getButtonData({
    pageCount,
    currentPage,
    maxPageButtons,
    maxAdjacent
  });

  return (
    <Container isVisible={isVisible}>
      <PageControls>
        {currentPage > 1 && (
          <DirectionButton onClick={() => handleClickPage(currentPage - 1)}>
            <IconChevronLeft />
          </DirectionButton>
        )}
        {buttonData.map(({ pageNumber, isSelected }, index) => {
          if (pageNumber === "ellipsis") {
            return <Ellipsis key={index}>&hellip;</Ellipsis>;
          }
          return (
            <PageButton
              data-testid={`pagination-button-${pageNumber}`}
              disabled={pageNumber === currentPage}
              onClick={() => handleClickPage(pageNumber)}
              isSelected={isSelected}
              key={index}>
              {pageNumber}
            </PageButton>
          );
        })}
        {currentPage < pageCount && (
          <DirectionButton onClick={() => handleClickPage(currentPage + 1)}>
            <IconChevronRight />
          </DirectionButton>
        )}
      </PageControls>
      {showInfo && (
        <ViewingInfo>
          {formatViewingInfo({ pageSize, totalCount, currentPage })}
        </ViewingInfo>
      )}
      {showLimitSelection && (
        <SelectContainer>
          <Select
            value={pageSize}
            onChange={(e) => handleSelectSize(e.target.value)}>
            {sizeOptions.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          <IconDropdown />
        </SelectContainer>
      )}
    </Container>
  );
}

function getButtonData({
  pageCount,
  currentPage,
  maxPageButtons,
  maxAdjacent
}) {
  const pageNumbers = range(pageCount).map((index) => index + 1);

  switch (true) {
    case pageCount <= maxPageButtons:
      return pageNumbers.map((pageNumber) => ({
        pageNumber,
        isSelected: pageNumber === currentPage
      }));
    case currentPage <= maxAdjacent:
      return compact(
        pageNumbers.map((pageNumber) => {
          const isPageButton =
            pageNumber <= maxAdjacent || pageNumber === pageCount;
          if (isPageButton) {
            return { pageNumber, isSelected: pageNumber === currentPage };
          }
          if (pageNumber === maxAdjacent + 1) {
            return { pageNumber: "ellipsis" };
          }
          return null;
        })
      );
    case currentPage > pageCount - maxAdjacent:
      return compact(
        pageNumbers.map((pageNumber) => {
          const isPageButton =
            pageNumber > pageCount - maxAdjacent || pageNumber === 1;
          if (isPageButton) {
            return { pageNumber, isSelected: pageNumber === currentPage };
          }
          if (pageNumber === pageCount - maxAdjacent) {
            return { pageNumber: "ellipsis" };
          }
          return null;
        })
      );
    default:
      return compact(
        pageNumbers.map((pageNumber) => {
          const isPageButton =
            pageNumber === 1 ||
            pageNumber === currentPage ||
            pageNumber === pageCount;
          if (isPageButton) {
            return { pageNumber, isSelected: pageNumber === currentPage };
          }
          if (pageNumber === 2 || pageNumber === pageCount - 1) {
            return { pageNumber: "ellipsis" };
          }
          return null;
        })
      );
  }
}

function formatViewingInfo({ pageSize, totalCount, currentPage }) {
  return `Viewing ${(currentPage - 1) * pageSize + 1} - ${
    currentPage * pageSize
  } of ${totalCount}`;
}

Pagination.propTypes = {
  isVisible: PropTypes.bool,
  pageSize: PropTypes.number,
  totalCount: PropTypes.number,
  currentPage: PropTypes.number,
  maxPageButtons: PropTypes.number,
  showInfo: PropTypes.bool,
  showLimitSelection: PropTypes.bool,
  handleSelectSize: PropTypes.func,
  handleClickPage: PropTypes.func
};

Pagination.defaultProps = {
  isVisible: true,
  maxPageButtons: 6,
  maxAdjacent: 3,
  showInfo: true,
  showLimitSelection: true
};

export default Pagination;
