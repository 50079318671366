import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Message = styled.div`
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin-left: 1.2rem;
  color: ${({ theme }) => theme.colors.grey};
`;
