import React, { Component } from "react";
import PropTypes from "prop-types";
import { RouteContainer } from "@wrstudios/components";
import StreamItem from "../../stream/connected/StreamItem";
import LikedRouteLoading from "./LikedRouteLoading";
import { Title, Body, CardLayout } from "./styled/liked-route";

class LikedRoute extends Component {
  render() {
    const { isLoading, hasLoaded, likedStreamItemIds } = this.props;
    return (
      <RouteContainer>
        <Title>Liked</Title>
        <Body>
          <CardLayout>
            {hasLoaded &&
              likedStreamItemIds.map((id) => (
                <StreamItem streamItemId={id} key={id} />
              ))}
          </CardLayout>
          <LikedRouteLoading on={isLoading} />
        </Body>
      </RouteContainer>
    );
  }

  componentDidMount() {
    this.props.getStreamItemsByIds(this.props.likedStreamItemIds);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.likedStreamItemIds.length !==
      this.props.likedStreamItemIds.length
    ) {
      this.props.getStreamItemsByIds(this.props.likedStreamItemIds);
    }
  }
}

LikedRoute.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  likedStreamItemIds: PropTypes.arrayOf(PropTypes.string)
};

export default LikedRoute;
