import React from "react";

function IconHeart(props) {
  return (
    <svg width={18} height={16} fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M9 15l-1.088-1.003C4.05 10.43 1.5 8.077 1.5 5.197 1.5 2.842 3.311 1 5.625 1 6.93 1 8.182 1.618 9 2.59 9.818 1.619 11.07 1 12.375 1 14.689 1 16.5 2.843 16.5 5.196c0 2.88-2.55 5.234-6.412 8.8L9 15z"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconHeart;
