import { connect } from "react-redux";
import { setListingUnderPointer } from "../../../actions/stream";
import StreamItem from "../StreamItem";

function select({ user, streamItems }, { streamItemId }) {
  const streamItem = streamItems.byId[streamItemId] || {};
  const recommendation = streamItem.recommendation || {};

  return {
    type: streamItem.type,
    itemId: streamItem.itemId,
    url: `/stream_items/${streamItemId}`,
    isRecommended: !user.isAgent && !!recommendation.id,
    isLiked: user.isAgent && (streamItem.likers || []).length > 0,
    isHidden: !!streamItem.hidden,
    isClient: user.isClient,
    isAgent: user.isAgent,
    streamItemId
  };
}

const actions = {
  setListingUnderPointer
};

export default connect(select, actions)(StreamItem);
