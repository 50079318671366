import { connect } from "react-redux";
import { deleteClient } from "../../../actions/client";
import { resendClientInvitation } from "../../../actions/client";
import ClientCard from "../ClientCard";

function select({ clients }, { clientId }) {
  const client = clients.byId[clientId] || {};

  return {
    id: client.id,
    name: client.name,
    email: client.email,
    initials: client.initials,
    initialsColor: client.initialsColor,
    avatarUrl: client.avatarUrl,
    updatedAtAgo: client.updatedAtAgo,
    sharedClients: client.sharedClients,
    invitationId: client.invitation.id,
    isPending: client.isPending,
    isDeleting: client.isDeleting,
    isResending: client.isResending,
    hasResendError: client.hasResent,
    resendingError: client.resendingError
  };
}

const actions = {
  deleteClient,
  resendClientInvitation
};

export default connect(select, actions)(ClientCard);
