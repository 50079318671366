import { HelixIcon } from "@helix/helix-icon";
import { wolfie } from "@helix/helix-icon/outlined";
import React from "react";

function LwSSO() {
  return (
    <a
      className="hover:no-underline w-full helix-w-100-percent"
      href={process.env.REACT_APP_LW_PLATFORM_URL}>
      <button className="helix-btn helix-btn--primary helix-btn--medium helix-d-flex helix-w-100-percent">
        <span className="helix-ml-n2">
          <HelixIcon icon={wolfie} />
        </span>
        <span className="helix-ml-2 helix-mr-n1">
          Sign in with Lone Wolf Account
        </span>
      </button>
    </a>
  );
}

export default LwSSO;
