import React from "react";
import PropTypes from "prop-types";
import { pluralize } from "@wrstudios/utils";
import { Container } from "./styled/stream-card-social";

function StreamCardSocial({ likes, comments }) {
  const text = `${pluralize("like", likes, true)} ${pluralize(
    "comment",
    comments,
    true
  )}`;

  return <Container title={text}>{text}</Container>;
}

StreamCardSocial.propTypes = {
  likes: PropTypes.number.isRequired,
  comments: PropTypes.number.isRequired
};

export default StreamCardSocial;
