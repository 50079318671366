import React from "react";
import PropTypes from "prop-types";
import ActivityAvatar from "../connected/ActivityAvatar";
import ActivityContent from "../connected/ActivityContent";
import ActivityListing from "../connected/ActivityListing";
import { Container, Body, Image, Content, Action } from "./styled/activity";

function ActivityLink({ activityId, hasListingIds, children }) {
  return (
    <Container>
      <Image>
        <ActivityAvatar activityId={activityId} />
      </Image>
      <Body>
        <Content>
          <ActivityContent activityId={activityId}>{children}</ActivityContent>
        </Content>
        {hasListingIds && (
          <Action>
            <ActivityListing activityId={activityId} />
          </Action>
        )}
      </Body>
    </Container>
  );
}

ActivityLink.propTypes = {
  activityId: PropTypes.string.isRequired,
  hasListingIds: PropTypes.bool.isRequired
};

export default ActivityLink;
