import { getInitials } from "@wrstudios/utils";
import { getInitialsColor } from "@wrstudios/theme";

export function transformInvitations(invitations = []) {
  return invitations.reduce(
    (state, invitation) => {
      const transformedInvitation = transformInvitation(invitation);

      return {
        ...state,
        invitations: {
          ...state.invitations,
          [transformedInvitation.id]: transformedInvitation
        },
        invitationIds: [...state.invitationIds, transformedInvitation.id]
      };
    },
    { invitations: {}, invitationIds: [] }
  );
}

export function transformInvitation(invitation = {}) {
  return {
    id: invitation.id || "",
    streamId: String(invitation.stream_id || ""),
    email: invitation.email || "",
    name: invitation.name || invitation.invitee_name || "",
    initials: getInitials(
      invitation.name || invitation.invitee_name || invitation.email || ""
    ),
    initialsColor: getInitialsColor(
      invitation.name || invitation.invitee_name || invitation.email || ""
    ),
    frequency: invitation.frequency || "",
    guid: (invitation.last_invitation || {}).guid || invitation.guid || "",
    updatedAt: invitation.updated_at || ""
  };
}
