import styled from "styled-components";

export const Container = styled.div``;

export const Plans = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const Plan = styled.li`
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Grid = styled.div`
  display: flex;
  align-items: center;
  margin: -1rem;
`;

export const Cell = styled.div`
  width: 50%;
  padding: 1rem;
`;
