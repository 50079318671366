import React from "react";

function IconPlayCircle(props) {
  return (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <circle stroke="#0AA1DC" cx="9" cy="9" r="9" />
        <path fill="#0AA1DC" d="M6.75 13.5v-9L13.5 9z" />
      </g>
    </svg>
  );
}

export default IconPlayCircle;
