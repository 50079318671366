export const initial = {
  byId: {},
  commentNetwork: { isFetching: false, hasFetched: false, errors: [] },
  getItemNetwork: { isFetching: true, hasFetched: false, errors: [] }
};

export function streamItems(state = initial, { type, payload }) {
  switch (type) {
    case "GET_STREAMS_SUCCEEDED":
    case "GET_STREAM_SUCCEEDED":
    case "GET_STREAM_ITEMS_SUCCEEDED":
    case "CREATE_STREAM_SUCCEEDED":
    case "UPDATE_STREAM_SUCCEEDED":
    case "RENAME_STREAM_SUCCEEDED":
    case "GET_CLIENT_LIKED_LISTINGS_SUCCEEDED":
    case "GET_CLIENT_RECOMMENDED_LISTINGS_SUCCEEDED":
      return { ...state, byId: { ...state.byId, ...payload.streamItems.byId } };
    case "GET_STREAM_ITEM_INITIATED":
    case "GET_PUBLIC_STREAM_ITEM_INITIATED":
      return {
        ...state,
        getItemNetwork: { isFetching: true, hasFetched: false, errors: [] }
      };
    case "GET_STREAM_ITEM_SUCCEEDED":
    case "GET_PUBLIC_STREAM_ITEM_SUCCEEDED":
      return {
        ...state,
        byId: { ...state.byId, [payload.streamItem.id]: payload.streamItem },
        getItemNetwork: { isFetching: false, hasFetched: true, errors: [] }
      };
    case "GET_STREAM_ITEM_FAILED":
    case "GET_PUBLIC_STREAM_ITEM_FAILED":
      return {
        ...state,
        getItemNetwork: {
          isFetching: false,
          hasFetched: false,
          errors: payload.errorsArray
        }
      };
    case "CREATE_RECOMMENDATION_RESOLVED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.streamItemId]: {
            ...state.byId[payload.streamItemId],
            recommendation: payload.recommendation
          }
        }
      };
    case "DELETE_RECOMMENDATION_RESOLVED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.streamItemId]: {
            ...state.byId[payload.streamItemId],
            recommendation: null
          }
        }
      };
    case "GET_MINIMAL_STREAM_ITEM_SUCCEEDED":
      return {
        ...state,
        byId: { ...state.byId, [payload.streamItem.id]: payload.streamItem }
      };
    case "UPDATE_STREAM_ITEM_LIKE_INITIATED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: { ...state.byId[payload.id], liked: payload.liked }
        }
      };
    case "UPDATE_STREAM_ITEM_HIDE_INITIATED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: { ...state.byId[payload.id], hidden: payload.hidden }
        }
      };
    case "UPDATE_STREAM_ITEM_LIKE_SUCCEEDED":
    case "UPDATE_STREAM_ITEM_HIDE_SUCCEEDED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: { ...state.byId[payload.id], ...payload.streamItem }
        }
      };
    case "CREATE_STREAM_ITEM_COMMENT_INITIATED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.comment.streamItemId]: {
            ...state.byId[payload.comment.streamItemId],
            comments: [
              ...state.byId[payload.comment.streamItemId].comments,
              payload.comment
            ]
          }
        },
        commentNetwork: { isFetching: true, hasFetched: false, errors: [] }
      };
    case "CREATE_STREAM_ITEM_COMMENT_SUCCEEDED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.comment.streamItemId]: {
            ...state.byId[payload.comment.streamItemId],
            comments: [...payload.comments, payload.comment]
          }
        },
        commentNetwork: { isFetching: false, hasFetched: true, errors: [] }
      };
    case "CREATE_STREAM_ITEM_COMMENT_FAILED":
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.comment.streamItemId]: {
            ...state.byId[payload.comment.streamItemId],
            comments: payload.comments
          }
        },
        commentNetwork: {
          isFetching: false,
          hasFetched: false,
          errors: payload.errorsArray
        }
      };
    case "ADD_CLIENTS_FOR_CURATION_RESOLVED":
      return {
        ...state,
        byId: {
          ...state.byId,
          ...Object.values(state.byId)
            .filter((streamItem) => streamItem.streamId === payload.streamId)
            .map((streamItem) => ({
              ...streamItem,
              subscriptions: {
                ...streamItem.subscriptions,
                ...payload.subscriptions
              }
            }))
        }
      };
    case "CLEAR_API_TOKEN":
      return initial;
    case "UPDATE_USER_MLS_SUCCEEDED":
      return initial;
    default:
      return state;
  }
}
