import { connect } from "react-redux";
import StreamItemRecommends from "../StreamItemRecommends";

function select({ streamItems, user }, { streamItemId }) {
  const streamItem = streamItems.byId[streamItemId] || {};
  const recommendation = streamItem.recommendation || {};

  return {
    name: recommendation.name || "",
    isHidden: !recommendation.name || recommendation.agentId === user.id
  };
}

export default connect(select)(StreamItemRecommends);
