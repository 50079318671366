export const initial = {
  planId: "",
  name: "",
  amount: "",
  amountFormatted: "",
  nextRenewal: "",
  nextRenewalFormatted: "",
  cardNumber: "",
  cardExpiration: "",
  billingId: "",
  state: "",
  billingNetwork: { isFetching: false, hasFetched: false, errors: [] }
};

export function billing(state = initial, { type, payload }) {
  switch (type) {
    case "GET_BILLING_INFO_SUCCEEDED":
      return { ...state, ...payload, billingNetwork: initial.billingNetwork };
    case "UPDATE_BILLING_INFO_INITIATED":
      return {
        ...state,
        billingNetwork: { isFetching: true, hasFetched: false, errors: [] }
      };
    case "UPDATE_BILLING_INFO_SUCCEEDED":
      return {
        ...state,
        billingNetwork: { isFetching: false, hasFetched: true, errors: [] }
      };
    case "UPDATE_BILLING_INFO_FAILED":
      return {
        ...state,
        billingNetwork: {
          isFetching: false,
          hasFetched: false,
          errors: payload.errorsArray
        }
      };
    case "CLEAR_API_TOKEN":
      return initial;
    default:
      return state;
  }
}
