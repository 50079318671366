import "react-virtualized/styles.css";
import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { List as RVList } from "react-virtualized";
import IconSearch from "../icons/IconSearch";
import IconTimes from "../icons/IconTimes";
import {
  Container,
  InnerContainer,
  Grid,
  Cell,
  List,
  ListItem,
  Pill,
  Input,
  Remove,
  AllItems,
  Item,
  Controls,
  Control
} from "./styled/pill-search";

function PillSearch({ allItems, selectedItems, onSelect, onRemove, onClose }) {
  const containerRef = useRef();
  const [showInput, setShowInput] = useState(false);
  const [showTagsWithInput, setShowTagsWithinput] = useState(false);
  const hasSelectedItems = !!selectedItems.length;

  const handleClose = useCallback(
    (e) => {
      if (e) e.stopPropagation();
      setShowInput(false);
      setShowTagsWithinput(false);
      if (showTagsWithInput || showInput) onClose();
    },
    [onClose, showInput, showTagsWithInput]
  );

  const handleActivate = () => {
    if (hasSelectedItems) {
      setShowTagsWithinput(true);
    } else {
      setShowInput(true);
    }
  };

  const handleSelect = (item) => {
    setShowInput(false);
    setShowTagsWithinput(true);
    onSelect(item);
  };

  const handleRemove = (item) => {
    if (selectedItems.length - 1 <= 0) {
      setShowTagsWithinput(false);
      setShowInput(true);
    }
    onRemove(item);
  };

  const handleClear = (e) => {
    e.stopPropagation();
    setShowTagsWithinput(false);
    setShowInput(true);
    selectedItems.forEach(onRemove);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape" || e.key === "Tab") {
      e.preventDefault();
      handleClose();
    }
  };

  useEffect(() => {
    function handler(e) {
      if (
        containerRef.current === e.target ||
        containerRef.current.contains(e.target)
      ) {
        return;
      }

      handleClose();
    }

    window.addEventListener("click", handler);

    return () => {
      window.removeEventListener("click", handler);
    };
  }, [showInput, showTagsWithInput, onClose, handleClose]);

  return (
    <Container
      ref={containerRef}
      onClick={handleActivate}
      tabIndex="0"
      onFocus={handleActivate}
      onKeyDown={handleKeyDown}>
      <InnerContainer>
        <Grid>
          <Cell>
            <IconSearch />
          </Cell>
          <Cell>
            {!hasSelectedItems && (
              <Input
                placeholder={`Enter ${
                  selectedItems.length > 1 ? "another" : "a"
                } location`}
                hasItems={false}
                readOnly
              />
            )}
            {hasSelectedItems && (
              <PillSelectedItems
                selectedItems={selectedItems}
                isOpen={showInput}
                onSelect={handleSelect}
              />
            )}
          </Cell>
        </Grid>
      </InnerContainer>
      {showInput && !hasSelectedItems && (
        <PillSearchWithInput
          allItems={allItems}
          selectedItems={selectedItems}
          hideInput={() => setShowInput(false)}
          onSelect={handleSelect}
          onClose={handleClose}
        />
      )}
      {!showInput && showTagsWithInput && (
        <PillSearchWithTags
          allItems={allItems}
          selectedItems={selectedItems}
          onSelect={handleSelect}
          onRemove={handleRemove}
          onClear={handleClear}
          onClose={handleClose}
        />
      )}
    </Container>
  );
}

PillSearch.defaultProps = {
  onClose: () => {}
};

PillSearch.propTypes = {
  allItems: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onClose: PropTypes.func
};

function PillSearchWithInput({ allItems, selectedItems, hideInput, onSelect }) {
  const inputRef = useRef();
  const [query, setQuery] = useState("");
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const filteredItems = allItems
    .filter((item) => item.toLowerCase().includes(query.toLowerCase()))
    .filter((item) => !selectedItems.includes(item));

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }

    if (e.key === "ArrowUp") {
      setHighlightedIndex((highlightedIndex) => {
        const nextIndex = highlightedIndex - 1;
        if (nextIndex < 0) return filteredItems.length - 1;
        return nextIndex;
      });
    } else if (e.key === "ArrowDown") {
      setHighlightedIndex((highlightedIndex) => {
        const nextIndex = highlightedIndex + 1;
        if (nextIndex > filteredItems.length - 1) return 0;
        return nextIndex;
      });
    } else if (e.key === "Enter") {
      if (query.length) {
        const item = filteredItems[highlightedIndex];
        if (item) handleSelect(item);
      }
    }
  };

  const handleSelect = (item) => {
    setQuery("");
    setHighlightedIndex(0);
    hideInput();
    onSelect(item);
  };

  useEffect(() => {
    setTimeout(() => {
      inputRef.current.focus();
    });
  }, []);

  return (
    <Container isOverlay onKeyDown={handleKeyDown}>
      <InnerContainer>
        <Grid>
          <Cell>
            <IconSearch />
          </Cell>
          <Cell>
            <Input
              ref={inputRef}
              placeholder="Enter a location"
              hasItems={!!selectedItems.length}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </Cell>
        </Grid>
      </InnerContainer>
      {!!query.length && (
        <PillItems
          items={filteredItems}
          highlightedIndex={highlightedIndex}
          onSelect={handleSelect}
        />
      )}
    </Container>
  );
}

function PillSearchWithTags({
  allItems,
  selectedItems,
  onSelect,
  onRemove,
  onClear,
  onClose
}) {
  const inputRef = useRef();
  const [query, setQuery] = useState("");
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const filteredItems = allItems
    .filter((item) => item.toLowerCase().includes(query.toLowerCase()))
    .filter((item) => !selectedItems.includes(item));

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }

    if (e.key === "ArrowUp") {
      setHighlightedIndex((highlightedIndex) => {
        const nextIndex = highlightedIndex - 1;
        if (nextIndex < 0) return filteredItems.length - 1;
        return nextIndex;
      });
    } else if (e.key === "ArrowDown") {
      setHighlightedIndex((highlightedIndex) => {
        const nextIndex = highlightedIndex + 1;
        if (nextIndex > filteredItems.length - 1) return 0;
        return nextIndex;
      });
    } else if (e.key === "Enter") {
      if (query.length) {
        const item = filteredItems[highlightedIndex];
        if (item) handleSelect(item);
      } else if (selectedItems.length) {
        onClose();
      }
    } else if (e.key === "Backspace") {
      if (e.target.value.length <= 0) {
        const lastElement = [...selectedItems].pop();
        onRemove(lastElement);
      }
    }
  };

  const handleSelect = (item) => {
    setQuery("");
    setHighlightedIndex(0);
    onSelect(item);
    inputRef.current.focus();
  };

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <Container isOverlay onKeyDown={handleKeyDown}>
      <InnerContainer>
        <Grid>
          <Cell>
            <IconSearch />
          </Cell>
          <Cell>
            <PillSelectedItems
              selectedItems={selectedItems}
              isOpen={true}
              onSelect={handleSelect}
              onRemove={onRemove}
            />
            <Input
              ref={inputRef}
              placeholder="Enter another location"
              hasItems={!!selectedItems.length}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </Cell>
        </Grid>
      </InnerContainer>
      {!!query.length && (
        <PillItems
          items={filteredItems}
          highlightedIndex={highlightedIndex}
          onSelect={handleSelect}
        />
      )}
      {!!selectedItems.length && (
        <Controls>
          <div>
            <Control onClick={onClear}>Clear</Control>
          </div>
          <div>
            <Control isConfirm onClick={onClose}>
              Apply
            </Control>
          </div>
        </Controls>
      )}
    </Container>
  );
}

function PillSelectedItems({ selectedItems, isOpen, onRemove }) {
  const showCount = !isOpen && selectedItems.length > 1;
  const items = !isOpen
    ? selectedItems.slice(selectedItems.length - 1, selectedItems.length)
    : selectedItems;

  return (
    <List>
      {items.map((item) => (
        <ListItem key={item}>
          <Pill>
            {item}
            {isOpen && (
              <Remove
                onClick={(e) => {
                  e.stopPropagation();
                  onRemove(item);
                }}>
                <IconTimes />
              </Remove>
            )}
          </Pill>
        </ListItem>
      ))}
      {showCount && (
        <ListItem>
          <Pill>{selectedItems.length - 1} More</Pill>
        </ListItem>
      )}
    </List>
  );
}

function PillItems({ items, highlightedIndex, onSelect }) {
  return (
    <AllItems>
      <RVList
        width={100}
        height={100}
        scrollToIndex={highlightedIndex}
        rowCount={items.length}
        rowHeight={34}
        rowRenderer={({ index, key, style }) => {
          const item = items[index];
          const isHighlighted = highlightedIndex === index;

          return (
            <Item
              key={key}
              style={style}
              title={item}
              isHighlighted={isHighlighted}
              onClick={(e) => {
                e.stopPropagation();
                onSelect(item);
              }}>
              {item}
            </Item>
          );
        }}
      />
    </AllItems>
  );
}

export default PillSearch;
