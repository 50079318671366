import styled from "styled-components";

export const Container = styled.div`
  padding: 1.4rem 0;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.whiteDark};

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    padding: 1.2rem 0;
    border: 0;
  }

  &:last-child {
    border-bottom: 0;
  }
`;
