import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { AnimatePresence, motion } from "framer-motion";
import StreamItem from "../stream/connected/StreamItem";
import { CardLayout, Empty } from "../routes/clients/styled/client-route";

function ClientRecommendedListings({
  isLoading,
  clientId,
  client,
  hasStreamItems,
  recommendedStreamItemIds,
  getClientRecommendedListings
}) {
  useEffect(() => {
    getClientRecommendedListings({ clientId });
  }, [clientId, getClientRecommendedListings]);

  return (
    <AnimatePresence>
      {!isLoading && !hasStreamItems && (
        <motion.div key="empty-state" {...animationConfig}>
          <Empty>
            You have not recommended any listings to {client.name.split(" ")[0]}
          </Empty>
        </motion.div>
      )}
      {!isLoading && hasStreamItems && (
        <motion.div key="data-state" {...animationConfig}>
          <CardLayout>
            {recommendedStreamItemIds.map((streamItemId) => (
              <StreamItem key={streamItemId} streamItemId={streamItemId} />
            ))}
          </CardLayout>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

ClientRecommendedListings.propTypes = {
  client: PropTypes.shape({
    name: PropTypes.string
  }),
  clientId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasStreamItems: PropTypes.bool.isRequired,
  recommendedStreamItemIds: PropTypes.arrayOf(PropTypes.string),
  getClientRecommendedListings: PropTypes.func.isRequired
};

export default ClientRecommendedListings;

const animationConfig = {
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
};
