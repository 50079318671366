import React from "react";
import PropTypes from "prop-types";
import { FullscreenMedia } from "@wrstudios/components";
import IconBackArrow from "../icons/IconBackArrow";
import { Container, Arrow } from "./styled/listing-fullscreen-media";

function ListingFullscreenMedia({
  listingId,
  isAuthed,
  setFullscreenMediaDetails,
  ...props
}) {
  return (
    <Container isAuthed={isAuthed}>
      <FullscreenMedia
        {...props}
        exitIcon={
          <Arrow>
            <IconBackArrow />
          </Arrow>
        }
        onExit={() => {
          setFullscreenMediaDetails({
            id: listingId,
            open: false,
            tab: "map",
            photoIndex: 0
          });
        }}
      />
    </Container>
  );
}

ListingFullscreenMedia.propTypes = {
  listingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  isAuthed: PropTypes.bool.isRequired,
  setFullscreenMediaDetails: PropTypes.func.isRequired
};

export default ListingFullscreenMedia;
