import { buildRailsApiClient } from ".";

export async function setPrimaryAlertType({
  streamId,
  alertType,
  railsApiToken
}) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const mutation = /* GraphQL */ `
    mutation setPrimaryAlertType($stream_id: ID!, $alert_type: AlertTypes!) {
      setPrimaryAlertType(stream_id: $stream_id, alert_type: $alert_type) {
        id
        primary_alert_type
        stream_subscriptions {
          id
          stream_id
          user_id
          email_frequency
          sms_alert_type
          user {
            id
            type
            name
            email
            avatar
            primary_photo
            social_profiles {
              username
              url
              service
            }
            social_data_status
          }
        }
      }
    }
  `;
  const variables = { stream_id: streamId, alert_type: alertType };
  const { setPrimaryAlertType } = await railsApiClient.request(
    mutation,
    variables
  );

  return setPrimaryAlertType;
}
