import { connect } from "react-redux";
import Developers from "../Developers";

function select({ application: { railsApiToken, forgeToken } }) {
  return {
    isAuthed: !!railsApiToken && !!forgeToken
  };
}

const actions = {};

export default connect(select, actions)(Developers);
