import { connect } from "react-redux";
import { ListingSchools } from "@wrstudios/components";

function select({ listings: { byId } }, { listingId }) {
  const listings = byId[listingId] || {};
  const schools = listings.schools || [];
  const hasSchools = !!schools.length;

  return {
    schools,
    hasSchools
  };
}

export default connect(select)(ListingSchools);
