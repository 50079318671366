export const initial = {
  isLoggingIn: false,
  loginError: null,
  isRequestingPasswordReset: false,
  requestPasswordResetSuccess: false,
  requestPasswordResetError: null,
  isResettingPassword: false,
  resetPasswordError: null,
  newRailsApiToken: null,
  friendlyRedirect: ""
};

export function login(state = initial, { type, payload }) {
  switch (type) {
    case "LOGIN_INITIATED":
      return { ...state, isLoggingIn: true };
    case "LOGIN_SUCCEEDED":
      return { ...state, isLoggingIn: false, loginError: null };
    case "LOGIN_FAILED":
      return { ...state, isLoggingIn: false, loginError: payload.message };
    case "RESET_LOGIN_TO_DEFAULT":
      const { isLoggingIn, loginError } = initial;
      return { ...state, isLoggingIn, loginError };
    case "REQUEST_PASSWORD_RESET_INITIATED":
      return { ...state, isRequestingPasswordReset: true };
    case "REQUEST_PASSWORD_RESET_SUCCEEDED":
      return {
        ...state,
        isRequestingPasswordReset: false,
        requestPasswordResetSuccess: true,
        requestPasswordResetError: null
      };
    case "REQUEST_PASSWORD_RESET_FAILED":
      return {
        ...state,
        isRequestingPasswordReset: false,
        requestPasswordResetSuccess: false,
        requestPasswordResetError: payload.message
      };
    case "REQUEST_PASSWORD_RESET_INVALID":
      return {
        ...state,
        isRequestingPasswordReset: false,
        requestPasswordResetSuccess: false,
        requestPasswordResetError: payload
      };
    case "RESET_REQUEST_PASSWORD_TO_DEFAULT":
      const {
        isRequestingPasswordReset,
        requestPasswordResetSuccess,
        requestPasswordResetError
      } = initial;
      return {
        ...state,
        isRequestingPasswordReset,
        requestPasswordResetSuccess,
        requestPasswordResetError
      };
    case "RESET_PASSWORD_INITIATED":
      return { ...state, isResettingPassword: true };
    case "RESET_PASSWORD_SUCCEEDED":
      return {
        ...state,
        isResettingPassword: false,
        resetPasswordError: null,
        newRailsApiToken: payload.token
      };
    case "RESET_PASSWORD_FAILED":
      return {
        ...state,
        isResettingPassword: false,
        resetPasswordError: payload.message
      };
    case "RESET_PASSWORD_INVALID":
      return {
        ...state,
        isResettingPassword: false,
        resetPasswordError: payload
      };
    case "SET_FRIENDLY_REDIRECT":
      return { ...state, friendlyRedirect: payload };
    default:
      return state;
  }
}
