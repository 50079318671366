import PropTypes from "prop-types";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import AgentInfoDropdown from "../user/connected/AgentInfoDropdown";
import UserMenu from "../user/connected/UserMenu";
import LegacyNavigation from "./connected/LegacyNavigation";
import NewNavigation from "./connected/NewNavigation";
import { Cell, Container, Navigation, NavigationLink } from "./styled/header";

export default function Header({
  lwaJwt,
  isImpersonating,
  isClient,
  isAppHeaderSticky,
  showLikedNavLink
}) {
  const match = useRouteMatch("/product_connector");

  if (match) {
    return null;
  }

  if (isClient) {
    return (
      <Container role="navigation" isAgent={false} isSticky={isAppHeaderSticky}>
        <Cell align="left">
          <AgentInfoDropdown />
        </Cell>
        <Cell align="center">
          <Navigation>
            <NavigationLink to="/activity">Activity</NavigationLink>
            <NavigationLink to="/streams">Streams</NavigationLink>
            {showLikedNavLink && (
              <NavigationLink to="/liked">Liked</NavigationLink>
            )}
          </Navigation>
        </Cell>
        <Cell align="right">
          <UserMenu />
        </Cell>
      </Container>
    );
  }

  return (
    <Container isAgent isSticky={isAppHeaderSticky}>
      {lwaJwt && !isImpersonating ? <NewNavigation /> : <LegacyNavigation />}
    </Container>
  );
}

Header.propTypes = {
  lwaJwt: PropTypes.string,
  isAppHeaderSticky: PropTypes.bool,
  isClient: PropTypes.bool.isRequired,
  showLikedNavLink: PropTypes.bool.isRequired
};
