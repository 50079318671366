/**
 * Constructors a media listener to programmatically listen for media query changes.
 */
class Media {
  /**
   * The constructor.
   *
   * @param {String} query
   */
  constructor(query) {
    this.media = window.matchMedia(query);
  }

  /**
   * Registers a media listener.
   *
   * @param {Function} listener
   */
  addListener(listener) {
    this.media.addListener(listener);

    // Called initially
    listener(this.media);
  }

  /**
   * Unregisters a media listener.
   *
   * @param {String} listener
   */
  removeListener(listener) {
    this.media.removeListener(listener);
  }
}

export default Media;
