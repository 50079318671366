import styled from "styled-components";
import { Modal as BaseModal } from "@wrstudios/components";

export const Modal = styled(BaseModal)`
  background-color: rgba(255, 255, 255, 0.5);
`;

export const ModalContent = styled(BaseModal.Content)`
  width: 100%;
  max-width: 61rem;
  overflow: hidden;
`;

export const ModalHeader = styled(BaseModal.Header)`
  font-size: 2.8rem;
  line-height: 3.2rem;

  svg {
    fill: ${({ theme }) => theme.colors.blackLight};
  }
`;

export const ModalFooter = styled(BaseModal.Footer)`
  margin: 2rem -2rem -2rem;
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.greyLightest};
  border-top: 0.1rem solid ${({ theme }) => theme.colors.greyLighter};
`;

export const Text = styled.p`
  font-size: 1.4rem;
  line-height: 2.4rem;
  margin: 0;
  padding-right: 2rem;
`;

export const Agent = styled.button`
  display: flex;
  width: 100%;
  margin-bottom: 2.4rem;
  padding: 1.7rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: 0.1rem solid
    ${({ theme, isActive }) =>
      isActive ? theme.colors.grey : theme.colors.greyLighter};
  border-radius: 0.4rem;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-align: left;
  outline: none;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.streamsBlue};
  }
`;

export const AgentAvatar = styled.span`
  display: block;
  margin-right: 1.6rem;
`;

export const AgentDetails = styled.span``;

export const AgentName = styled.span`
  display: block;
  font-size: 1.8rem;
  line-height: 2.2rem;
  margin-bottom: 0.4rem;
`;

export const AgentCompany = styled.span`
  display: block;
  font-size: 1.4rem;
  line-height: 2rem;
  margin-bottom: 0.4rem;
`;

export const AgentMLS = styled.i`
  display: block;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.grey};
`;
