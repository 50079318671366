import { connect } from "react-redux";
import {
  updateUserMLSCredentials,
  resetUserMLSNetwork
} from "../../../../actions/user";
import SettingsRouteMLS from "../SettingsRouteMLS";

function select({
  user: {
    isAgent,
    isSSOUser,
    mlsCode,
    mlsName,
    mlsNetwork: { isFetching, hasFetched, errors }
  },
  application: { states },
  mls: { all }
}) {
  const statesAsArray = Object.values(states.byId);
  const statesLabels = statesAsArray.map(({ label }) => label);
  const selectedMLS = all.byId[mlsCode] || {};
  const [state = null] = Object.values(selectedMLS.state || {});

  return {
    state,
    states: statesAsArray,
    statesLabels,
    isAgent,
    isSSOUser,
    mlses: Object.values(all.byId),
    mlsesById: all.byId,
    mlsCode,
    mlsUserId: mlsName,
    isFetching,
    hasFetched,
    errors
  };
}

const actions = {
  updateUserMLSCredentials,
  resetUserMLSNetwork
};

export default connect(select, actions)(SettingsRouteMLS);
