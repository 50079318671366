import styled from "styled-components";

export const Container = styled.button`
  cursor: pointer;

  &:hover,
  &:focus {
    svg {
      stroke: ${({ theme }) => theme.colors.red};
    }
  }

  svg {
    stroke: ${({ theme, active }) =>
      active ? theme.colors.red : theme.colors.grey};
    fill: ${({ theme, active }) =>
      active ? theme.colors.red : theme.colors.white};
  }
`;
