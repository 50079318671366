import { connect } from "react-redux";
import { deleteStream } from "../../../actions/stream";
import { getClientStreams } from "../../../actions/client";
import ClientStreams from "../ClientStreams";

function select({ user, clientStreams: streams, clients }, { match }) {
  const clientId = match.params.clientId;
  const client = clients.byId[clientId];
  const hasStreams =
    client.streamIds.length > 0 &&
    client.streamIds.every((streamId) => streams.byId[streamId]);
  const isLoading = !hasStreams && streams.isFetching;

  return {
    user,
    streams,
    clientId,
    isLoading,
    hasStreams,
    streamIds: client.streamIds
  };
}

const actions = {
  getClientStreams,
  deleteStream
};

export default connect(select, actions)(ClientStreams);
