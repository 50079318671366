import { connect } from "react-redux";
import { getClients, resetClients } from "../../../actions/client";
import ClientCards from "../ClientCards";

function select({ clients }) {
  return {
    clientIds: clients.filteredIds,
    page: clients.page,
    limit: clients.limit,
    totalCount: clients.count,
    hasLoaded: clients.hasLoadedClients
  };
}

const actions = {
  getClients,
  resetClients
};

export default connect(select, actions)(ClientCards);
