import { getMLSField, initMLS as initMLSAPI } from "../modules/forge";
import {
  transformMLSConfigs,
  transformMLSes,
  transformFields,
  transformField,
  transformStatus,
  transformLocations,
  getLocationsLabels
} from "../modules/mls";

export async function initMLS({ mlsKey, forgeToken }) {
  const { mls, mlses } = await initMLSAPI({ mlsKey, forgeToken });
  const {
    area,
    city,
    config,
    fields,
    prop_sub_type,
    prop_type,
    status_mapping,
    zip
  } = mls || {};

  const mlsFields = mls
    ? [...fields, city, area, zip, prop_type, prop_sub_type].filter(Boolean)
    : [];
  const locations = [city, area, zip].filter(Boolean);

  const transformedMLSes = transformMLSes(mlses || []);
  const transformedMLSConfigs = transformMLSConfigs(config || "{}");
  const transformedLocations = transformLocations(locations);
  const locationsLabels = getLocationsLabels(transformedLocations);
  const transformedMLSFields = transformFields(mlsFields);
  const transformedMLSStatus = transformStatus(status_mapping);

  return {
    mlses: transformedMLSes,
    configs: transformedMLSConfigs,
    locations: transformedLocations,
    locationsLabels,
    fields: transformedMLSFields,
    status: transformedMLSStatus
  };
}

export function getMLSFineTuningField(field) {
  return async (dispatch, getState) => {
    const {
      application: { forgeToken },
      user: { mlsCode }
    } = getState();

    dispatch({ type: "GET_MLS_FINE_TUNING_FIELD_INITIATED" });

    try {
      const { mls } = await getMLSField({ field, mlsCode, forgeToken });

      dispatch({
        type: "GET_MLS_FINE_TUNING_FIELD_SUCCEEDED",
        payload: transformField(mls.field)
      });
    } catch (error) {
      dispatch({
        type: "GET_MLS_FINE_TUNING_FIELD_FAILED",
        payload: { error }
      });
    }
  };
}
