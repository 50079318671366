import styled from "styled-components";

export const ToastWrapper = styled.div`
  .Toastify__toast-container {
    width: 528px;
  }
  .Toastify__toast {
    padding: 16px 40px 16px 16px;
    font-family: "SF Pro Text";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    border-radius: 4px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  .Toastify__toast-body {
    padding: 0;
  }
  .Toastify__toast--success {
    color: #033003;
    background-color: #e7fee7;
    border: 1px solid #a3f5a3;
  }

  .Toastify__toast--error {
    background-color: #fee7e7;
    border: 1px solid #f7baba;
    color: #4b0202;
  }
  .Toastify__toast--warning {
    color: #4d2e00;
    background: #fef6e6;
    border: 1px solid #fde0b4;
  }
  .Toastify__toast--info {
    background: #e7eefe;
    border: 1px solid #bacff7;
    color: #041b49;
  }
  .Toastify__toast--default {
    border: 1px solid #dddddd;
  }
  .Toastify__toast-icon {
    margin-right: 16px;
  }
`;
