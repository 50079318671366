import React, { Component } from "react";
import PropTypes from "prop-types";
import { Waypoint } from "react-waypoint";
import ActivitiesLoading from "./ActivitiesLoading";
import Activity from "./connected/Activity";
import { Container, Empty, Notice } from "./styled/activities";

class Activities extends Component {
  render() {
    const {
      activityIds,
      hasActivityIds,
      fullscreen,
      isLoading,
      isComplete,
      client,
      mlsConfigsLoaded,
      getActivities
    } = this.props;

    return (
      <>
        <Container fullscreen={fullscreen}>
          {!hasActivityIds && !isLoading && (
            <Empty>
              {!!client
                ? `${client.name.split(" ")[0]} has no activity yet.`
                : "No activity for the past 90 days."}
            </Empty>
          )}
          {activityIds.map((activityId) => (
            <Activity key={activityId} activityId={activityId} />
          ))}
          {mlsConfigsLoaded && !isLoading && !isComplete && (
            <Waypoint onEnter={getActivities} bottomOffset={-500} />
          )}
          {isLoading && <ActivitiesLoading />}
        </Container>
        {hasActivityIds && isComplete && (
          <Notice>End of activity in the past 90 days.</Notice>
        )}
      </>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter) {
      this.props.getActivities();
    }
  }

  componentWillUnmount() {
    this.props.resetActivities();
  }
}

Activities.propTypes = {
  client: PropTypes.shape({
    name: PropTypes.string
  }),
  clientId: PropTypes.string,
  activityIds: PropTypes.array.isRequired,
  hasActivityIds: PropTypes.bool.isRequired,
  fullscreen: PropTypes.bool.isRequired,
  filter: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isComplete: PropTypes.bool.isRequired,
  mlsConfigsLoaded: PropTypes.bool.isRequired,
  getActivities: PropTypes.func.isRequired,
  resetActivities: PropTypes.func.isRequired
};

Activities.defaultProps = {
  fullscreen: true,
  clientId: undefined
};

export default Activities;
