import React, { Component } from "react";
import PropTypes from "prop-types";
import { Menu } from "@wrstudios/components";
import IconDropdownArrowDown from "../icons/IconDropdownArrowDown";
import IconEmail from "../icons/IconEmail";
import { MenuButton, MenuButtonIcon } from "./styled/stream-frequency";
import {
  emailFrequencies,
  getFrequencyLabel,
  getFrequencyName
} from "./invite/utils";

class StreamEmailFrequency extends Component {
  state = {
    frequency: this.props.initialValue
  };
  render() {
    const { showLabel } = this.props;
    const { frequency } = this.state;
    return (
      <Menu>
        <MenuButton isHighlighted={frequency !== emailFrequencies.NEVER}>
          <MenuButtonIcon>
            <IconEmail />
          </MenuButtonIcon>
          {showLabel
            ? getFrequencyLabel(frequency)
            : getFrequencyName(frequency)}
          <IconDropdownArrowDown />
        </MenuButton>
        <Menu.List offset={6} showPoint={false}>
          <Menu.Item
            onClick={() => this.handleSelect(emailFrequencies.REAL_TIME)}>
            Real-time Email
          </Menu.Item>
          <Menu.Item onClick={() => this.handleSelect(emailFrequencies.DAILY)}>
            Daily Email
          </Menu.Item>
          <Menu.Item onClick={() => this.handleSelect(emailFrequencies.WEEKLY)}>
            Weekly Email
          </Menu.Item>
          <Menu.Item onClick={() => this.handleSelect(emailFrequencies.NEVER)}>
            Never Email
          </Menu.Item>
        </Menu.List>
      </Menu>
    );
  }

  handleSelect = (frequency) => {
    this.setState({ frequency });
    this.props.onSelect(frequency);
  };
}

StreamEmailFrequency.propTypes = {
  initialValue: PropTypes.oneOf(Object.values(emailFrequencies)),
  showLabel: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired
};

StreamEmailFrequency.defaultProps = {
  initialValue: emailFrequencies.NEVER,
  showLabel: true
};

export default StreamEmailFrequency;
