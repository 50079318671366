export const initial = {
  redirectUrl: "",
  connectNetwork: { isFetching: false, hasFetched: false, errors: [] }
};

export function cas(state = initial, { type, payload }) {
  switch (type) {
    case "CONNECT_CAS_ACCOUNT_INITIATED":
      return {
        ...state,
        connectNetwork: { isFetching: false, hasFetched: false, errors: [] }
      };
    case "CONNECT_CAS_ACCOUNT_FAILED":
      return {
        ...state,
        connectNetwork: {
          isFetching: false,
          hasFetched: false,
          errors: payload.errorsArray
        }
      };
    case "CONNECT_CAS_ACCOUNT_SUCCEEDED":
      return {
        ...state,
        ...payload,
        connectNetwork: { isFetching: false, hasFetched: true, errors: [] }
      };
    default:
      return state;
  }
}
