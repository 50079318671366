import React, { Component } from "react";
import PropTypes from "prop-types";
import { formatNumber } from "@wrstudios/utils";
import Label from "../../common/Label";
import Input from "../../common/Input";
import { Grid, Cell, Spacer } from "./styled/stream-filter-sqft-overlay";

class StreamFilterSqftOverlay extends Component {
  state = {
    min: null,
    max: null
  };

  static getDerivedStateFromProps(props, state) {
    return {
      min:
        state.min === null &&
        typeof props.min !== "undefined" &&
        props.min !== "0"
          ? props.min
          : state.min,
      max:
        state.max === null &&
        typeof props.max !== "undefined" &&
        props.max !== "0"
          ? props.max
          : state.max
    };
  }

  render() {
    let minValue = formatNumber(this.state.min, "0,0");
    if (minValue === "0") minValue = "";

    let maxValue = formatNumber(this.state.max, "0,0");
    if (maxValue === "0") maxValue = "";

    return (
      <>
        <Label htmlFor="sqft-min">Square Feet Range</Label>
        <Grid>
          <Cell>
            <Input
              id="sqft-min"
              name="min"
              value={minValue}
              placeholder="No min"
              onChange={this.handleOnChange}
            />
          </Cell>
          <Cell>
            <Spacer>-</Spacer>
          </Cell>
          <Cell>
            <Input
              name="max"
              value={maxValue}
              placeholder="No Max"
              onChange={this.handleOnChange}
            />
          </Cell>
        </Grid>
      </>
    );
  }

  componentDidMount() {
    if (this.state.min !== null || this.state.max !== null) {
      this.sendFilter();
    }
  }

  componentDidUpdate(_, prevState) {
    if (
      (prevState.min === null && this.state.min !== null) ||
      (prevState.max === null && this.state.max !== null)
    ) {
      this.sendFilter();
    }
  }

  handleOnChange = (e) => {
    let formattedValue = formatNumber(e.target.value, "0");
    if (formattedValue.startsWith("-"))
      formattedValue = formattedValue.substr(1);

    this.setState({ [e.target.name]: formattedValue }, this.sendFilter);
  };

  sendFilter = () => {
    let sqft = {};
    const min = Number(this.state.min);
    const max = Number(this.state.max);

    if (min) sqft = { ...sqft, gte: String(min), field: "sqft" };
    if (max) sqft = { ...sqft, lte: String(max), field: "sqft" };

    this.props.onChange({ sqft });
  };
}

StreamFilterSqftOverlay.propTypes = {
  min: PropTypes.string,
  max: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default StreamFilterSqftOverlay;
