import React from "react";

function IconWarning() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.45936 3.96912C10.5047 1.87695 13.488 1.87695 14.5333 3.96913L21.2896 17.492C22.2325 19.3793 20.8611 21.6 18.7526 21.6H5.24007C3.13153 21.6 1.76013 19.3793 2.7031 17.492L9.45936 3.96912Z"
        fill="#DA870B"
      />
      <path
        d="M12.8727 14.6693C12.843 15.1267 12.4634 15.4824 12.0051 15.4824C11.5468 15.4824 11.1672 15.1267 11.1376 14.6693L10.7357 8.47774C10.6908 7.78587 11.2398 7.20001 11.9332 7.20001H12.0771C12.7705 7.20001 13.3195 7.78587 13.2746 8.47774L12.8727 14.6693ZM10.6863 17.9184C10.6863 17.5488 10.8151 17.2352 11.0727 16.9776C11.3303 16.7088 11.6383 16.5744 11.9967 16.5744C12.3663 16.5744 12.6799 16.7088 12.9375 16.9776C13.2063 17.2352 13.3407 17.5488 13.3407 17.9184C13.3407 18.288 13.2063 18.6016 12.9375 18.8592C12.6799 19.1168 12.3663 19.2456 11.9967 19.2456C11.6383 19.2456 11.3303 19.1168 11.0727 18.8592C10.8151 18.6016 10.6863 18.288 10.6863 17.9184Z"
        fill="white"
      />
    </svg>
  );
}

export default IconWarning;
