import { connect } from "react-redux";
import { setFullscreenMediaDetails } from "../../../actions/listing";
import ListingFullscreenMedia from "../ListingFullscreenMedia";

function select({ application, listings }, { listingId }) {
  const listing = listings.byId[listingId] || {};

  return {
    street: listing.address,
    city: listing.city,
    state: listing.state,
    zip: listing.zip,
    markerLat: listing.latitude,
    markerLon: listing.longitude,
    photos: listing.photos,
    env: application.railsEnv || "production",
    mapboxApiToken: process.env.REACT_APP_MAPBOX_API_TOKEN,
    virtualTour: listing.virtualTour,
    initialTab: listing.fullscreenMediaTab,
    initialSlideIndex: listing.fullscreenMediaPhotoIndex,
    useFullscreen: false,
    isAuthed: !!application.railsApiToken
  };
}

const actions = {
  setFullscreenMediaDetails
};

export default connect(select, actions)(ListingFullscreenMedia);
