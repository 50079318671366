import { connect } from "react-redux";
import StreamFilterSqftOverlay from "../StreamFilterSqftOverlay";

function select({ stream: { filters } }) {
  const sqft = filters.byId.sqft || {};
  const min = sqft.gte;
  const max = sqft.lte;

  return {
    min,
    max
  };
}

export default connect(select)(StreamFilterSqftOverlay);
