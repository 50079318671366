import React from "react";

function IconRecommend(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20 " {...props}>
      <path
        d="M10 14.5l-4.702 2.472.898-5.236-3.804-3.708 5.257-.764L10 2.5l2.351 4.764 5.257.764-3.804 3.708.898 5.236z"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconRecommend;
