import styled from "styled-components";
import { Button as BaseButton, Card as BaseCard } from "@wrstudios/components";
import { Container as StreamItemRecommend } from "../../stream/styled/stream-item-recommend";
import { Container as StreamItemLike } from "../../stream/styled/stream-item-like";
import { Container as StreamItemHide } from "../../stream/styled/stream-item-hide";
import { Container as StreamItemComment } from "../../stream/styled/stream-item-comment";
import { Container as StreamItemShare } from "../../stream/styled/stream-item-share";

export const Card = styled(BaseCard)`
  margin-bottom: 1.6rem;
  border-radius: 0.4rem;
  z-index: auto;
`;

export const CardBody = styled(BaseCard.Body)`
  padding: 1.6rem;
`;

export const CardFooter = styled(BaseCard.Footer)`
  display: block;
  padding: 1rem 1.6rem;

  ${StreamItemRecommend},
  ${StreamItemLike},
  ${StreamItemHide},
  ${StreamItemComment} {
    margin-right: 1.9rem;
  }

  ${StreamItemShare} {
    margin-left: auto;
  }
`;

export const Header = styled.div`
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: -0.4rem 0 0.4rem;
`;

export const Status = styled.span`
  ${({ theme }) => theme.text.medium};
  display: block;
  font-weight: 700;
  text-transform: uppercase;
  color: ${({ theme, statusValue }) => theme.status[statusValue]};
`;

export const Action = styled.button`
  width: 3.2rem;
  height: 3.2rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.greyLight};
  border-radius: 50%;
  cursor: pointer;

  svg {
    width: 2.4rem;
    height: 2.4rem;
    fill: ${({ theme }) => theme.colors.grey};
  }
`;

export const Price = styled.h2`
  ${({ theme }) => theme.text.h2}
  margin-bottom: 0.8rem;
  white-space: nowrap;
`;

export const PricePerSqft = styled.span`
  ${({ theme }) => theme.text.medium};
  font-weight: 400;
  margin-left: 1.2rem;
  color: ${({ theme }) => theme.colors.grey};
`;

export const DOM = styled.span`
  ${({ theme }) => theme.text.small};
  text-transform: capitalize;
  display: block;
  margin-bottom: ${({ showAddComment }) => (showAddComment ? 1.6 : 0)}rem;
`;

export const Button = styled(BaseButton)`
  display: block;
  width: 100%;
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin: 1.2rem 0 0.8rem;
  padding: 1.2rem;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;

  & + * {
    margin-top: 1.8rem;
  }
`;

export const LoginMessage = styled.div`
  ${({ theme }) => theme.text.medium}
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CommentNotifiers = styled.div`
  padding: 0 1.6rem 1.2rem 2rem;
`;
