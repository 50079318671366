import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Container } from "./styled/input";

const Input = forwardRef((props, ref) => {
  return <Container {...props} ref={ref} />;
});

Input.propTypes = {
  large: PropTypes.bool
};

export default Input;
