import { connect } from "react-redux";
import { ListingDisclaimer } from "@wrstudios/components";

function select({ listings: { byId } }, { listingId }) {
  const { mlsnum, updatedAtDateShort, updatedAtTime, officeName } =
    byId[listingId] || {};

  return {
    mlsnum,
    updatedAtDateShort,
    updatedAtTime,
    officeName
  };
}

export default connect(select)(ListingDisclaimer);
