import { connect } from "react-redux";
import { inviteClient } from "../../../actions/client";
import ClientInviteModal from "../ClientInviteModal";

function select({ user, clients }) {
  const agent = user.primaryAgent || {};
  const clientEmails = Object.values(clients.byId).map(
    (client) => client.email
  );

  return {
    agentId: agent.id || "",
    clientEmails,
    currentUserEmail: user.email,
    isInvitingClient: clients.isInvitingClient,
    inviteClientError: clients.inviteClientError
  };
}

const actions = {
  inviteClient
};

export default connect(select, actions)(ClientInviteModal);
