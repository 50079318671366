import React from "react";
import PropTypes from "prop-types";
import ActivityLink from "../activity-types/connected/ActivityLink";
import { ResourceLink } from "../styled/activities";

function getPersonComponent({
  clientId,
  clientName,
  isAgent,
  isLiker,
  isCurrentlyViewedClient
}) {
  if (isAgent && !isCurrentlyViewedClient) {
    return (
      <ResourceLink to={`/clients/${clientId}`}>{clientName}</ResourceLink>
    );
  } else if (isLiker) {
    return <span>You</span>;
  } else {
    return <strong>{clientName}</strong>;
  }
}

function AcitivityView({
  activityId,
  streamItemId,
  address,
  clientId,
  clientName,
  isAgent,
  isLiker,
  isCurrentlyViewedClient
}) {
  return (
    <ActivityLink isAgent={isAgent} activityId={activityId}>
      {getPersonComponent({
        clientId,
        clientName,
        isAgent,
        isLiker,
        isCurrentlyViewedClient
      })}{" "}
      viewed{" "}
      <ResourceLink to={`/stream_items/${streamItemId}`}>
        {address}
      </ResourceLink>
    </ActivityLink>
  );
}

AcitivityView.propTypes = {
  activityId: PropTypes.string.isRequired,
  streamItemId: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
  isAgent: PropTypes.bool.isRequired,
  isLiker: PropTypes.bool.isRequired,
  isCurrentlyViewedClient: PropTypes.bool.isRequired
};

export default AcitivityView;
