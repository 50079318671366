import styled from "styled-components";

export const Container = styled.form`
  margin-bottom: 0.6rem;
  border-top: 0.1rem solid ${({ theme }) => theme.colors.whiteDark};
`;

export const Inner = styled.div`
  position: relative;
`;

export const Input = styled.input`
  width: 100%;
  font-size: 1.6rem;
  line-height: 2rem;
  padding: 1rem 4.6rem 1rem 1.6rem;

  ::placeholder {
    color: ${({ theme }) => theme.colors.blackLight};
  }
`;

export const Submit = styled.button`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.8rem;
  margin-left: auto;
  padding-top: 0;
  padding-bottom: 0;
  color: ${({ theme }) => theme.colors.streamsBlue};
  cursor: pointer;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.oneColumn}px) {
    display: none;
  }
`;

export const Errors = styled.div`
  margin-top: 1.5rem;
`;
