import { connect } from "react-redux";
import ActivityLink from "../ActivityLink";

function select({ activities, user }, { activityId }) {
  const activity = activities.byId[activityId] || { stream: {} };
  const listingIds = activity.stream.listingIds || [];
  const hasListingIds = !!listingIds.length;

  return {
    hasListingIds
  };
}

export default connect(select)(ActivityLink);
