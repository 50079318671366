import React from "react";

function IconHide(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}>
      <path
        d="M10 3.767c-4.2 0-7.787 2.613-9.24 6.3 1.453 3.688 5.04 6.3 9.24 6.3s7.787-2.612 9.24-6.3c-1.453-3.687-5.04-6.3-9.24-6.3zm-2.792 8.97L1.254 19l5.954-6.263a3.865 3.865 0 0 1 5.318-5.594L18.366 1l-5.84 6.144a3.865 3.865 0 1 1-5.318 5.594zm0 0l5.318-5.593-5.318 5.593z"
        stroke="currentColor"
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconHide;
