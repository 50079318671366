import styled from "styled-components";

export const Container = styled.button`
  cursor: pointer;

  svg {
    stroke: ${({ theme, active }) =>
      active ? theme.colors.yellow : theme.colors.grey};
    fill: ${({ theme, active }) =>
      active ? theme.colors.yellow : "transparent"};

    &:hover,
    &:focus {
      stroke: ${({ theme }) => theme.colors.yellow};
      fill: ${({ theme }) => theme.colors.yellow};
    }
  }
`;
