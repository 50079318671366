import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import VisuallyHidden from "@reach/visually-hidden";
import IconDelete from "../../icons/IconDelete";
import StreamInviteClient from "./StreamInviteClient";
import {
  Status,
  ResendButton,
  RemoveButton
} from "./styled/stream-invite-client";

function StreamInvitedClient({
  invitationId,
  name,
  initials,
  initialsColor,
  avatarUrl,
  email,
  isAgent,
  isFetching,
  hasFetched,
  resendInvitation,
  deleteInvitation
}) {
  const [hasSent, setHasSent] = useState(false);

  useEffect(() => {
    if (hasFetched) setHasSent(true);
  }, [hasFetched]);

  // Then show "Invitation Resent" for 15 minutes (to prevent re-send spamming)
  useEffect(() => {
    let timeoutId = null;
    if (hasSent) {
      timeoutId = setTimeout(() => setHasSent(false), 15 * (1000 * 60));
    }
    return () => clearTimeout(timeoutId);
  }, [hasSent]);

  return (
    <StreamInviteClient
      id="-1"
      name={name}
      initials={initials}
      initialsColor={initialsColor}
      email={email}
      avatarUrl={avatarUrl}>
      <Status>
        {!isFetching && hasSent && "Invitation Resent"}
        {!isFetching && !hasSent && (
          <>
            Invitation pending.
            <ResendButton
              variant="tertiary"
              onClick={() => resendInvitation({ invitationId })}>
              Resend
            </ResendButton>
          </>
        )}
        {isFetching && "Resending Invitation"}
      </Status>
      {isAgent && (
        <RemoveButton onClick={() => deleteInvitation({ invitationId })}>
          <IconDelete />
          <VisuallyHidden>Delete Invitation for {name || email}</VisuallyHidden>
        </RemoveButton>
      )}
    </StreamInviteClient>
  );
}

StreamInvitedClient.propTypes = {
  invitationId: PropTypes.string,
  name: PropTypes.string,
  initials: PropTypes.string,
  initialsColor: PropTypes.string,
  email: PropTypes.string,
  isAgent: PropTypes.bool,
  isFetching: PropTypes.bool,
  hasFetched: PropTypes.bool,
  resendInvitation: PropTypes.func.isRequired,
  deleteInvitation: PropTypes.func.isRequired
};

export default StreamInvitedClient;
