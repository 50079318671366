import React from "react";

function IconEmail(props) {
  return (
    <svg height="20" width="20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth="1.5">
        <path d="M2 4h16v12H2z" />
        <path d="M2 5l8 6 8-6" strokeLinecap="round" />
      </g>
    </svg>
  );
}

export default IconEmail;
