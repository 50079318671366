import styled from "styled-components";

export const Comments = styled.ol`
  max-height: 30vh;
  margin: 0;
  padding: 0;
  overflow: auto;
  list-style: none;
`;

export const Empty = styled.li`
  font-size: 1.2rem;
  line-height: 2.1rem;
`;

export const Comment = styled.li`
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0.5rem 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ViewAll = styled.button`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  margin-top: 1.2rem;
  color: ${({ theme }) => theme.colors.blackLight};
  cursor: pointer;
`;
