import React, { useState } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import {
  UserAvatar as Avatar,
  Modal as BaseModal
} from "@wrstudios/components";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Text,
  Agent,
  AgentAvatar,
  AgentDetails,
  AgentName,
  AgentCompany,
  AgentMLS
} from "./styled/client-agents-modal";

function ClientAgentsModal({
  agents,
  activeAgentId,
  updateActiveAgent,
  onClose
}) {
  const [shouldRedirect, setShouldRedirect] = useState(agents.length <= 1);

  if (shouldRedirect) {
    return <Redirect to="/streams/new" />;
  }

  return (
    <Modal onClose={onClose}>
      <ModalContent>
        <ModalHeader>
          Choose Your Agent
          <BaseModal.Close />
        </ModalHeader>
        <BaseModal.Body>
          <Text>
            Since you have worked with more than one agent, please select which
            agent and MLS you’d prefer to create a Property Stream in:
          </Text>
        </BaseModal.Body>
        <ModalFooter>
          <div>
            {agents.map((agent) => (
              <Agent
                key={agent.id}
                isActive={agent.id === activeAgentId}
                onClick={() => {
                  updateActiveAgent({
                    agentId: agent.id,
                    mlsCode: agent.mlsCode
                  });
                  setShouldRedirect(true);
                }}>
                <AgentAvatar>
                  <Avatar
                    initials={agent.initials}
                    avatarUrl={agent.avatar}
                    size="md"
                  />
                </AgentAvatar>
                <AgentDetails>
                  <AgentName>{agent.name}</AgentName>
                  {agent.company && (
                    <AgentCompany>{agent.company}</AgentCompany>
                  )}
                  {agent.mls && <AgentMLS>{agent.mls}</AgentMLS>}
                </AgentDetails>
              </Agent>
            ))}
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

ClientAgentsModal.propTypes = {
  agents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      initials: PropTypes.string,
      comapny: PropTypes.string,
      mls: PropTypes.string,
      avatar: PropTypes.string
    })
  ).isRequired,
  activeAgentId: PropTypes.string.isRequired,
  updateActiveAgent: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ClientAgentsModal;
