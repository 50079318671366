import { connect } from "react-redux";
import { logout } from "../../../actions/app";
import Header from "../Header";

function select({
  application: { isAppHeaderSticky, railsApiToken, forgeToken },
  user
}) {
  return {
    lwaJwt: user.lwaJwt,
    isAppHeaderSticky,
    isClient: user.isClient,
    showLikedNavLink: user.isClient && user.likedStreamItemIds.length > 0,
    isImpersonating: user.isImpersonating
  };
}

const actions = {
  logout
};

export default connect(select, actions)(Header);
