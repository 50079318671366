import React from "react";
import PropTypes from "prop-types";
import PokerChips from "../poker-chips/PokerChips";
import { Container, Message } from "./styled/stream-item-comment-subscribers";

function StreamItemCommentSubscribers({ subscribers }) {
  return (
    <Container>
      <PokerChips clients={subscribers} />
      {subscribers.length === 1 && <Message>1 person will be notified</Message>}
      {subscribers.length >= 2 && (
        <Message>{subscribers.length} people will be notified</Message>
      )}
    </Container>
  );
}

StreamItemCommentSubscribers.propTypes = {
  subscribers: PropTypes.array.isRequired
};

export default StreamItemCommentSubscribers;
