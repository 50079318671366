import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import FormGroup from "../../common/FormGroup";
import Selector from "../../common/Selector";

class StreamFilterPropertyTypeOverlay extends Component {
  state = {
    selectedPropertyTypes: null,
    selectedPropertySubTypes: null
  };

  static getDerivedStateFromProps(props, state) {
    return {
      selectedPropertyTypes:
        state.selectedPropertyTypes === null && props.hasSelectedPropertyTypes
          ? props.selectedPropertyTypes
          : state.selectedPropertyTypes,
      selectedPropertySubTypes:
        state.selectedPropertySubTypes === null &&
        props.hasSelectedPropertySubTypes
          ? props.selectedPropertySubTypes
          : state.selectedPropertySubTypes
    };
  }

  render() {
    return (
      <>
        <FormGroup>
          <Selector
            label="Property Type"
            options={this.props.propertyTypes}
            selectedOptions={this.state.selectedPropertyTypes || []}
            onChange={this.handleOnPropertyTypesChange}
          />
        </FormGroup>
        {this.props.hasPropertySubTypes && (
          <FormGroup>
            <Selector
              label="Sub Property Type"
              options={this.props.propertySubTypes}
              selectedOptions={this.state.selectedPropertySubTypes || []}
              onChange={this.handleOnPropertySubTypeChange}
            />
          </FormGroup>
        )}
      </>
    );
  }

  componentDidMount() {
    if (this.state.selectedPropertyTypes !== null) {
      this.sendPropertyTypesFilter();
    }
  }

  componentDidUpdate(_, prevState) {
    if (
      !isEqual(
        prevState.selectedPropertyTypes,
        this.state.selectedPropertyTypes
      )
    ) {
      this.sendPropertyTypesFilter();
    }

    if (
      !isEqual(
        prevState.selectedPropertySubTypes,
        this.state.selectedPropertySubTypes
      )
    ) {
      this.sendPropertySubTypesFilter();
    }
  }

  handleOnPropertyTypesChange = (e) => {
    const { value, checked } = e.target;

    this.setState(({ selectedPropertyTypes }) => {
      selectedPropertyTypes = selectedPropertyTypes || [];

      if (!checked) {
        return {
          selectedPropertyTypes: selectedPropertyTypes.filter(
            (propertyType) => propertyType !== value
          )
        };
      } else {
        return {
          selectedPropertyTypes: [...new Set([...selectedPropertyTypes, value])]
        };
      }
    });
  };

  handleOnPropertySubTypeChange = (e) => {
    const { value, checked } = e.target;

    this.setState(({ selectedPropertySubTypes }) => {
      selectedPropertySubTypes = selectedPropertySubTypes || [];

      if (!checked) {
        return {
          selectedPropertySubTypes: selectedPropertySubTypes.filter(
            (propertySubType) => propertySubType !== value
          )
        };
      } else {
        return {
          selectedPropertySubTypes: [
            ...new Set([...selectedPropertySubTypes, value])
          ]
        };
      }
    });
  };

  sendPropertyTypesFilter = () => {
    let propTypes = {};
    const selectedPropertyTypes = this.state.selectedPropertyTypes || [];

    if (!!selectedPropertyTypes.length) {
      propTypes = { field: "prop_type", eq: selectedPropertyTypes };
    }

    this.props.onChange({ prop_type: propTypes });
  };

  sendPropertySubTypesFilter = () => {
    let propSubTypes = {};
    const selectedPropertySubTypes = this.state.selectedPropertySubTypes || [];

    if (!!selectedPropertySubTypes.length) {
      propSubTypes = { field: "prop_sub_type", eq: selectedPropertySubTypes };
    }

    this.props.onChange({ prop_sub_type: propSubTypes });
  };
}

StreamFilterPropertyTypeOverlay.propTypes = {
  propertyTypes: PropTypes.array.isRequired,
  hasPropertySubTypes: PropTypes.bool.isRequired,
  selectedPropertyTypes: PropTypes.array,
  hasSelectedPropertyTypes: PropTypes.bool.isRequired,
  propertySubTypes: PropTypes.array.isRequired,
  selectedPropertySubTypes: PropTypes.array,
  hasSelectedPropertySubTypes: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default StreamFilterPropertyTypeOverlay;
