import { toast } from "react-toastify";
import React from "react";
import IconSuccess from "../icons/IconSuccess";
import IconWarning from "../icons/IconWarning";
import IconInfo from "../icons/IconInfo";
import NotificationIconError from "../icons/NotificationIconError";
export function notify(notificationType, message) {
  if (notificationType === "success") {
    toast.success(message, {
      icon: <IconSuccess />
    });
  } else if (notificationType === "error") {
    toast.error(message, {
      icon: <NotificationIconError />
    });
  } else if (notificationType === "warning") {
    toast.warn(message, {
      icon: <IconWarning />
    });
  } else if (notificationType === "info") {
    toast.info(message, {
      icon: <IconInfo />
    });
  } else {
    toast(message);
  }
}
