import styled from "styled-components";

export const Label = styled.label`
  ${({ theme }) => theme.text.medium}
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const LabelText = styled.span`
  margin-right: 1rem;
  text-align: right;
`;
