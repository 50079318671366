import styled from "styled-components";
import { Button as BaseButton } from "@wrstudios/components";
import { colors } from "@wrstudios/theme";

export const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2.4rem;

  @media (max-width: 570px) {
    flex-direction: column;
  }
`;

export const CASLink = styled.a`
  display: inline-flex;
  align-items: center;
`;

export const Arrow = styled.div`
  margin-right: 1.3rem;

  svg {
    width: 2.5rem;
    height: 3rem;
  }
`;

export const CASLogo = styled.div`
  svg {
    width: 19.5rem;
    height: 2.5rem;
    fill: #131943;
  }
`;

export const LearnMore = styled.a`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.8rem;
  color: ${colors.streams[700]};

  @media (max-width: 570px) {
    margin-top: 2.4rem;
  }

  &:hover {
    svg {
      fill: currentColor;
    }
  }

  svg {
    width: 2rem;
    height: 2rem;
    margin-left: 0.6rem;
    fill: currentColor;
    transition: fill 0.2s;
  }
`;

export const Logo = styled.div`
  text-align: center;

  svg {
    height: 6.4rem;
    fill: ${({ theme }) => theme.colors.streamsBlue};
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 32.8rem;
  margin: auto;
`;

export const Title = styled.h1`
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 3rem;
  margin-bottom: 2.4rem;
  text-align: center;
`;

export const Text = styled.p`
  ${({ theme }) => theme.text.small};
  margin: 0 0 2.4rem;
`;

export const Divider = styled.div`
  display: block;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6rem;
  letter-spacing: 0.05rem;
  color: ${({ theme }) => theme.colors.grey};
  text-transform: uppercase;
  text-align: center;
  position: relative;

  &:before {
    content: "";
    width: 100%;
    height: 0.2rem;
    background-color: ${({ theme }) => theme.colors.greyLighter};
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 0;
  }

  span {
    padding: 0 1.2rem;
    background-color: ${({ theme }) => theme.colors.white};
    position: relative;
    z-index: 1;
  }
`;

export const Form = styled.form`
  margin: 3.4rem 0;
  padding: 2.4rem 3.2rem;
  border-radius: 0.4rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.greyLighter};
  background-color: ${({ theme }) => theme.colors.white};
`;

export const FormContainer = styled.div`
  margin: 3.4rem 0;
  padding: 2.4rem 3.2rem;
  border-radius: 0.4rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.greyLighter};
  background-color: ${({ theme }) => theme.colors.white};
`;

export const FormGroup = styled.div`
  margin-bottom: 1.6rem;
`;

export const Error = styled.div`
  ${({ theme }) => theme.text.small};
  display: flex;
  align-items: center;
  font-weight: 600;
  padding: 0.8rem;
  color: ${({ theme }) => theme.colors.red};
  background-color: ${({ theme }) => theme.colors.pastelRed};
  border-radius: 0.4rem;

  svg {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 0.8rem;
    fill: ${({ theme }) => theme.colors.red};
  }
`;

export const Success = styled.div`
  ${({ theme }) => theme.text.small};
  display: flex;
  align-items: center;
  font-weight: 600;
  padding: 0.8rem;
  color: ${({ theme }) => theme.colors.green};
  background-color: ${({ theme }) => theme.colors.pastelGreen};
  border-radius: 0.4rem;

  svg {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 0.8rem;
    fill: ${({ theme }) => theme.colors.green};
  }
`;

export const SubmitContainer = styled.div`
  text-align: center;
`;

export const Submit = styled(BaseButton)`
  margin-top: 2.4rem;
  background-color: ${colors.streams[700]};
  border-color: ${colors.streams[700]};
`;

export const Reset = styled.div`
  text-align: center;

  a {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.6rem;
    color: ${colors.streams[700]};
  }
`;

export const Nav = styled.nav`
  margin: 3.2rem auto;
`;

export const NavItems = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const NavItem = styled.li`
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin: 0 1.2rem;
  text-decoration: underline;

  a {
    color: ${({ theme }) => theme.colors.greyDark};
  }
`;
