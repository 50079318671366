import React from "react";
import uuidv4 from "uuid/v4";

function IconAddCircle(props) {
  const uuid = uuidv4();

  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <circle id={`b-${uuid}`} cx="26" cy="26" r="26" />
        <filter
          x="-26.9%"
          y="-19.2%"
          width="153.8%"
          height="153.8%"
          filterUnits="objectBoundingBox"
          id={`a-${uuid}`}>
          <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation="4"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(8 4)">
          <use
            fill="#000"
            filter={`url(#a-${uuid})`}
            xlinkHref={`#b-${uuid}`}
          />
          <use fill="#0AA1DC" xlinkHref={`#b-${uuid}`} />
        </g>
        <g transform="translate(22 18)" fill="#FFF">
          <rect x="11" width="2" height="24" rx="1" />
          <rect
            transform="rotate(90 12 12)"
            x="11"
            width="2"
            height="24"
            rx="1"
          />
        </g>
      </g>
    </svg>
  );
}

export default IconAddCircle;
