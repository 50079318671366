import styled from "styled-components";
import { Link } from "@wrstudios/components";

export const Container = styled.div`
  position: relative;
  ${({ theme }) => theme.layers.flat};
  padding: 0 2.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    padding: 0 1.2rem;
    border: ${({ fullscreen }) => fullscreen && 0};
  }
`;

export const ResourceLink = styled(Link)`
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: 700;
  color: ${({ theme }) =>
    theme.colors
      .greyDark} !important; /* this ensures that we'll take precendence of [data-active-link] styling */

  &:hover {
    color: ${({ theme }) => theme.colors.greyDark};
    text-decoration: underline;
  }
`;

export const Empty = styled.div`
  font-size: 1.8rem;
  line-height: 2.2rem;
  text-align: center;
  padding: 5.2rem 0;
  color: ${({ theme }) => theme.colors.blackLight};
`;

export const Notice = styled.div`
  ${({ theme }) => theme.text.small};
  margin-top: 1.5rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.blackLight};
`;
