import { connect } from "react-redux";
import { updateClientName } from "../../../../actions/client";
import StreamInviteClient from "../StreamInviteClient";

function select(
  { clients: { byId }, stream: { autoFocusNewClient }, user },
  { clientId }
) {
  const { id = "", name, initials, initialsColor, email, avatarUrl } =
    byId[clientId] || {};
  const isFakeClient = id.startsWith("client-");
  return {
    id,
    name,
    initials,
    initialsColor,
    email,
    avatarUrl,
    autoFocusNewClient,
    isFakeClient
  };
}

const actions = {
  updateClientName
};

export default connect(select, actions)(StreamInviteClient);
