import { connect } from "react-redux";
import ClientProfileContactInfo from "../ClientProfileContactInfo";

function select({ clients }, { clientId }) {
  const client = clients.byId[clientId] || {};

  return {
    name: client.name || "",
    email: client.email || "",
    phoneNumber: client.phoneNumber || "",
    avatarUrl: client.avatarUrl || "",
    initials: client.initials || "",
    initialsColor: client.initialsColor || ""
  };
}

export default connect(select)(ClientProfileContactInfo);
