import React from "react";

const IllustrationAutomaticAlerts = (props) => (
  <svg width={186} height={68} {...props}>
    <defs>
      <path
        d="M10.866 12H9.732a2 2 0 0 1-3.464 0h4.598zM12 11H4v-1c.332-.021.53-.07.594-.149C4.988 9.376 5 8.171 5 7c0-2.182 2-3 3-3s3 .823 3 3c0 1.166.013 2.373.406 2.85.065.08.263.13.594.15v1zM7.5 3h1v2h-1V3z"
        id="AutomaticAlertsIllustration_svg__a"
      />
      <path
        d="M10.866 12H9.732a2 2 0 0 1-3.464 0h4.598zM12 11H4v-1c.332-.021.53-.07.594-.149C4.988 9.376 5 8.171 5 7c0-2.182 2-3 3-3s3 .823 3 3c0 1.166.013 2.373.406 2.85.065.08.263.13.594.15v1zM7.5 3h1v2h-1V3z"
        id="AutomaticAlertsIllustration_svg__b"
      />
      <path
        d="M10.866 12H9.732a2 2 0 0 1-3.464 0h4.598zM12 11H4v-1c.332-.021.53-.07.594-.149C4.988 9.376 5 8.171 5 7c0-2.182 2-3 3-3s3 .823 3 3c0 1.166.013 2.373.406 2.85.065.08.263.13.594.15v1zM7.5 3h1v2h-1V3z"
        id="AutomaticAlertsIllustration_svg__c"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(66)">
        <rect
          stroke="#576474"
          x={0.5}
          y={8}
          width={52.69}
          height={58.69}
          rx={2.4}
        />
        <path
          d="M27 25.83L14.39 36.75v12.07h9.87v-6.7a2.61 2.61 0 1 1 5.22 0v6.66h9.82V36.93L27 25.83z"
          fill="#576474"
          fillRule="nonzero"
        />
        <g transform="translate(19)">
          <circle fill="#0AA1DC" cx={8} cy={8} r={8} />
          <use
            fill="#FFF"
            fillRule="nonzero"
            xlinkHref="#AutomaticAlertsIllustration_svg__a"
          />
        </g>
      </g>
      <g transform="translate(132)">
        <rect
          stroke="#576474"
          x={0.5}
          y={8}
          width={52.69}
          height={58.69}
          rx={2.4}
        />
        <path
          d="M27 25.83L14.39 36.75v12.07h9.87v-6.7a2.61 2.61 0 1 1 5.22 0v6.66h9.82V36.93L27 25.83z"
          fill="#576474"
          fillRule="nonzero"
        />
        <g transform="translate(19)">
          <circle fill="#0AA1DC" cx={8} cy={8} r={8} />
          <use
            fill="#FFF"
            fillRule="nonzero"
            xlinkHref="#AutomaticAlertsIllustration_svg__b"
          />
        </g>
      </g>
      <rect
        stroke="#576474"
        x={0.5}
        y={8}
        width={52.69}
        height={58.69}
        rx={2.4}
      />
      <path
        d="M27 25.83L14.39 36.75v12.07h9.87v-6.7a2.61 2.61 0 1 1 5.22 0v6.66h9.82V36.93L27 25.83z"
        fill="#576474"
        fillRule="nonzero"
      />
      <g transform="translate(19)">
        <circle fill="#0AA1DC" cx={8} cy={8} r={8} />
        <use
          fill="#FFF"
          fillRule="nonzero"
          xlinkHref="#AutomaticAlertsIllustration_svg__c"
        />
      </g>
    </g>
  </svg>
);

export default IllustrationAutomaticAlerts;
