import React from "react";

function IconMiniMLX(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="16"
      width="16"
      viewBox="0 0 16 16"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx="8" cy="8" fill="#3a7abe" r="8" />
        <g fill="#fff" fillRule="nonzero">
          <path d="M5.486 11.237H3.628V7.284c0-.304.136-.592.372-.779l3.43-2.684a.9.9 0 0 1 1.123.008l3.473 2.9a.983.983 0 0 1 .352.763l-.03 3.885h-1.855v-3.48l-2.52-2.104-2.487 1.949z" />
          <path d="M5.486 11.237H3.628V7.284c0-.304.136-.592.372-.779l3.43-2.684a.9.9 0 0 1 1.123.008l3.473 2.9a.983.983 0 0 1 .352.763l-.03 3.885h-1.855v-3.48l-2.52-2.104-2.487 1.949z" />
        </g>
      </g>
    </svg>
  );
}

export default IconMiniMLX;
