import React from "react";
import PropTypes from "prop-types";
import Rect from "@reach/rect";
import FocusTrap from "focus-trap-react";
import { Portal } from "@wrstudios/components";
import { getModalPosition } from "./utils";
import { Container, Actions, Clear, Apply } from "./styled/stream-filter-modal";

function StreamFilterModal({
  buttonRect,
  disableClear,
  disableApply,
  children,
  onClear,
  onApply,
  onClose
}) {
  return (
    <Portal>
      <Rect>
        {({ ref, rect }) => {
          return (
            <FocusTrap
              focusTrapOptions={{
                onDeactivate: onClose,
                clickOutsideDeactivates: true,
                initialFocus: "#modal"
              }}>
              <Container
                id="modal"
                tabIndex="-1"
                ref={ref}
                style={getModalPosition(buttonRect, rect)}>
                {children}
                <Actions>
                  <Clear
                    variant="secondary"
                    disabled={disableClear}
                    onClick={onClear}>
                    Clear
                  </Clear>
                  <Apply
                    variant="secondary"
                    disabled={disableApply}
                    onClick={onApply}>
                    Apply
                  </Apply>
                </Actions>
              </Container>
            </FocusTrap>
          );
        }}
      </Rect>
    </Portal>
  );
}

StreamFilterModal.propTypes = {
  buttonRect: PropTypes.object,
  disableClear: PropTypes.bool,
  disableApply: PropTypes.bool,
  onClear: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

StreamFilterModal.defaultProps = {
  rect: {
    bottom: 0
  },
  disableClear: false,
  disableApply: false
};

export default StreamFilterModal;
