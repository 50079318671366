import React from "react";

function IconAlertCircle(props) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx="24" cy="24" r="24" fill="#0AA1DC" opacity=".1" />
        <path d="M10 10h28v28H10z" />
        <path
          d="M21.678 34.512A2.32 2.32 0 0 0 24 36.833a2.32 2.32 0 0 0 2.322-2.321h-4.644zm10.349-11.679a8.019 8.019 0 0 0-6.172-7.805v-.84A1.852 1.852 0 0 0 24 12.333a1.852 1.852 0 0 0-1.855 1.855v.84a8.019 8.019 0 0 0-6.172 7.805v6.79L13.5 32.097v1.236h21v-1.236l-2.473-2.474v-6.79z"
          fill="#0AA1DC"
        />
      </g>
    </svg>
  );
}

export default IconAlertCircle;
