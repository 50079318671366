import { connect } from "react-redux";
import { generateAutocomplete } from "../../utils";
import { countFilters } from "../../../../modules/stream";
import {
  setShowStreamFilters,
  createStream,
  updateStream
} from "../../../../actions/stream";
import StreamFilters from "../StreamFilters";

function select(
  {
    user,
    streams,
    stream: {
      isSaving,
      isShowingFilters,
      filters,
      isEditingCriteria,
      updateNetwork
    }
  },
  { streamId, showOnboarding = false }
) {
  const autogeneratedStreamName = generateAutocomplete(filters.byId)[0];
  const streamById = streams.byId[streamId] || {};
  const hasStream = !!Object.values(streamById).length;
  const hasEdited = hasStream && isEditingCriteria;
  const hasOnboarded = !user.criteriaOnboarding;
  const shouldShowOnboarding = showOnboarding && !hasOnboarded;
  const showListings = countFilters(filters.byId) > 0;

  return {
    hasStream,
    hasEdited,
    isSaving,
    isClient: user.isClient,
    isUpdating: updateNetwork.isFetching,
    isShowingFilters,
    streamId: streamById.id,
    streamName: streamById.name,
    autogeneratedStreamName,
    shouldShowOnboarding,
    canUpdateOrSave: hasEdited && showListings,
    filters: Object.values(filters.byId)
  };
}

const actions = {
  setShowStreamFilters,
  createStream,
  updateStream
};

export default connect(select, actions)(StreamFilters);
