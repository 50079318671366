import React from "react";
import { Container, LinkWrapper } from "./styled/sub-nav";

function SubNav({ children }) {
  return (
    <Container>
      {React.Children.map(
        children,
        (child) => child !== null && <LinkWrapper>{child}</LinkWrapper>
      )}
    </Container>
  );
}

export default SubNav;
