import {
  getStreamItem as getStreamItemAPI,
  getPublicStreamItem as getPublicStreamItemAPI,
  setStreamItemView as setStreamItemViewAPI,
  setStreamItemLike as setStreamItemLikeAPI,
  setStreamItemHide as setStreamItemHideAPI,
  createStreamItemComment as createStreamItemCommentAPI,
  getStreamItemsByIds as getStreamItemsByIdsAPI
} from "../modules/railsApi";
import {
  transformStreamItems,
  transformStreamItem,
  transformStreamItemComment,
  transformStreamItemRecommendation
} from "../modules/streamItem";
import { createRecommendation, deleteRecommendation } from "../api/streamItem";

export function getStreamItem({ streamItemId }) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken },
      mls
    } = getState();

    dispatch({ type: "GET_STREAM_ITEM_INITIATED" });

    try {
      const streamItemData = await getStreamItemAPI({
        streamItemId,
        railsApiToken
      });
      const transformedStreamItem = transformStreamItem({
        streamItem: streamItemData || {},
        mls
      });

      dispatch({
        type: "GET_STREAM_ITEM_SUCCEEDED",
        payload: transformedStreamItem
      });
    } catch (error) {
      dispatch({
        type: "GET_STREAM_ITEM_FAILED",
        payload: { error, errorsArray: (error.response || {}).errors || [] }
      });
    }
  };
}

export function getPublicStreamItem({ streamItemId }) {
  return async (dispatch, getState) => {
    const { mls } = getState();

    dispatch({ type: "GET_PUBLIC_STREAM_ITEM_INITIATED" });

    try {
      const { stream_item } = await getPublicStreamItemAPI({ streamItemId });
      const transformedStreamItem = transformStreamItem({
        streamItem: stream_item || {},
        mls
      });

      dispatch({
        type: "GET_PUBLIC_STREAM_ITEM_SUCCEEDED",
        payload: transformedStreamItem
      });
    } catch (error) {
      dispatch({
        type: "GET_PUBLIC_STREAM_ITEM_FAILED",
        payload: { error, errorsArray: (error.response || {}).errors || [] }
      });
    }
  };
}

export function getStreamItemsByIds(ids) {
  return async (dispatch, getState) => {
    const {
      mls,
      application: { railsApiToken }
    } = getState();

    dispatch({ type: "GET_STREAM_ITEMS_INITIATED" });

    try {
      const streamItems = await getStreamItemsByIdsAPI({
        streamItemIds: ids.map((id) => parseInt(id, 10)),
        railsApiToken
      });
      const transformedStreamItems = transformStreamItems({ streamItems, mls });

      dispatch({
        type: "GET_STREAM_ITEMS_SUCCEEDED",
        payload: { streamItems: transformedStreamItems }
      });
    } catch (error) {
      dispatch({ type: "GET_STREAM_ITEMS_FAILED", payload: { error } });
    }
  };
}

export function setStreamItemView({ streamItemId }) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken }
    } = getState();

    dispatch({ type: "SET_STREAM_ITEM_VIEW_INITIATED" });

    try {
      await setStreamItemViewAPI({ streamItemId, railsApiToken });

      dispatch({ type: "SET_STREAM_ITEM_VIEW_SUCCEEDED" });
    } catch (error) {
      dispatch({ type: "SET_STREAM_ITEM_VIEW_FAILED", payload: { error } });
    }
  };
}

export function recommendStreamItem({
  notifications,
  streamItemId,
  listingId,
  streamId,
  agentId,
  message,
  mls
}) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken }
    } = getState();

    dispatch({ type: "CREATE_RECOMMENDATION_REQUEST" });

    try {
      const { stream, stream_item } = await createRecommendation({
        notifications,
        railsApiToken,
        streamItemId,
        listingId,
        streamId,
        agentId,
        message,
        mls
      });

      dispatch({
        type: "CREATE_RECOMMENDATION_RESOLVED",
        payload: {
          streamId: stream.id,
          recommendation: transformStreamItemRecommendation(
            stream_item.recommendation || {}
          ),
          streamItemId: stream_item.id,
          recommendedCount: stream.recommendation_count
        }
      });
    } catch (error) {
      dispatch({ type: "CREATE_RECOMMENDATION_REJECTED", payload: error });
    }
  };
}

export function unrecommendStreamItem({
  streamId,
  streamItemId,
  recommendationId
}) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken }
    } = getState();

    dispatch({ type: "DELETE_RECOMMENDATION_REQUEST" });

    try {
      await deleteRecommendation({ recommendationId, railsApiToken });
      dispatch({
        type: "DELETE_RECOMMENDATION_RESOLVED",
        payload: { streamId, streamItemId, recommendationId }
      });
    } catch (error) {
      dispatch({ type: "DELETE_RECOMMENDATION_REJECTED", payload: error });
    }
  };
}

export function setStreamItemLike({ streamItemId, liked }) {
  return async (dispatch, getState) => {
    const {
      streamItems: { byId },
      application: { railsApiToken },
      mls,
      user: { likedStreamItemIds }
    } = getState();

    const { streamId } = byId[streamItemId];

    // This is for an optimistic response.
    dispatch({
      type: "UPDATE_STREAM_ITEM_LIKE_INITIATED",
      payload: { id: streamItemId, streamId, liked }
    });

    try {
      const streamItemData = await setStreamItemLikeAPI({
        streamItemId,
        railsApiToken
      });
      const transformedStreamItem = transformStreamItem({
        streamItem: streamItemData,
        mls
      });

      if (liked) {
        dispatch({
          type: "ADD_STREAM_ITEM_LIKE",
          payload: [...likedStreamItemIds, transformedStreamItem.streamItem.id]
        });
      } else {
        dispatch({
          type: "REMOVE_STREAM_ITEM_LIKE",
          payload: likedStreamItemIds.filter(
            (id) => id !== transformedStreamItem.streamItem.id
          )
        });
      }

      dispatch({
        type: "UPDATE_STREAM_ITEM_LIKE_SUCCEEDED",
        payload: {
          streamId,
          streamItem: transformedStreamItem,
          likedCount: parseInt(streamItemData.stream.liked_count, 10)
        }
      });
    } catch (error) {
      dispatch({ type: "UPDATE_STREAM_ITEM_LIKE_FAILED", payload: { error } });
    }
  };
}

export function setStreamItemHide({ streamItemId, hidden, type }) {
  return async (dispatch, getState) => {
    const {
      streamItems: { byId },
      application: { railsApiToken },
      mls
    } = getState();

    const { streamId } = byId[streamItemId];

    // This is for an optimistic response.
    dispatch({
      type: "UPDATE_STREAM_ITEM_HIDE_INITIATED",
      payload: { id: streamItemId, hidden }
    });

    try {
      const streamItemData = await setStreamItemHideAPI({
        streamItemId,
        type,
        railsApiToken
      });
      const transformedStreamItem = transformStreamItem({
        streamItem: streamItemData,
        mls
      });

      dispatch({
        type: "UPDATE_STREAM_ITEM_HIDE_SUCCEEDED",
        payload: {
          streamId,
          streamItem: transformedStreamItem,
          hiddenCount: parseInt(streamItemData.stream.hidden_count, 10)
        }
      });
    } catch (error) {
      dispatch({ type: "UPDATE_STREAM_ITEM_HIDE_FAILED", payload: { error } });
    }
  };
}

export function createStreamItemComment({ streamItemId, comment }) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken },
      user: { name },
      streamItems: { byId }
    } = getState();

    const streamItem = byId[streamItemId] || {};

    // Used in case we need to rollback our optimistic response.
    const oldComments = streamItem.comments;

    // This is for an optimistic response.
    const newComment = transformStreamItemComment({
      user_name: name,
      content: comment,
      updated_at: Date.now(),
      stream_item: { id: streamItemId }
    });

    dispatch({
      type: "CREATE_STREAM_ITEM_COMMENT_INITIATED",
      payload: { comment: newComment }
    });

    try {
      const streamItemCommentData = await createStreamItemCommentAPI({
        streamItemId,
        comment,
        railsApiToken
      });
      const transformedComment = transformStreamItemComment(
        streamItemCommentData
      );

      dispatch({
        type: "CREATE_STREAM_ITEM_COMMENT_SUCCEEDED",
        payload: {
          streamId: streamItem.streamId,
          comments: oldComments,
          comment: transformedComment,
          commentedCount:
            streamItemCommentData.stream_item.stream.commented_count
        }
      });
    } catch (error) {
      dispatch({
        type: "CREATE_STREAM_ITEM_COMMENT_FAILED",
        payload: {
          error,
          comments: oldComments,
          comment: newComment,
          errorsArray: (error.response || {}).errors || []
        }
      });
    }
  };
}
