import { GraphQLClient } from "graphql-request";
import { listingFields } from "./forge";
import { STREAM_ITEM_FILTER_TYPES } from "./streamItem";

const railsUrl = process.env.REACT_APP_API_URL;
const railsApiUrl = `${railsUrl}/graphql`;

// TODO: we need to add stream_item.stream.commented_count (once its available)
const streamItemCommentFields = `
  id
  content
  commentable_id
  updated_at
  user_name
  stream_item {
    id
  }
`;

const streamSubscriptionFields = `
  id
  stream_id
  user_id
  email_frequency
  sms_alert_type
  user {
    id
    type
    name
    email
    avatar
    primary_photo
    social_profiles {
      username
      url
      service
    }
    social_data_status
  }
`;

export const streamItemFields = `
  id
  stream_id
  item_id
  item_type
  stream_id
  hidden
  liked
  likes_count
  comments(sort: { field: "updated_at", dir: asc }) {
    ${streamItemCommentFields}
  }
  stream {
    id
    mls_code
    stream_subscriptions(limit: 50) {
      ${streamSubscriptionFields}
    }
  }
  listing {
    ${listingFields}
  }
  recommendation {
    id
    message
    stream_item_id
    updated_at
    agent {
      id
      name
    }
  }
  likers {
    id
    name
    email
    avatar
  }
  hiders {
    id
    name
    email
    avatar
  }
`;

const minimalStreamItemFields = `
  id
  item_id
  item_type
  stream {
    id
    mls_code
  }
  listing {
    ${listingFields}
  }
`;

const StreamInvitationFields = `
  id
  stream_id
  email
  invitee_name
  frequency
  guid
  updated_at
`;

const streamFields = `
  id
  name
  mls_code
  likes_count
  comments_count
  filter_criteria
  sms_enabled
  created_at
  updated_at
  stream_item_count
  primary_alert_type
  first_three_listing_ids
  stream_subscriptions(limit: 50) {
    ${streamSubscriptionFields}
  }
  invitations {
    ${StreamInvitationFields}
  }
  stream_items {
    ${streamItemFields}
  }
`;

const mlsCredentialFields = `
  id
  code
  name
  is_valid
`;

const billingInfoFields = `
  current_plan {
    id
    name
  }
  subscription {
    card_number
    card_expiration
    billing_id
    next_renewal_at
    amount
    state
  }
`;

const helpersQuery = /* GraphQL */ `
  query HelpersQuery {
    helpers {
      states
      time_zones
    }
  }
`;

const streamsQuery = /* GraphQL */ `
  query StreamsQuery($page: Int, $limit: Int, $order: StreamOrderArgs) {
    currentUser {
      streams_count
    }
    streams(page: $page, limit: $limit, order: $order) {
      id
      name
      likes_count
      comments_count
      filter_criteria
      sms_enabled
      updated_at
      stream_item_count
      first_three_listing_ids
      mls_code
      stream_subscriptions(limit: 50) {
        ${streamSubscriptionFields}
      }
      invitations {
        id
        stream_id
        email
        invitee_name
        frequency
        guid
        updated_at
      }
    }
  }
`;

const streamsSearchQuery = /* GraphQL */ `
  query StreamsSearchQuery($search: String!, $page: Int, $limit: Int, $order: QuerySortType) {
    stream_search(search: $search, limit: $limit, page: $page, sort: $order) {
      count
      results {
        id
        name
        likes_count
        comments_count
        filter_criteria
        sms_enabled
        updated_at
        stream_item_count
        first_three_listing_ids
        stream_subscriptions(limit: 50) {
          ${streamSubscriptionFields}
        }
        invitations {
          id
          stream_id
          email
          invitee_name
          frequency
          guid
          updated_at
        }
      }
    }
  }
`;

const streamQuery = `query StreamQuery($id: ID!, $limit: Int, $page: Int, $activity_type: StreamItemsActivityType) {
  stream(id: $id) {
    id
    name
    mls_code
    recommendation_count
    likes_count
    liked_count
    comments_count
    commented_count
    hidden_count
    filter_criteria
    sms_enabled
    created_at
    updated_at
    primary_alert_type
    stream_item_count
    first_three_listing_ids
    stream_subscriptions(limit: 50) {
      ${streamSubscriptionFields}
    }
    invitations {
      ${StreamInvitationFields}
    }
    filtered_stream_items(limit: $limit, page: $page, activity_type: $activity_type) {
      ${streamItemFields}
    }
  }
}`;

const minimalStreamQuery = `query StreamByIdQuery($id: ID!, $sort: QuerySortType, $sortSecondary: QuerySortType, $limit: Int, $page: Int) {
  streamById(id:$id) {
    id
    name
    mls_code
    likes_count
    comments_count
    filter_criteria
    sms_enabled
    created_at
    updated_at
    primary_alert_type
    stream_item_count
    stream_items(sort: $sort, sort_secondary: $sortSecondary, limit: $limit, page: $page) {
      ${streamItemFields}
    }
  }
}`;

const deleteStreamMutation = /* GraphQL */ `
  mutation DeleteStreamMutation($streamId: ID!) {
    deleteStream(id: $streamId)
  }
`;

const streamSubscriptionQuery = /* GraphQL */ `
  query StreamSubscriptionQuery($id: ID) {
    stream_subscription(id: $id) {
      ${streamSubscriptionFields}
      stream {
        ${streamFields}
      }
    }
  }
`;

const streamItemsQuery = /* GraphQL */ `
  query StreamItemsQuery($streamId: ID!, $page: Int!, $limit: Int!, $sort: QuerySortType, $sortSecondary: QuerySortType) {
    streamById(id: $streamId) {
      stream_item_count
      likes_count
      comments_count
      hidden_count
      stream_items(page: $page, limit: $limit, sort: $sort, sort_secondary: $sortSecondary) {
        ${streamItemFields}
      }
    }
  }
`;

const filteredStreamItemsQuery = /* GraphQL */ `
  query FilteredStreamItemsQuery($streamId: ID!, $page: Int!, $limit: Int!, $activity_type: StreamItemsActivityType) {
    streamById(id: $streamId)  {
      stream_item_count
      likes_count
      comments_count
      hidden_count
      filtered_stream_items(activity_type: $activity_type, page: $page, limit: $limit) {
        ${streamItemFields}
      }
    }
  }
`;

const streamItemsByIdsQuery = /* GraphQL */ `
  query StreamItemsByIdsQuery($streamItemIds: [Int]) {
    stream_items(where: { id: { in: $streamItemIds } }) {
      ${streamItemFields}
    }
  }
`;

const streamItemQuery = `query SteamItemQuery($streamItemId: ID!) {
  stream_item(id: $streamItemId) {
    ${streamItemFields}
  }
}`;

const publicStreamItemQuery = `query SteamItemQuery($streamItemId: ID!) {
  stream_item(id: $streamItemId) {
    ${minimalStreamItemFields}
  }
}`;

const streamItemViewMutation = /* GraphQL */ `
  mutation StreamItemViewMutation($streamItemId: ID!) {
    viewStreamitem(stream_item_id: $streamItemId) {
      id
    }
  }
`;

const streamItemLikeMutation = `mutation StreamItemLikeMutation($streamItemId: ID!) {
  voteOnStreamItem(stream_item_id: $streamItemId, type: "toggle") {
    ${streamItemFields}
    stream {
      liked_count
    }
  }
}`;

const streamItemHideMutation = `mutation StreamItemHideMutation($streamItemId: ID!, $type: String!) {
  voteOnStreamItem(stream_item_id: $streamItemId, type: $type) {
    ${streamItemFields}
    stream {
      hidden_count
    }
  }
}`;

const createStreamItemCommentMutation = `mutation CreateStreamItemCommentMutation($streamItemId: Int!, $comment: String!) {
  createComment(commentable_id: $streamItemId, content: $comment) {
    ${streamItemCommentFields}
    stream_item {
      stream {
        commented_count
      }
    }
  }
}`;

const createStreamMutation = `mutation CreateStreamMutation($agentId: Int!, $name: String!, $filter: [FilterCriteriaType]!) {
  createStream(agent_id: $agentId, name: $name, filter_criteria: $filter) {
    ${streamFields}
  }
}`;

const updateStreamMutation = `mutation UpdateStreamMutation($id: ID!, $name: String!, $filter: [FilterCriteriaType]) {
  updateStream(id: $id, name: $name, filter_criteria: $filter) {
    ${streamFields}
  }
}`;

const renameStreamMutation = `mutation RenameStreamMutation($id: ID!, $name: String!) {
  renameStream(id: $id, name: $name) {
    ${streamFields}
  }
}`;

const streamInviteClientsMutation = /* GraphQL */ `
  mutation StreamInviteClientsMutation(
    $id: ID!
    $clients: [InvitationParamsType]!
  ) {
    inviteClients(stream_id: $id, invitations: $clients) {
      id
      name
      email
      avatar
      created_at
      updated_at
      primary_photo
      social_profiles {
        username
        url
        service
      }
      social_data_status
      pending
      last_invitation {
        id
        guid
        email
      }
      shared_clients {
        id
        name
        email
        avatar
        primary_photo
        social_profiles {
          username
          url
          service
        }
        social_data_status
        pending
      }
    }
  }
`;

const updateStreamSubscriptionFrequencyMutation = `mutation UpdateStreamSubscriptionFrequencyMutation($subscriptionId: ID!, $emailFrequency: Frequencies!, $smsFrequency: SMSAlertTypes!) {
  updateStreamSubscription(id: $subscriptionId, email_frequency: $emailFrequency, sms_alert_type: $smsFrequency) {
    ${streamSubscriptionFields}
  }
}`;

const createStreamSubscriptionMutation = `mutation CreateStreamSubscriptionMutation($streamId: Int!, $userId: Int!, $emailFrequency: Frequencies!) {
  createStreamSubscription(stream_id: $streamId, user_id: $userId, email_frequency: $emailFrequency) {
    ${streamSubscriptionFields}
  }
}`;

const deleteStreamSubscriptionMutation = /* GraphQL */ `
  mutation DeleteStreamSubscriptionMutation($subscriptionId: ID!) {
    deleteStreamSubscription(id: $subscriptionId)
  }
`;

const resendInvitationMutation = /* GraphQL */ `
  mutation ResendInvitationMutation($invitationId: ID!) {
    resendInvitation(invitation_id: $invitationId) {
      id
      stream_id
      email
      invitee_name
      frequency
      guid
      updated_at
    }
  }
`;

const deleteInvitationMutation = /* GraphQL */ `
  mutation DeleteInvitationMutation($invitationId: ID!) {
    deleteInvitation(id: $invitationId)
  }
`;

const listingPhotosQuery = /* GraphQL */ `
  query ListingPhotosQuery($id: ID) {
    listing(id: $id) {
      id
      photos
    }
  }
`;

const clientsQuery = /* GraphQL */ `
  query ClientsQuery($page: Int, $limit: Int, $order: ClientOrderArgs) {
    currentUser {
      clients_count
    }
    clients(page: $page, limit: $limit, order: $order) {
      id
      name
      email
      avatar
      created_at
      updated_at
      primary_photo
      social_profiles {
        username
        url
        service
      }
      social_data_status
      pending
      last_invitation {
        id
        guid
        email
      }
      shared_clients {
        id
        name
        email
        avatar
        primary_photo
        social_profiles {
          username
          url
          service
        }
        social_data_status
        pending
      }
    }
  }
`;

const clientsSearchQuery = /* GraphQL */ `
  query ClientSearchQuery(
    $search: String!
    $page: Int
    $limit: Int
    $order: QuerySortType
  ) {
    client_search(search: $search, sort: $order, limit: $limit, page: $page) {
      count
      results {
        id
        name
        email
        avatar
        created_at
        updated_at
        pending
        last_invitation {
          id
          guid
          email
        }
        shared_clients {
          id
          name
          email
          avatar
          primary_photo
          social_profiles {
            username
            url
            service
          }
          social_data_status
        }
      }
    }
  }
`;

const clientQuery = /* GraphQL */ `
  query ClientQuery($id: ID) {
    client(id: $id) {
      id
      name
      email
      avatar
      created_at
      updated_at
      phone_number
      pending
      last_invitation {
        id
        guid
        email
      }
      streams_by_mls {
        id
      }
      likes {
        id
      }
      shared_clients {
        id
        name
        email
        avatar
        primary_photo
        social_profiles {
          username
          url
          service
        }
        social_data_status
      }
      primary_photo
      social_profiles {
        username
        url
        service
      }
      social_data_status
    }
  }
`;

const clientStreamsQuery = `query ClientStreamsQuery($id: ID, $page: Int!, $limit: Int) {
  client(id: $id) {
    id
    streams_by_mls(page: $page, limit: $limit) {
      ${streamFields}
      first_three_listing_ids
    }
  }
}`;

const clientLikedListingsQuery = `query ClientLikedListingsQuery($id: ID, $page: Int, $limit: Int) {
  client(id: $id) {
    likes(page: $page, limit: $limit) {
      ${streamItemFields}
    }
  }
}`;

const deleteClientMutation = /* GraphQL */ `
  mutation DeleteClientMutation($clientId: ID!) {
    deleteClient(id: $clientId)
  }
`;

const inviteClientMutation = /* GraphQL */ `
  mutation InviteClients($agentId: ID, $invitations: [InvitationParamsType]!) {
    inviteClients(agent_id: $agentId, invitations: $invitations) {
      id
      name
      email
      avatar
      created_at
      updated_at
      primary_photo
      social_profiles {
        username
        url
        service
      }
      social_data_status
      pending
      last_invitation {
        id
        guid
        email
      }
      shared_clients {
        id
        name
        email
        avatar
        primary_photo
        social_profiles {
          username
          url
          service
        }
        social_data_status
        pending
      }
    }
  }
`;

// TODO: back-end should prob change name of this mutation from `fetchClientData` to something like `getClientSocialProfiles`
const clientSocialProfilesMutation = /* GraphQL */ `
  mutation GetClientSocialProfiles($id: ID!) {
    fetchClientData(id: $id) {
      id
      name
      email
      avatar
      activities {
        id
        event_type
      }
      comments {
        id
      }
      created_at
      updated_at
      phone_number
      streams {
        id
      }
      primary_photo
      social_profiles {
        username
        url
        service
      }
      social_data_status
    }
  }
`;

const feedEventsFields = `
  event_id
  event_type
  updated_at
  mls
  stream {
    id
    name
  }
  user {
    id
    type
    name
    email
    avatar
    primary_photo
    social_profiles {
      username
      url
      service
    }
    social_data_status
  }
  stream_item {
    id
    item_id
    stream {
      id
      name
    }
  }
  stream_alert {
    id
    delivery_method
    listing_ids_array
    stream_item_ids
  }
  comment {
    content
  }
  recommendation {
    message
  }
`;

const activityFeedEventsQuery = `query ActivityFeedEventsQuery($page: Int, $limit: Int, $filter: String) {
  activity_feed_events(page: $page, limit: $limit, event_type: $filter) {
    ${feedEventsFields}
  }
}`;

const clientFeedEventsQuery = `query ClientFeedEventsQuery($id: ID!, $page: Int, $limit: Int){
  client(id: $id) {
    feed_events_by_mls(page: $page, limit: $limit) {
      ${feedEventsFields}
    }
  }
}`;

const activityFeedStatsQuery = `query ActivityFeedStatsQuery {
  activity_feed_stats {
    alerts_count
    views_count
    likes_count
    comments_count
  }
}`;

const updateBillingMutation = /* GraphQL */ `
  mutation UpdateBillingMutation($plan_id: ID!, $credit_card: CreditCardParamsType!) {
    updateBilling(plan_id: $plan_id, credit_card: $credit_card) {
      ${billingInfoFields}
    }
  }
`;

const cancelUserAccountMutation = /* GraphQL */ `
  mutation CancelUserAccountMutation {
    cancelAccount
  }
`;

const userFields = /* GraphQL */ `
  id
  name
  email
  phone_number
  business_phone
  city
  state
  company_name
  website
  license_number
  time_zone
  avatar
  type
  cas_user
  cas_user_id
  cas_jwt
  lwa_id
  lwa_jwt
  client_digest
  criteria_onboarding
  authentication_token
  streams_count
  clients_count
  liked_stream_items {
    id
  }
  primary_photo
  social_profiles {
    username
    url
    service
  }
  social_data_status
  agents {
    id
    name
    email
    company_name
    mls_name
    avatar
    phone_number
    mls_credential {
      code
    }
  }
`;

// TODO: add business address [blocker on #164557283]
const primaryAgentFields = `
  id
  avatar
  name
  company_name
  street_address
  city
  state
  postal_code
  business_phone
  phone_number
  email
`;

const currentUserQuery = `{
  currentUser {
    ${userFields}
    primary_agent {
      ${primaryAgentFields}
    }
    shared_clients {
      id
      name
      email
      avatar
      primary_photo
      social_profiles {
        username
        url
        service
      }
      social_data_status
    }
  }
  agent {
    mls_credential {
      ${mlsCredentialFields}
    }
  }
}`;

const updateUserProfileQuery = `mutation UpdateUserProfileMutation(
  $id: ID!,
  $name: String,
  $email: String,
  $avatar: String,
  $phone_number: String,
  $business_phone: String,
  $city: String,
  $state: String,
  $company: String,
  $website: String,
  $license: String,
  $timezone: String
) {
  updateUser(
    id: $id,
    avatar: $avatar,
    name: $name,
    email: $email,
    phone_number: $phone_number,
    business_phone: $business_phone,
    city: $city,
    state: $state,
    company_name: $company,
    website: $website,
    license_number: $license,
    time_zone: $timezone
  ) {
    ${userFields}
    primary_agent {
      ${primaryAgentFields}
    }
  }
}`;

const updateUserPasswordMutation = `mutation UpdatePasswordMutation($password: String!, $passwordConfirm: String!) {
  resetPassword(password: $password, password_confirmation: $passwordConfirm) {
    token
    user {
      ${userFields}
      primary_agent {
        ${primaryAgentFields}
      }
    }
  }
}`;

const updateUserMLSCredentialsMutation = `mutation UpdateUserMLSCredentialsMutation(
  $mlsCredentialId: ID!,
  $mlsCode: String,
  $mlsUserId: String,
  $mlsUserPassword: String
) {
  updateMlsCredential(
    id: $mlsCredentialId,
    code: $mlsCode,
    name: $mlsUserId,
    password: $mlsUserPassword
  ) {
    agent {
      mls_credential {
        ${mlsCredentialFields}
      }
    }
  }
}`;

const updateUserClientDigestSubscriptionMutation = /* GraphQL */ `
  mutation UpdateUserClientDigestSubscriptionMutation(
    $id: ID!
    $client_digest: Boolean
  ) {
    updateUser(id: $id, client_digest: $client_digest) {
      client_digest
    }
  }
`;

const sendUserPhoneVerificationCodeMutation = /* GraphQL */ `
  mutation SendUserPhoneVerificationCodeMutation($phoneNumber: String!) {
    sendVerificationCode(phone_number: $phoneNumber)
  }
`;

const verifyUserPhoneVerificationCodeMutation = /* GraphQL */ `
  mutation VerifyUserPhoneVerificationCodeMutation(
    $phoneNumber: String!
    $code: String!
    $secret: String!
  ) {
    verifyCode(phone_number: $phoneNumber, code: $code, secret: $secret)
  }
`;

const billingInfoQuery = /* GraphQL */ `
  query BillingInfoQuery {
    billing_info {
      ${billingInfoFields}
    }
  }
`;

const loginMutation = /* GraphQL */ `
  mutation LoginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`;

const requestPasswordResetMutation = /* GraphQL */ `
  mutation RequestPasswordResetMutation($email: String!) {
    requestPasswordReset(email: $email) {
      email
    }
  }
`;

const resetPasswordFromEmailMutation = /* GraphQL */ `
  mutation ResetPasswordFromEmail(
    $reset_password_token: String!
    $password: String!
    $password_confirmation: String!
  ) {
    resetPasswordFromEmail(
      reset_password_token: $reset_password_token
      password: $password
      password_confirmation: $password_confirmation
    ) {
      token
    }
  }
`;

const resetPasswordMutation = /* GraphQL */ `
  mutation ResetPasswordMutation(
    $password: String!
    $password_confirmation: String!
  ) {
    resetPassword(
      password: $password
      password_confirmation: $password_confirmation
    ) {
      token
    }
  }
`;

const acceptInvitationMutation = /* GraphQL */ `
  mutation AcceptInvitationMutation(
    $name: String
    $email: String
    $password: String!
    $password_confirmation: String!
    $frequency: Frequencies
    $invitation_guid: String!
    $agent_id: Int!
    $client_id: Int!
  ) {
    acceptInvitation(
      name: $name
      email: $email
      password: $password
      password_confirmation: $password_confirmation
      frequency: $frequency
      invitation_guid: $invitation_guid
      agent_id: $agent_id
      client_id: $client_id
    ) {
      token
    }
  }
`;

const invitationsQuery = /* GraphQL */ `
  query InvitationsQuery($page: Int, $limit: Int, $order: InvitationOrderArgs) {
    invitations(page: $page, limit: $limit, order: $order) {
      id
      stream_id
      email
      invitee_name
      frequency
      guid
      updated_at
    }
  }
`;

const connectCASAccountMutation = /* GraphQL */ `
  mutation ConnectCASAccountMutation($jwt: String!, $redirectUrl: String!) {
    connectCasAccount(jwt: $jwt, redirect_url: $redirectUrl) {
      url
    }
  }
`;

const ssoParamsQuery = /* GraphQL */ `
  query SsoParamsQuery {
    ssoParams
  }
`;

function buildRailsApiClient(railsApiToken) {
  if (!railsApiToken) {
    return new GraphQLClient(railsApiUrl);
  }

  return new GraphQLClient(railsApiUrl, {
    headers: { Authorization: `Bearer ${railsApiToken}` }
  });
}

export async function getAppHelpers() {
  const railsApiClient = buildRailsApiClient();
  const helpers = await railsApiClient.request(helpersQuery);

  return helpers;
}

export async function getApiToken({ email, password }) {
  const railsApiClient = buildRailsApiClient();
  const loginVariables = {
    email,
    password
  };
  const { login } = await railsApiClient.request(loginMutation, loginVariables);
  return login;
}

export async function getCurrentUserAPI(railsApiToken) {
  const client = buildRailsApiClient(railsApiToken);
  const data = await client.request(currentUserQuery);
  return data;
}

export async function requestPasswordResetAPI(email) {
  const railsApiClient = buildRailsApiClient();
  const variables = { email };
  const data = await railsApiClient.request(
    requestPasswordResetMutation,
    variables
  );
  return data;
}

export async function resetPasswordFromEmailAPI(
  password,
  passwordConfirmation,
  resetToken
) {
  const railsApiClient = buildRailsApiClient();
  const variables = {
    password,
    password_confirmation: passwordConfirmation,
    reset_password_token: resetToken
  };
  const data = await railsApiClient.request(
    resetPasswordFromEmailMutation,
    variables
  );
  return data;
}

export async function resetPasswordAPI(password, passwordConfirmation, token) {
  const railsApiClient = buildRailsApiClient(token);
  const variables = {
    password: password,
    password_confirmation: passwordConfirmation
  };
  const data = await railsApiClient.request(resetPasswordMutation, variables);
  return data;
}

export async function acceptInvitation({
  name,
  email,
  password,
  passwordConfirmation,
  frequency,
  invitationGuid,
  agentId,
  clientId,
  token
}) {
  const railsApiClient = buildRailsApiClient(token);
  const variables = {
    name,
    email,
    password,
    password_confirmation: passwordConfirmation,
    invitation_guid: invitationGuid,
    agent_id: parseInt(agentId, 10),
    client_id: parseInt(clientId, 10),
    frequency
  };
  const { acceptInvitation } = await railsApiClient.request(
    acceptInvitationMutation,
    variables
  );
  return acceptInvitation;
}

export async function getInvitations({
  page,
  limit,
  sortTerm,
  sortDir,
  railsApiToken
}) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const variables = { page, limit, order: { field: sortTerm, dir: sortDir } };
  return await railsApiClient.request(invitationsQuery, variables);
}

export async function getSsoParams(railsApiToken) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  return await railsApiClient.request(ssoParamsQuery);
}

export async function getStreams({
  page,
  limit,
  sortTerm,
  sortDir,
  railsApiToken
}) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const variables = { page, limit, order: { field: sortTerm, dir: sortDir } };
  return await railsApiClient.request(streamsQuery, variables);
}

export async function searchStreams({
  page,
  limit,
  searchTerm,
  sortTerm,
  sortDir,
  railsApiToken
}) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const variables = {
    page,
    limit,
    search: searchTerm,
    order: { field: sortTerm, dir: sortDir }
  };
  const { stream_search } = await railsApiClient.request(
    streamsSearchQuery,
    variables
  );
  return stream_search;
}

export async function getStreamById({
  id,
  railsApiToken,
  limit,
  page,
  filter
}) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const streamVariables = {
    activity_type: filter,
    id: parseInt(id, 10),
    limit,
    page
  };
  const { stream } = await railsApiClient.request(streamQuery, streamVariables);
  return stream;
}

export async function getMinimalStreamById({ id, railsApiToken, limit, page }) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const streamVariables = {
    id: parseInt(id, 10),
    sort: { field: "freshness", dir: "desc" },
    sortSecondary: { field: "activity", dir: "desc" },
    limit,
    page
  };
  const { streamById } = await railsApiClient.request(
    minimalStreamQuery,
    streamVariables
  );

  return streamById;
}

export async function deleteStream({ streamId, railsApiToken }) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const clientVariables = { streamId };
  const { deleteStream } = await railsApiClient.request(
    deleteStreamMutation,
    clientVariables
  );

  return deleteStream;
}

export async function getStreamSubscriptionAPI({
  subscriptionId,
  railsApiToken
}) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const clientVariables = { id: subscriptionId };
  const { stream_subscription } = await railsApiClient.request(
    streamSubscriptionQuery,
    clientVariables
  );

  return stream_subscription;
}

export async function getStreamItems({
  streamId,
  page,
  limit,
  filter,
  railsApiToken
}) {
  const railsApiClient = buildRailsApiClient(railsApiToken);

  const queryHasFilter = filter !== STREAM_ITEM_FILTER_TYPES.ALL;
  const query = queryHasFilter ? filteredStreamItemsQuery : streamItemsQuery;

  const streamVariables = {
    streamId: parseInt(streamId, 10),
    page: parseInt(page, 10),
    limit: parseInt(limit, 10),
    ...(queryHasFilter
      ? {
          activity_type: filter
        }
      : {
          sort: { field: "freshness", dir: "desc" },
          sortSecondary: { field: "activity", dir: "desc" }
        })
  };
  const { streamById } = await railsApiClient.request(query, streamVariables);

  return streamById;
}

export async function getStreamItemsByIds({ streamItemIds, railsApiToken }) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const variables = { streamItemIds };
  const { stream_items } = await railsApiClient.request(
    streamItemsByIdsQuery,
    variables
  );

  return stream_items;
}

export async function getStreamItem({ streamItemId, railsApiToken }) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const variables = { streamItemId };
  const { stream_item } = await railsApiClient.request(
    streamItemQuery,
    variables
  );

  return stream_item;
}

export async function getPublicStreamItem({ streamItemId }) {
  const railsApiClient = buildRailsApiClient();
  const variables = { streamItemId };
  return await railsApiClient.request(publicStreamItemQuery, variables);
}

export async function setStreamItemView({ streamItemId, railsApiToken }) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const variables = { streamItemId };
  const { viewStreamItem } = await railsApiClient.request(
    streamItemViewMutation,
    variables
  );

  return viewStreamItem;
}

export async function setStreamItemLike({ streamItemId, railsApiToken }) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const variables = { streamItemId };
  const { voteOnStreamItem } = await railsApiClient.request(
    streamItemLikeMutation,
    variables
  );

  return voteOnStreamItem;
}

export async function setStreamItemHide({ streamItemId, type, railsApiToken }) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const variables = { streamItemId, type };
  const { voteOnStreamItem } = await railsApiClient.request(
    streamItemHideMutation,
    variables
  );

  return voteOnStreamItem;
}

export async function createStreamItemComment({
  streamItemId,
  comment,
  railsApiToken
}) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const variables = { streamItemId: parseInt(streamItemId, 10), comment };
  const { createComment } = await railsApiClient.request(
    createStreamItemCommentMutation,
    variables
  );

  return createComment;
}

export async function createStream({ agentId, name, filter, railsApiToken }) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const streamVariables = { agentId: Number(agentId), name, filter };
  const { createStream } = await railsApiClient.request(
    createStreamMutation,
    streamVariables
  );

  return createStream;
}

export async function updateStream({ id, name, filter, railsApiToken }) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const streamVariables = { id, name, filter };
  const { updateStream } = await railsApiClient.request(
    updateStreamMutation,
    streamVariables
  );

  return updateStream;
}

export async function renameStream({ id, name, railsApiToken }) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const streamVariables = { id, name };
  const { updateStream } = await railsApiClient.request(
    renameStreamMutation,
    streamVariables
  );

  return updateStream;
}

export async function sendStreamInvite({ id, clients, railsApiToken }) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const streamVariables = { id, clients };
  const { inviteClients } = await railsApiClient.request(
    streamInviteClientsMutation,
    streamVariables
  );

  return inviteClients;
}

export async function updateStreamSubscriptionFrequency({
  subscriptionId,
  emailFrequency,
  smsFrequency,
  railsApiToken
}) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const streamVariables = { subscriptionId, emailFrequency, smsFrequency };
  const { updateStreamSubscription } = await railsApiClient.request(
    updateStreamSubscriptionFrequencyMutation,
    streamVariables
  );

  return updateStreamSubscription;
}

export async function updateClientDigestSubscriptionAPI({
  id,
  value,
  railsApiToken
}) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const variables = { client_digest: value, id };
  const {
    updateUser: { client_digest }
  } = await railsApiClient.request(
    updateUserClientDigestSubscriptionMutation,
    variables
  );

  return client_digest;
}

export async function createStreamSubscription({
  streamId,
  userId,
  emailFrequency,
  railsApiToken
}) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const streamVariables = {
    streamId: parseInt(streamId, 10),
    userId: parseInt(userId, 10),
    emailFrequency
  };
  const { createStreamSubscription } = await railsApiClient.request(
    createStreamSubscriptionMutation,
    streamVariables
  );

  return createStreamSubscription;
}

export async function deleteStreamSubscription({
  subscriptionId,
  railsApiToken
}) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const streamVariables = { subscriptionId };
  const { deleteStreamSubscription } = await railsApiClient.request(
    deleteStreamSubscriptionMutation,
    streamVariables
  );

  return deleteStreamSubscription;
}

export async function resendInvitation({ invitationId, railsApiToken }) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const streamVariables = { invitationId };
  const { resendInvitation } = await railsApiClient.request(
    resendInvitationMutation,
    streamVariables
  );

  return resendInvitation;
}

export async function deleteInvitation({ invitationId, railsApiToken }) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const streamVariables = { invitationId };
  const { deleteInvitation } = await railsApiClient.request(
    deleteInvitationMutation,
    streamVariables
  );

  return deleteInvitation;
}

export async function getListingPhotosById({ id, railsApiToken }) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const streamVariables = { id: parseInt(id, 10) };
  const { stream } = await railsApiClient.request(
    listingPhotosQuery,
    streamVariables
  );
  return stream;
}

export async function getClients({
  page,
  limit,
  sortTerm,
  sortDir,
  railsApiToken
}) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const variables = { page, limit, order: { field: sortTerm, dir: sortDir } };
  return await railsApiClient.request(clientsQuery, variables);
}

export async function searchClients({
  page,
  limit,
  searchTerm,
  sortTerm,
  sortDir,
  railsApiToken
}) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const variables = {
    page,
    limit,
    search: searchTerm,
    order: { field: sortTerm, dir: sortDir }
  };
  const { client_search } = await railsApiClient.request(
    clientsSearchQuery,
    variables
  );
  return client_search;
}

export async function getClientStreamsAPI({
  page = 1,
  limit = 200,
  id,
  railsApiToken
}) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const queryVariables = { page, limit, id };
  const {
    client: { streams_by_mls }
  } = await railsApiClient.request(clientStreamsQuery, queryVariables);

  return streams_by_mls;
}

export async function getClientLikedListingsAPI({
  page = 1,
  limit = 200,
  id,
  railsApiToken
}) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const queryVariables = { page, limit, id };
  const {
    client: { likes }
  } = await railsApiClient.request(clientLikedListingsQuery, queryVariables);

  return likes;
}

export async function getClientById({ id, railsApiToken }) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const clientVariables = { id: parseInt(id, 10) };
  const { client } = await railsApiClient.request(clientQuery, clientVariables);
  return client;
}

export async function getActivityFeedEvents({
  page,
  limit,
  filter,
  railsApiToken
}) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const variables = { page, limit, filter: filter || null };
  const { activity_feed_events } = await railsApiClient.request(
    activityFeedEventsQuery,
    variables
  );
  return activity_feed_events;
}

export async function getActivityFeedStats(railsApiToken) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const { activity_feed_stats } = await railsApiClient.request(
    activityFeedStatsQuery
  );
  return activity_feed_stats;
}

export async function getClientFeedEvents({
  clientId,
  page,
  limit,
  railsApiToken
}) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const {
    client: { feed_events_by_mls }
  } = await railsApiClient.request(clientFeedEventsQuery, {
    id: clientId,
    page,
    limit
  });
  return feed_events_by_mls;
}

export async function deleteClient({ clientId, railsApiToken }) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const clientVariables = { clientId };
  const { deleteClient } = await railsApiClient.request(
    deleteClientMutation,
    clientVariables
  );

  return deleteClient;
}

export async function inviteClient({
  agentId,
  name,
  email,
  message,
  railsApiToken
}) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const clientVariables = {
    agentId,
    invitations: [
      {
        name,
        email,
        message,
        frequency: "never",
        alert_type: "self_serve"
      }
    ]
  };
  const { inviteClients } = await railsApiClient.request(
    inviteClientMutation,
    clientVariables
  );
  const [invitedClient] = inviteClients;

  return invitedClient;
}

export async function getClientSocialDataAPI({ id, railsApiToken }) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  const clientVariables = { id };
  const { fetchClientData: clientData } = await railsApiClient.request(
    clientSocialProfilesMutation,
    clientVariables
  );

  return clientData;
}

export async function updateUserProfile({
  id,
  avatar,
  name,
  email,
  phone,
  officePhone,
  city,
  state,
  company,
  website,
  license,
  timezone,
  railsApiToken
}) {
  const client = buildRailsApiClient(railsApiToken);
  const variables = {
    id,
    avatar,
    name,
    email,
    phone_number: phone,
    business_phone: officePhone,
    city,
    state,
    company,
    website,
    license,
    timezone
  };
  const data = await client.request(updateUserProfileQuery, variables);

  return data;
}

// * Notes: This is an exception to talk with a restful endpoint.
export async function uploadUserAvatar({ formData, railsApiToken }) {
  const data = await fetch(`${railsUrl}/avatar`, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${railsApiToken}`
    }
  }).then((res) => res.json());

  return data;
}

export async function updateUserPassword({
  id,
  password,
  passwordConfirm,
  railsApiToken
}) {
  const client = buildRailsApiClient(railsApiToken);
  const variables = { id, password, passwordConfirm };
  const data = await client.request(updateUserPasswordMutation, variables);

  return data;
}

export async function updateUserMLSCredentials({
  mlsCredentialId,
  mlsCode,
  mlsUserId,
  mlsUserPassword,
  railsApiToken
}) {
  const client = buildRailsApiClient(railsApiToken);
  const variables = {
    mlsCredentialId,
    mlsCode,
    mlsUserId,
    mlsUserPassword,
    railsApiToken
  };
  const data = await client.request(
    updateUserMLSCredentialsMutation,
    variables
  );

  return data;
}

export async function sendUserPhoneVerificationCode({
  phoneNumber,
  railsApiToken
}) {
  const client = buildRailsApiClient(railsApiToken);
  const variables = { phoneNumber, railsApiToken };
  const { sendVerificationCode } = await client.request(
    sendUserPhoneVerificationCodeMutation,
    variables
  );

  return sendVerificationCode;
}

export async function verifyUserPhoneVerificationCode({
  phoneNumber,
  code,
  secret,
  railsApiToken
}) {
  const client = buildRailsApiClient(railsApiToken);
  const variables = { phoneNumber, code, secret, railsApiToken };
  const { verifyCode } = await client.request(
    verifyUserPhoneVerificationCodeMutation,
    variables
  );

  return verifyCode;
}

export async function updateBillingInfo({
  planId,
  newBillingInfo,
  railsApiToken
}) {
  const client = buildRailsApiClient(railsApiToken);
  const updateBillingVariables = {
    plan_id: planId,
    credit_card: {
      first_name: newBillingInfo.firstName,
      last_name: newBillingInfo.lastName,
      brand: newBillingInfo.cardType,
      number: newBillingInfo.cardNumber,
      month: newBillingInfo.cardMonth,
      year: newBillingInfo.cardYear,
      verification_value: newBillingInfo.cardCVV,
      zip: newBillingInfo.postal
    }
  };
  const data = await client.request(
    updateBillingMutation,
    updateBillingVariables
  );

  return data;
}

export async function getBillingInfo({ railsApiToken }) {
  const client = buildRailsApiClient(railsApiToken);
  const data = await client.request(billingInfoQuery);

  return data;
}

export async function cancelUserAccount({ railsApiToken }) {
  const client = buildRailsApiClient(railsApiToken);
  const data = await client.request(cancelUserAccountMutation);

  return data;
}

export async function connectCASAccount({ jwt, redirectUrl, railsApiToken }) {
  const client = buildRailsApiClient(railsApiToken);
  const variables = { jwt, redirectUrl };
  const data = await client.request(connectCASAccountMutation, variables);

  return data;
}
