import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  formatNumber,
  retainCaretPositionForCurrencyAndNumbers
} from "@wrstudios/utils";
import Label from "../../common/Label";
import Input from "../../common/Input";
import StreamFilter from "./StreamFilter";
import { buildMinMaxLabel } from "./utils";
import { Grid, Cell, Spacer } from "./styled/stream-filter-sqft";

class StreamFilterSqft extends Component {
  state = {
    min: null,
    max: null
  };

  static getDerivedStateFromProps(props, state) {
    return {
      min:
        state.min === null &&
        typeof props.min !== "undefined" &&
        props.min !== "0"
          ? props.min
          : state.min,
      max:
        state.max === null &&
        typeof props.max !== "undefined" &&
        props.max !== "0"
          ? props.max
          : state.max
    };
  }

  render() {
    const label = buildMinMaxLabel(
      formatNumber(this.props.min, "0,0"),
      formatNumber(this.props.max, "0,0"),
      "sqft"
    );

    let minValue = formatNumber(this.state.min, "0,0");
    if (minValue === "0") minValue = "";

    let maxValue = formatNumber(this.state.max, "0,0");
    if (maxValue === "0") maxValue = "";

    return (
      <StreamFilter
        hasSelectedValues={!!label}
        label={label || "Square Feet"}
        onClear={this.handleOnClear}
        onClose={this.handleOnClose}>
        {({ apply }) => (
          <>
            <Label htmlFor="sqft-min">Square Feet Range</Label>
            <Grid>
              <Cell>
                <Input
                  id="sqft-min"
                  name="min"
                  value={minValue}
                  placeholder="No min"
                  onChange={this.handleOnChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      apply();
                    }
                  }}
                />
              </Cell>
              <Cell>
                <Spacer>-</Spacer>
              </Cell>
              <Cell>
                <Input
                  name="max"
                  value={maxValue}
                  placeholder="No Max"
                  onChange={this.handleOnChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      apply();
                    }
                  }}
                />
              </Cell>
            </Grid>
          </>
        )}
      </StreamFilter>
    );
  }

  handleOnChange = (e) => {
    const prefixLength = 0;
    const previousValue = this.state[e.target.name];
    retainCaretPositionForCurrencyAndNumbers(
      e.target,
      prefixLength,
      previousValue
    );
    let formattedValue = formatNumber(e.target.value, "0,0");
    if (formattedValue.startsWith("-"))
      formattedValue = formattedValue.substr(1);

    this.setState({ [e.target.name]: formattedValue });
  };

  handleOnClear = () => {
    this.setState({ min: "", max: "" });
  };

  handleOnClose = () => {
    const hasStateChanged = this.state.min !== null || this.state.max !== null;
    const stateEqualsProps =
      this.state.min === this.props.min && this.state.max === this.props.max;

    if (stateEqualsProps || !hasStateChanged) return;

    let sqft = {};

    const min = Number(formatNumber(this.state.min, "0"));
    const max = Number(formatNumber(this.state.max, "0"));

    if (min) sqft = { ...sqft, gte: String(min), field: "sqft" };
    if (max) sqft = { ...sqft, lte: String(max), field: "sqft" };

    const newFilters = !!Object.values(sqft).length
      ? { ...this.props.filters, sqft }
      : this.props.filters;

    this.props.replaceStreamFilters(newFilters);
  };
}

StreamFilterSqft.propTypes = {
  filters: PropTypes.object.isRequired,
  min: PropTypes.string,
  max: PropTypes.string,
  replaceStreamFilters: PropTypes.func.isRequired
};

export default StreamFilterSqft;
