import styled from "styled-components";
import { RouteContainer } from "@wrstudios/components";

export const Container = styled(RouteContainer)`
  max-width: 77rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    min-height: calc(100vh - 6rem);
    margin: 0;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  a {
    @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
      display: none;
    }
  }
`;

export const Title = styled.h1`
  ${({ theme }) => theme.text.h1};
`;

export const TitleTip = styled.span`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.1rem;
  color: ${({ theme }) => theme.colors.blackLight};
`;

export const Body = styled.div`
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    margin: 0;
  }
`;

export const NewCircle = styled.button`
  display: none;
  position: fixed;
  right: 1.6rem;
  bottom: 1.1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    display: block;
  }
`;
