import React from "react";
import PropTypes from "prop-types";
import Activities from "../activity/Activities";

function ClientActivities({
  activityIds,
  getActivitiesForClient,
  resetActivitiesForClient,
  client,
  clientId,
  mlsConfigsLoaded,
  isComplete,
  isLoading
}) {
  return (
    <Activities
      client={client}
      filter=""
      fullscreen={false}
      activityIds={activityIds}
      hasActivityIds={!!activityIds.length}
      getActivities={() => getActivitiesForClient(clientId)}
      resetActivities={() => resetActivitiesForClient(clientId)}
      mlsConfigsLoaded={mlsConfigsLoaded}
      isComplete={isComplete}
      isLoading={isLoading}
    />
  );
}

ClientActivities.propTypes = {
  client: PropTypes.shape({
    name: PropTypes.string
  }),
  clientId: PropTypes.string.isRequired,
  activityIds: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isComplete: PropTypes.bool.isRequired,
  mlsConfigsLoaded: PropTypes.bool.isRequired,
  getActivitiesForClient: PropTypes.func.isRequired,
  resetActivitiesForClient: PropTypes.func.isRequired
};

export default ClientActivities;
