import { connect } from "react-redux";
import ActivityRoute from "../ActivityRoute";

function select({ user }) {
  return {
    isAgent: user.isAgent || false
  };
}

export default connect(select)(ActivityRoute);
