import { connect } from "react-redux";
import { setStreamItemHide } from "../../../actions/streamItem";
import StreamItemHide from "../StreamItemHide";

function select({ streamItems: { byId } }, { streamItemId }) {
  const { hidden } = byId[streamItemId] || {};
  const type = hidden ? "unhide" : "hide";

  return {
    hidden: !!hidden,
    type
  };
}

const actions = {
  setStreamItemHide
};

export default connect(select, actions)(StreamItemHide);
