import { currency, getDateShort } from "@wrstudios/utils";

export function transformBillingInfo(billingInfo) {
  const amount = billingInfo.current_plan.amount || "";
  const nextRenewal = billingInfo.subscription.next_renewal_at || "";

  return {
    planId: billingInfo.current_plan.id,
    name: billingInfo.current_plan.name || "",
    amount,
    amountFormatted: currency(amount),
    nextRenewal,
    nextRenewalFormatted: nextRenewal ? getDateShort(nextRenewal) : "",
    cardNumber: billingInfo.subscription.card_number || "",
    cardExpiration: billingInfo.subscription.card_expiration || "",
    billingId: billingInfo.subscription.billing_id || "",
    state: billingInfo.subscription.state || ""
  };
}
