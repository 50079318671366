import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, UserAvatar as Avatar, Menu, Card } from "@wrstudios/components";
import { IconMoreHoriz } from "@wrstudios/icons";
import { timer } from "../../utils/timer";
import PokerChips from "../poker-chips/PokerChips";
import {
  Container,
  Overlay,
  Grid,
  Cell,
  Details,
  Name,
  Timestamp,
  Pending
} from "./styled/client-card";
import {
  SharedClient,
  SharedClientImage,
  SharedClientName,
  SharedClientResend
} from "./styled/client-card";
import {
  Controls,
  MenuContainer,
  MenuButton,
  MenuItemDelete
} from "./styled/client-card";

class ClientCard extends Component {
  state = {
    hasResent: false
  };

  render() {
    const {
      id,
      name,
      email,
      initials,
      initialsColor,
      avatarUrl,
      updatedAtAgo,
      sharedClients
    } = this.props;
    const {
      invitationId,
      isPending,
      isDeleting,
      isResending,
      deleteClient,
      resendClientInvitation
    } = this.props;
    const hasOneSharedClient = sharedClients.length === 1;
    const hasMultipleSharedClients = sharedClients.length > 1;
    const [singleClient] = sharedClients;

    return (
      <Container>
        <Card title={email}>
          <Card.Body
            as={isPending ? "div" : Link}
            to={isPending ? undefined : `/clients/${id}`}>
            <Grid>
              <Cell>
                <Avatar
                  initials={initials}
                  initialsColor={initialsColor}
                  avatarUrl={avatarUrl}
                  size="md"
                />
              </Cell>
              <Cell grow>
                <Details>
                  <Name data-testid="derp">{name || email}</Name>
                  <Timestamp>
                    {isPending
                      ? "Has not signed up"
                      : updatedAtAgo || "No activity yet"}
                  </Timestamp>
                </Details>
              </Cell>
            </Grid>
          </Card.Body>
          <Card.Footer>
            <Controls>
              {!isPending && hasMultipleSharedClients && (
                <PokerChips clients={sharedClients} />
              )}
              {!isPending && hasOneSharedClient && (
                <SharedClient to={`/clients/${singleClient.id}`}>
                  <SharedClientImage aria-hidden>
                    <Avatar
                      initials={singleClient.initials}
                      initialsColor={singleClient.initialsColor}
                      avatarUrl={singleClient.avatarUrl}
                      size="xxs"
                    />
                  </SharedClientImage>
                  <SharedClientName>{singleClient.name}</SharedClientName>
                </SharedClient>
              )}
              {isPending && (
                <Pending>
                  Invitation Pending.{" "}
                  <SharedClientResend
                    aria-label="Re-send Invitation"
                    disabled={isResending || this.state.hasResent}
                    onClick={() =>
                      resendClientInvitation({ clientId: id, invitationId })
                    }>
                    {isResending && "Resending..."}
                    {this.state.hasResent && "Resent!"}
                    {!isResending && !this.state.hasResent && "Resend"}
                  </SharedClientResend>
                </Pending>
              )}
              <MenuContainer>
                <Menu>
                  <MenuButton aria-label="More Options">
                    <IconMoreHoriz aria-hidden />
                  </MenuButton>
                  <Menu.List offset={12.5}>
                    <MenuItemDelete
                      onClick={() => deleteClient({ clientId: id })}>
                      Delete Client
                    </MenuItemDelete>
                  </Menu.List>
                </Menu>
              </MenuContainer>
            </Controls>
          </Card.Footer>
        </Card>
        {isDeleting && <Overlay />}
      </Container>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isResending && !this.props.isResending) {
      this.setState({ hasResent: true }, async () => {
        await timer(1000);
        this.setState({ hasResent: false });
      });
    }
  }
}

ClientCard.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  initials: PropTypes.string.isRequired,
  initialsColor: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string.isRequired,
  updatedAtAgo: PropTypes.string.isRequired,
  sharedClients: PropTypes.array.isRequired,
  invitationId: PropTypes.string.isRequired,
  isPending: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool,
  isResending: PropTypes.bool,
  hasResent: PropTypes.bool,
  hasResendError: PropTypes.bool,
  deleteClient: PropTypes.func.isRequired,
  resendClientInvitation: PropTypes.func.isRequired
};

export default ClientCard;
