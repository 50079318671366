import { connect } from "react-redux";
import { uploadUserAvatar } from "../../../actions/user";
import UserAvatar from "../UserAvatar";

function select({
  user: {
    initials,
    initialsColor,
    avatar,
    avatarNetwork: { isFetching }
  }
}) {
  return {
    initials,
    initialsColor,
    avatar,
    isFetching
  };
}

const actions = {
  uploadUserAvatar
};

export default connect(select, actions)(UserAvatar);
