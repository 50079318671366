import { connect } from "react-redux";
import { getStreamItems } from "../../../actions/stream";
import { getListingsFromForge } from "../../../actions/listing";
import StreamItemsPagination from "../StreamItemsPagination";

function select({
  stream: {
    itemsCount,
    listingsCount,
    filters,
    page,
    limit,
    filter,
    isEditingCriteria
  },
  listings: { isLoading }
}) {
  const count = isEditingCriteria ? listingsCount : itemsCount;
  const hasItems = !!count;

  return {
    count,
    hasItems,
    filters: Object.values(filters.byId),
    page,
    limit,
    filter,
    isEditingCriteria,
    isLoading
  };
}

const actions = {
  getStreamItems,
  getListingsFromForge
};

export default connect(select, actions)(StreamItemsPagination);
