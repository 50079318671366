import { connect } from "react-redux";
import { emitAction } from "../../../utils/redux";
import { setPrimaryAlertType } from "../../../actions/stream";
import {
  inviteClients,
  addClientsToCuratedStream
} from "../../../actions/client";
import StreamModal from "../StreamModal";

function select(
  {
    user: { id: userId, isAgent, isClient, primaryAgent },
    subscriptions,
    stream: {
      primaryAlertType,
      isShowingStreamModal,
      isShowingNestedModal,
      streamModalOpenedViaSave
    },
    invitations,
    network
  },
  { streamId }
) {
  const clientSubscriptions = Object.values(subscriptions.byId)
    .filter((subscription) => subscription.userId !== userId) // remove the agent's subscription
    .filter((sub) => sub.streamId === streamId); // get only subscriptions for this stream
  const hasInvitations =
    Object.values(invitations.byId).filter(
      (invitation) => invitation.streamId === streamId
    ).length > 0;
  const hasSubscription = clientSubscriptions.length > 0;
  const { hasLoaded: inviteClientsResolved = false } =
    network.INVITE_CLIENTS || {};
  const { hasLoaded: addClientsToCuratedStreamResolved = false } =
    network.ADD_CLIENTS_FOR_CURATION || {};
  const agentId = isAgent ? userId : (primaryAgent || {}).id;

  return {
    isAgent,
    agentId,
    isClient,
    streamId,
    hasInvitations,
    hasSubscription,
    primaryAlertType,
    isShowingStreamModal,
    isShowingNestedModal,
    inviteClientsResolved,
    streamModalOpenedViaSave,
    addClientsToCuratedStreamResolved
  };
}

const actions = {
  setPrimaryAlertType,
  inviteClients,
  addClientsToCuratedStream,
  emitAction
};

export default connect(select, actions)(StreamModal);
