export function transformHelpers(helpers) {
  return {
    states: (helpers.states || []).reduce(
      (states, [label, value]) => ({ ...states, [value]: { label, value } }),
      {}
    ),
    timezones: (helpers.time_zones || []).reduce(
      (timezones, timezone) => ({
        ...timezones,
        [timezone]: { label: timezone, value: timezone }
      }),
      {}
    )
  };
}
