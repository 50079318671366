import axios from "axios";
import {
  buildIsEnabledUrl,
  buildShowingTimeUrl,
  isShowingTimeEnabled
} from "showing-time-client";
import {
  getForgeListings,
  getForgeListingsByIds,
  getForgePinsByMapBounds
} from "../modules/forge";
import { transformListings, transformListing } from "../modules/listing";
import { transformPins } from "../modules/mapping";
import * as listingApi from "../api/listing";

export function getListings({ mlsCode, ids }) {
  return async (dispatch, getState) => {
    const {
      application: { forgeToken },
      mls
    } = getState();

    dispatch({ type: "GET_LISTINGS_INITIATED" });

    try {
      const {
        mls: {
          listings: { results }
        }
      } = await getForgeListingsByIds({ ids, mlsCode, forgeToken });
      const transformedListings = transformListings({ listings: results, mls });
      const listingIds = Object.keys(transformedListings);

      dispatch({
        type: "GET_LISTINGS_SUCCEEDED",
        payload: { listings: transformedListings, listingIds }
      });
    } catch (error) {
      dispatch({ type: "GET_LISTINGS_FAILED", payload: { error } });
    }
  };
}

export function getListingsFromForge({ filter, page, limit }) {
  return async (dispatch, getState) => {
    const {
      application: { forgeToken },
      user: { mlsCode },
      mls
    } = getState();

    dispatch({ type: "GET_FORGE_LISTINGS_INITIATED" });

    try {
      // We always want to send the active status for mls
      const hasStatusFilter = filter.find(
        (filter) => filter.field === "status"
      );
      const modifiedFilters = [...filter].concat(
        !hasStatusFilter ? { field: "status", eq: mls.status.byId.active } : []
      );
      const {
        mls: {
          listings: { count, results }
        }
      } = await getForgeListings({
        filter: modifiedFilters,
        mlsCode,
        forgeToken,
        page,
        limit
      });
      const transformedFilters = modifiedFilters.reduce(
        (filters, filter) => ({ ...filters, [filter.field]: filter }),
        {}
      );
      const transformedListings = transformListings({ listings: results, mls });
      const listingIds = Object.keys(transformedListings);

      dispatch({
        type: "GET_FORGE_LISTINGS_SUCCEEDED",
        payload: {
          listings: transformedListings,
          listingsCount: count,
          listingIds,
          filtersHash: transformedFilters,
          page,
          limit
        }
      });
    } catch (error) {
      dispatch({ type: "GET_FORGE_LISTINGS_FAILED", payload: { error } });
    }
  };
}

export function getForgeListing(id) {
  return async (dispatch, getState) => {
    const {
      application: { forgeToken },
      mls,
      user: { mlsCode }
    } = getState();

    dispatch({ type: "GET_LISTING_INITIATED" });
    try {
      const {
        mls: {
          listings: { results }
        }
      } = await getForgeListingsByIds({ ids: [id], forgeToken, mlsCode });
      const listing = results[0] || {};

      dispatch({
        type: "GET_LISTING_SUCCEEDED",
        payload: transformListing({ listing, mls })
      });
    } catch (error) {
      dispatch({
        type: "GET_LISTING_FAILED",
        payload: { error, errorsArray: (error.response || {}).errors || [] }
      });
    }
  };
}

export function getPinsFromForge({ mapBounds, precision }) {
  return async (dispatch, getState) => {
    const {
      application: { forgeToken },
      user: { mlsCode },
      mls
    } = getState();

    dispatch({ type: "GET_FORGE_PINS_INITIATED" });

    try {
      const {
        mls: {
          listings: { pins }
        }
      } = await getForgePinsByMapBounds({
        mapBounds,
        precision,
        mlsCode,
        forgeToken
      });
      dispatch({
        type: "GET_FORGE_PINS_SUCCEEDED",
        payload: transformPins({ pins, mls })
      });
    } catch (error) {
      dispatch({ type: "GET_FORGE_PINS_FAILED", payload: { error } });
    }
  };
}

export function getListingsForPin(ids) {
  return async (dispatch, getState) => {
    const {
      application: { forgeToken },
      user: { mlsCode },
      mls
    } = getState();

    dispatch({ type: "GET_LISTINGS_FOR_PIN_INITIATED" });

    try {
      const {
        mls: {
          listings: { results }
        }
      } = await getForgeListingsByIds({ ids, mlsCode, forgeToken });
      const transformedListings = transformListings({ listings: results, mls });
      dispatch({
        type: "GET_LISTINGS_FOR_PIN_SUCCEEDED",
        payload: { listings: transformedListings }
      });
    } catch (error) {
      dispatch({ type: "GET_LISTINGS_FOR_PIN_FAILED", payload: { error } });
    }
  };
}

export function getShowingTimeUrl(id) {
  return async (dispatch, getState) => {
    const {
      listings,
      user: { mlsCode, mlsName }
    } = getState();
    const listing = listings.byId[id] || {};
    const {
      mlsnum,
      officeId,
      agentIdFromMls,
      statusLabel,
      address,
      city,
      zip,
      price
    } = listing;

    const agentId = agentIdFromMls,
      agentOfficeId = officeId;

    dispatch({ type: "GET_SHOWING_TIME_URL_INITIATED" });

    try {
      const isEnabledUrl = buildIsEnabledUrl({
        mlsCode,
        mlsName,
        mlsnum,
        agentId: mlsName,
        agentOfficeId,
        statusLabel
      });
      const isEnabledResponse = await axios.get(isEnabledUrl);
      const isEnabled = isShowingTimeEnabled(isEnabledResponse.data);

      if (isEnabled) {
        const showingTimeUrl = buildShowingTimeUrl({
          mlsCode,
          mlsName,
          mlsnum,
          requestingAgentId: mlsName,
          listingAgentId: agentId,
          agentOfficeId,
          statusLabel,
          address,
          city,
          zip,
          price
        });
        dispatch({
          type: "GET_SHOWING_TIME_URL_SUCCEEDED",
          payload: { id, showingTimeUrl }
        });
      } else {
        dispatch({
          type: "SHOWING_TIME_URL_IS_NOT_ENABLED",
          payload: isEnabledResponse
        });
      }
    } catch (error) {
      dispatch({ type: "GET_SHOWING_TIME_URL_FAILED", payload: error });
    }
  };
}

export function setFullscreenMediaDetails({ id, open, tab, photoIndex }) {
  return {
    type: "SET_LISTING_FULLSCREEN_MEDIA_DETAILS",
    payload: { id, open, tab, photoIndex }
  };
}

export function requestShowing({ streamItemId, listingId, phone, message }) {
  return async (dispatch, getState) => {
    const { application, user } = getState();
    const [agent] = user.agents || [];

    dispatch({ type: "REQUEST_SHOWING_INITIATED" });

    try {
      const requestDetails = await listingApi.requestShowing({
        railsApiToken: application.railsApiToken,
        clientId: user.id,
        agentId: agent.id,
        streamItemId,
        listingId,
        phone,
        message
      });
      dispatch({
        type: "REQUEST_SHOWING_SUCCEEDED",
        payload: requestDetails
      });
    } catch (error) {
      dispatch({
        type: "REQUEST_SHOWING_FAILED",
        payload: error.message
      });
    }
  };
}

export function resetRequestShowing() {
  return { type: "RESET_REQUEST_SHOWING" };
}
