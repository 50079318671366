import { getStreamSubscriptionAPI } from "../modules/railsApi";
import {
  transformStreamSubscription,
  transformStream
} from "../modules/stream";

export function getStreamSubscription(subscriptionId) {
  return async (dispatch, getState) => {
    const {
      application: { railsApiToken },
      mls
    } = getState();

    dispatch({ type: "GET_STREAM_SUBSCRIPTION_INITIATED" });

    try {
      const subscription = await getStreamSubscriptionAPI({
        subscriptionId,
        railsApiToken
      });
      const transformedSubscription = transformStreamSubscription(subscription);
      const { stream } = transformStream({ stream: subscription.stream, mls });

      dispatch({
        type: "GET_STREAM_SUBSCRIPTION_SUCCEEDED",
        payload: {
          stream,
          subscription: transformedSubscription
        }
      });
    } catch (error) {
      dispatch({
        type: "GET_STREAM_SUBSCRIPTION_FAILED",
        payload: {
          error,
          errorsArray: (error.response || {}).errors || []
        }
      });
    }
  };
}
