import styled from "styled-components";
import { default as BaseSelect } from "../../select/Select";
import {
  Selected,
  SelectedItemInput,
  Input,
  Menu
} from "../../select/styled/select";

export const Container = styled.form`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  svg {
    margin-left: 1rem;
    fill: ${({ theme }) => theme.colors.streamsBlue};
  }
`;

export const Select = styled(BaseSelect)`
  border: 0;
  background-color: transparent;

  ${Selected} {
    padding: 0;
  }

  ${SelectedItemInput} {
    margin: 0;
  }

  ${Input} {
    ${({ theme }) => theme.inputs.base}
    margin-top: 0.6rem;
  }

  ${Menu} {
    margin-top: 0.4rem;
  }
`;

export const IndicatorLayout = styled.div`
  position: absolute;

  top: 1.2rem;
  right: 0.6rem;
`;
