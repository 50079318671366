import {
  ApplicationSwitcher,
  Navbar,
  NavbarAdditionalItems,
  NavbarHelpLink,
  NavbarNavigationBar,
  NavbarUserMenu
} from "@helix/navbar";
import { Link } from "@wrstudios/components";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

function getCasDomain() {
  switch (window.location.host) {
    case "app.staging.cloudstreams.net":
      return "https://staging.cloudagentsuite.com";
    case "app.cloudstreams.net":
      return "https://cloudagentsuite.com";
    default:
      return "http://localhost:3400";
  }
}

export default function LegacyNavigation({
  casJwt,
  userId,
  userName,
  userAvatar,
  userEmail,
  casUserId,
  mlsCode,
  isAuthed,
  showLikedNavLink,
  isImpersonating,
  adminUrl,
  logout
}) {
  const { data: appSwitcher } = useAppSwitcher({
    id: userId,
    email: userEmail,
    casUserId,
    mlsCode,
    casJwt
  });

  return (
    <Navbar
      dataLwtId="default"
      applicationName={!appSwitcher?.enabled ? "Cloud Streams" : undefined}>
      {isAuthed && (
        <>
          {appSwitcher?.enabled && (
            <ApplicationSwitcher
              applicationName="Cloud Streams"
              appSections={[
                {
                  sectionName: "Promote",
                  sectionItems: appSwitcher.products.map((product) => ({
                    selected: product.key === "cloud_streams",
                    icon:
                      product.key === "cloud_cma"
                        ? "compare"
                        : product.key === "cloud_streams"
                        ? "alert"
                        : product.key === "cloud_mlx"
                        ? "search"
                        : product.key === "cloud_attract"
                        ? "agent"
                        : product.key === "launchpad"
                        ? "cards"
                        : undefined,
                    label: product.name || "N/A",
                    description: product.tagline || "",
                    onClick:
                      product.key !== "cloud_cma"
                        ? () => product.url && window.open(product.url, "_self")
                        : undefined
                  }))
                }
              ]}
            />
          )}
          <NavbarNavigationBar
            navItems={[
              { label: "Activity", url: "/activity" },
              { label: "Streams", url: "/streams" },
              { label: "Clients", url: "/clients" },
              showLikedNavLink ? { label: "Liked", url: "/liked" } : undefined
            ].filter(Boolean)}
            linkWrapper={({ item }) => <Link to={item.url}>{item.label}</Link>}
          />
          <NavbarAdditionalItems>
            <NavbarHelpLink
              url="https://lonewolf.my.site.com/s/topic/0TO2L0000008JRFWA2/cloud-streams"
              linkWrapper={({ url, label }) => (
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {label}
                </a>
              )}
            />
            <NavbarUserMenu
              id="avatar"
              user={{
                name: userName,
                email: userEmail,
                avatarURL: userAvatar
              }}
              menuItems={[
                { label: "Account Settings", url: "/settings" },
                { label: "Manage Notifications", url: "/subscriptions" },
                { divider: true },

                {
                  label: "Lead Generation",
                  url: "/settings/lead_generation"
                },
                { divider: true },
                {
                  label: "Learning Resources",
                  url: "https://learn.cloudagentsuite.com/videos-streams"
                },
                {
                  label: "Support Center",
                  url: "https://lonewolf.my.site.com/s/topic/0TO2L0000008JRFWA2/cloud-streams"
                },
                { divider: true },
                {
                  label: isImpersonating ? "Back to your account" : "Logout",
                  url: "#logout"
                }
              ].filter((item) => item && !!Object.keys(item).length)}
              linkWrapper={({ item }) => {
                if (item.url === "#logout") {
                  return (
                    <a
                      href={item.url}
                      onClick={(e) => {
                        e.preventDefault();
                        logout();

                        if (isImpersonating) {
                          window.location.href = adminUrl;
                        }
                      }}>
                      {item.label}
                    </a>
                  );
                }

                const isExternal = [
                  "Learning Resources",
                  "Support Center"
                ].includes(item.label);

                if (isExternal) {
                  return (
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noreferrer noopener">
                      {item.label}
                    </a>
                  );
                }

                return <Link to={item.url}>{item.label}</Link>;
              }}
            />
          </NavbarAdditionalItems>
        </>
      )}
    </Navbar>
  );
}

LegacyNavigation.propTypes = {
  casJwt: PropTypes.string,
  userId: PropTypes.string,
  userName: PropTypes.string,
  userAvatar: PropTypes.string,
  userEmail: PropTypes.string,
  casUserId: PropTypes.string,
  mlsCode: PropTypes.string,
  isAuthed: PropTypes.bool,
  showLikedNavLink: PropTypes.bool.isRequired,
  isImpersonating: PropTypes.bool,
  adminUrl: PropTypes.string
};

function useAppSwitcher(currentUser) {
  const currentUserRef = useRef(currentUser);
  const [data, setData] = useState();

  useEffect(() => {
    currentUserRef.current = currentUser;
  });

  useEffect(() => {
    const currentUser = currentUserRef.current;

    fetch(`${getCasDomain()}/graphql/protected`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${currentUser.casJwt}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        operationName: "ProductAvailability",
        query: /* GraphQL */ `
          mutation ProductAvailability($user: ExternalUserInputType!) {
            userProductAvailability(external_user: $user) {
              enabled: enable_app_switcher
              products {
                key
                name
                tagline
                url: switch_route
              }
            }
          }
        `,
        variables: {
          user: {
            application_key: "cloud_streams",
            id: currentUser.id,
            email: currentUser.email,
            cas_user_id: currentUser.casUserId,
            mls_code: currentUser.mlsCode
          }
        }
      })
    })
      .then((res) => res.json())
      .then((res) => {
        const productsWithLaunchpad = res.data.userProductAvailability.products;
        const enableLaunchpad = !!currentUser?.casUserId;

        if (enableLaunchpad) {
          productsWithLaunchpad.push({
            key: "launchpad",
            name: "Launchpad",
            tagline: "Learn and manage billing",
            url: `${getCasDomain()}/launchpad`
          });
        }

        setData({
          enabled: res.data.userProductAvailability.enabled,
          products: productsWithLaunchpad.map((product) => ({
            ...product,
            url: `${product.url}?jwt=${currentUser.casJwt}`
          }))
        });
      })
      .catch(() => {
        setData({ enabled: false, products: [] });
      });
  }, []);

  return { data };
}
