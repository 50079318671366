import { connect } from "react-redux";
import { getParsedQueryString } from "@wrstudios/utils";
import { handleAppMount, login } from "../../../actions/app";
import { setInvitationAcceptanceCredentials } from "../../../actions/user";
import { emailFrequencies } from "../../stream/invite/utils";
import App from "../App";

function select({
  application: { isAppScrollable, railsApiToken, forgeToken }
}) {
  const queryString = getParsedQueryString();
  const isAuthed = !!railsApiToken && !!forgeToken;

  return {
    isAuthed,
    isAppScrollable,
    urlToken: queryString.token || "",
    isNewUser: queryString.new_user === "true",
    isSSOUser: queryString.sso_user === "true",
    clientId: queryString.client_id,
    agentId: queryString.agent_id,
    invitationGuid: queryString.invitation_guid,
    emailFrequency: queryString.email_frequency || emailFrequencies.REAL_TIME,
    streamSubscriptionId: queryString.stream_subscription_id,
    isImpersonating: !!queryString.impersonate
  };
}

const actions = {
  handleAppMount,
  setInvitationAcceptanceCredentials,
  login
};

export default connect(select, actions)(App);
