import styled from "styled-components";
import { Container as StreamItemLike } from "./stream-item-like";
import { Container as StreamItemComment } from "./stream-item-comment";
import { Container as StreamItemShare } from "./stream-item-share";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  > * + * {
    margin-left: 2rem;
  }

  ${StreamItemLike},
  ${StreamItemComment} {
    opacity: ${({ isHidden }) => (isHidden ? 0.5 : 1)};
  }

  ${StreamItemShare} {
    margin-left: auto;
  }
`;
