import { connect } from "react-redux";
import { replaceStreamFilters } from "../../../../actions/stream";
import StreamFilterLocations from "../StreamFilterLocations";

function select({
  mls,
  stream: {
    filters: {
      byId: { city, zip, area, location = {}, ...filters }
    }
  }
}) {
  const parsedLocations = JSON.parse(location.within || "[]");
  const customAreas = parsedLocations.map((polygon, index) => ({
    key: "location",
    label: `Custom Area ${index + 1}`,
    within: polygon
  }));
  const customAreasLabels = customAreas.map(
    (_, index) => `Custom Area ${index + 1}`
  );
  const hasCustomAreasLabels = !!customAreasLabels.length;

  // prettier-ignore
  const selectedLocations = hasCustomAreasLabels ? customAreasLabels : [
    ...(city ? city.should : []),
    ...(zip ? zip.should : []),
    ...(area ? area.should : [])
  ]

  return {
    filters,
    locations: mls.locations,
    locationLabels: mls.locationsLabels,
    customAreas,
    selectedLocations
  };
}

const actions = {
  replaceStreamFilters
};

export default connect(select, actions)(StreamFilterLocations);
