import styled from "styled-components";

export const Container = styled.form`
  ${({ theme }) => theme.inputs.base};
  width: ${({ isShowingInput }) => (!isShowingInput ? "100%" : "auto")};
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  border-color: ${({ theme, isFocused }) =>
    isFocused ? theme.colors.streamsBlue : theme.colors.greyLight};
`;

export const Slider = styled.div`
  ${({ theme }) => theme.removeScrollbar};
  display: flex;
  align-items: center;
`;

export const Items = styled.ol`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0 0.15rem 0 -0.3rem;
  padding: 0;
  list-style: none;
`;

export const Item = styled.li`
  flex-shrink: 0;
  margin: 0 0.3rem;
  padding: 0.2rem 0.5rem 0.2rem 0.9rem;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.streamsBlue};
  border-radius: 0.4rem;
`;

export const Remove = styled.button`
  margin-left: 0.5rem;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline-color: ${({ theme }) => theme.colors.red};
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export const Input = styled.input`
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0.2rem 0;
  border: 0;
  outline: none;
`;
