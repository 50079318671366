import { buildRailsApiClient } from "./";

const completeCriteriaOnboardingMutation = /* GraphQL */ `
  mutation completeCriteriaOnboardingMutation(
    $id: ID!
    $criteria_onboarding: Boolean
  ) {
    updateUser(id: $id, criteria_onboarding: $criteria_onboarding) {
      criteria_onboarding
    }
  }
`;

export async function completeCriteriaOnboarding({ railsApiToken, userId }) {
  const railsApiClient = buildRailsApiClient(railsApiToken);
  // We use false to denote a completed onboarding step. Ask yourself "Should the user see this?".
  const variables = { id: userId, criteria_onboarding: false };

  return railsApiClient.request(completeCriteriaOnboardingMutation, variables);
}
