import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { isEqual } from "lodash";
import qs from "qs";
import StreamSignupModal from "../../stream/connected/StreamSignupModal";
import StreamRouteNewEditStep from "./StreamRouteNewEditStep";
import StreamRouteNewLocationStep from "./StreamRouteNewLocationStep";

class StreamRouteNew extends Component {
  render() {

    // Has the new stream been created? If so, redirect to that stream.
    if (this.props.hasSaved && this.props.hasNewStreamId) {
      return <Redirect to={`/streams/${this.props.newStreamId}`} />;
    }

    // Do we have filters? Let's show the search results (and map) if so.
    if (this.props.hasFilters || this.props.hasFiltersFromParams) {
      return (
        <>
          {this.props.isNewUser && <StreamSignupModal />}
          <StreamRouteNewEditStep />
        </>
      );
    }

    // Show the location step if we don't have filters yet.
    return <StreamRouteNewLocationStep />;
  }

  componentDidMount() {
    this.handleNewLocation();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.filters, this.props.filters)) {
      this.props.history.push({
        search: qs.stringify({ criteria: Object.values(this.props.filters) })
      });
    }
  }

  componentWillUnmount() {
    this.props.setStreamIsCreating(false);
  }

  handleNewLocation = () => {
    if (this.props.hasFiltersFromParams) {
      this.props.setStreamFilters(this.props.filtersFromParams);
    }
  };
}

StreamRouteNew.propTypes = {
  newStreamId: PropTypes.string.isRequired,
  hasNewStreamId: PropTypes.bool.isRequired,
  hasFilters: PropTypes.bool.isRequired,
  hasSaved: PropTypes.bool.isRequired,
  isCreating: PropTypes.bool.isRequired,
  isNewUser: PropTypes.bool.isRequired,
  setStreamIsCreating: PropTypes.func.isRequired,
  setStreamFilters: PropTypes.func.isRequired
};

export default StreamRouteNew;
