import React from "react";

function IconCheck(props) {
  return (
    <svg width="14" height="12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2 7l3 3m7-8l-7 8"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="square"
      />
    </svg>
  );
}

export default IconCheck;
