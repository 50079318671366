import React from "react";

function IconViewCircle(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      {...props}>
      <g fill="#00C9C7" fillRule="evenodd" clipRule="evenodd">
        <path d="M24 48a24 24 0 100-48 24 24 0 000 48z" opacity=".1" />
        <path d="M24 15c-5.31 0-10.81 4.1-12.84 8.63a.9.9 0 000 .74C13.19 28.91 18.69 33 24 33s10.81-4.1 12.84-8.63a.9.9 0 000-.74C34.81 19.09 29.31 15 24 15zm0 5.61a3.25 3.25 0 100 6.5 3.25 3.25 0 000-6.5zm-4.75 3.25a4.75 4.75 0 119.5 0 4.75 4.75 0 01-9.5 0z" />
      </g>
    </svg>
  );
}

export default IconViewCircle;
