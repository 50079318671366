import { initialState } from "../reducers";

const STORAGE_KEY = "cloudStreams";

export function readFromStorage() {
  try {
    const localStorageStore = JSON.parse(
      window.localStorage.getItem(STORAGE_KEY) || "{}"
    );
    const isImpersonating =
      (localStorageStore.user || {}).isImpersonating || false;

    if (isImpersonating) {
      const sessionStorageStore = JSON.parse(
        window.sessionStorage.getItem(STORAGE_KEY) || "{}"
      );

      return sessionStorageStore;
    } else {
      return localStorageStore;
    }
  } catch (error) {
    return {};
  }
}

export function writeToStorage(store) {
  const { application, user, stream, listings } = store.getState();

  try {
    const storage = user.isImpersonating
      ? window.sessionStorage
      : window.localStorage;

    storage.setItem(
      STORAGE_KEY,
      JSON.stringify({
        application: {
          ...initialState.application,
          railsApiToken: application.railsApiToken,
          forgeToken: application.forgeToken
        },
        stream: {
          ...initialState.stream,
          limit: stream.limit,
          filter: stream.filter
        },
        listings: {
          ...initialState.listings,
          zoomLevel: listings.zoomLevel
        },
        user: {
          ...initialState.user,
          isSSOUser: user.isSSOUser
        }
      })
    );
  } catch (error) {
    console.error(error);
  }
}

export function removeFromStorage(fromSessionStorage = false) {
  fromSessionStorage
    ? window.sessionStorage.removeItem(STORAGE_KEY)
    : window.localStorage.removeItem(STORAGE_KEY);
  fromSessionStorage
    ? window.sessionStorage.removeItem("token")
    : window.localStorage.removeItem("token");
}
