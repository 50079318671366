import styled from "styled-components";
import { Container as Input } from "./input";

export const Container = styled.div`
  margin-bottom: 1.4rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${Input} {
    width: 100%;
  }
`;
