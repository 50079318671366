import styled from "styled-components";
import { Link } from "@wrstudios/components";

export const Container = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-size: 1.3rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.colors.streamsBlue};
  letter-spacing: 0;
  cursor: pointer;

  svg {
    width: 1.8rem;
    height: 1.8rem;
    fill: ${({ theme }) => theme.colors.streamsBlue};
    margin-right: 0.8rem;
  }
`;
