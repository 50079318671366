import { connect } from "react-redux";
import {
  sendUserPhoneVerificationCode,
  verifyUserPhoneVerificationCode,
  resetUserPhoneVerification
} from "../../../actions/user";
import UserPhoneVerify from "../UserPhoneVerify";

function select(
  {
    user: {
      phoneVerificationCode,
      phoneVerifyNetwork,
      phoneCodeVerifyNetwork,
      isAgent,
      name,
      agents
    }
  },
  { showVerifyCodeStep }
) {
  const hasPhoneVerificationCode = !!phoneVerificationCode;
  const fetchingVerifyPhone = phoneVerifyNetwork.isFetching;
  const fetchingVerifyCode = phoneCodeVerifyNetwork.isFetching;
  const fetchedVerifyCode = phoneCodeVerifyNetwork.hasFetched;
  const showVerifyPhone =
    !phoneVerifyNetwork.hasFetched &&
    !hasPhoneVerificationCode &&
    !showVerifyCodeStep;
  const showVerifyCode =
    (showVerifyCodeStep ||
      phoneVerifyNetwork.hasFetched ||
      hasPhoneVerificationCode) &&
    !phoneCodeVerifyNetwork.hasFetched;
  const showSuccess =
    phoneVerifyNetwork.hasFetched && phoneCodeVerifyNetwork.hasFetched;
  const verifyPhoneErrors = phoneVerifyNetwork.errors;
  const verifyCodeErrors = phoneCodeVerifyNetwork.errors;
  const hasVerifyPhoneErrors = !!verifyPhoneErrors.length;
  const hasVerifyCodeErrors = !!verifyCodeErrors.length;
  const agentName = isAgent ? name : agents[0].name;

  return {
    phoneVerificationCode,
    fetchingVerifyPhone,
    fetchingVerifyCode,
    fetchedVerifyCode,
    showVerifyPhone,
    showVerifyCode,
    showSuccess,
    verifyPhoneErrors,
    verifyCodeErrors,
    hasVerifyPhoneErrors,
    hasVerifyCodeErrors,
    agentName
  };
}

const actions = {
  sendUserPhoneVerificationCode,
  verifyUserPhoneVerificationCode,
  resetUserPhoneVerification
};

export default connect(select, actions)(UserPhoneVerify);
