import React from "react";
import PropTypes from "prop-types";
import ListingCard from "../listing/connected/ListingCard";
import StreamItemControls from "./StreamItemControls";
import StreamItemStatus from "./StreamItemStatus";
import { Container } from "./styled/stream-item";

function StreamItem(props) {
  const handleMouseEnter = () => props.setListingUnderPointer(props.itemId);
  const handleMouseLeave = () => props.setListingUnderPointer(null);

  // Only one type for now.
  switch (props.type) {
    case "Listing":
      return (
        <Container>
          <ListingCard
            url={props.url}
            listingId={props.itemId}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            isHidden={props.isHidden}
            footer={
              <StreamItemControls
                streamItemId={props.streamItemId}
                isHidden={props.isHidden}
                isClient={props.isClient}
                isAgent={props.isAgent}
              />
            }
          />
          <StreamItemStatus
            isLiked={props.isLiked}
            isRecommended={props.isRecommended}
          />
        </Container>
      );
    default:
      return null;
  }
}

StreamItem.propTypes = {
  type: PropTypes.string,
  url: PropTypes.string,
  itemId: PropTypes.string,
  streamItemId: PropTypes.string,
  isHidden: PropTypes.bool,
  isRecommended: PropTypes.bool,
  isLiked: PropTypes.bool,
  isClient: PropTypes.bool,
  isAgent: PropTypes.bool,
  setListingUnderPointer: PropTypes.func
};

export default StreamItem;
