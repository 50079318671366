import { RouteContainer } from "@wrstudios/components";
import PropTypes from "prop-types";
import React from "react";
import Header from "../../root/connected/Header";
import {
  Container,
  Paragraph,
  SubTitle,
  Title,
  Underlined
} from "./styled/developers";

function Developers({ isAuthed }) {
  return (
    <Container>
      {!isAuthed && <Header />}
      <RouteContainer>
        <Title>Cloud Streams API</Title>
        <Paragraph>
          Cloud Streams allows real estate agents to easily create beautiful
          listing alerts. In order to enable this functionality to be embedded
          in other web sites, especially MLS sites, we have made an API publicly
          available. Using the simple links and images below, you can send the
          current listing query directly to Cloud Streams to initiate the new
          stream creation process for the agent.
        </Paragraph>
        <Paragraph>
          Cloud Streams is a subscription-based service and requires an id and
          password to use. All uses of the API will work immediately if the user
          is already logged into a session. If a current session does not exist,
          Cloud Streams will handle the authentication by requesting the user
          log in first, then automatically route them to the requested page from
          there. Developers who need a trial id and password can request one
          from the e-mail at the bottom of this page.
        </Paragraph>
        <Paragraph>
          We also offer Single Sign On options for MLSs and Brokers that want a
          more seemless experience. SSO eliminates the login step above.
        </Paragraph>
        <Paragraph isLast={true}>
          More functionality of Cloud Streams can be exposed pretty easily using
          standard RESTful web services - send over your suggestions.
        </Paragraph>
        <SubTitle>Create a New Stream</SubTitle>
        <Underlined>Use case:</Underlined>
        <Paragraph>
          Your user has entered listing search criteria on your site and you
          provide them with a one-click solution to send that criteria to Cloud
          Streams and start the new stream creation process.
        </Paragraph>
        <Underlined>Method:</Underlined>
        <Paragraph>
          Just do a very simple GET or POST to our RESTful API from a text or
          image link.
        </Paragraph>
        <Underlined>Parameter notes:</Underlined>
        <Paragraph>
          <strong>city</strong> - (Optional) An array of city names.
        </Paragraph>
        <Paragraph>
          <strong>zip</strong> - (Optional) An array of zip codes.
        </Paragraph>
        <Paragraph>
          <strong>area</strong> - (Optional) An array of area names.
        </Paragraph>
        <Paragraph>
          <strong>prop_type</strong> - (Optional) An array of property type
          names.
        </Paragraph>
        <Paragraph>
          <strong>prop_sub_type</strong> - (Optional) An array of property sub
          types.
        </Paragraph>
        <Paragraph>
          <strong>min_price</strong> - (Optional) Minimum price.
        </Paragraph>
        <Paragraph>
          <strong>max_price</strong> - (Optional) Maximum price.
        </Paragraph>
        <Paragraph>
          <strong>min_beds</strong> - (Optional) Minimum number of bedrooms.
          Note: there is no max beds - the more the merrier.
        </Paragraph>
        <Paragraph>
          <strong>min_baths</strong> - (Optional) Minimum number of baths. Note:
          there is no max baths - the more the merrier.
        </Paragraph>
        <Paragraph>
          <strong>min_sqft</strong> - (Optional) Minimum number of square feet.
          Note: there is no max sqft - the more the merrier.
        </Paragraph>
        <Underlined>Example request:</Underlined>
        <Paragraph isLast={true}>
          http://cloudstreams.net/streams/new?city[]=Huntington+Beach&city[]=Costa+Mesa
          &prop_type[]=Residential&prop_sub_type[]=Single+Family+Residential
          &min_price=850000&max_price=990000&min_beds=3
        </Paragraph>
        <Title>Cloud Streams and MLS Security</Title>
        <Paragraph>
          Cloud Streams allows real estate agents to easily create beautiful
          listing alerts. We aggregate a subset of the MLS database and
          synchronize the listing data at set intervals. We take great care to
          minimize the queries to the RETS server and ensure that we get only
          recently updated listings, but no more than that.
        </Paragraph>
        <Paragraph isLast={true}>
          When a new user sets up their profile in Cloud Streams, they are asked
          to choose their MLS, then they are prompted for their MLS credentials.
          Whenever the user creates a new stream, we first verify that they are
          an active member of the MLS. The active agent data comes from the
          Agent resource of your RETS server. It is kept in sync multiple times
          a day.If the users MLS credentials are invalid, the user will be
          notified and not allowed to create the new stream. In addition, their
          current streams will no longer update with fresh listing data, and
          listing updates will stop being sent to their clients.
        </Paragraph>
        <Title>Cloud Streams Single Sign-On</Title>
        <Paragraph>
          We offer two 'Single Sign On' methods, which are especially good for
          MLSs that have a site license where every member has free access to
          Cloud Streams. In this scenario, users cannot log in directly at
          cloudstreams.net, but instead must first log into their MLS system and
          click on a button or link to access Cloud Streams.
        </Paragraph>
        <Paragraph>
          We have our own encrypted form of SSO where parameters are passed in
          the url - the MLS code, the users id, a secure hash, and a timestamp.
          Details of this algorithm can be provided on request. We have several
          MLSs using this method, it works well, and it's very simple to
          implement.
        </Paragraph>
        <Paragraph>
          We also support the SAML standard for SSO and are currently SAML
          certified with several providers. We are set up to easily add new SAML
          providers.
        </Paragraph>
        <Paragraph isLast={true}>
          SSO works especially well when the MLS implements the API for passing
          listing criteria to automatically create new streams. This allows for
          a deep integration between the MLS system and Cloud Streams, requiring
          no additional login by the user and making it seem like one seamless
          system.
        </Paragraph>
        <Title>Questions or suggestions?</Title>
        <Paragraph>
          E-mail questions to{" "}
          <a href="mailto:dan@cloudstreams.net">dan@cloudstreams.net</a>.
        </Paragraph>
      </RouteContainer>
    </Container>
  );
}

Developers.propTypes = {
  isAuthed: PropTypes.bool
};

export default Developers;
