import React from "react";
import PropTypes from "prop-types";
import ActivityRecommend from "./activities/connected/ActivityRecommend";
import ActivityLike from "./activities/connected/ActivityLike";
import AcitivityView from "./activities/connected/ActivityView";
import ActivityComment from "./activities/connected/ActivityComment";
import ActivitySMS from "./activities/connected/ActivitySMS";
import ActivityEmail from "./activities/connected/ActivityEmail";
import { Container } from "./styled/activity";

function Activity({ activityId, type }) {
  return (
    <Container>
      {type === "recommend" && <ActivityRecommend activityId={activityId} />}
      {type === "like" && <ActivityLike activityId={activityId} />}
      {type === "view" && <AcitivityView activityId={activityId} />}
      {type === "comment" && <ActivityComment activityId={activityId} />}
      {type === "sms" && <ActivitySMS activityId={activityId} />}
      {type === "email" && <ActivityEmail activityId={activityId} />}
    </Container>
  );
}

Activity.propTypes = {
  activityId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default Activity;
