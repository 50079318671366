import React from "react";
import PropTypes from "prop-types";
import { Container } from "./styled/tab-panel";

function TabPanel({ tabId, tabPanelId, isPanelActive, children }) {
  return (
    <Container
      id={tabPanelId}
      role="tabpanel"
      aria-labelledby={tabId}
      aria-hidden={!isPanelActive}
      isActive={isPanelActive}
      tabIndex="0">
      {children}
    </Container>
  );
}

TabPanel.propTypes = {
  tabId: PropTypes.string,
  tabPanelId: PropTypes.string,
  isPanelActive: PropTypes.bool
};

export default TabPanel;
