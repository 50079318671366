import React from "react";
import PropTypes from "prop-types";
import IconRecommend from "../icons/IconRecommend";
import IconHeart from "../icons/IconHeart";
import {
  RecommendedContainer,
  LikedContainer
} from "./styled/stream-item-status";

function StreamItemStatus({ isRecommended, isLiked }) {
  return (
    <>
      {isRecommended && (
        <RecommendedContainer>
          <IconRecommend width="23" height="23" />
        </RecommendedContainer>
      )}
      {isLiked && (
        <LikedContainer>
          <IconHeart />
        </LikedContainer>
      )}
    </>
  );
}

StreamItemStatus.propTypes = {
  isLiked: PropTypes.bool,
  isRecommended: PropTypes.bool
};

export default StreamItemStatus;
